import React, { useMemo } from "react";

export default ({ day, onChangeDay }) => {
  const dayNames = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  const days = dayNames.map((day, index) => {
    return { id: (index+1)%7, name: day };
  });

  return (
    <div className="form-time-wrapper">
      <div className="form-group select optional booking_day">
        <label className="control-label select optional">Day</label>
        <select className="form-control select optional day"
                name="booking[day]"
                value={day}
                onChange={(e) => onChangeDay(e.target.value)}
                style={{ width: 70 }}>
          {days.map((entry, i) => <option key={entry.id} value={entry.id}>{entry.name}</option>)}
        </select>
      </div>
    </div>
  )
}
