import React, {useState} from 'react'
import ErrorMessage from "./ErrorMessage";
import MultiSelect from "./MultiSelectOptions";

export default ({ options, selectedOptions, onChange }) => {
  return (
    <>
      <MultiSelect
        value={selectedOptions}
        options={options}
        onChange={onChange}
        getOptionLabel={opt => opt.label}
        getOptionValue={opt => opt.value}
        isOptionDisabled={() => selectedOptions.length >= (options.length - 1)}
      />

      {selectedOptions.map((option, index) => {
        return (
          <input
            key={index}
            name="question[option_trigger_sub_questions][]"
            value={option.value}
            type="hidden"
          />
        )
      })}

      {selectedOptions.length === 0 &&
        <input name="question[option_trigger_sub_questions]" type="hidden"/>
      }
    </>
  )
};
