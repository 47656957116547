import React from 'react';
import ErrorMessage from "./ErrorMessage";

export default ({ defaultValue, name, label, errors }) => (
  <div className="form-group text required question_text">
    <label className="control-label text required">
      {label} <abbr title="required">*</abbr>
    </label>

    <textarea
      className="form-control text required"
      required="required"
      aria-required="true"
      name={name}
      defaultValue={defaultValue}
      rows="8"
    />

    <ErrorMessage errors={errors}/>
  </div>
)
