import React, { lazy, Suspense } from 'react'
import ReactDOM from 'react-dom'

const LAZY_COMPONENTS = [
  { id: 'dashboard_component', load: () => import('../components/Dashboard/index') },
  { id: 'report_component', load: () => import('../components/Reports/Index') },
  { id: 'admin_venue_schedule_items_component', load: () => import('../components/VenueScheduleItems/Index') },
  { id: 'booking_form_component', load: () => import("src/components/BookingForm") },
  { id: 'report_schedules_component', load: () => import("src/components/ReportSchedules/Index") },
]

import ReportSchedules from '../components/ReportSchedules/Index';
import StaffRota from '../components/StaffRota/Index';

const COMPONENTS = [
  { id: 'report_schedules_component', Component: ReportSchedules },
  { id: 'staff_rota_component', Component: StaffRota }
]

document.addEventListener('DOMContentLoaded', () => {
  LAZY_COMPONENTS.forEach(({ id, load }) => {
    const container = document.getElementById(id);
    if (container) {
      const Component = lazy(load);
      ReactDOM.render(
        <Suspense fallback={<div/>}>
          <Component/>
        </Suspense>, container
      );
    }
  });

  COMPONENTS.forEach(({ id, Component }) => {
    const container = document.getElementById(id);
    if (container) {
      ReactDOM.render(<Component/>, container);
    }
  })
})
