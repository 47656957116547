import SmartyForm from "../../helpers/smarty_form";

(function() {
  $(function() {
    let $new_venue_note_modal, showVenueNoteModal, saveVenueNote, reloadData;

    $new_venue_note_modal = $('#admin_orders_modal');

    showVenueNoteModal = function (link) {
      $.ajax({
        type: "GET",
        url: link,
        async: true,
        success: function (data) {
          $new_venue_note_modal.find('.modal-content').empty().append(data);
          $new_venue_note_modal.modal('show');
        },
        error: function (data) {
          $new_venue_note_modal.find('.modal-content').empty();
          $new_venue_note_modal.modal('show');
        }
      });
    };

    saveVenueNote = function (e) {
      e.attr('disabled', true);
      e.find("input[type=text], textarea").prop('readonly', true);
      let $form = e.closest('form');
      let note = $form.find('#order_venue_note_note').val()
      let send_venue_note_url = e.attr('href') + '?' + 'note=' + note
      $.ajax({
        type: "POST",
        url: send_venue_note_url,
        async: false,
        success: function () {
          $new_venue_note_modal.find('.modal-content').empty();
          reloadData(false);
          $new_venue_note_modal.modal('hide');
        },
        error: function (response) {
          new SmartyForm().addErrors($form, 'order_venue_note', (response.responseJSON || {}).errors)
        }
      });
    };

    reloadData = function(change_page) {
      let orders_url = $('#orders-table').data('source') + '?' + $("form#new_filter").serialize()
      let ajax = $('#orders-table').DataTable().ajax
      ajax.url(orders_url)
      ajax.reload(null, change_page)
    }

    $(document).on('click', '.dropdown-menu li a.open_venue_note_modal', function (e) {
      e.preventDefault();
      showVenueNoteModal($(this).attr('href'));
    });

    $(document).on('click', '#send_venue_note', function (e) {
      e.preventDefault();
      saveVenueNote($(this))
    });
  });
}).call(this);