export function searchAddressAutocomplete(input, onPlaceChanged) {
  const optionsForAutocomplete = {
    language: 'en-GB',
    componentRestrictions: {
      country: "uk"
    },
    fields: ["geometry", 'name'],
  };

  const autocomplete = new google.maps.places.Autocomplete(input, optionsForAutocomplete);
  autocomplete.addListener("place_changed", () => {
    const place = autocomplete.getPlace();

    if (!place.geometry || !place.geometry.location) {
      onPlaceChanged(null)
      console.log("No details available for input: '" + place.name + "'");
      return;
    }

    onPlaceChanged(place)
  });

  autocomplete.addListener("focus", () => { console.log('focus') })
  autocomplete.addListener("blur", () => { console.log('blur') })
  autocomplete.addListener("changed", () => { console.log('blur') })

  return autocomplete;
}