import axios from "axios";

const Facilities = (function() {
  let refreshCalendarFn = null;

  function init(refreshCalendar) {
    refreshCalendarFn = refreshCalendar;

    $('#facility_id_').multiselect({
      enableClickableOptGroups: true,
      enableCollapsibleOptGroups: true,
      onChange: () => refreshCalendar(),
      buttonText: (options) => (options.length === 0 || options.val() === '') ? 'All' : options.length + ' selected'
    });

    $('#facility_id_').on('change', function() {
      refreshCalendar()
    });

    $('#venue_id').on('change', function (e) {
      const venueId = e.target.value;
      onVenueIdUpdate(venueId);
    })

    onVenueIdUpdate($('#venue_id').val())
  };

  function onVenueIdUpdate(venueId) {
    axios.get(`/admin/calendar/facilities.json?venue_id=${venueId}`).then((resp) => {
      calendarFilterReplaceFacilities(resp.data)
    })
  }

  function calendarFilterReplaceFacilities(facilities) {
    const sel = $('<select/>', {
      id: "facility_id_",
      multiple: "multiple",
      class: "facility_id_filter form-control input-sm",
      name: 'facility_id[]'
    });

    (facilities || []).forEach(item => {
      if (item.childFacilities.length === 0) {
        let normalOption = $('<option/>', {
          class: "normal-facility-option",
          value: item.id,
          text: item.internalReference
        });

        sel.append(normalOption);
      } else {
        let optGroup = $('<optgroup/>', { label: item.internalReference });

        item.childFacilities.forEach(childFacility => {
          let normalOption = $('<option/>', {
            value: `${item.id}:${childFacility.id}`,
            text: childFacility.internalReference
          });

          optGroup.append(normalOption);
        })

        sel.append(optGroup);

        let fakeOptGroup = $('<optgroup/>', { label: '', class: 'hidden' });
        sel.append(fakeOptGroup);
      }
    });

    $('#facility_id_').multiselect('destroy');
    $('#facility_id_').parent().replaceWith(sel);
    initFacilityMultiselect()
  }

  function initFacilityMultiselect() {
    $('#facility_id_').multiselect({
      enableClickableOptGroups: true,
      includeSelectAllOption: true,
      selectAllText: 'All',
      onChange: () => {
        $('#facility_id_').next().removeClass('open');
        refreshCalendarFn()
      },
      buttonText: (options) => (options.length === 0 || options.val() === '') ? 'All' : options.length + ' selected',
      onInitialized: (select, container) => {
        const el = $(select).next();
        $(el).on('change', 'a.multiselect-all input[type=checkbox]', function() {
          $(el).find('a.multiselect-all input[type=checkbox]').click();
          $(el).removeClass('open');
          setTimeout(refreshCalendarFn, 200);
        })
      }
    });
  }

  return {
    init: init,
  };
})();

export default Facilities;