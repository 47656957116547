import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import DropDown from "./DropDown";

const incomeTypes = [
  { title: 'Projected %', value: 'projected' },
  { title: 'Total value', value: 'total_value' }
];

export default ({ onChanged }) => {
  const [incomeType, setincomeType] = useState(incomeTypes[0]);

  useEffect(() => {
    const params = incomeType ? { incomeType: incomeType.value } : null;
    onChanged({ value: incomeType, pending: false, params });
  }, [incomeType]);

  return (
    <div className={classNames(['filter-block filter-report-types'])}>
      <label htmlFor="filter-income-types">Type:</label>
      <div className='input-box input-box-react-select'>
        <DropDown
          value={incomeType}
          options={incomeTypes}
          onChange={setincomeType}
          getOptionLabel={opt => opt.title}
          getOptionValue={opt => opt.value}
        />
      </div>
    </div>
  )
};
