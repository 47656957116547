import { v4 as uuidv4 } from 'uuid';

class NeoModal {
  static add(data, options = {}) {
    const callback = options.callback;
    const contentDiv = document.getElementById('neo_modals');

    const parser = new DOMParser();
    const parsedHTML = parser.parseFromString(data, 'text/html');
    const firstDiv = parsedHTML.querySelector('div');

    const randomUUID = uuidv4();

    if (firstDiv)
      firstDiv.setAttribute('modal_id', randomUUID);

    contentDiv.appendChild(parsedHTML.body);

    if (callback) { callback() }

    return document.querySelector('div[modal_id="' + randomUUID + '"]');
  }
}

export default NeoModal;
