import React, { useState } from 'react';
import CheckboxOption from "./CheckboxOption";
import ErrorMessage from "./ErrorMessage";

export default ({ defaultChecked, label, questionDocument, errors, name }) => {
  const [enabled, setEnabled] = useState(!!defaultChecked);

  return (
    <>
      <div className='form-group checkbox'>
        <CheckboxOption
          label={label}
          defaultChecked={defaultChecked}
          onChange={(e) => setEnabled(e.target.checked)}
        />
      </div>
      {enabled && questionDocument &&
        <div>
          <a href={questionDocument.downloadFileLink}>{questionDocument.title}</a>
        </div>
      }
      {enabled && !questionDocument &&
      <div style={{ marginLeft: 20 }}>
        <div className="form-group text">
          <label className="control-label text required">Title</label>

          <input
            className="form-control text required"
            name={`${name}[question_document_attributes][title]`}
            defaultValue={(questionDocument || {}).title}
          />
          <ErrorMessage errors={(errors || {})['question_document.title']}/>
        </div>

        <div className="form-group file optional">
          <label className="control-label file optional">File</label>
          <input className="file optional" type="file" name={`${name}[question_document_attributes][file]`}/>
          <ErrorMessage errors={(errors || {})['question_document.file']}/>
        </div>
      </div>
      }

      <input type="hidden" name={`${name}[question_document_attributes][id]`} defaultValue={(questionDocument || {}).id}/>
      <input type="hidden" value={!enabled ? '1' : '0'} name={`${name}[question_document_attributes][_destroy]`}/>
    </>
  )
};