export const handleReceived = (message, onSuccess, onFailed) => {
  switch (message.status) {
    case 'succeeded':
      onSuccess(message.id);
      break;
    case 'failed':
      onFailed();
      break;
    case 'pending':
      console.log('The task added to queue. [status: pending]');
      break;
    case 'in_progress':
      console.log('The task added to queue. [status: in_progress]');
      break;
    default:
      console.log(`Unknown status: ${message.status}`);
      break;
  }
};