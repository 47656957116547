// axios

window.ProfileClass = function() {
  function changeNotificationState(event) {
    event.preventDefault();

    const el = $(event.target);
    const form = el.closest('form');

    $.ajax({
      type: 'PUT',
      url: form.attr('action'),
      dataType: 'html',
      data: form.serializeArray(),
      success: () => Helper.flash_message('success', 'Successfully updated'),
      error: () => Helper.flash_message('error', "Couldn't update")
    });
  }

  function changeAllNotificationStates(enabled) {
    $('.venue_user_notify_by_email input[type=checkbox]').prop('checked', enabled);

    $.ajax({
      method: 'PUT',
      url: '/admin/users/notification_availability',
      data: { enabled: enabled },
      success: () => Helper.flash_message('success', 'Successfully updated'),
      error: () => Helper.flash_message('error', "Couldn't update")
    });
  }

  return {
    changeAllNotificationStates: changeAllNotificationStates,
    changeNotificationState: changeNotificationState
  }
}

window.Profile = new ProfileClass();




