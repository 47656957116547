function Multiple() {
  const show = function(event) {
    const $target = $(event.target);
    const $bookingsBlock = $target.closest('#bookings-block');

    $bookingsBlock.find('.edit-remove-multiple').hide();
    $bookingsBlock.find('.action_block').hide();
    $bookingsBlock.find('.multiple-modification').show();

    if ($(window).width() < 800) {
      $bookingsBlock.find('.add-session-btn-wrapper').hide()
    }
  }

  const hide = function(event) {
    const $target = $(event.target);
    const $bookingsBlock = $target.closest('#bookings-block');

    $bookingsBlock.find('.edit-remove-multiple').show();
    $bookingsBlock.find('.action_block').show();
    $bookingsBlock.find('.multiple-modification').hide();
    $bookingsBlock.find('.multiple-modification input[type=checkbox]').prop('checked', 0);
    clicked = false
    $bookingsBlock.find('.check-uncheck-btn').text('Select all')

    if ($(window).width() < 800) {
      $bookingsBlock.find('.add-session-btn-wrapper').show()
    }
  }

  const selectAll = function(event) {
    const checked = $(event.target).is(':checked')
    $('.multiple-check').prop('checked', checked);
  }

  var clicked = false;

  const selectAllMobile = function(event) {
    clicked = $('.multiple-check:checked').length === $('.multiple-check').length
    $(".multiple-check").prop("checked", !clicked);
    clicked = !clicked;
    $(".check-uncheck-btn").text(clicked ? 'Unselect all' : 'Select all');
  }

  $(document).on('change', function(event) {
    if($('.multiple-check:checked').length === $('.multiple-check').length) {
      $(".check-uncheck-btn").text('Unselect all');
      clicked = true
    } else {
      $(".check-uncheck-btn").text('Select all');
      clicked = false
    }
  })

  return {
    show: show,
    hide: hide,
    selectAll: selectAll,
    selectAllMobile: selectAllMobile,
  }

}


window.multiple = Multiple();