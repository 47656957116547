class Notice {
  constructor() { }

  start() {
    if ($('body.venues_show, body.venues_standalone, body.facilities_show, body.facilities_standalone').length < 1) {
      return
    }

    if ($('input#is_new_message').val() === 'new') {
      $('a#new_message').click();
    }

    if ($('#standalone-message-show-modal').length > 0) {
      this.showModal();
    }
  }

  showModal() {
    const $box = $('#standalone-message-show-modal');
    const venueID = $box.data('venue');
    const cookie = Cookies.get('standaloneMessage');

    if (cookie === void 0) {
      $box.modal('show');

      Cookies.set('standaloneMessage', [venueID]);
    } else {
      const venues = JSON.parse(cookie);

      if (venues.indexOf(venueID) === -1) {
        $box.modal('show');
        venues.push(venueID);

        Cookies.set('standaloneMessage', venues);
      }
    }
  }
}

export default Notice;