class InfoPage {
  constructor() { }

  start() {
    if ($('body.admin.admin_settings_info_pages_index').length < 1) {
      return;
    }

    $('.action_block .publish').click((event) => this.publishClick(event));
  }

  publishClick(event) {
    const $publishForm = $('#publish_info_page_form');
    const $target = $(event.currentTarget);

    if ($publishForm.length < 1) {
      return;
    }

    const data = {};
          data.publish_type = $target.attr('id');
          data.id = $target.val();
          data.checked = $target.prop('checked');

    $.ajax({
      type: 'POST',
      url: $publishForm.attr('action'),
      data: data,
      dataType: 'json',

      success: (data) => {
        $target.attr('checked', data.checked);
      },

      error: () => { }
    });
  }
}

export default InfoPage;
