window.createInternalOrderModal = function(facility_code, date) {
  const link = '/admin/internal_order';
  const form = $('form.calendar-form');
  const params = form.serialize();

  const $admin_internal_order_modal = $('#admin_internal_order_modal');

  $.get(link, params, function(data) {
    $admin_internal_order_modal.find('.modal-content').empty().append(data);
    $('#internal_order_venue_id').val($('.calendar-form select#venue_id').val());
    $admin_internal_order_modal.modal('show');
  });
};

$(document).on('click', '#admin_internal_order_modal form [type=submit]', function(e) {
  e.preventDefault();
  var $form = $(this).closest('form');
  var url = $form.attr('action');

  $.ajax({
    type: 'POST',
    url: url,
    data: $form.serialize(),
    success: function(data) {
      window.location = data.redirect_to;
    },
    error: function(data) {
      $('#admin_internal_order_modal').find('.modal-content').empty().append(data.responseText);
    }
  });
});
