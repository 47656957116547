window.updateConversationsCounter = function(data) {
  const { venues, inbox } = data;

  let counter = 0;
  counter += inbox;

  const badge = $('[id^=conversation_inbox_venue_] span.badge');
  badge.hide();
  badge.html(0);

  _.mapObject(venues, function (unread_count, venue_id) {
    let inboxBadge, inboxEl;
    counter += unread_count;
    inboxEl = $("#conversation_inbox_venue_" + venue_id);
    inboxBadge = inboxEl.find('span.badge');
    inboxBadge.html(unread_count);
    inboxBadge.toggle(!(unread_count === 0));
  });

  if ($("#conversation_inbox_current_user").length > 0) {
    const span = $("#conversation_inbox_current_user").find('span.badge');
    span.html(inbox);
    span.toggle(!(inbox === 0));
  }

  const showCounter = !(counter === 0);

  const headCounters = document.querySelectorAll('[data-messages_counter-target="messagesLink"] span');
  headCounters.forEach(headCounter => {
    headCounter.innerHTML = counter.toString();

    if (showCounter) {
      headCounter.classList.remove('hidden'); // Add 'show' class
    } else {
      headCounter.classList.add('hidden'); // Remove 'show' class
    }
  });
}

