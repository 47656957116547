import React, { useEffect, useState } from 'react';
import Search from "./Filter/Search";
import Venue from "./Filter/Venue";
import TimePeriod from "./Filter/TimePeriod";
import ViewMode from "./Filter/ViewMode";

export default ({ ready, setReady,
                  term, setTerm,
                  venueId, setVenueId,
                  viewMode, setViewMode,
                  timePeriod, setTimePeriod,
                  startDate, setStartDate,
                  endDate, setEndDate,
                  location }) => {

  const [venueFilterReady, setVenueFilterReady] = useState(false);

  useEffect(()=> { setReady(venueFilterReady) }, [venueFilterReady])

  return (
    <>
      <div className='filter-input-group'>
        <Search
          term={term}
          setTerm={setTerm}
        />

        <Venue
          venueId={venueId}
          setVenueId={setVenueId}
          ready={venueFilterReady}
          setReady={setVenueFilterReady}
          location={location}
        />
      </div>

      <ViewMode
        viewMode={viewMode}
        setViewMode={setViewMode}
      />

      <TimePeriod
        timePeriod={timePeriod}
        setTimePeriod={setTimePeriod}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
      />
    </>
  )
};