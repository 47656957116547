import { elementStyles } from "./elementStyles";

export default function stripeForm({ stripe, onSubmit, onDisabledInputs, onEnabledInputs }) {
  const stripeElements = stripe.elements({
    fonts: [{ cssSrc: 'https://fonts.googleapis.com/css?family=Quicksand' }],
    locale: 'en',
  });

  const elementClasses = { focus: 'focus', empty: 'empty', invalid: 'invalid' };
  const elementOptions = { style: elementStyles, classes: elementClasses };
  const cardNumber = stripeElements.create('cardNumber', elementOptions);
  const cardExpiry = stripeElements.create('cardExpiry', elementOptions);
  const cardCvc = stripeElements.create('cardCvc', elementOptions);

  cardNumber.mount(`#stripe-card-number`);
  cardExpiry.mount(`#stripe-card-expiry`);
  cardCvc.mount(`#stripe-card-cvc`);
  
  const elements = [cardNumber, cardExpiry, cardCvc];
  const example = document.querySelector(`#stripe-modal`);
  const form = example.querySelector('form.stripe-box-form');
  const error = form.querySelector('.error');
  const submit = form.querySelector('button[type="submit"]');

  const resetForm = () => {
    form.reset();
    elements.forEach(element => element.clear());
    error.classList.remove('visible');
    enableInputs();
  }

  const enableInputs = () => {
    form.querySelector("button[type='submit']").removeAttribute('disabled');
    Array.prototype.forEach.call(
      form.querySelectorAll("input[type='text'], input[type='email'], input[type='tel']"),
      (input) => input.removeAttribute('disabled')
    );

    if (onEnabledInputs) onEnabledInputs();
  }

  const disableInputs = () => {
    form.querySelector("button[type='submit']").setAttribute('disabled', 'disabled');
    Array.prototype.forEach.call(
      form.querySelectorAll("input[type='text'], input[type='email'], input[type='tel']"),
      (input) => input.setAttribute('disabled', 'true')
    );

    if (onDisabledInputs) onDisabledInputs();
  }

  const triggerBrowserValidation = () => {
    let submit = document.createElement('input');
    submit.type = 'submit';
    submit.style.display = 'none';
    form.appendChild(submit);
    submit.click();
    submit.remove();
  }

  form.addEventListener('submit', (e) => {
    e.preventDefault();

    if (e.target.disabled) return;

    var plainInputsValid = true;

    Array.prototype.forEach.call(form.querySelectorAll('input'), (input) => {
      if (input.checkValidity && !input.checkValidity()) {
        plainInputsValid = false;
        return;
      }
    });

    if (!plainInputsValid) {
      triggerBrowserValidation();
      return;
    }

    disableInputs();

    handleSubmit();
  });

  const handleSubmit = () => {
    const name = form.querySelector('#stripe-card-name');
    const zip = form.querySelector('#stripe-card-zip');

    const additionalData = {
      name: name ? name.value : undefined,
      address_zip: zip ? zip.value : undefined,
    };

    onSubmit(cardNumber, additionalData);
  }

  addErrorsHandler(form, elements)

  return {
    resetForm: resetForm,
    enableInputs: enableInputs,
    disableInputs: disableInputs
  }
}

function addErrorsHandler(form, elements) {
  const savedErrors = [];
  const error = form.querySelector('.error');
  const errorMessage = error.querySelector('.message');

  elements.forEach((element, idx) => {
    element.on('change', (event) => {
      if (event.error) {
        error.classList.add('visible');
        savedErrors[idx] = event.error.message;
        errorMessage.innerText = event.error.message;
      } else {
        savedErrors[idx] = null;

        let nextError = Object
          .keys(savedErrors)
          .sort()
          .reduce((maybeFoundError, key) => maybeFoundError || savedErrors[key], null);

        if (nextError) {
          errorMessage.innerText = nextError;
        } else {
          error.classList.remove('visible');
        }
      }
    });
  });
}

