import { Controller } from "@hotwired/stimulus"

class SignOutController extends Controller {
  connect() {
    this.element.addEventListener('click', this.clearLocalStorage)
  }

  clearLocalStorage() {
    ['shiftOptions'].forEach((key) => localStorage.removeItem(key));
  }
}

export default SignOutController;
