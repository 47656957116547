window.adminSchedulesForm = function(scheduleType, area, disabledCheckboxes = false) {
  (function (scheduleType, area, disabledCheckboxes = false) {
    const checkbox = $(`#show_off_peak_${scheduleType}_prices`);
    const anyOffPeak = $(`div.area_${scheduleType}_off_peak_enabled input[type=checkbox]:checked`).length > 0;
    const wrapper = $(`#${scheduleType}_table_wrapper`);
    const toggleArea = () => $(`.area_${scheduleType}_off_peak_enabled`).toggle(checkbox.is(':checked'));
    const wrapperStatus = wrapper.data(`prev${area}Status`);

    checkbox.on('change', () => {
      wrapper.data(`prev${area}Status`, checkbox.is(':checked'));
      toggleArea();
    });

    if (disabledCheckboxes) {
      checkbox.prop('checked', anyOffPeak).prop('disabled', true);
    } else if (anyOffPeak) {
      checkbox.prop('checked', true).prop('disabled', true);
      wrapper.data(`prev${area}Status`, checkbox.is(':checked'));
    }

    if (wrapperStatus === true) checkbox.prop('checked', true);

    toggleArea();
  })(scheduleType, area, disabledCheckboxes);

  (function (scheduleType, area, disabledCheckboxes = false) {
    const checkbox = $(`#show_${scheduleType}_closed_hours`);
    const anyClosedHour = $(`tr.closed-${scheduleType}-hours-field`).length > 0;
    const wrapper = $(`#${scheduleType}_table_wrapper`);
    const statusName = `prevClosedHour${area}Status`;
    const toggleClosedArea = () => $(`.${scheduleType}-btn-add-close-hour-wrapper`).toggle(checkbox.is(':checked'));
    const wrapperStatus = wrapper.data(statusName);

    checkbox.on('change', () => {
      wrapper.data(statusName, checkbox.is(':checked'));
      toggleClosedArea();
    });

    if (disabledCheckboxes) {
      checkbox.prop('disabled', true).prop('checked', anyClosedHour);
    } else if (anyClosedHour) {
      checkbox.prop('checked', true).prop('disabled', true);
      wrapper.data(statusName, checkbox.is(':checked'));
    }

    if (wrapperStatus === true) checkbox.prop('checked', true);

    toggleClosedArea();
  })(scheduleType, area, disabledCheckboxes);

  $('.info-icon').each(function() {
    $(this).popover({
      html: true,
      trigger: "hover",
      placement: 'right',
      content: () => $(this).find('.popover-body').html()
    });
  });
}

