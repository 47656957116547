import React from "react";

export default ({ facilities, value, onChange }) => (
  <div className="form-group select optional booking_facility_id ">
    <label className="control-label select optional">Facility</label>
    <select className='form-control select optional facility_id'
            value={value || ''}
            name="booking[facility_id]"
            onChange={onChange}>
      {facilities.map((item, i) => (
        <option key={i} value={item.id}>
          {item.title}{item.soft_deleted ? ' (deleted)' : ''}
        </option>
      ))}
    </select>
  </div>
)
