import React from "react";
import classnames from 'classnames';
import Linkify from 'linkify-react';

export default ({ id, text,
                  allowComment,
                  allFacilities,
                  isVenue,
                  isUser,
                  multipleVenues,
                  canManage,
                  venuesCount,
                  facilitiesCount,
                  allowDocuments,
                  mandatoryDocuments,
                  allowOptions,
                  allowQuestionItems,
                  allowYesNoOptions,
                  questionItems,
                  questionDocument,
                  onUp, onDown,
                  allowReorder,
                  marker,
                  onRemove,
                  onEdit,
                  isChild }) => {

  let moveUpBtn = null;
  let moveDownBtn = null;

  if (onUp) {
    moveUpBtn = (
      <a className={classnames(['move-up', !onUp && 'disabled'])} onClick={() => onUp && onUp()}>
        <i className="fa fa-arrow-up"/>
      </a>
    )
  }

  if (onDown) {
    moveDownBtn = (
      <a className={classnames(['move-down', !onDown && 'disabled'])} onClick={() => onDown && onDown()}>
        <i className="fa fa-arrow-down"/>
      </a>
    )
  }

  return (
    <div data-id={id} style={{ paddingBottom: 15 }} className={isChild ? 'sub-question-item' : 'question'}>
      <div className='pull-right'  style={{ width: 380 }}>
        {!isChild &&
          <>
            {isVenue && !multipleVenues &&
            <div style={{ whiteSpace: 'nowrap', width: 190, display: 'inline-block' }}>
              {allFacilities ? 'All facilities' : facilitiesCount + (facilitiesCount > 1 ? ' facilities' : ' facility') }
            </div>
            }
            {multipleVenues && isUser &&
            <div style={{ whiteSpace: 'nowrap', width: 190, display: 'inline-block' }}>
              {venuesCount + (venuesCount > 1 ? ' venues' : ' venue') }
            </div>
            }
          </>
        }

        {!isChild && marker && <div style={{ whiteSpace: 'nowrap', width: 190, display: 'inline-block' }}>{marker}</div>}

        {(allowReorder || canManage) &&
        <div className="action_block">
          {!isChild &&
            <div>
              {allowReorder && moveUpBtn}
              {allowReorder && moveDownBtn}

              {canManage && <a onClick={onRemove}>Delete</a>}
              {canManage && <a onClick={onEdit}>Edit</a>}
            </div>
          }
        </div>
        }
      </div>

      <div className='question-text'>
        <h4>
          <Linkify options={{ target: '_blank' }}>{text}</Linkify>
        </h4>
      </div>
      <div className='form-group checkbox'>
        <label>
          <input type="checkbox" disabled="disabled" checked={allowOptions}/>
          Allow users to select answer from Yes/No/Not applicable options
        </label>
      </div>
      <div className='form-group checkbox'>
        <label>
          <input type="checkbox" disabled="disabled" checked={allowYesNoOptions}/>
          Allow users to select answer from Yes/No options
        </label>
      </div>
      <div className='form-group checkbox'>
        <label>
          <input type="checkbox" disabled="disabled" checked={allowDocuments}/>
          Allow users to upload documents
          {mandatoryDocuments &&
            <abbr style={{ paddingLeft: 5 }} title="required">*</abbr>
          }
        </label>
      </div>
      <div className='form-group checkbox'>
        <label>
          <input type="checkbox" disabled="disabled" checked={allowComment}/>
          Allow users to leave additional comments
        </label>
      </div>
      <div className='form-group checkbox'>
        <label>
          <input type="checkbox" disabled="disabled" checked={allowQuestionItems}/>
          Allow users to select from dropdown list
          {allowQuestionItems &&
          <ul>{(questionItems || []).map(questionItem => <li key={questionItem.id}>{questionItem.title}</li>)}</ul>
          }
        </label>
      </div>

      {questionDocument &&
        <div>
          <a href={questionDocument.downloadFileLink} target='_blank'>{questionDocument.title}</a>
        </div>
      }
    </div>
  )
}
