import React from "react";
import Select from "react-select";

export default ({ availableCcUsers, ccUsers, setCcUsers }) => (
  <Select isMulti='true'
          getOptionLabel={opt => opt.name}
          getOptionValue={opt => opt.id}
          options={availableCcUsers}
          value={ccUsers}
          onChange={setCcUsers}
  />
);
