export default function axn() {
  let subscription = null;

  function subscribe(id, onReceived) {
    subscription = App.cable.subscriptions.create(
      { channel: "ModificationChannel", id: id },
      {
        connected: () => subscription.send({}),
        received: (data) => {
          switch (data.status) {
            case 'succeeded':
            case 'failed':
              subscription.unsubscribe();
              onReceived(data)
              break;
            case 'request_action':
              onReceived(data)
              break;
            case 'pending':
              console.log("Pending.......................")
              break;
            default:
              console.log(`Unknown status: ${data.status}`)
              break;
          }
        }
      }
    );
  }

  return { subscribe: subscribe }
}