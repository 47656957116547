import React from "react";

export default ({ reportTypes, reportTypeKey, onSelectReportType }) => (
  <select name="report"
          className="form-control select required"
          value={reportTypeKey || ''}
          onChange={(e) => onSelectReportType(e.target.value)}>
    {reportTypes.map(item =>
      <option key={item.key} value={item.key}>{item.title}</option>
    )}
  </select>
)