import axios from 'axios';

$(document).on('click', '#open_resend_message_modal', function(e) {
  e.preventDefault()
  axios
    .get($(e.target).data('url'))
    .then(response => {
      $('#admin_orders_modal').find('.modal-content').empty().append(response.data)
      $('#admin_orders_modal').modal('show')
    })
    .catch((error) => {
      Helper.flash_message('error', error.response.data.error)
    });
});

$(document).on('click', '#resend_message_to_user', (e) => {
  e.preventDefault()

  const button = $(e.target);
  button.prop('disabled', true);
  $('.resend_message_message').removeClass('has-error');
  $('.resend_message_message').find('.text-danger').remove();

  axios
    .patch(button.data('url'), { message: $('#resend_message_message').val() } )
    .then(response => {
        button.prop('disabled', false)
        $('#admin_orders_modal').modal('hide')
        Helper.flash_message('success', 'Message sent')
    })
    .catch((error) => {
      button.prop('disabled', false)
      if (error.response.data.blank_message) {
        const html = '<span class="text-danger">' + error.response.data.blank_message + '</span>';
        $('.resend_message_message').addClass('has-error');
        $('.resend_message_message').append(html)
      } else {
        $('#admin_orders_modal').modal('hide')
        Helper.flash_message('error', error.response.data.error )
      }
    });
});


