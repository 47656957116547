import React, { useEffect, useState, useReducer } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Form from "./Form";
import ErrorBoundary from "../ErrorBoundary";

import { initialState, reducer } from './reducer';
import api from "src/components/ReportSchedules/Modal/api";

export default ({ id, show, setShow, onCompleted }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const [disabledConfirm, setDisabledConfirm] = useState(false);

  useEffect(() => {
    if (show !== true) {
      dispatch({ type: 'resetSchedule' });
    }
  }, [show])

  const handleCreate = () => {
    // dispatch start processing
    setDisabledConfirm(true);

    api
      .saveReportSchedule(id, state)
      .then(() => {
        setShow(false);
        onCompleted();
        setDisabledConfirm(false)
      })
      .catch((error) => {
        if (error?.response?.data?.errors) {
          dispatch({ type: 'setErrors', value: error.response.data.errors })
        }
        setDisabledConfirm(false)
      });
  }

  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{id === null || id === undefined ? 'New' : 'Edit'} Report Schedule</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ErrorBoundary>
          {show && <Form id={id} state={state} dispatch={dispatch}/>}
        </ErrorBoundary>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => setShow(false)}>Cancel</Button>
        <Button className='btn-primary'
                disabled={disabledConfirm}
                onClick={handleCreate}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  )
};
