import React from "react";
import moment from "moment";

export const dateFormatter = (date) => {
  if (!moment(date).isValid()) return null;
  return moment(date).format("DD/MM/YYYY");
};

export const dayDateFormatter = (date) => {
  if (!moment(date).isValid()) return null;
  return moment(date).format("ddd, D MMM YYYY");
};

export const timeFormatter = (date) => {
  if (!moment(date).isValid()) return null;
  return moment(date).format("HH:mm");
};

const Text = ({ align = 'left', style, children }) => (
  <td className={`text-${align}`} style={style}>
    {children}
  </td>
);

const Date = ({ children }) => {
  if (!moment(children).isValid()) return <td/>;
  return <td className='text-left'>{dateFormatter(children)}</td>;
};

const periodGenerator = (startKey, endKey, children, formatter, splitter) => {
  const validDates = [moment(children[startKey]), moment(children[endKey])].filter(date => date.isValid());
  const uniqDates = validDates.filter((v, i) => validDates.findIndex(m => v.isSame(moment(m))) === i);
  return uniqDates.map(date => formatter(date).toString()).join(splitter)
};

const DatePeriod = ({ startKey, endKey, children }) => (
  <td className='text-left'>{periodGenerator(startKey, endKey, children, dateFormatter, ' to ')}</td>
);

const TimeSlot = ({ startKey, endKey, style, children }) => (
  <td className='text-left' style={style}>{periodGenerator(startKey, endKey, children, timeFormatter, ' - ')}</td>
);

const currencyFormatter = (value) => {
  const settings = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
  return new Intl.NumberFormat('en-UK', settings).format(value);
};

export const toCurrency = (children, negativeBraces = false) => {
  if (isNaN(children)) return '';

  if (negativeBraces && children < 0) {
    return `(${currencyFormatter(Math.abs(children))})`;
  } else {
    return currencyFormatter(children);
  }
};

export const toPercent = (children, hideSign = false) => {
  if (isNaN(children)) return '';

  const settings = { minimumFractionDigits: 0, maximumFractionDigits: 2 };
  const value = new Intl.NumberFormat('en-UK', settings).format(children);
  const sign =  hideSign ? '' : '%'

  return`${value}${sign}`
}

export const toCurrencyOrBlank = (amount, negativeBraces = false) => {
  return amount === null ? '' : toCurrency(amount, negativeBraces);
}

const CurrencyOrBlank = ({ children, negativeBraces}) => (
  <td className='text-left'>
    {children === null ? '' : toCurrency(children, negativeBraces)}
  </td>
);

const Currency = ({ children, negativeBraces}) => <Cell>{toCurrency(children, negativeBraces)}</Cell>;
const Percent = ({ children, hideSign, style }) => <Cell style={style}>{toPercent(children, hideSign)}</Cell>;

export const linkFormatter = ({ link = null, target = null, children }) => {
  if (link) {
    return <a href={link} target={target}>{children}</a>
  } else {
    return children
  }
}

const Link = ({ align = 'left', link = null, target = null, style, children }) => (
  <td className={`text-${align}`} style={style}>
    {linkFormatter({ link, target, children })}
  </td>
);

const Column = {
  Text: Text,
  Link: Link,
  Date: Date,
  Currency: Currency,
  CurrencyOrBlank: CurrencyOrBlank,
  Percent: Percent,
  DatePeriod: DatePeriod,
  TimeSlot: TimeSlot
};

export default Column;

const LOCALE = 'en-UK';
const HOUR_SETTINGS = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
const PERCENT_SETTINGS = { minimumFractionDigits: 2, maximumFractionDigits: 2 }

const getNumberFormat = (settings) => new Intl.NumberFormat(LOCALE, settings)

export const HourValue = ({ value }) => getNumberFormat(HOUR_SETTINGS).format(value);
export const PercentValue = ({ value }) => getNumberFormat(PERCENT_SETTINGS).format(value);

export const Cell = ({ align = 'left', style, children }) => (
  <td className={`text-${align}`} style={style}>
    {children}
  </td>
);