import axios from 'axios';

(function() {
  $(function() {
    if ($('#members-table').length < 1) return;

    function reloadData(change_page) {
      var ajax = $('#members-table').DataTable().ajax;
      ajax.url(gerCustomersUrl());
      ajax.reload(null, change_page);
    }

    function gerCustomersUrl() {
      return $('#members-table').data('source') + '?' + $("form#new_filter").serialize();
    }

    $('#members-table').dataTable({
      order: [[0, "asc"]],
      processing: true,
      serverSide: true,
      searching: false,
      lengthChange: false,
      pageLength: 30,
      ajax: gerCustomersUrl(),
      pagingType: 'full_numbers',
      columnDefs: [
        {
          targets: 0,
          width: '10%'
        }, {
          targets: 6,
          width: '6%'
        }, {
          targets: 8,
          orderable: false
        }, {
          targets: 9,
          orderable: false
        }
      ],
      fnDrawCallback: (oSettings) => {
        const $table_wrapper = $(oSettings.nTableWrapper);
        const show = oSettings._iDisplayLength <= oSettings.fnRecordsDisplay();
        $table_wrapper.find('.dataTables_paginate').toggle(show);
        $table_wrapper.find('.dataTables_info').toggle(show);
        const $table = $table_wrapper.find('table');
        const titles = _.map($table.find('thead th'), (x) => x.innerText);

        _.each($table.find('tr'), (row) => {
          _.each($(row).find('td'), (cell, index) => {
            $(cell).attr('data-title', titles[index]);
          });
        });
      }
    });

    function setDisabled(disabled) {
      if (disabled) {
        $('.export-btn').attr('disabled', 'disabled');
      } else {
        $('.generation').removeClass('generation');
        $('.export-btn').attr('disabled', null);
      }
    }

    function prepareReport(e, name) {
      e.preventDefault();
      const filter = $("form#new_filter").serialize();

      if ($(e.target).prop('disabled')) return;

      const btn = $(e.target);
      btn.addClass('generation');
      setDisabled(true);

      axios
        .post(`/admin/members/csv.json?report_type=${name}&` + filter)
        .then(response => startWait(response.data.id))
        .catch(() => setDisabled(false));
    }

    var subscription;

    const handleReceived = (message) => {
      switch (message.status) {
        case 'succeeded':
          setDisabled(false);
          window.location.href = `/admin/reports/downloads/${message.id}`;
          break;
        case 'failed':
          setDisabled(false);
          Helper.flash_message('error', "Something went wrong!");
          break;
        case 'pending':
          console.log('The task added to queue. [status: pending]');
          break;
        case 'in_progress':
          console.log('The task added to queue. [status: in_progress]');
          break;
        default:
          console.log(`Unknown status: ${message.status}`);
          break;
      }
    };

    function startWait(id) {
      subscription = App.cable.subscriptions.create(
        { channel: "ReportFileGeneratorChannel", id: id },
        { received: (message) => handleReceived(message) }
      );
    }

    $('a#download_member_details').on('click', (e) =>  prepareReport(e, 'member_details'));
    $('a#download_member_visits').on('click', (e) => prepareReport(e, 'member_visits'));
    $('a#download_member_history').on('click', (e) => prepareReport(e, 'membership_histories'));

    $('#new_filter .filter_venue_id select, #new_filter .filter_membership_plan_id select').on('change', function() {
      reloadData(true);
    });

    $('#new_filter .filter_terms input').on('keyup', (e) => reloadData(true));
  });
}).call(this);