import axios from 'axios';

class AddVenues {
  constructor(onVenuesAdded) {
    this.onVenuesAdded = onVenuesAdded;
  }

  start() {
    $('#venue-users-add-venue').on('click', (e) => {
      const modal = $('#modal_venues');
      modal.modal('show');

      const regex = /user\[venue_users_attributes\]\[\d+\]\[venue_id\]/;

      let ids = [];
      $('.access_table_body').find('input').filter((index, el) => {
        if (regex.test($(el).attr('name'))) ids.push($(el).val());
      });

      axios
        .get(e.target.dataset.path, { params: { id: ids } })
        .then(({ data }) => modal.find('.modal-body').html(data));
    });

    $('#add_venues').on('click', () => {
      const modal = $('#modal_venues');
      const $form = $(modal.find('form'));

      axios
        .post($form.attr('action'), new FormData($form[0]))
        .then(response => {
          const regex = /user\[venue_users_attributes\]\[(\d+)\]/g;
          const regex2 = /user\[venue_users_attributes\]\[(\d+)\]\[venue_id\]/;
          const table = $('.access_table_body');

          let indexes = [];
          table.find('input').filter((index, el) => {
            const name = $(el).attr('name');
            if (regex2.test(name)) {
              const match = regex2.exec($(el).attr('name'));
              indexes.push(parseInt(match[1]))
            }
          });

          let lastIndex = 0;
          if (indexes.length !== 0) lastIndex = Math.max(...indexes) + 1;

          const newHtml = response.data.replace(regex, (match, index) => {
            const newIndex = parseInt(index) + lastIndex;
            return `user[venue_users_attributes][${newIndex}]`;
          });

          table.append(newHtml);
          modal.modal('hide');
          modal.find('.modal-body').html('')

          if (this.onVenuesAdded) this.onVenuesAdded();
        })
        .catch(() => Helper.flash_message('error', 'Something wrong happened'))
    });
  }
}

export default AddVenues;