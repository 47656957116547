const EventDate = (function() {
  function init(refreshCalendar) {
    $('#date').datepicker({
      format: 'D, dd M yyyy',
      weekStart: 1,
      autoclose: true
    });

    const momentDate = moment(null, "ddd, DD MMM YYYY");
    if (momentDate.isValid()) $('#date').datepicker("setDate", momentDate.toDate());

    $('#date').on('change', function() {
      refreshCalendar()
    });
  }

  return {
    init: init,
  };
})();

export default EventDate;