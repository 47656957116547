import React, { useState } from "react";
import TimePicker from "rc-time-picker";
import SmartTime from "./SmartTime";


export default (props) => {
  const [timeOption, setTimeOption] = useState(props.defaultTimeOption || 'flexible');
  const { name, index, defaultValue } = props;
  const { defaultMinutes, defaultDirection, defaultSession, defaultIncludeInternal, saveShiftOptions } = props;
  const { handleMinutesChange } = props;

  return (
    <>
      <div className='form-inline vert-offset-bottom-10'>
        <div className="radio occurrences">
          <label>
            <input type="radio"
                   value="fixed"
                   checked={timeOption === 'fixed'}
                   name={`staff_item[${index}][${name}_time_option]`}
                   onChange={()=>setTimeOption('fixed')}
                   style={{ marginRight: 10 }}/>

            <TimePicker
              className='staff-item-time-picker'
              name={`staff_item[${index}][${name}_time]`}
              defaultValue={defaultValue}
              showSecond={false}
              inputReadOnly
              disabled={timeOption !== 'fixed'}
              minuteStep={5}
              allowEmpty={false}
            />
          </label>
        </div>
      </div>

      <div className='form-inline vert-offset-bottom-10'>
        <div className="radio">
          <label>
            <input
              type="radio"
              value="flexible"
              checked={timeOption === 'flexible'}
              onChange={()=>setTimeOption('flexible')}
              name={`staff_item[${index}][${name}_time_option]`}
              style={{ marginRight: 10 }}
            />
            <SmartTime
              index={index}
              name={name}
              defaultMinutes={defaultMinutes}
              defaultDirection={defaultDirection}
              defaultSession={defaultSession}
              defaultIncludeInternal={defaultIncludeInternal}
              disabled={timeOption !== 'flexible'}
              handleMinutesChange={handleMinutesChange}
            />
          </label>
        </div>
      </div>
    </>
  )
}