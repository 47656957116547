import { Controller } from "@hotwired/stimulus"

export default class InboxUnreadCountController extends Controller {
  connect() {
    document.addEventListener('messenger:inbox:unread_count', (event) => {
      this.element.style.display = event.detail.unreadCount > 0 ? 'block' : 'none';
      this.element.textContent = event.detail.unreadCount;
    })
  }
}
