import axios from 'axios';
import SmartyForm from '../../../../src/nextgen/helpers/smarty_form';

$(document).on('click', '.calendar-note a', function(e) {
  e.preventDefault()

  axios
    .get($(this).data('url'))
    .then(response => {
      var modal = $('#calendar-note-modal');
      modal.find('.modal-content').empty().append(response.data);
      modal.modal('show');
    })
});

$(document).on('submit', '.send_calendar_note_request_form', function(e) {
  e.preventDefault()

  new SmartyForm().process({
    form: $(e.target),
    onSuccess: (response) => {
      var form = $('.send_calendar_note_request_form');
      var modal = $('#calendar-note-modal');
      $('#note-' + form.data('eventdate')).empty().append(response);
      modal.modal('hide');
      Helper.flash_message('success', 'Note updated');
    },
    onError: (response) => { processingModal.hide() }
  });
});
