import React, { useContext } from "react";
import { AccessContext, ViewModeContext } from "../../utils/context";

const ViewModes = [
  { id: 'all', title: 'Show all shifts' },
  { id: 'my', title: 'Show my shifts only' },
];

export default () => {
  const allowManage = useContext(AccessContext);
  const { viewMode, setViewMode } = useContext(ViewModeContext);

  if (!allowManage) return <input type='hidden' name="filter[view_mode]" value='my'/>;

  return (
    <div className="form-group radio_buttons optional">
      <label className="control-label radio_buttons optional">Staff rota:</label>
      {ViewModes.map(item => (
        <span className="radio" key={item.id}>
          <label>
            <input className="radio_buttons optional"
                   type="radio"
                   value={item.id}
                   checked={viewMode === item.id}
                   onChange={() => setViewMode(item.id)}
                   name="filter[view_mode]"/>
            {item.title}
          </label>
        </span>
      ))}
    </div>
  )
}
