class Vats {
  constructor() {
    this.hideClass = 'hide';
  }

  start() {
    this.onClick();
    this.onChange();
    this.onFocusOut();
  }

  onClick() {
    $(document).on('click', '.vat_checkbox', () => {
      $('.vat-field').toggleClass(this.hideClass);
      $('.vat_registered').attr('required', $('.vat-field').hasClass(this.hideClass));
    });
  }

  onChange() {
    $(document).on('change', '.facility_venue_vat input[type=radio]', () => {
      const venueVatRegistration = $('input[name="facility[venue_vat]"]:checked').val();
      const isVenueVatRegistered = (venueVatRegistration === 'true');

      $('#facility_vat_registered').prop('disabled', isVenueVatRegistered);
      $('#facility_vat').prop('disabled', isVenueVatRegistered);
    });
  }

  onFocusOut() {
    $(document).on('focusout', '#area_vat', (event) => {
      const $target = $(event.currentTarget);
      const $form = $target.closest('form');

      $.ajax({
        url: $form.attr('action'),
        data: $form.serialize(),
        type: 'POST',

        success: () => {
          Helper.flash_message('success', 'Successfully updated');
        }
      });
    });
  }
}

export default Vats;
