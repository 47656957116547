class Facility {
  constructor() { }

  start() {
    if ($('.admin_facilities_show').length > 0) {
      $('.facility_venue_vat input').change(() => {
        $('#vat_form').slideToggle(500);
      });
    }

    this.onFacilityCategoryChange();
    this.onClassRoomsCountChange();
    this.onGroupMainFacilityChange();

    if ($('.admin_facilities_show').length > 0) {
      $('input.datepicker').datepicker('setDate', new Date());
    }
  }

  onFacilityCategoryChange() {
    $(document).on('change', '#facility_facility_category_id', (event) => {
      const $target = $(event.currentTarget);

      $.ajax({
        url: $target.data('url'),
        data: {
          facility_category_id: $target.val(),
          facility_id: $target.data('facility-id')
        }
      });
    });
  }

  onClassRoomsCountChange() {
    $(document).on('change', '#facility_group_main_facility', (event) => {
      const $target = $(event.currentTarget);

      // NOTE: We can probably simplify this with:
      //
      // $('#facility_group_main_facility').val(~~$target.is(':checked'));
      // return loadItems();
      //
      $('#facility_group_main_facility').val($target.is(':checked'));

      if ($('#facility_bulk_replace').val() !== '1') {
        $('#facility_bulk_replace').val(1);
      } else {
        $('#facility_bulk_replace').val('');
      }

      this.loadItems();
    });
  }

  onGroupMainFacilityChange() {
    $(document).on('change', '#facility_classrooms_count', () => {
      this.loadItems();
    });
  }

  loadItems() {
    const groupMainFacility = $('#facility_group_main_facility').val();
    let classRoomsCount = $('#facility_classrooms_count').val();

    if (groupMainFacility && classRoomsCount === void 0) {
      classRoomsCount = 2;
    }

    $.ajax({
      url: $('#facility_group_main_facility').data('url'),
      data: {
        group_main_facilities: groupMainFacility,
        classrooms_count: classRoomsCount,
        bulk_replase: $('#facility_bulk_replace').val()
      }
    });
  }
}

export default Facility;
