class VenueGroups {
  constructor() { }

  start() {
    const venueGroupsIndex = $('.admin.admin_venue_groups_index');
    const venueGroupsDashboard = $('.admin.admin_dashboard_venue_groups');

    if (venueGroupsIndex.length === 0) {
      if (venueGroupsDashboard.length === 0) {
        return false;
      }
    }

    this.toDataTable();
    this.reloadDataDebounced();

    $('#venue_groups_filter select').on('change', () => {
      this.reloadDataDebounced();
    });

    $('#venue_groups_filter input').on('keyup', () => {
      this.reloadDataDebounced();
    });
  }

  toDataTable() {
    $('#venue-groups.admin_side').dataTable({
      order: [[0, 'asc']],
      processing: true,
      serverSide: true,
      searching: false,
      lengthChange: false,
      pageLength: 30,
      ajax: this.usersURL,
      pagingType: 'full_numbers',

      fnDrawCallback: (oSettings) => {
        const $table_wrapper = $(oSettings.nTableWrapper);
        const $table = $table_wrapper.find('table');
        const titles = _.map($table.find('thead th'), (x, y) => x.innerText);
        const show = oSettings._iDisplayLength <= oSettings.fnRecordsDisplay();

        $table_wrapper.find('.dataTables_paginate').toggle(show);
        $table_wrapper.find('.dataTables_info').toggle(show);

        _.each($table.find('tr'), (row) => {
          _.each($(row).find('td'), (cell, index) => {
            $(cell).attr('data-title', titles[index]);
          });
        });
      }
    });
  }

  reloadDataDebounced() {
    _.debounce(() => this.reloadData(true), 250)();
  }

  reloadData(changePage) {
    const ajax = $('#venue-groups').DataTable().ajax;
          ajax.url(this.usersURL);
          ajax.reload(null, changePage);
  }

  get usersURL() {
    return $('#venue-groups').data('source') + '?' + $('#venue_groups_filter > form').serialize();
  }
}

export default VenueGroups;
