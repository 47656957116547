import moment from "moment/moment";

export function reassignStartAndEndTime(periods, prev, init = false) {
  if (periods.type !== 'list') return { startTime: null, endTime: null };
  if (periods.booking_day_rate_enabled) return bookingDayRateAvailablePeriods(periods);

  const list = periods.list;
  const startTimes = list.map(period => period.start_time);
  const endTimes = list.map(period => period.end_time);
  if (startTimes.includes(prev.startTime) && endTimes.includes(prev.endTime)) return prev;

  const converter = { 60: 1, 30: 2, 15: 4 }
  const selectPeriods = converter[periods.period_minutes];

  if (init) { // do we really want to use init?
    const endTime = endTimes[selectPeriods - 1] || endTimes[0];
    return { startTime: startTimes[0], endTime: endTime }
  } else {
    if (prev.startTime === null && prev.endTime === null) {
      // From notice to list
      const endTime = endTimes[selectPeriods - 1] || endTimes[0];
      return { startTime: startTimes[0], endTime: endTime }
    } else {
      const momentPrevStartTime = moment(prev.startTime, 'HH:mm');
      const momentPrevEndTime = moment(prev.endTime, 'HH:mm');

      const diff = momentPrevEndTime.diff(momentPrevStartTime, 'minutes');
      const selectedItems = Math.ceil(diff / periods.period_minutes);

      const startList = startTimes.map((time, index) => prepareItem(index, time, momentPrevStartTime)).sort(sortItems)

      const startItem = startList[0];
      const maxEndIndex = endTimes.length - 1;

      let startTimeIndex = startItem.index;
      let endTimeIndex = startItem.index + selectedItems - 1;

      if (endTimeIndex > maxEndIndex) endTimeIndex = maxEndIndex;

      return { startTime: startTimes[startTimeIndex], endTime: endTimes[endTimeIndex] }
    }
  }
}

function bookingDayRateAvailablePeriods(periods) {
  const availablePeriods = periods.list.filter(period => !period.closed);
  const startTimes = availablePeriods.map(period => period.start_time);
  const endTimes = availablePeriods.map(period => period.end_time);

  return { startTime: startTimes[0], endTime: endTimes[endTimes.length - 1] }
}

function prepareItem(index, time, momentStartTime) {
  const momentTime = moment(time, 'HH:mm');
  const diff = momentTime.diff(momentStartTime, 'minutes');
  return {
    index: index,
    time: time,
    priority: diff < 0 ? 1 : 0,
    momentTime: momentTime,
    diff: Math.abs(diff)
  }
}

function sortItems(a, b) {
  return a.diff - b.diff || a.priority - b.priority;
}