class SmartyForm {
  constructor() { }

  addErrors($form, prefix, errors) {
    _.each(errors, (value, key) => {
      const selector = '#' + [prefix, key].filter((el) => {
        return el
      }).join('_');

      const formGroup = $form.find(selector).closest('.form-group');
            formGroup.addClass('has-error');
            formGroup.append(`<span class="text-danger">${ value.join(', ') }</span>`);
    });
  }

  removeErrors($form) {
    $form.find('.has-error').removeClass('has-error');
    $form.find('.text-danger').remove();
  }

  process(options = {}) {
    const formOptions = options,
          $form = formOptions.form,
          onSuccess = formOptions.onSuccess,
          onError = formOptions.onError,
          method = formOptions.method || 'POST',
          prefix = formOptions.prefix || '';

    this.removeErrors($form);

    const formId = $form.attr('id');

    // console.log(formId); const newString = myString.replace(/^(new|edit)_/, '');

    $.ajax({
      type: method,
      url: $form.attr('action'),
      data: $form.serialize(),
      success: (response) => { if (onSuccess) onSuccess(response); },
      error: (response) => {
        if (response.status === 422) {
          $form.find('input[type=submit]').prop('disabled', false);
          this.addErrors($form, prefix, (response.responseJSON || {}).errors)
        } else {
          Helper.flash_message('error', "Something went wrong")
        }

        if (onError) onError(response);
      }
    });
  }
}

export default SmartyForm;