import axios from "axios";

const Venues = (function() {
  function init(refreshCalendar) {
    const formJson = localStorage.getItem('calendar_filter_json');
    let formAttributes = {};

    try {
      formAttributes = JSON.parse(formJson) || {};
    } catch (e) {
      console.log('no attributes')
    }

    const venue_id = formAttributes.venue_id;
    const venueOptions = $('#venue_id option');
    const venueValues = $.map(venueOptions, function (option) {
      return option.value
    });
    if (venue_id && _.contains(venueValues, venue_id)) $("#venue_id").val(venue_id);

    $('#venue_id').on('change', function() {
      const sel = $('<select/>', {
        id: "facility_id_",
        multiple: "multiple",
        class: "facility_id_filter form-control input-sm",
        name: 'facility_id[]'
      });

      $('#facility_id_').multiselect('destroy');
      $('#facility_id_').parent().replaceWith(sel);
      $('#facility_id_').multiselect({ nonSelectedText: 'All' });

      setTimeout(refreshCalendar, 200);
    });
  }

  return {
    init: init,
  };
})();

export default Venues;