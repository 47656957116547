import { useState, useEffect } from 'react';

export function useWindowPosition() {
  const [position, setPosition] = useState(window.innerWidth > 640 ? 'right' : 'left');

  useEffect(() => {
    const handleResize = () => {
      const newPosition = window.innerWidth > 640 ? 'right' : 'left';

      if (newPosition !== position) {
        setPosition(newPosition);
      }
    };

    window.addEventListener('resize', handleResize);


    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [position]);


  return position;
}
