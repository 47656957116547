import axios from 'axios';

const updateOrderMessageRule = (e) => {
  axios
    .patch($(e.target).data('url'),null, { params: {
      send_user_order_completed: $('input[name="order_message_rule[send_user_order_completed]"]').is(':checked'),
      send_venue_order_completed: $('input[name="order_message_rule[send_venue_order_completed]"]').is(':checked')
    }})
    .then(response => {})
    .catch((error) => {
      Helper.flash_message('error', error.response.data.error)
    });
}

$(document).on('change', '#order_message_rule_send_user_order_completed', function(e) {
  e.preventDefault()
  updateOrderMessageRule(e)
});

$(document).on('change', '#order_message_rule_send_venue_order_completed', function(e) {
  e.preventDefault()
  updateOrderMessageRule(e)
});




