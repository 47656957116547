import { Controller } from "@hotwired/stimulus"
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import PhotoSwipe from 'photoswipe';
import 'photoswipe/style.css';


class GalleryController extends Controller {
  connect() {
    this.addLightGallery();
    this.preloadImages();
    this.lightbox = this.initInvisibleGallery();
  }

  addLightGallery() {
    const self = this;

    $('#imageGallery').lightSlider({
      item: 1,
      loop: true,
      slideMargin: 0,
      pager: false,
      onSliderLoad(el) {
        el.find('li').on('click', function () {
          const index = $(this).data('index');
          self.lightbox.loadAndOpen(index);
        });
      }
    });

    this.element.querySelectorAll('.lightGallery li').forEach((item) => {
      item.addEventListener('click', function () {
        const index = this.dataset.index;
        self.lightbox.loadAndOpen(index);
      });
    });
  }


  initInvisibleGallery(el) {
    const lightbox = new PhotoSwipeLightbox({
      gallery: '#invisible-gallery',
      children: 'a',
      pswpModule: () => PhotoSwipe
    });

    lightbox.init();

    return lightbox;
  }

  preloadImages() {
    document.querySelectorAll('.main-photo li.index-0').forEach((el) => {
      this.preloadImage(el, el.dataset.src, () => {
        document.querySelectorAll('.main-photo li:not(.index-0)').forEach((el2) => {
          this.preloadImage(el2, el2.dataset.src);
        });
      });
    });

    document.querySelectorAll('.thumb-photo li:not(.hide)').forEach((el) => {
      this.preloadImage(el, el.dataset.thumb);
    });
  }

  preloadImage(el, src, callback) {
    const imageBox = el.querySelector('.image-box');
    const img = new Image();
    img.src = src;

    const assignBackground = () => {
      imageBox.style.backgroundImage = `url('${src}')`;
      if (callback) callback();
    };

    img.addEventListener('load', assignBackground, false);
    window.addEventListener("resize", assignBackground);
  }
}

export default GalleryController;