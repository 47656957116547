import axios from "axios";

class Api {
  saveReportSchedule(id, state) {
    const formData = {
      report_schedule: {
        report: state.reportTypeKey,
        filters: state.searchFilters,
        start_date: state.startDate,
        week_days: state.weekDays.map(item => item.value),
        export_type: state.exportType,
        report_time: state.reportTime,
        cc_users: state.ccUsers.map(user => delete user['readOnly?'] && user),
        report_interval: state.reportInterval
      }
    };

    const noId = id === null || id === undefined;

    return noId ?
           axios.post('/admin/reports/schedules.json', formData) :
           axios.put(`/admin/reports/schedules/${id}.json`, formData)
  }

  fetchUsers(venueId, reportTypeKey) {
    const params = { venue_id: venueId, report_type: reportTypeKey };
    return axios.get('/admin/reports/schedules/venue_users.json', { params: params })
  }
}

const api = new Api();

export default api;