import { Controller } from "@hotwired/stimulus";
import axios from "axios";
import Neo from "src/Neo";
import NeoModal from "src/NeoModal";

class OnlineMembershipPlanController extends Controller {
  static targets = ['numberDays', 'numberMonths', 'paymentFrequency']

  connect() {
    const data = this.element.dataset;
    this.id = data.id;
    this.facilityId = data.facilityId;
  }

  publish(e) {
    e.preventDefault();

    const url = e.target.getAttribute('href');

    axios.put(url).then((response) => {
      Neo.reloadTable('admin_online_membership_plan_table');
    }).catch((error) => {
      Neo.processError(error, (e) => Helper.flash_message('error', e.response.data.errors.join(', ')))
    })
  }

  selectType(e) {
    const targets = {
      less_one_month: this.numberDaysTarget,
      one_month_or_more: this.numberMonthsTarget
    }

    Object.keys(targets).map((key) => {
      let target = targets[key];
      let input = target.querySelector('select');

      if (key === e.target.value) {
        target.classList.remove('hide');
        input.disabled = false;
      } else {
        target.classList.add('hide');          
        input.disabled = true;
        this.paymentFrequencyTarget.classList.add('hide');
      }
      input.value = '';
    });
  }

  selectNumberMonths(e) {
    if (parseInt(e.target.value) < 2) {
      this.paymentFrequencyTarget.classList.add('hide');
    } else {
      this.paymentFrequencyTarget.classList.remove('hide');
    }    
  }

  send(e) {
    e.preventDefault();

    const button = e.target;
    const url = button.dataset.url;

    axios.get(url).then((response) => {
      const modal = NeoModal.add(response.data);

      modal.addEventListener('neo_modal:success', (event) => {
        Helper.flash_message('success', event.detail.response.data.notice);
        $(modal).modal('hide');
        Neo.reloadTable('admin_online_membership_plan_table');
      })
    });
  }
}

export default OnlineMembershipPlanController;
