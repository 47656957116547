import React, { useContext } from "react";
import { ViewModeContext } from "../../utils/context";

export default ({ showFirstColumn, multiple, clearHighlight, toggleSelectAll }) => {
  const { fullAccess } = useContext(ViewModeContext)

  return (
    <thead>
    <tr>
      {showFirstColumn && <th className='tl'>{fullAccess ? 'Name' : 'Venue'}</th>}
      <th className='date-column'>Date</th>
      <th>Start time</th>
      <th>End time</th>
      <th className='type-column'>Type</th>
      <th>Total</th>
      {fullAccess &&
      <th className='actions-column'>
        {(!multiple && !clearHighlight) && 'Actions'}
        {multiple &&
        <div className='text-center'>
          <input
            style={{ margin: '3px 0 0' }}
            type='checkbox'
            onClick={(e) => toggleSelectAll(e)}
          />
        </div>
        }
        {clearHighlight &&
        <div className='text-center'>
          <input
            style={{ margin: '3px 0 0' }}
            type='checkbox'
            onClick={(e) => toggleSelectAll(e)}
          />
        </div>
        }
      </th>
      }
    </tr>
    </thead>
  )
};