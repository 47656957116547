import React, { useState, useEffect, useMemo, useRef } from 'react';
import classNames from "classnames";
import { getQueryString } from "../Api";
import axios from "axios";
import { createConsumer } from "@rails/actioncable";
import { handleReceived } from './cable';

const REPORTS_PATH = '/admin/reports/';

export default ({ title, item, filter, format, customPath = null }) => {
  const [disabled, setDisabled] = useState(false);
  const [reportFileGeneratorId, setReportFileGeneratorId] = useState(null);

  const consumer = useMemo(() => createConsumer(), []);
  const subscription = useRef();

  const isDisabled = !!(reportFileGeneratorId) || disabled;
  const classes = classNames(['btn btn-primary export-btn', { 'generation': isDisabled }]);

  const handleExport = () => {
    setDisabled(true);
    const defaultReportPath = `${REPORTS_PATH}/${item.key}`;
    const urlWithParams = `${customPath || defaultReportPath}/${format}.json?${getQueryString(filter)}`;
    axios.post(urlWithParams).then(response => setReportFileGeneratorId(response.data.id));
  };

  const disconnect = () => {
    if (subscription.current) subscription.current.unsubscribe();
    consumer.disconnect();
  }

  useEffect(() => {
    if (reportFileGeneratorId) {
      const params = { channel: 'ReportFileGeneratorChannel', id: reportFileGeneratorId };

      subscription.current = consumer.subscriptions.create(params, {
        connected: () => {
          consumer.send({
            identifier: JSON.stringify(params),
            command: 'message',
            data: JSON.stringify({ test: '' })
          });
        },
        disconnected: () => console.log('disconnected'),
        received: (message) => handleReceived(message, (id) => {
          setDisabled(false);
          setReportFileGeneratorId(null);
          window.location.href = `${REPORTS_PATH}/downloads/${id}`;
        }, () => {
          setDisabled(false);
          setReportFileGeneratorId(null);
          Helper.flash_message('error', "Something went wrong!");
        }),
      })
    } else {
      disconnect();
    }

    return disconnect;
  }, [reportFileGeneratorId]);


  return (
    <button className={classes} disabled={!!(isDisabled)} onClick={handleExport}>
      {title}
    </button>
  )
};