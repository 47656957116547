import React, {useState} from 'react'
import MultipleFields from "./MultipleFields";
import { Button } from 'react-bootstrap';

export default React.forwardRef(({ question, errors }, ref ) => {
  const [subQuestions, setSubQuestions] = useState(question.subQuestions);
  const [subQuestionsCount, setSubQuestionsCount] = useState(question.subQuestions.length);
  const [enabledCreateChildButton, setEnabledCreateChildButton] = useState(question.enabledChildCreateButton);
  const childErrors = ((errors || {})['sub_questions'] || {});

  let subQuestionErrorIndex = 0;

  const addSubQuestion = () => {
    let newSubQuestions = [...subQuestions];
    let newSubQuestion = Object.assign({}, question.newSubQuestion);

    newSubQuestions = newSubQuestions.concat(newSubQuestion);

    setSubQuestions(newSubQuestions);
    setSubQuestionsCount(subQuestionsCount + 1);
  }

  const handleRemoveSubQuestion = (index) => {
    let newSubQuestions = subQuestions.slice();

    newSubQuestions[index].deleted = 'true';

    if (!newSubQuestions[index]['id']) {
      newSubQuestions[index].hidden = 'true';
    }

    setSubQuestions(newSubQuestions);
    setSubQuestionsCount(subQuestionsCount - 1);
  }

  return (
    <form ref={ref}>
      <MultipleFields
        question={question}
        suffix={'question'}
        errors={errors}
        key={question.id}
        hasChild={subQuestionsCount > 0}
        deleted={false}
        enabledRemoveChildButton={false}
        onEnableCreateChildButton={setEnabledCreateChildButton}
        onDeleteSubQuestion={() => {}}
      />

      {subQuestions.map((item, index) => {
        return (
          <MultipleFields
            question={item}
            suffix={`question[sub_questions_attributes][${index}]`}
            errors={childErrors[item.hidden ? subQuestionErrorIndex : subQuestionErrorIndex++]}
            key={index}
            deleted={item.deleted}
            enabledRemoveChildButton={subQuestionsCount > 0}
            onEnableCreateChildButton={() => {}}
            onDeleteSubQuestion={() => handleRemoveSubQuestion(index)}
          />
        )
      })}

      {!question.isChild && enabledCreateChildButton &&
        <Button className='btn-primary' onClick={(e) => addSubQuestion()}>
          {subQuestionsCount > 0 ? 'Create another sub-question' : 'Create sub-question'}
        </Button>
      }
    </form>
  )
});
