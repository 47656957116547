(() => {
  window.FormUtils = class FormUtils {
    saveToLocalStorage(form) {
      const formData = new FormData(form);
      let iterator = formData.entries();
      let entry = iterator.next();
      let result = [];

      while(!entry.done) {
        let value = entry.value;

        if (!['utf8', 'authenticity_token'].includes(value[0])) {
          result.push(value[0] + "=" + value[1]);
        }

        entry = iterator.next();
      }

      localStorage.setItem(form.id, JSON.stringify(result));
    }

    clearLocalStorage(formId) {
      localStorage.removeItem(formId);
    }

    getFromLocalStorage(formId) {
      const formData = localStorage.getItem(formId);
      
      return JSON.parse(formData);
    }
  }
})()
