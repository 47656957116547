import React, { useState } from "react";

const SHIFTS = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60];
const DIRECTIONS = ['before', 'after'];
const SESSIONS_OPTIONS = ['first', 'last']
const SESSIONS = {
  'start': { 'before': SESSIONS_OPTIONS, 'after': SESSIONS_OPTIONS.slice(0, 1) },
  'end': { 'before': SESSIONS_OPTIONS, 'after': SESSIONS_OPTIONS }
};

export default (props) => {
  const { defaultMinutes, defaultDirection, defaultSession, defaultIncludeInternal } = props;
  const { disabled, name, index } = props;
  const [internalEnabled, setInternalEnabled] = useState(defaultIncludeInternal || false);
  const { handleMinutesChange } = props;
  const [direction, setDirection] = useState(defaultDirection);

  return (
    <>
      <div className='form-inline' style={{ width: 300, display: 'inline-block' }}>
        <select defaultValue={defaultMinutes}
                className="form-control select optional"
                name={`staff_item[${index}][${name}_minutes]`}
                disabled={disabled}
                onChange={(event)=>{ handleMinutesChange(name, event.target.value) }}>
          {SHIFTS.map(shift => <option key={shift} value={shift}>{shift}</option>)}
        </select>

        minutes

        <select defaultValue={defaultDirection}
                className="form-control select optional"
                name={`staff_item[${index}][${name}_direction]`}
                disabled={disabled}
                onChange={(e)=> setDirection(e.target.value)}>
          {DIRECTIONS.map(dir => <option key={dir} value={dir}>{dir}</option>)}
        </select>

        <select defaultValue={defaultSession}
                className="form-control select optional"
                name={`staff_item[${index}][${name}_session]`}
                disabled={disabled}>
          {(SESSIONS[name][direction] || []).map(session => <option key={session} value={session}>{session}</option>)}
        </select>
        &nbsp;
        session
      </div>

      <div className="form-group check_boxes optional" style={{ margin: '10px 0 10px 23px' }}>
        <span className="checkbox">
          <input type='hidden' name={`staff_item[${index}][${name}_include_internal]`} value="0"/>
          <input
            className="check_boxes optional"
            type="checkbox"
            name={`staff_item[${index}][${name}_include_internal]`}
            value="1"
            onChange={() => setInternalEnabled(!internalEnabled)}
            disabled={disabled}
            checked={internalEnabled}
          />
          <span style={{ marginLeft: 5 }}>
            include any internal bookings
          </span>
        </span>
      </div>
    </>
  );
}