import moment from "moment-timezone";
import { isMobile } from "react-device-detect";

moment.tz.setDefault('Europe/London');

export function dateFormat(date) {
  const dateFormat = isMobile ? 'ddd, DD MMM' : 'ddd, DD MMM Y'
  const momentDate = moment(date);
  return  momentDate.isValid() ? momentDate.format(dateFormat) : '';
}

export function timeFormat(time) {
  const momentTime = moment(time);
  return momentTime.isValid() ? momentTime.format('HH:mm') : '';
}
