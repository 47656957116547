import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { ViewModeContext } from "../../utils/context";

export default ({ venueId, setVenueId, setReady, location }) => {
  const params = new URLSearchParams(location.search)
  const { fullAccess } = useContext(ViewModeContext);
  const [venues, setVenues] = useState([]);

  useEffect(() => {
    setReady(false);

    axios
      .get('/admin/staff_rota/venues.json')
      .then((response) => {
        const venues = prepareVenues(response.data);

        setVenues(venues);

        if (fullAccess || venues.length === 1) {
          let paramVenueId = parseInt(params.get('venue_id'));
          let selectedVenueId = venues.map(x => x.id).includes(paramVenueId) ? paramVenueId : venues[0].id;
          setVenueId(selectedVenueId)
        }

        if (!fullAccess) {
          let selectedVenueId = venues[0].id;
          setVenueId(selectedVenueId)
        }

        setReady(true);
      });
  }, [fullAccess]);

  const prepareVenues = (venues) => {
    if (!fullAccess && venues.length > 1) venues.unshift({ id: "" , title: 'All Venues' })
    return venues;
  }

  return (
    <div className="form-group select optional filter_venue_id">
      <label className="control-label select optional">Venue:</label>
      <select className="form-control select optional"
              name="filter[venue_id]"
              value={venueId}
              onChange={(e) => setVenueId(e.target.value)}>
        {venues.map(venue => (
          <option key={venue.id} value={venue.id}>{venue.title}</option>
        ))}
      </select>
    </div>
  )
}
