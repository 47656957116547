export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function snakeToTitle(string) {
  return string.replace(/^(.)/, (_, char) => char.toUpperCase())
          .replace(/[-_]+(.)/g, (_, char) => ` ${char.toUpperCase()}`)
}

export function singularize(string) {
    const endings = {ies: 'y',ves: 'fe',i: 'us',zes: 'ze',ses: 's',es: 'e',s: ''}
    const regexp = new RegExp(`(${Object.keys(endings).join('|')})$`)

    return string.replace(regexp, toReplace => endings[toReplace])
}
