import { Controller } from "@hotwired/stimulus"
import axios from "axios";

class OnlineMembershipCancellationController extends Controller {
  static targets = ['cancellationDate'];

  connect() {
    this.cancellationDate = null;

    window.onChangeCancellationDate = (val) => {
      this.cancellationDate = moment(val.date).format('YYYY-MM-DD');
      this.updateFormData();
    }
  }

  updateFormData() {
    const url = this.element.action;

    axios
      .get(url, {
        params: { cancellation_date: this.cancellationDate },
        headers: { 'Accept': 'text/vnd.turbo-stream.html' }
      })
      .then((r) => Turbo.renderStreamMessage(r.data))
      .catch((e) => Helper.flash_message('error', e.response.data.errors));
  }
}

export default OnlineMembershipCancellationController;
