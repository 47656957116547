import { Controller } from "@hotwired/stimulus"
import NeoModal from "src/NeoModal";
import axios from 'axios';

class NeoButtonController extends Controller {
  create(e) {
    e.preventDefault();

    const button = e.target;
    const url = button.dataset.url;

    axios.get(url).then((response) => {
      const modal = NeoModal.add(response.data);

      modal.addEventListener('neo_modal:success', (event) => {
        Helper.flash_message('success', event.detail.response.data.notice);
        $(modal).modal('hide');
        const e = new CustomEvent("reload-neo-table", { detail: {  } });
        document.dispatchEvent(e);
      })
    });
  }

  edit(e) {
    e.preventDefault();

    const button = e.target;
    const url = button.dataset.url;

    axios.get(url).then((response) => {
      const m = NeoModal.add(response.data);

      m.addEventListener('neo_modal:success', (event) => {
        Helper.flash_message('success', event.detail.response.data.notice);
        $(m).modal('hide');
        const e = new CustomEvent("reload-neo-table", { detail: {  } });
        document.dispatchEvent(e);
      })
    });
  }

  toggle_hide(e) {
    e.preventDefault();

    const button = e.target;
    const url = button.dataset.url;

    axios.post(url).then((response) => {
      Helper.flash_message('success', response.data.notice);
      const e = new CustomEvent("reload-neo-table", { detail: {  } });
      document.dispatchEvent(e);
    });
  }
}

export default NeoButtonController;