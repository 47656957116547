import { Controller } from "@hotwired/stimulus"
import AddVenues from "src/nextgen/admin/users/AddVenues";

class VenueUsersController extends Controller {
  connect() {
    const form = $(this.element);

    function updForm() {
      form.find('.multiselect.venue-btn').click((e) => {
        const checked = $(e.target).data('action') === 'check';
        const checkboxes = form.find(`.type-venue`);

        $.each(checkboxes, (index, el) => $(el).prop('checked', checked).trigger('change'));
      });

      form.find('.multiselect.notification-btn').click((e) => {
        const checked = $(e.target).data('action') === 'check';
        const checkboxes = form.find(`.type-notification`);

        $.each(checkboxes, (index, el) => {
          if ($(el).is(':enabled')) $(el).prop('checked', checked).trigger('change')
        });
      });

      form.find('.type-venue').on('change', (e) => {
        const target = $(e.target);
        const notification = target.closest('tr').find('.type-notification');
        const disabled = !target.is(':checked');

        notification.prop('disabled', disabled)
        notification.prop('checked', disabled ? false : notification.data('status') === true)
      });

      const userAccessType = $('#user_access_type');

      userAccessType.on('change', (e) => {
        const accessType = $(e.target).val();
        const notificationColumn = form.find('.notification_column');
        const showNotificationColumn = ['full_access', 'full_access_2'].includes(accessType);
        notificationColumn.toggle(showNotificationColumn)
      });


      const venueCheckboxes = form.find(`.type-venue`);
      const multiselectRow = form.find('.multiselect-row');

      if (venueCheckboxes.length < 2) multiselectRow.hide();
      $.each(venueCheckboxes, (i, el) => $(el).trigger('change'));
      userAccessType.trigger('change');
    }

    const addVenues = new AddVenues(() => {
      updForm();
    });

    updForm();
    addVenues.start();
  }
}

export default VenueUsersController;
