import { Controller } from "@hotwired/stimulus"

class FileUploaderValidatorController extends Controller {
  connect() {
    this.element.addEventListener('change', (e) => {
      const input = e.target;
      const file = input.files[0];
      const fileSize = file.size / 1024 / 1024;
      let errorMessage = null;

      if (fileSize > 10) {
        errorMessage = 'The file you have uploaded is larger than our limit of 10 MB'
      } else if (fileSize == 0) {
        errorMessage = 'The file you have uploaded is corrupted'
      }

      if (errorMessage) {
        input.value = '';
        Helper.flash_message('error', errorMessage);
      }
    });
  }


}

export default FileUploaderValidatorController;
