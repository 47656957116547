import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import DropDown from "./DropDown";

const documentTypes = [
  { title: 'All documents', value: 'all' },
  { title: 'Documents with expiry date', value: 'expired' }
];

export default ({ onChanged, options }) => {
  const documentTypeIndex = options.documentType === 'expired' ? 1 : 0
  const [documentType, setDocumentType] = useState(documentTypes[documentTypeIndex]);

  const pending = false;

  useEffect(() => {
    const params = documentType ? { documentType: documentType.value } : null;
    onChanged({ value: documentType, pending, params })
  }, [documentType]);

  return (
    <div className={classNames(['filter-block filter-document-types'])}>
      <label htmlFor="filter-document-types">Documents:</label>
      <div className='input-box input-box-react-select'>
        <DropDown
          value={documentType}
          options={documentTypes}
          onChange={setDocumentType}
          isLoading={pending}
          isDisabled={pending}
          getOptionLabel={opt => opt.title}
          getOptionValue={opt => opt.value}
        />
      </div>
    </div>
  )
};
