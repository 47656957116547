export default function Rescue(error, fn) {
  const { response } = error;

  if (response) {
    const { status, data } = response;

    if (status === 422)  {
      if (fn) fn(data.errors);
    } else {
      console.warn(error);
    }
  } else {
    console.warn(error)
  }
}