import React, { useContext, useEffect, useState } from 'react';
import Filter from './Filter';
import Table from "../components/Table/Table";
import axios from "axios";
import Pagination from "../components/Table/Pagination";
import { ViewModeContext } from "../utils/context";
import { withRouter } from 'react-router-dom';

function Index({ location, history }) {
  const [multiple, setMultiple] = useState(false);
  const [clearHighlight, setClearHighlight] = useState(false);
  const [staffItems, setStaffItems] = useState(false);
  const [total, setTotal] = useState({});
  const [nonViewed, setNonViewed] = useState(false);
  const [nonViewedNames, setNonViewedNames] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [term, setTerm] = useState('');
  const [venueId, setVenueId] = useState("");
  const [timePeriod, setTimePeriod] = useState('present_and_upcoming');
  const [qid, setQid] = useState(0);
  const [page, setPage] = useState(1);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [filterReady, setFilterReady] = useState(false);
  const { viewMode, fullAccess } = useContext(ViewModeContext);

  const filter = {
    venueId: venueId,
    timePeriod: timePeriod,
    startDate: startDate,
    endDate: endDate,
    page: page,
    viewMode: viewMode,
    term: term
  };

  useEffect(() => {
    setQid(Math.random())
    setPage(1)
  }, [term, venueId, timePeriod, startDate, endDate, nonViewed])

  useEffect(() => {
    if (filterReady) {
      const params = {
        term: term,
        venue_id: venueId,
        time_period: timePeriod,
        start_date: moment(startDate).format('ll'),
        end_date: moment(endDate).format('ll'),
        page: page,
        view_mode: fullAccess ? viewMode : 'my'
      }

      axios
        .get('/admin/staff_items.json', { params: params })
        .then(response => {
          const { data } = response;

          setStaffItems(data.staffItems);
          setTotal(data.total);
          setPagination(data.pagination);
          setNonViewed(data.nonViewed);
          setNonViewedNames(data.nonViewedNames);
        });
    }
  }, [qid, page, filterReady])

  const fetchData = () => { setQid(Math.random()) };
  const handleChangePage = (page) => { setPage(page) };
  const handleChangeVenueId = (venueId) => {
    history.replace(`/admin/staff_rota?venue_id=${venueId}`);
    setVenueId(venueId);
  }

  return (
    <div className='row'>
      <div className='col-lg-3'>
        <h2 style={{ marginTop: 0, marginLeft: 0 }}>Staff Rota</h2>
        <div className='filter-wrapper'>
          <Filter
            term={term}
            setTerm={setTerm}
            venueId={venueId}
            setVenueId={handleChangeVenueId}
            timePeriod={timePeriod}
            setTimePeriod={setTimePeriod}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            ready={filterReady}
            setReady={setFilterReady}
            location={location}
          />
        </div>
      </div>

      <div className='col-lg-9'>
        {filterReady &&
        <>
          <Table
            data={staffItems}
            total={total}
            nonViewed={nonViewed}
            nonViewedNames={nonViewedNames}
            setNonViewed={setNonViewed}
            onUpdated={fetchData}
            showCreateButton={true}
            venueId={venueId}
            multiple={multiple}
            setMultiple={setMultiple}
            clearHighlight={clearHighlight}
            setClearHighlight={setClearHighlight}
            filter={filter}
            showExportButton={true}
          />

          {pagination && pagination.pages > 1 &&
          <Pagination
            totalPages={pagination.pages}
            currentPage={pagination.page}
            onChange={handleChangePage}
          />
          }
        </>
        }
      </div>
    </div>
  )
}

export default withRouter(Index);
