class VenueAdminCastling {
  constructor() { }

  start() {
    $('#venue-admin-castling-button').on('click', () => {
      $('#venue-admin-castling-modal').modal('show');
    });

    $('#venue-admin-castling-submit').on('click', () => {
      const $form = $('#new_venue_admin_castling');

      $.ajax({
        type: 'POST',
        url: $form.attr('action'),
        data: new FormData($form[0]),
        processData: false,
        contentType: false,

        success: (response) => {
          window.location.href = response.redirect_to;
        },

        error: (error) => {
          const { errors } = error.responseJSON;

          if (errors.base) {
            Helper.flash_message('error', errors.base.join(', '));
          } else {
            Helper.flash_message('error', 'Something wrong happened');
          }
        }
      });
    });
  }
}

export default VenueAdminCastling;