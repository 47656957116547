import React from "react";

export const CheckFileSize = (input) => {
  const file = input.files[0];
  const size = file.size / 1024 / 1024;
  let message = null;

  if (size > 10) {
    message = 'The file you have uploaded is larger than our limit of 10 MB';
  } else if (size == 0) {
    message = 'The file you have uploaded is corrupted';
  };

  if (message) input.value = '';

  return message;
};
