import React from 'react';
import error from "src/components/Form/Error";

export default ({ errors, name }) => {
  if (!Object.keys(errors).includes(name)) return null;
  if (!Array.isArray(errors[name])) return null;
  if (errors[name].length < 1) return null;

  return (
    <div className='has-error'>
      <div className='help-block'>
        {errors[name].join(', ')}
      </div>
    </div>
  )
}

