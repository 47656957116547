import { processingModal } from "../helpers/processing_modal";

function MultiVat() {
  function sendVat(checkbox) {
    processingModal.show(1)
    const url = checkbox.data('url');

    $.ajax({
      url: url,
      dataType: 'json',
      data: { booking: { admin_vat_enabled: checkbox.prop('checked') } },
      type: 'POST',
      beforeSend: function() {
        checkbox.prop('disabled', true)
      },
      success: function() {
        window.bookingTable.reload(false);
      },
      error: function(error) {
        processingModal.hide();
        checkbox.prop('disabled', false);
        switchMultiVatCheckbox();
        Helper.flash_message('error', error.responseJSON);
      }
    });
  }

  const submitMultiVatModal = function() {
    $('#admin_orders_multi_vat_confirmation_modal').modal('hide');
    sendVat(switchMultiVatCheckbox());
  };

  const showVatConfirmationModal = function(event) {
    const target = event.target;
    target.checked = !target.checked;

    const confirmationModal = $('.pending_table #bookings').data('vat-confirmation-modal');

    if (confirmationModal) {
      $('#admin_orders_multi_vat_confirmation_modal').modal('show');
    } else {
      sendVat(switchMultiVatCheckbox());
    }
  };

  const switchMultiVatCheckbox = function() {
    const checkbox = $('#all-bookings-vat-checkbox');
    checkbox.prop('checked', !checkbox.prop('checked'));

    return checkbox;
  };

  return {
    showVatConfirmationModal: showVatConfirmationModal,
    submitMultiVatModal: submitMultiVatModal
  }
}


window.multiVat = MultiVat();