import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import MultipleForm from "./MultipleForm";
import axios from "axios";
import rescue from "../../../rescue";
import ErrorBoundary from "../../../ErrorBoundary";

export default ({ show, setShow, selectedItemIds, selectedNonCancelledItemIds, onUpdated, setAfterEditMessageType }) => {
  const [staffItem, setStaffItem] = useState(null);
  const [errors, setErrors] = useState({});
  const [staffItems, setStaffItems] = useState([]);
  const [updatingInProgress, setUpdatingInProgress] = useState(false);

  useEffect(() => {
    if (show) {
      axios
        .get(`/admin/staff_items/${selectedNonCancelledItemIds[0]}/edit.json`)
        .then((response) => {
          setStaffItem(response.data.staffItem);
          setStaffItems(response.data.staffItems);
        });
    } else {
      setStaffItem(null);
      setErrors({});
      setStaffItems([]);
    }
  }, [show])

  const confirmChanges = () => {
    if (updatingInProgress) return true;
    setUpdatingInProgress(true);

    const formData = new FormData(document.getElementById('edit-multi-staff-items'));

    axios
      .put(`/admin/staff_items/multiupdate.json`, formData)
      .then((response) => {
        setShow(false);
        onUpdated();
        setAfterEditMessageType(response.data.message);
        setTimeout(() => setUpdatingInProgress(false), 150)
      })
      .catch((error) => {
        setUpdatingInProgress(false);
        rescue(error, setErrors)
      });
  }

  return (
    <ErrorBoundary>
      <Modal show={show} onHide={() => setShow(false)} dialogClassName="modal-xm">
        <Modal.Header closeButton>
          <Modal.Title>Edit multiple shifts</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {staffItem &&
          <MultipleForm
            staffItem={staffItem}
            errors={errors}
            selectedItemIds={selectedNonCancelledItemIds}
          />
          }
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShow(false)}>Close</Button>
          <Button bsStyle="primary" disabled={updatingInProgress} onClick={confirmChanges}>Confirm</Button>
        </Modal.Footer>
      </Modal>
    </ErrorBoundary>
  );
}