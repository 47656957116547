import { processingModal } from "../helpers/processing_modal";

function Vat() {
  const showVatConfirmationModal = function (event) {
    const target = event.target;
    target.checked = !target.checked;

    const confirmationModal = $('.pending_table #bookings').data('vat-confirmation-modal');

    if (confirmationModal) {
      const modal = $('#admin_orders_single_vat_confirmation_modal');
      modal.modal('show');
      modal.data('target', target);
    } else {
      sendVat(switchVatCheckboxElement(target));
    }
  };

  const switchVatCheckboxElement = function (target) {
    $(target).prop('checked', !$(target).is(':checked'));
    return target;
  };

  const sendVat = function (target) {
    processingModal.show(1)
    const $el = $(target);
    const data = {};

    data[$el.attr('name')] = target.checked;

    $.ajax({
      url: $el.data('url'),
      dataType: 'json',
      data: data,
      type: 'PATCH',
      beforeSend: function () {
        $el.prop('disabled', true)
      },
      success: function () {
        window.bookingTable.reload(false);
      },
      error: function (error) {
        processingModal.hide();
        $el.prop('disabled', false)
        switchVatCheckboxElement(target);
        Helper.flash_message('error', error.responseJSON);
      }
    })
  };

  const changeBookingsExtraVat = function (event) {
    sendVat(event.target);
  };

  const submitVatModal = function () {
    const modal = $('#admin_orders_single_vat_confirmation_modal');
    modal.modal('hide');
    const target = modal.data('target');
    sendVat(switchVatCheckboxElement(target));
  };

  return {
    showVatConfirmationModal: showVatConfirmationModal,
    changeBookingsExtraVat: changeBookingsExtraVat,
    submitVatModal: submitVatModal
  }
}

window.vat = Vat();