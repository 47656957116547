import React from "react";

export default ({ index, deleteChildFacility, revertChildFacility, data }) => {
  const { deleteRequest, deleteRequestDisabled } = data ;
  const disabled = deleteRequestDisabled || false;

  if (deleteRequest) {
    return (
      <button type='button'
              disabled={disabled}
              style={{ width: '165px' }}
              className="btn btn-warning"
              onClick={() => revertChildFacility(index)}>
        Undo delete request
      </button>
    )
  } else {
    return (
      <button type='button'
              disabled={disabled}
              style={{ width: '165px' }}
              className="btn btn-danger"
              onClick={() => deleteChildFacility(index)}>
        {data.id === '' ? 'Delete' : 'Send delete request'}
      </button>
    )
  }
}