import { Controller } from "@hotwired/stimulus"
import moment from "moment/moment";

export default class extends Controller {
  prev_page(e) {
    if (e.target.dataset.disabled === 'true') return;
    this.scrollCalendar('prev')
  }
  next_page(e) {
    if (e.target.dataset.disabled === 'true') return;
    this.scrollCalendar('next')
  }

  scrollCalendar(direction) {
    const dateEl = $('#date');
    const currentRenderer = $('.hidden-view').val();
    const pageStep = currentRenderer === 'day' ? 1 : 7;
    const pageDirection = direction === 'next' ? -pageStep : pageStep
    const currentDate = dateEl.datepicker('getDate');
    const newDate = moment(currentDate).subtract(pageDirection, 'days').toDate()

    dateEl.datepicker('setDate', newDate);
  }
}

