const Days = (function() {
  function init(refreshCalendar) {
    $('#days_of_week').multiselect({
      includeSelectAllOption: false,
      selectAllNumber: false,
      nonSelectedText: 'None',
      numberDisplayed: 0,
      onChange: () => {
        refreshCalendar();
        disableLastCheckedDay()
      },
      buttonText: (options) => (options.length === 7 || options.val() === '') ? 'All' : options.length + ' selected'
    });
  }

  function disableLastCheckedDay() {
    const activeElements = $('#days_of_week').parent().find('.multiselect-container').find('li.active');

    if (activeElements.length === 1) {
      $(activeElements[0]).addClass('disabled');
      $(activeElements[0]).find('input').prop('disabled', true);
    } else {
      const disabledElements = $('#days_of_week').parent().find('.multiselect-container').find('li.disabled');

      $.each(disabledElements, function(index, el) {
        $(el).removeClass('disabled');
        $(el).find('input').prop('disabled', false);
      })
    }
  }

  return {
    init: init,
  };
})();

export default Days;