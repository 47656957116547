(function() {
  window.initSortableLogoSection = function() {
    $('.sortable-logo-section').sortable({
      items: "> tr.allow-sort",
      stop: function(e, u) {
        collectLogoSection();
      }
    });
  }

  initSortableLogoSection();

  function collectLogoSection() {
    var document_records, ids;
    document_records = $('.sortable-logo-section .ui-sortable-handle');

    ids = $.map(document_records, function(record, _) {
      var id;
      return id = $(record).data('id');
    });

    $.ajax({
      method: 'PUT',
      url: '/admin/settings/logo_sections/reorder',
      data: {
        ids: ids
      },
      success: function(result) {
        $('.success').fadeIn('slow');
        $('.success').fadeOut('slow');
      },
      fail: function(result) {
        $('.error').fadeIn('slow');
        $('.error').fadeOut('slow');
      }
    });
  }
}).call(this);