import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import DropDown from "./DropDown";

const filterOptions = [
  { title: 'Excluding Pending User', value: 0 },
  { title: 'Including Pending User', value: 1 },
  { title: 'Only Pending User', value: 2 }
];

export default ({ onChanged, options }) => {
  const selectedOption = parseInt(options.bookingsStatus) || 0;
  const [bookingsStatus, setBookingsStatus] = useState(filterOptions[selectedOption]);

  useEffect(() => {
    const params = bookingsStatus ? { bookingsStatus: bookingsStatus.value } : null;
    onChanged({ value: bookingsStatus, pending: false, params });
  }, [bookingsStatus]);

  return (
    <div className={classNames(['filter-block filter-pending-users'])}>
      <label htmlFor="filter-pending-users">Bookings:</label>
      <div className='input-box input-box-react-select'>
        <DropDown
          value={bookingsStatus}
          options={filterOptions}
          onChange={setBookingsStatus}
          getOptionLabel={opt => opt.title}
          getOptionValue={opt => opt.value}
        />
      </div>
    </div>
  )
};
