(function( $ ) {
  $.fn.expandableTable = function() {
    var self = $(this);
    var globalExpand = jQuery('<i/>', { class: 'exp fa fa-angle-down pointer' });
    var singleExpand = jQuery('<i/>', { class: 'single-exp fa fa-angle-down pointer' });

    function replaceClass($el, aClass, bClass) {
      if ($el.hasClass(aClass)) {
        $el.removeClass(aClass).addClass(bClass);
      } else {
        $el.removeClass(bClass).addClass(aClass);
      }
    }

    function checkAllRows() {
      var allUp =  _.every(self.find('.single-exp'), function(x) { return $(x).hasClass('fa-angle-up') });

      if (allUp) {
        globalExpand.removeClass('fa-angle-down').addClass('fa-angle-up')
      } else {
        globalExpand.removeClass('fa-angle-up').addClass('fa-angle-down')
      }
    }

    globalExpand.on('click', function(event) {
      var $el = $(event.target)

      if ($el.hasClass('fa-angle-up')) {
        var elements = self.find('.single-exp.fa-angle-up')
      } else {
        var elements = self.find('.single-exp.fa-angle-down')
      }

      $.each(elements, function(key, el) { el.click() });
    })

    singleExpand.on('click', function(event) {
      var $el = $(event.target)
      replaceClass($el, 'fa-angle-down', 'fa-angle-up')
      var $nextRow = $el.closest('tr').next();

      if ($nextRow.hasClass('expanded-row')) {
        $nextRow.toggle($el.hasClass('fa-angle-up'));
      }

      checkAllRows();
    })

    this.filter("table").each(function(x) {
      $(this).find('.global-expand').replaceWith(globalExpand);
      $(this).find('.single-expand').replaceWith(singleExpand);
      $(this).find('.expanded-row').hide();
    });

    return this;
  };
}( jQuery ));