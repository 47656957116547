import React, { useState, useEffect } from "react";
import api from "./api";
import Fields from "./Fields";

export default () => {
  const [noticeMessage, setNoticeMessage] = useState(null);

  useEffect(() => {
    api.getLast().then((response) => setNoticeMessage(response.data))
  }, [])

  const updateNoticeMessage = (event) => {
    event.preventDefault();
    //disable button

    let elements = event.target.elements;

    setNoticeMessage(null);

    api
      .updateLast({
        notice_message: {
          id: elements.id.value,
          enabled: elements.enabled.checked,
          title: elements.title.value,
          message: elements.message.value
        }
      })
      .then((response) => {
        setNoticeMessage(response.data)
        Helper.flash_message('success', 'Successfully updated')
      })
      .catch(() => {
        Helper.flash_message('error', 'Something went wrong')
      })
  }

  const newNoticeMessage = () => {
    event.preventDefault();
    setNoticeMessage(null);
    api.getNew().then((response) => setNoticeMessage(response.data))
  }

  return(
    <>
      <h1>Notice message on venue page</h1>

      <form onSubmit={updateNoticeMessage}>
        {noticeMessage && <Fields noticeMessage={noticeMessage}/>}

        <div>
          <div className='pull-left'>
            {noticeMessage &&
              <>
                <input
                  type="submit"
                  className="btn btn-primary"
                  value={noticeMessage.id ? "Update" : "Create"}
                />
                <i className="fa fa-eye" aria-hidden="true" style={{ marginLeft: 5, marginRight: 5 }}/>
                {noticeMessage.viewedCount}
              </>
            }
          </div>

          <div className='pull-right'>
            {noticeMessage &&
            <input type="button" className="btn btn-primary" value="New message" onClick={newNoticeMessage}/>
            }
          </div>
        </div>
      </form>
    </>
  )
};