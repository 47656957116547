import { Controller } from "@hotwired/stimulus"

class ExtraFormController extends Controller {
  connect() {
    $('input.datepicker').datepicker({
      format: 'D, dd M yyyy',
      autoclose: true,
      startDate: '+1d',
      weekStart: 1
    })

    const toggleFacilities = () => {
      const val = $('input[name="extra[all_facilities]"]:checked').val();
      $('.facilities-for-extras').toggle(val === '0');
    }

    const togglePricingCalculationType = () => {
      const val = $('select[name="extra[pricing_calculation_type]"]').val()
      $('.extra_apply_discounts').toggle(val !== 'percent')
    }

    const toggleFuturePrice = () => {
      const future_extra_price_block = $('#future_extra_price_block')
      const futureToggle = $('#extra_future_price_toggle')
      const creation_marker = $('#extra_future_extra_price_attributes__destroy')

      creation_marker.val(!futureToggle.is(':checked'))
      future_extra_price_block.toggle(futureToggle.is(':checked'))
    }

    $('input[name="extra[all_facilities]"]').on('change', toggleFacilities);
    $('select[name="extra[pricing_calculation_type]"]').on('change', togglePricingCalculationType);
    $('input[name="extra[add_future_price]"]').on('change', toggleFuturePrice);

    toggleFuturePrice();
    toggleFacilities();
    togglePricingCalculationType();
  }
}

export default ExtraFormController;
