import React, { useContext, useEffect, useState } from 'react';
import { ViewModeContext } from "../../utils/context";
import Header from "./Header";
import Modals from "./Modals";
import Row from './Row';
import MarkViewed from "./MarkViewed";
import Actions from './Actions';
import './Modals/styles.scss';
import api from '../../api';
import axios from "axios";

export default ({ data, total, onUpdated, venueId, showCreateButton,
                  nonViewed, nonViewedNames, setNonViewed,
                  creationProcess, filter, showExportButton }) => {
  const { fullAccess } = useContext(ViewModeContext);

  const [itemId, setItemId] = useState(null);
  const [multiple, setMultiple] = useState(false);
  const [clearHighlight, setClearHighlight] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showMultiEdit, setShowMultiEdit] = useState(false);
  const [showClearHighlightEdit, setShowClearHighlightEdit] = useState(false);
  const [showMultiDelete, setShowMultiDelete] = useState(false);
  const [showAfterEdit, setShowAfterEdit] = useState(false);
  const [afterEditMessageType, setAfterEditMessageType] = useState(null);
  const [showAfterConfirmed, setShowAfterConfirmed] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedNonCancelledItems, setSelectedNonCancelledItems] = useState([]);

  let showCreate = showCreateButton || false;
  let showFirstColumn = !(!(["", "0", 0].includes(venueId)) && !fullAccess);

  const handleEdit = (itemId) => {
    if ((multiple  || clearHighlight) && selectedItems.length > 1) {
      setShowMultiEdit(true)
    } else {
      preloadItem(itemId, () => {
        // render children twice - investigate
        setItemId(itemId);
        setShowEdit(true);
      });
    }
  }

  const handleClearHighlightEdit = (itemId) => {
      setShowClearHighlightEdit(true)
  }

  const handleDelete = (itemId) => {
    if ((multiple || clearHighlight)  && selectedItems.length > 1) {
      if (creationProcess) {
        api.removeRows(selectedItems).then(onUpdated)
      } else {
        setShowMultiDelete(true)
      }
    } else {
      preloadItem(itemId, () => {
        // render children twice - investigate
        if (creationProcess) {
          api.removeRow(itemId).then(onUpdated)
        } else {
          setItemId(itemId);
          setShowDelete(true)
        }
      })
    }
  }

  const preloadItem = (itemId, callback) => {
    axios.get(`/admin/staff_items/${itemId}.json`).then(() => {
      callback();
    }).catch(() => onUpdated());
  }

  const handleMultiEdit = () => handleEdit(null);
  const handleMultiDelete = () => handleDelete(null);

  const toggleSelectedItem = (itemId) => {
    if (selectedItems.includes(itemId)) {
      setSelectedItems(selectedItems.filter(x => x !== itemId));
      setSelectedNonCancelledItems(selectedNonCancelledItems.filter(x => x !== itemId))
    } else {
      setSelectedItems(selectedItems.concat([itemId]))
      setSelectedNonCancelledItems(selectedNonCancelledItems.concat([itemId]))
    }
  }

  const toggleSelectAll = (e) => {
    let newSelectedItems = (e.target.checked) ? data.map(item => item.id) : []
    setSelectedItems(newSelectedItems);
    let newSelectedNonCancelledItems = (e.target.checked) ? data.filter(item => item.deletedAt === null).map(item => item.id) : []
    setSelectedNonCancelledItems(newSelectedNonCancelledItems);
  }

  useEffect(() => {
    setSelectedItems([]);
    setSelectedNonCancelledItems([]);
    setMultiple(false);
  }, [data]);

  const showMultiple = (data || []).filter(x => x.deletedAt === null).length > 1;
  const showClearHighlight = (data || []).filter(x => x.hightlight !== "none").length > 0;

  return (
    <>
      <Modals
        itemId={itemId}
        selectedItemIds={selectedItems}
        selectedNonCancelledItemIds={selectedNonCancelledItems}
        onUpdated={onUpdated}
        onEdited={() => { if (!creationProcess) setShowAfterEdit(true) }}
        showEdit={showEdit}
        setShowEdit={setShowEdit}
        showClearHighlightEdit={showClearHighlightEdit}
        setShowClearHighlightEdit={setShowClearHighlightEdit}
        showDelete={showDelete}
        setShowDelete={setShowDelete}
        showMultiEdit={showMultiEdit}
        setShowMultiEdit={setShowMultiEdit}
        showMultiDelete={showMultiDelete}
        setShowMultiDelete={setShowMultiDelete}
        showAfterEdit={showAfterEdit}
        afterEditMessageType={afterEditMessageType}
        setAfterEditMessageType={setAfterEditMessageType}
        setShowAfterEdit={setShowAfterEdit}
        showAfterConfirmed={showAfterConfirmed}
        setShowAfterConfirmed={setShowAfterConfirmed}
        data={data || []}
        clearHighlight={clearHighlight}
        setClearHighlight={setClearHighlight}
      />

      <div className='row table-actions-box'>
        <div className='col-lg-12'>
          {fullAccess &&
            <Actions
              showExportButton={showExportButton}
              showCreate={showCreate}
              venueId={venueId}
              multiple={multiple}
              setMultiple={setMultiple}
              clearHighlight={clearHighlight}
              setClearHighlight={setClearHighlight}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              handleMultiEdit={handleMultiEdit}
              handleMultiDelete={handleMultiDelete}
              showMultiple={showMultiple}
              showClearHighlight={showClearHighlight}
              handleClearHighlightEdit={handleClearHighlightEdit}
              filter={filter}
            />
          }
          <MarkViewed
            venueId={venueId}
            nonViewed={nonViewed}
            nonViewedNames={nonViewedNames}
            setNonViewed={setNonViewed}
          />
        </div>
      </div>

      <table className="table-bookings table-shifts" id="bookings">
        <Header
          showFirstColumn={showFirstColumn}
          multiple={multiple}
          clearHighlight={clearHighlight}
          toggleSelectAll={toggleSelectAll}
        />

        <tbody>
        {(data || []).map(row => (
          <Row
            showFirstColumn={showFirstColumn}
            key={row.id}
            multiple={multiple}
            clearHighlight={clearHighlight}
            item={row}
            selectedItems={selectedItems}
            toggleSelectedItem={toggleSelectedItem}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        ))}
        </tbody>
        {(total || {}).minutes &&
        <tfoot>
        <tr>
          <td className='tl' data-title="" colSpan={showFirstColumn ? 2 : 1}>
            <strong>Total</strong>
          </td>
          <td/>
          <td/>
          <td className='type-column'/>
          <td data-title="Total">
            <strong>{total.minutes}</strong>
          </td>
          {fullAccess && <td className='actions-column'/>}
        </tr>
        </tfoot>
        }
      </table>
    </>
  )
}
