import React, { useState, useEffect, useMemo } from 'react';
import DatePicker from './Date';
import moment from 'moment';
import classNames from 'classnames';

export default ({ rangeLimit, timePeriod, onDateChange }) => {
  const [startDate, setStartDate] = useState(timePeriod.startDate);
  const [endDate, setEndDate] = useState(timePeriod.endDate);

  const setStartAndEndDate = (startDate) => {
    let momentStartDate = moment(startDate);
    let momentEndDate = endDate;

    if (momentStartDate && momentEndDate) {
      if (momentStartDate > momentEndDate) momentEndDate = momentStartDate;

      if (momentEndDate.diff(momentStartDate, 'days') > rangeLimit) {
        momentEndDate = moment(momentStartDate.toDate()).add(rangeLimit, 'day');
      }
    }

    setStartDate(momentStartDate);
    setEndDate(momentEndDate);
  };

  const setEndAndStartDate = (endDate) => {
    let momentEndDate = moment(endDate);
    let momentStartDate = startDate;

    if (momentStartDate && momentEndDate) {
      if (momentEndDate < momentStartDate) momentStartDate = momentEndDate;

      if (momentEndDate.diff(momentStartDate, 'days') > rangeLimit) {
        momentStartDate = moment(momentEndDate.toDate()).subtract(rangeLimit, 'day');
      }
    }

    setStartDate(momentStartDate);
    setEndDate(momentEndDate);
  };

  const enabledButton = useMemo(() => (startDate && endDate), [startDate, endDate]);

  return (
    <div className='calendar-wrapper'>
      <div className='pull-left calendar-left-part'>
        <div className='text-center'><h4>FROM</h4></div>
        <DatePicker date={startDate} setDate={setStartAndEndDate} secondDate={endDate}/>
      </div>

      <div className='pull-left calendar-right-part'>
        <div className='text-center'><h4>TO</h4></div>
        <DatePicker date={endDate} setDate={setEndAndStartDate} secondDate={startDate}/>
      </div>

      <div className='clearfix'/>

      <div style={{ margin: '0px 10px 10px 10px' }}>
        <button className={classNames(['btn btn-primary btn-block', { 'btn-grey': !enabledButton }])}
                disabled={!enabledButton}
                onClick={() => onDateChange({ startDate: startDate, endDate: endDate })}>
          Apply
        </button>
      </div>
    </div>
  )
};