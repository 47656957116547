import axios from "axios";
import NeoModal from "src/NeoModal";
import FileManager from './file_manager';

$(document).on('click', 'a.open_file_manager_modal', (event) => {
  event.preventDefault();

  const button = $(event.target);
  const link = button.attr('href');

  if (button.parent('li').hasClass('disabled')) return;

  axios.get(link).then((response) => {
    const modal = NeoModal.add(response.data, { callback: () => {
      const fileManager = new FileManager();
      fileManager.init();
    }});
  })
});
