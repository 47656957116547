import { processingModal } from "../helpers/processing_modal";
import axios from 'axios';

function BookingTable() {
  const fixWidth = function() {
    const tables = $('.table-bookings');

    tables.each(function() {
      const table = $(this);
      const pricePosition = table.find('.price_position');
      const nextElements = pricePosition.nextAll();

      let totalWidth = 0;

      nextElements.each(function(index) {
        totalWidth += parseInt($(this).outerWidth(), 0);
      });

      table.find('.price-reader').css('width', pricePosition.outerWidth());
      table.find('.price-corrector').css('min-width', totalWidth)
    });
  }

  const updateBookingsTable = (data, options = {}) => {
    $('#bookings-block').empty().append(data);
    start();

    if (options?.scroll) $('html, body').animate({ scrollTop: $("#bookings-block").offset().top });

    if (options?.onUpdated) {
      if (typeof options.onUpdated === 'function') options.onUpdated();
    }
  }

  const cleanBookingsTable = () => {
    $('#bookings-block').empty();
  }

  const start = function() {
    fixWidth();
  }

  const reloadTable = function(withScroll, options = {}) {
    const reload_url = $('#bookings-block .table-bookings').data('reload-url');

    axios.get(reload_url).then((response) => {
      updateBookingsTable(response.data, options);
      processingModal.hide();
    }).catch(() => console.error('Something went wrong'));
  };

  return {
    start: start,
    update: updateBookingsTable,
    clean: cleanBookingsTable,
    reload: reloadTable
  }
}

window.bookingTable = BookingTable();
