import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Form from "./Form";
import axios from "axios";
import rescue from "../../../rescue";

export default ({ show, setShow, onUpdated, itemId, setAfterEditMessageType}) => {
  const [staffItem, setStaffItem] = useState(null);
  const [errors, setErrors] = useState({});
  const [updatingInProgress, setUpdatingInProgress] = useState(false);

  useEffect(() => {
    if (show) {
      axios
        .get(`/admin/staff_items/${itemId}/edit.json`)
        .then((response) => {
          setStaffItem(response.data.staffItem);
        });
    } else {
      setStaffItem(null);
      setErrors({});
    }
  }, [show])

  const confirmChanges = () => {
    if (updatingInProgress) return true;

    setUpdatingInProgress(true)

    const formData = new FormData(document.getElementById(`edit-staff-item-${itemId}`));

    axios
      .put(`/admin/staff_items/${itemId}.json`, formData)
      .then((response) => {
        setShow(false)
        onUpdated();
        setAfterEditMessageType(response.data.message)
        setTimeout(() => setUpdatingInProgress(false), 150)
      })
      .catch(error => {
        setUpdatingInProgress(false)
        rescue(error, setErrors)
      });
  }

  return (
    <Modal show={show} onHide={() => setShow(false)} dialogClassName="modal-slarge">
      <Modal.Header closeButton>
        <Modal.Title>Edit shift</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {staffItem && <Form errors={errors} staffItem={staffItem}/>}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => setShow(false)}>Close</Button>
        <Button bsStyle={'primary'} disabled={updatingInProgress} onClick={confirmChanges}>Confirm</Button>
      </Modal.Footer>
    </Modal>
  );
}