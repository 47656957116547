import React from "react";

export default ({ availableExportOptions, exportType, setExportType }) => {
  return (
    <select name="reportExportType"
            className="form-control select required"
            value={exportType || ''}
            onChange={e => setExportType(e.target.value)}>
      {availableExportOptions.map(exportOption => <option key={exportOption} value={exportOption}>{exportOption}</option>)}
    </select>
  )
};
