import axios from "axios";

class Api {
  getStaffRota = () => {
    return axios.get('/admin/staff_rota.json')
  }

  removeRow = (staffItemId) => {
    return axios.delete(`/admin/staff_items/${staffItemId}.json`)
  }

  removeRows = (staffItemIds) => {
    return axios.delete(`/admin/staff_items/multidestroy`, { data: { id: staffItemIds } })
  }
}

const api = new Api();
export default api;