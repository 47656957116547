import snakeCaseKeys from 'snakecase-keys';

export const getQueryString = (filter) => {
  return Object
    .entries(snakeCaseKeys(filter))
    .filter(([_key, val]) => (val !== undefined && val !== null))
    .map(([key, val]) => {
      if (Array.isArray(val)) {
        return val.map((val) => `${key}[]=${val}`).join('&')
      } else {
        return `${key}=${val}`
      }
    }).join('&');
};

export const getReportUrl = (item, filter, page) => {
  const queryString = getQueryString(filter);

  return `/admin/reports/${item.key}.json?${queryString}&page=${page}`;
};

export const loadData = (url) => {
  return fetch(url).then(res => res.json())
};

export const browseFacilityRequest = (url, data) => {
  const headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Cache-Control': 'no-store, no-cache, must-revalidate, private'
  }
  if (data) {
    data = encodeURI(getQueryString(data))
    url = url + "?" + data
  }
  return fetch(url, { headers: headers }).then(res => res.json() )
}
