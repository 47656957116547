import React, {useState} from 'react'

export default ({question, suffix}) => {
  const [enabled, setEnabled] = useState(question.allFacilities);

  const toggleVenues = (e, type) => {
    e.preventDefault();

    const checked = type === 'all';
    const checkboxes = $('.modal-body').find(`.type-venue`);

    $.each(checkboxes, (index, el) => $(el).prop('checked', checked).trigger('change'));
  }

  return (
    <div className="form-group">
      <label className="m-t">Applicable Venues</label>
      <div className="row" style={{paddingBottom: "10px"}}>
        <div className="col-md-12 ">
          <a className="multiselect venue-btn" href="#" onClick={(e) => toggleVenues(e, 'all')}>All</a>
          &nbsp;&nbsp;&nbsp;
          <a className="multiselect venue-btn" href="#" onClick={(e) => toggleVenues(e, 'none')}>None</a>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 " style={{columns: 2}}>
          <input name={`${suffix}[venue_ids][]`} type="hidden"/>

          {question.userVenues.map(venue =>
            <div className="form-group checkbox" style={{marginTop: 0}} key={`${suffix}_venue_ids_`+venue.id}>
              <label htmlFor={`${suffix}_venue_ids_`+venue.id}>
                <input
                  className="boolean optional type-venue"
                  type="checkbox"
                  value={venue.id}
                  defaultChecked={question.questionVenueIds.includes(venue.id)}
                  name={`${suffix}[venue_ids][]`}
                  id={`${suffix}_venue_ids_`+venue.id}
                />
                {venue.title}
              </label>
            </div>
          )
          }
        </div>
      </div>
    </div>
  )
};
