import React, { useState, useEffect } from "react";
import Calendar from 'react-calendar';
import Block from "./Block";
import TimeSelector from "../components/Form/TimeSelector";
import axios from 'axios';

import 'rc-time-picker/assets/index.css';
import 'react-calendar/dist/Calendar.css';
import Error from "../components/Form/Error";
import DateInput from "src/components/DateInput";

export default (props) => {
  const { size, removeItem, repeatShiftEnabled, index, venueId, errors } = props;
  const [block, setBlock] = useState(false)
  const [valueB, onChangeValueB] = useState(new Date());
  const [users, setUsers] = useState([]);
  const [shiftOptions, setShiftOptions] = useState(JSON.parse(localStorage.getItem('shiftOptions')) || { start: 30, end: 15 });

  const handleMinutesChange = (name, value) => {
    shiftOptions[name] = value;
    localStorage.setItem('shiftOptions', JSON.stringify(shiftOptions));
    setShiftOptions(JSON.parse(localStorage.getItem('shiftOptions')));
  };

  useEffect(() => {
    axios
      .get('/admin/staff_rota/users.json', { params: { venue_id: venueId } })
      .then((response) => setUsers(response.data))

    return function() {
      console.log('unmount')
    };
  }, []);

  return (
    <div className="shift-item bookings-time-list">
      <input type='hidden' name={`staff_item[${index}][venue_id]`} value={venueId}/>
      <div className='calendar-box'>
        <Calendar
          onChange={onChangeValueB}
          value={valueB}
        />
      </div>
      <div className='calendar-outside'>
        <div className='row'>
          <div className="col-md-3 col-sm-12">
            <div className="form-group select optional">
              <label className="control-label select optional">
                Name
              </label>
              <select className="form-control select optional"
                      name={`staff_item[${index}][user_id]`}>
                {users.map(user => <option key={user.id} value={user.id}>{user.name}</option>)}
              </select>

              <Error errors={errors} name={`staff_item[${index}][user_id]`} prefix={'User'}/>
            </div>
          </div>

          <div className='col-md-3 col-sm-12'>
            <div className="form-group optional">
              <label className="optional control-label">Date</label>
              <DateInput
                onChange={onChangeValueB}
                value={valueB}
                name={`staff_item[${index}][event_date]`}
                className='form-control date-input text-left'
                //format="dd/MM/y"
              />
            </div>
          </div>
        </div>

        <div className='row'>
          <div className="col-md-6">
            <label className="control-label select optional">
              Start
            </label>
            <TimeSelector
              name='start'
              index={index}
              defaultValue={moment("17:00", 'H:m')}
              defaultMinutes={shiftOptions['start']}
              defaultDirection={'before'}
              defaultSession={'first'}
              handleMinutesChange={handleMinutesChange}
            />
          </div>

          <div className="col-md-6">
            <label className="control-label select optional">End</label>
            <TimeSelector
              name='end'
              index={index}
              defaultValue={moment("22:00", 'H:m')}
              defaultMinutes={shiftOptions['end']}
              defaultDirection={'after'}
              defaultSession={'last'}
              saveShiftOptions={true}
              handleMinutesChange={handleMinutesChange}
            />
          </div>
        </div>

        {(repeatShiftEnabled || repeatShiftEnabled === undefined) &&
        <>
          <div className="vert-offset-bottom-10">
            <div className='row'>
              <div className='col-md-6'>
                <a className="btn btn-primary btn-one-more btn-no-radius message" onClick={() => setBlock(!block)}>
                  {block ? 'Delete Repeat Shift' : 'Repeat Shift'}
                </a>
              </div>
              <div className='col-md-6 text-right'>
                {size > 1 &&
                <a className="btn btn-danger btn-one-more btn-no-radius message" onClick={() => removeItem()}>
                  Delete shift
                </a>
                }
              </div>
            </div>
          </div>

          {block && <Block eventDate={valueB} setEventDate={onChangeValueB} index={index}/>}
        </>
        }

        <Error errors={errors} name={`staff_item[${index}][end_time]`} prefix={'End time'}/>
      </div>

      <div className="clearfix"/>
    </div>
  )
}