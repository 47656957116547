import { Controller } from "@hotwired/stimulus";
import NeoDialog from "../src/NeoDialog";
import {bookingOptionDayRateEvents} from "src/nextgen/admin/booking_options/day_rate";

class BookingOptionController extends Controller {
  connect() {
    this.init()
  }

  init() {
    this.venueId = this.element.dataset.venueId;

    $('input.datepicker').datepicker({
      format: 'D, dd M yyyy',
      autoclose: true,
      startDate: '+1d',
      weekStart: 1
    })

    const months = ["jan", "feb", "mar", "apr", "may", "jun", "jul","aug", "sep", "oct", "nov", "dec"];
    const types = ['', 'ext_']

    const virtualSpace = $('#booking_option_virtual_space');
    const currentOffPeakEnabled = $('#booking_option_current_booking_option_price_attributes_off_peak_enabled');
    const futureOffPeakEnabled = $('#booking_option_future_booking_option_price_attributes_off_peak_enabled');
    const addFuturePrice = $('#booking_option_add_future_price');
    const current_off_peak_price = $('.booking_option_current_booking_option_price_off_peak_price');
    const current_off_peak_weekend_price = $('.booking_option_current_booking_option_price_off_peak_weekend_price');
    const future_off_peak_price = $('.booking_option_future_booking_option_price_off_peak_price');
    const future_off_peak_weekend_price = $('.booking_option_future_booking_option_price_off_peak_weekend_price');

    const ext_current_off_peak_price = $('.booking_option_current_booking_option_price_ext_off_peak_price');
    const ext_current_off_peak_weekend_price = $('.booking_option_current_booking_option_price_ext_off_peak_weekend_price');
    const ext_future_off_peak_price = $('.booking_option_future_booking_option_price_ext_off_peak_price');
    const ext_future_off_peak_weekend_price = $('.booking_option_future_booking_option_price_ext_off_peak_weekend_price');

    const currentOffPeakPrice = $('#booking_option_current_booking_option_price_attributes_off_peak_price');
    const currentOffPeakWeekendPrice = $('#booking_option_current_booking_option_price_attributes_off_peak_weekend_price')
    const futureOffPeakPrice = $('#booking_option_future_booking_option_price_attributes_off_peak_price');
    const futureOffPeakWeekendPrice = $('#booking_option_future_booking_option_price_attributes_off_peak_weekend_price');

    const extCurrentOffPeakPrice = $('#booking_option_current_booking_option_price_attributes_ext_off_peak_price');
    const extCurrentOffPeakWeekendPrice = $('#booking_option_current_booking_option_price_attributes_ext_off_peak_weekend_price')
    const extFutureOffPeakPrice = $('#booking_option_future_booking_option_price_attributes_ext_off_peak_price');
    const extFutureOffPeakWeekendPrice = $('#booking_option_future_booking_option_price_attributes_ext_off_peak_weekend_price');

    const currentSeasonsEnabled = $('#booking_option_current_booking_option_price_attributes_seasons_enabled');
    const futureSeasonsEnabled = $('#booking_option_future_booking_option_price_attributes_seasons_enabled');

    const onPeakWeekdayPrice = $('#booking_option_current_booking_option_price_attributes_on_peak_price');
    const onPeakWeekendPrice = $('#booking_option_current_booking_option_price_attributes_on_peak_weekend_price');

    const addDayRate = $('#booking_option_add_day_rate');

    const checkCustomSpace = (preassign) => {
      $('#custom_space').toggle(virtualSpace.val() === 'Custom');
      toggleParentBookingOption(preassign);
    }

    const toggleCurrentOffPeakEnabled = () => {
      let checked = currentOffPeakEnabled.is(':checked')
      current_off_peak_price.toggle(checked);
      current_off_peak_weekend_price.toggle(checked);
      ext_current_off_peak_price.toggle(checked);
      ext_current_off_peak_weekend_price.toggle(checked);
      if(checked) { addDayRate.prop('checked', !checked).change() }
      toggleBookingOptionDayRateOnCheckedPricing();
    }

    const toggleFutureOffPeakEnabled = () => {
      let checked = futureOffPeakEnabled.is(':checked')
      future_off_peak_price.toggle(checked);
      future_off_peak_weekend_price.toggle(checked);
      ext_future_off_peak_price.toggle(checked);
      ext_future_off_peak_weekend_price.toggle(checked);
    }

    const toggleFuturePrice = () => {
      let checked = addFuturePrice.is(':checked');
      let marker = $('.future_booking_option_price input[name="booking_option[future_booking_option_price_attributes][_destroy]"]')
      $('.future_booking_option_price').toggle(checked)
      marker.val(!checked);

      if ($('#add-future-price-block').data('persisted') === false) {
        futureOffPeakEnabled.prop('checked', currentOffPeakEnabled.is(':checked'))
        toggleFutureOffPeakEnabled()

        futureSeasonsEnabled.prop('checked', currentSeasonsEnabled.is(':checked'))
        toggleFutureSeasonPrice();

        months.forEach(month => {
          types.forEach(priceType => {
            const currentMonth = $(`#booking_option_current_booking_option_price_attributes_${priceType}months_${month}`)
            const futureMonth = $(`#booking_option_future_booking_option_price_attributes_${priceType}months_${month}`)

            futureMonth.prop('checked', currentMonth.is(':checked'))
          })
        })
      }
      toggleBookingOptionDayRateOnCheckedPricing();
    }

    const toggleDayRateCheckbox = () => {
      let checked = addDayRate.is(':checked');
      let marker = $('.booking_option_day_rate input[name="booking_option[booking_option_day_rate_attributes][_destroy]"]')
      $('.booking_option_day_rate').toggle(checked)
      marker.val(!checked);
      if (!checked) {
        onPeakWeekdayPrice.attr('disabled', checked);
        onPeakWeekendPrice.attr('disabled', checked);
        checkCurrent();
      } else {
        addFuturePrice.prop('checked', !checked).change();
        currentSeasonsEnabled.prop('checked', !checked).change();
        currentOffPeakEnabled.prop('checked', !checked).change();
        currentOffPeakEnabled.attr('disabled', checked);
      }
      addFuturePrice.attr('disabled', checked);
      currentSeasonsEnabled.attr('disabled', checked);
    }

    const toggleCurrentSeasonPrice = () => {
      let checked = currentSeasonsEnabled.is(':checked')
      $('.current_booking_option_price_days').toggle(checked)
      $('.ext_block_current_booking_option_price').toggle(checked)
      toggleBookingOptionDayRateOnCheckedPricing();
    }

    const toggleFutureSeasonPrice = () => {
      let checked = futureSeasonsEnabled.is(':checked')
      $('.future_booking_option_price_days').toggle(checked)
      $('.ext_block_future_booking_option_price').toggle(checked)
    }

    const checkCurrent = () => {
      const seasonsEnabled = !currentSeasonsEnabled.is(':checked') ||
        (currentSeasonsEnabled.is(':checked') &&
          extCurrentOffPeakPrice.val() === '' &&
          extCurrentOffPeakWeekendPrice.val() === '');

      const disabled = !(currentOffPeakPrice.val() === '' &&
        currentOffPeakWeekendPrice.val() === '' &&
        seasonsEnabled);

      $('input[name="booking_option[current_booking_option_price_attributes][off_peak_enabled]"][type=hidden]').val(disabled ? '1' : 0)
      currentOffPeakEnabled.prop('disabled', disabled);
    }

    const checkFuture = () => {
      const seasonsEnabled = !futureSeasonsEnabled.is(':checked') ||
        (futureSeasonsEnabled.is(':checked') &&
          extFutureOffPeakPrice.val() === '' &&
          extFutureOffPeakWeekendPrice.val() === '');

      const disabled = !(futureOffPeakPrice.val() === '' &&
        futureOffPeakWeekendPrice.val() === '' &&
        seasonsEnabled);

      $('input[name="booking_option[future_booking_option_price_attributes][off_peak_enabled]"][type=hidden]').val(disabled ? '1' : 0)
      futureOffPeakEnabled.prop('disabled', disabled);
    }

    virtualSpace.on('change', () => checkCustomSpace(true));
    currentOffPeakEnabled.on('change', toggleCurrentOffPeakEnabled);
    futureOffPeakEnabled.on('change', toggleFutureOffPeakEnabled);
    addFuturePrice.on('change', toggleFuturePrice);

    currentOffPeakPrice.on('keyup', checkCurrent);
    currentOffPeakWeekendPrice.on('keyup', checkCurrent);
    futureOffPeakPrice.on('keyup', checkFuture);
    futureOffPeakWeekendPrice.on('keyup', checkFuture);

    currentSeasonsEnabled.on('change', toggleCurrentSeasonPrice);
    futureSeasonsEnabled.on('change', toggleFutureSeasonPrice);

    addDayRate.on('change', toggleDayRateCheckbox);


    $('.booking-option-price-month').on('change', function(el) {
      const regex=/booking_option_(current_booking_option_price|future_booking_option_price)_attributes_(ext_|)months_([a-z]{3})/
      const id = $(this).attr('id');
      const match = regex.exec(id);
      const wsInverse = match[2] === 'ext_' ? '' : 'ext_'
      const newId = `#booking_option_${match[1]}_attributes_${wsInverse}months_${match[3]}`;

      $(newId).prop('checked', !$(this).prop('checked'));
    });

    const toggleParentBookingOption = (preassign) => {
      $('#booking_option_parent_booking_option_id option').each(function(i, el) {
        const percent = $(el).data('percent');
        if (percent === undefined) return;
        const space = $('#booking_option_virtual_space').val();
        $(el).toggle(parseFloat(percent) === parseFloat(space));
      })

      const visibleElements = $('#booking_option_parent_booking_option_id option:not([style*="display: none"])');
      const visible = visibleElements.length > 1;
      $('div.booking_option_parent_booking_option_id').toggle(visible);

      if (visible && preassign) {
        const bookingOptionGroups = visibleElements.filter('*[data-group=\"true\"]');

        if (bookingOptionGroups.length > 0) {
          const value = $(bookingOptionGroups[0]).val()
          $('#booking_option_parent_booking_option_id').val(value).change()
        }
      }

      const bookingOptionSelect = $('#booking_option_parent_booking_option_id');

      if (visible && bookingOptionSelect.val() === null) {
        const firstVisibleOptionVal = visibleElements[0].value;

        bookingOptionSelect.val(firstVisibleOptionVal).change();
      }

      if (visible && bookingOptionSelect.val() !== '') {
        const hiddenBookingOptions = visibleElements.filter('*[data-hidden-empty-option=\"true\"]');

        hiddenBookingOptions.each((i, el) => el.remove());
      }
    }

    const toggleBookingOptionDayRateOnCheckedPricing = () => {
      let checked = currentOffPeakEnabled.is(':checked') ||
        currentSeasonsEnabled.is(':checked') ||
        addFuturePrice.is(':checked')

      addDayRate.attr('disabled', checked);
      if (checked) {
        addDayRate.attr('checked', !checked);
        toggleDayRateCheckbox();
      }
    }

    const parentBookingOption = $('#booking_option_parent_booking_option_id');
    const parentBookingOptionValue = parentBookingOption.data('value') || ''
    parentBookingOption.val(parentBookingOptionValue).change()

    $('#booking_option_add_future_price').on('click', (event) => {
      const showPopup = sessionStorage.getItem('showFuturePriceRedirectConfirmation');
      const popupText = "If you are updating future prices for multiple booking options/facilities, " +
        "this can be done all in one go via the Pricing tab in the Venue section. " +
        "Would you like to be redirected there?"
      const checked = event.target.checked;

      if (checked && showPopup == null || showPopup === 'true') {
        NeoDialog.confirm(popupText, 'Yes', 'No')
          .then((result) => {
            if (result.isConfirmed) {
              sessionStorage.setItem('showFuturePriceRedirectConfirmation', true);
              window.location = `/admin/venues/${this.venueId}?tab=pricing&futurePricesModal=true`;
            } else {
              sessionStorage.setItem('showFuturePriceRedirectConfirmation', false);
            }
          });
      }
    })

    checkCustomSpace();
    toggleCurrentOffPeakEnabled();
    toggleFutureOffPeakEnabled();
    toggleFuturePrice();
    toggleCurrentSeasonPrice();
    toggleFutureSeasonPrice();
    checkCurrent();
    checkFuture();
    toggleDayRateCheckbox();
    toggleParentBookingOption();
    bookingOptionDayRateEvents();
    toggleBookingOptionDayRateOnCheckedPricing();
  }
}

export default BookingOptionController;
