import React from "react";
import { components } from "react-select";

const ValueContainer = ({ children, ...props }) => {
  const [values, input] = children;
  let newValues = '';

  if (Array.isArray(values)) {
    if (props.selectProps.selectedAll) {
      newValues  = 'All';
    } else if (values.length > 1) {
      newValues = `${values.length} selected`;
    } else {
      newValues = values[0].props.children
      if (values.length > 26) newValues = values.substring(0, 26) + '...'
    }
  }

  return (
    <components.ValueContainer {...props}>
      {newValues}
      {input}
    </components.ValueContainer>
  );
};

export default ValueContainer;