import { processingModal } from "../helpers/processing_modal";

function ContinueButton () {
  const handleOrderContinue = function(e) {
    e.preventDefault();

    const button = $('#continue-order');

    if (!button.prop('disabled')) {
      button.prop('disabled', true)

      const data = $(e.target).data();
      const { issue, orderId } = data;

      if (issue) {
        showBootstrapAlert('', issue)
        return true;
      }

      const checkoutOptionKey = $('input[name="checkout_option_changing[checkout_option_key]"]:checked').val();

      if (checkoutOptionKey === 'customer_to_checkout') {
        $('#send-to-user-modal').modal('show')
        button.prop('disabled', false)
      } else {
        processingModal.show();

        goToCheckout(
          orderId,
          function(data) { window.location = data.url },
          function(data) {
            processingModal.hide();
            showBootstrapAlert('', data.responseJSON.error);
            window.bookingTable.reload(false);
            button.prop('disabled', false)
          }
        );
      }
    }
  }

  const handleOrderCastling = function(e) {
    e.preventDefault();

    const data = $(e.target).data();
    const { owner, orderCalculationId } = data;

    if (owner) {
      if (owner === 'venue') {
        window.location.href = `/bills/${orderCalculationId}/castling`;
      } else {
        $('#confirm-modification').modal('show');
        button.prop('disabled', false)
      }
    }
  }

  function goToCheckout(orderId, onSuccess, onFailed) {
    $.ajax({
      type: "GET",
      url: `/orders/${orderId}/prepare`,
      success: onSuccess,
      error: onFailed
    })
  }

  function showBootstrapAlert(title, text) {
    const alertModal = $('#bootstrap-alert');
    const titleElement = alertModal.find('.modal-header .modal-title');
    const bodyElement = alertModal.find('.modal-body .alert-context');

    titleElement.html(title);
    bodyElement.html(text);

    alertModal.modal('show');
  }

  const submitSendToUserForm = function(e) {
    e.preventDefault();

    const form = $('#send-to-user-modal form');
    const submitBtn = form.find('input[type=submit]')
    form.find('.text-danger').remove();

    $.ajax({
      type : 'POST',
      url: form.attr('action'),
      dataType: 'json',
      data: form.serialize(),
      success: (data) => {
        if (data.add_show_notification) localStorage.showNotification = 'showNotification';
        setTimeout(() => window.location.replace(data.redirect_link), 100);
      },
      error: (error) => {
        const { errors } = error.responseJSON;

        if (errors.base) {
          $('#send-to-user-modal').modal('hide');
          showBootstrapAlert('', errors.base.join(', '));
          window.bookingTable.reload();
          submitBtn.prop('disabled', false)
        } else {
          submitBtn.prop('disabled', false)
          addErrors(errors);
        }
      }
    });
  }

  function addErrors(errors) {
    removeErrors();

    _.each(errors, function (value, key) {
      $('#send_to_user_' + key)
        .closest('.form-group')
        .append('<span class="text-danger">' + value.join(', ') + '</span>')
    });
  }

  function removeErrors() {
  }

  return {
    handleOrderContinue: handleOrderContinue,
    handleOrderCastling: handleOrderCastling,
    submitSendToUserForm: submitSendToUserForm
  }
}

window.continueButton = new ContinueButton();