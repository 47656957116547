import { Controller } from "@hotwired/stimulus";
import axios from "axios";
import NeoModal from "src/NeoModal";
import Neo from "src/Neo";

class FacilityController extends Controller {
  connect() {
    this.id = this.element.dataset.id;
  }

  publish(e) {
    e.preventDefault();
    const link = e.target;
    const url = link.getAttribute('href');

    axios.put(url).then((response) => {
      this.updateColumns();
    }).catch((error) => {
      Neo.processError(error, (e) => Helper.flash_message('error', e.response.data.errors.join(', ')))
    })
  }

  deleteAsSystemAdmin(e) {
    e.preventDefault();

    const button = e.target;
    const url = button.dataset.url;

    axios.get(url).then((response) => {
      const modal = NeoModal.add(response.data);

      modal.addEventListener('neo_modal:success', (event) => {
        Helper.flash_message('success', event.detail.response.data.notice);
        $(modal).modal('hide');
        Neo.reloadTable('admin_facilities_table');
      })
    });
  }

  delete(e) {
    e.preventDefault();

    const { dataset } = e.target;

    Neo.confirm(dataset.message, () => {
      axios.delete(dataset.url).then((response) => {
        Helper.flash_message('success', response.data.notice)
        Neo.reloadTable('admin_facilities_table');
      }).catch((error) => {
        Neo.processError(error, () => Helper.flash_message('error', error.response.data.error))
      })
    })
  }

  addDestroyRequest(e) {
    e.preventDefault();

    axios.get(e.target.dataset.url).then(response => {
      const modal = NeoModal.add(response.data);

      modal.addEventListener('neo_modal:success', (data) => {
        const notice = data.detail?.response?.data?.notice;
        Helper.flash_message('success', notice);
        $(modal).modal('hide');
        this.updateColumns();
      });
    })
  }

  removeDestroyRequest(e) {
    e.preventDefault();

    Neo.confirm(e.target.dataset.message, () => {
      axios.delete(e.target.dataset.url).then((response) => {
        Helper.flash_message('success', response.data.notice);
        this.updateColumns();
      })
    });
  }

  updateColumns(facilityId) {
    axios.get(`/admin/facilities/${this.id}`, { headers: { 'Accept': 'text/vnd.turbo-stream.html' } }).then((r) => {
      Turbo.renderStreamMessage(r.data);
    }).catch((error) => Helper.flash_message('error', 'Something went wrong'));
  }
}

export default FacilityController;
