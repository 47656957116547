(function() {
  $.initVenueListOrder = function() {
    $('#sortable-venues').sortable();
    $('.venue-order-wrapper').on('click', (e) => {
      const target = $(e.target)
      const group = $(e.target).closest('.group')
      group.find('.children-group').slideToggle(500);
      target.find('.fa-angle-down').slideToggle(0);
      target.find('.fa-angle-up').slideToggle(0);
    });
  };
}).call(this);
