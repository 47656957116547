import axios from 'axios';
import { processingModal } from "../helpers/processing_modal";

function PaymentOptions() {
  const onChange = function(event) {
    processingModal.show(500)

    const target = event.target;
    const url = target.closest('form').getAttribute('action');
    const submitLink = document.getElementById('continue-order');
    const table = $('#bookings-block .table-bookings');
    const reloadPaymentsUrl = table.data('reload-payments-url');
    const paymentOption = target.value;
    const reloadBillIssueUrl = table.data('reload-issue-url');
    const endpoints = [reloadPaymentsUrl, reloadBillIssueUrl];

    submitLink.classList.add('disabled_link_continue');

    axios
      .put(url, { order: { payment_option: paymentOption } })
      .then(() => {
        axios
          .all(endpoints.map((endpoint) => axios.get(endpoint)))
          .then(axios.spread(({ data: paymentsTable }, { data: billIssue }) => {
            submitLink.classList.remove('disabled_link_continue');
            document.getElementById('payments_list').innerHTML = paymentsTable;

            if (_.isEmpty(billIssue.issue)) {
              submitLink.classList.remove('disabled_link_continue');
              submitLink.disabled = false;
            }

            $(submitLink).data('issue', billIssue.issue);
            processingModal.hide();
          }))
          .catch(() => {
            Helper.flash_message('error', 'Something went wrong!');
            submitLink.classList.remove('disabled_link_continue');
            processingModal.hide()
          });
      })
      .catch(error => {
        Helper.flash_message('error', error.response.data.errors);
        submitLink.classList.remove('disabled_link_continue');
        processingModal.hide();
      });
  };

  return {
    onChange: onChange
  }
}

window.paymentOptions = PaymentOptions();
