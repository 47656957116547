import { Controller } from "@hotwired/stimulus"
import NeoDialog from "../../src/NeoDialog";

class FuturePricesModalController extends Controller {
  static values = { 
    confirmOpen: Boolean,
    alternativeUrl: String
  }

  connect() {
    const queryParams = new URLSearchParams(window.location.search);
    const openModal = queryParams.get('futurePricesModal');

    if (openModal == 'true') {
      this.element.click();
    }
  }

  openWithConfirm(event) {
    event.preventDefault();

    if (this.confirmOpenValue) {
      const dialogText = "One or more of your facilities already have a future price set. " +
                         "Any changes you make will override the future prices currently on the system. " +
                         "The Pricing Report will allow you to review future prices set on the system.";    

      NeoDialog.confirm(dialogText, 'Continue', 'Review Pricing Report')
        .then((result) => {
          if (result.isConfirmed) {
          this.confirmOpenValue = false;
          this.element.classList.add('open_link_in_modal');
          this.element.click();
          this.confirmOpenValue = true;
          this.element.classList.remove('open_link_in_modal');
        } else {
          window.location = this.alternativeUrlValue;
        }
      });
    } else {
      this.element.classList.add('open_link_in_modal');
      this.element.click();
    }
  }
}

export default FuturePricesModalController;
