import React, {useContext, useState} from "react";
import { dateFormat, timeFormat } from "../../Formats";
import { Button } from "react-bootstrap";
import classnames from 'classnames'
import { ViewModeContext } from "../../utils/context";

const timePresenter = ({ timeOption, time, minutes, direction, session }) => {
  if (timeOption === 'fixed' || time) {
    return timeFormat(time);
  } else {
    return "TBC";
  }
}

export default ({ multiple, clearHighlight, item, selectedItems, toggleSelectedItem,
                  handleEdit, handleDelete, showFirstColumn }) => {
  const { fullAccess } = useContext(ViewModeContext);
  const { start, end } = item;

  const [hightlightClass, setHighlightClass] = useState(item.hightlight);
  const rowClass = classnames(
    [
      item.valid ? 'time_slot_available' : 'time_slot_closed',
      `staff-item-${item.hightlight}`
    ]
  );

  return (
    <tr data-id={item.id} className={rowClass}>
      {showFirstColumn &&
        <td className="tl" data-title={fullAccess ? 'Staff' : 'Venue'}>
          {fullAccess ? item.userName : item.venueTitle}
        </td>
      }
      <td data-title="Date" className='date-column'>
        {dateFormat(item.eventDate)}
      </td>
      <td data-title="Start time">
        {timePresenter(start)}
      </td>
      <td data-title="End time">
        {timePresenter(end)}
      </td>
      <td className='type-column'>
        {item.linkType === 'linked_to_bookings' ? 'Linked to bookings' : 'Fixed'}
      </td>
      <td data-title="Total">
        {item.deletedAt === null ? item.minutes : 'Cancelled'}
      </td>
      {fullAccess &&
        <td data-title="" className='actions-column'>
          <>
            {(item.deletedAt === null && multiple) &&
            <div className='text-center' style={{ padding: '3px 0 3px 0'}}>
              <input
                type='checkbox'
                checked={selectedItems.includes(item.id)}
                onChange={() => toggleSelectedItem(item.id)}
              />
            </div>
            }
            {(clearHighlight && item.hightlight != "none") &&
            <div className='text-center' style={{ padding: '3px 0 3px 0'}}>
              <input
                type='checkbox'
                checked={selectedItems.includes(item.id)}
                onChange={() => {
                  toggleSelectedItem(item.id)
                }}
              />
            </div>
            }
            {(item.deletedAt === null && !multiple && !clearHighlight) &&
            <div className='btn-group btn-group-xs sh-btn-group'>
              <Button onClick={() => handleEdit(item.id)}>Edit</Button>
              <Button onClick={() => handleDelete(item.id)}>Delete</Button>
            </div>
            }
          </>
        </td>
      }
    </tr>
  )
}