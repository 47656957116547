import React from "react";
import { Link } from "react-router-dom";


export default ({ venueId }) => (
  <Link className="btn btn-lg btn-primary btn-no-radius"
        style={{ width: 250 }}
        type='button'
        to={`/admin/staff_rota?venue_id=${venueId}`}>
    Back
  </Link>
)