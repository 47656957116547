class MembershipPlan {
  constructor() { }

  start() {
    if ($('#membership-plan-table').length < 1) {
      return;
    }

    this.toDataTable();

    $('#new_filter .filter_venue_id select').on('change', () => {
      this.reloadData(true);
    });

    $('#new_filter .filter_terms input').on('keyup', () => {
      this.reloadData(true);
    });
  }

  toDataTable() {
    $('#membership-plan-table').dataTable({
      order: [[0, 'asc']],
      processing: true,
      serverSide: true,
      searching: false,
      lengthChange: false,
      pageLength: 30,
      ajax: this.customersURL,
      pagingType: 'full_numbers',
      columnDefs: [
        {
          targets: 5,
          orderable: false,
          width: '10%'
        }
      ],

      fnDrawCallback: (oSettings) => {
        const $table_wrapper = $(oSettings.nTableWrapper);
        const $table = $table_wrapper.find('table');
        const titles = _.map($table.find('thead th'), (x, y) => x.innerText);
        const show = oSettings._iDisplayLength <= oSettings.fnRecordsDisplay();

        $table_wrapper.find('.dataTables_paginate').toggle(show);
        $table_wrapper.find('.dataTables_info').toggle(show);

        _.each($table.find('tr'), (row) => {
          _.each($(row).find('td'), (cell, index) => {
            $(cell).attr('data-title', titles[index]);
          });
        });
      }
    });
  }

  reloadData(changePage) {
    const ajax = $('#membership-plan-table').DataTable().ajax;
          ajax.url(this.customersURL);
          ajax.reload(null, changePage);
  }

  get customersURL() {
    return $('#membership-plan-table').data('source') + '?' + $('form#new_filter').serialize();
  }
}

export default MembershipPlan;
