import axios from 'axios';

$(document).ready(function() {
  $('.area_nav').on('selected_tab_facilities-order', function () {
    $('#sortable-facility, #sortable-facility .children-group').sortable({ stop: (e) => collectOrders(e) });

    $('.facility-order-wrapper.parent-facility').on('click', (e) => {
      const target = $(e.target)
      const group = $(e.target).closest('.group')
      group.find('.children-group').slideToggle(500);
      target.find('.fa-angle-down').slideToggle(0);
      target.find('.fa-angle-up').slideToggle(0);
    });

    function collectOrders(e) {
      const self = $(e.target);
      const facilities = self.find('> .ui-sortable-handle > .facility-order-wrapper');
      const ids = $.map(facilities, (el) => $(el).data('id'));

      axios
        .put('/admin/facilities/reorder', { ids: ids })
        .then(() => {
          $.each(facilities, (index, el) => $(el).find('span.list-order').html(index + 1));
          Helper.flash_message('success', 'Successfully updated')
        })
        .catch((e) => {
          console.log(e)
          Helper.flash_message('error', 'Something went wrong')
        })
    }
  });
});
