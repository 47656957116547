window.adminInternalNotes = function() {
  $('.create_notes_action').on('click', function(e) {
    e.preventDefault();
    make('create_notes');
  });

  $('.append_notes_action').on('click', function(e) {
    e.preventDefault();
    make('append_notes');
  });

  $('.delete_notes_action').on('click', function(e) {
    e.preventDefault();
    make('delete_notes');
  });

  $('.bookings-checkboxes').on('change', function(e) {
    e.preventDefault();
    toggleNotesButtons();
  });

  function toggleNotesButtons() {
    const disabled = $("#internal_notes input:checkbox:checked").length === 0;
    $('.notes-buttons').prop('disabled', disabled)
  }

  function make(actionType) {
    $('#action_type').val(actionType);
    $('#action_type').closest('form').submit();
  }

  $('.internal-note-form').on('ajax:success', function(_, data) {
    $('#internal_notes').html(data);
  });

  $('.select-all-bookings').on('click', function() {
    $('.bookings-checkboxes').prop('checked', $('.select-all-bookings')[0].checked);
    toggleNotesButtons();
  });
}

