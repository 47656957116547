import React, { useContext, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import ApiContext from "./ApiContext";

export default ({ show, setShow, id, onCompleted }) => {
  const api = useContext(ApiContext);

  const [disabledConfirm, setDisabledConfirm] = useState(false);

  const handleRemove = () => {
    if (disabledConfirm) return true;
    setDisabledConfirm(true);

    api.deleteQuestion(id)
      .then(response => {
        setShow(false);
        onCompleted();
        setDisabledConfirm(false);
      })
      .catch(() => {
        setDisabledConfirm(false);
      })
  }

  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Remove question</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure?
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => setShow(false)}>Cancel</Button>
        <Button className='btn-primary' disabled={disabledConfirm} onClick={handleRemove}>Confirm</Button>
      </Modal.Footer>
    </Modal>
  )
}
