import axios from "axios";
import { processingModal } from "../helpers/processing_modal";

function DeleteBookingItem() {
  let disabledSingleEdit = false;
  const single = function(event) {
    event.preventDefault();
    if (disabledSingleEdit) return;

    disabledSingleEdit = true;
    processingModal.show();
    const url = event.target.dataset.url;

    axios.delete(url).then(() => {
      window.bookingTable.reload(false);
      disabledSingleEdit = false;
    }).catch(() => {
      processingModal.hide();
      disabledSingleEdit = false;
    });
  }

  function multiple() {
    const bookingIds = $.map($('.multiple-check:checked'), (row) => row.value)

    if (bookingIds.length < 1) {
      Helper.flash_message('warning', 'Please select the lines you wish to delete')
      return;
    }

    processingModal.show();

    const orderId = $('#bookings-block .table-bookings').data('order-id');
    const url = `/orders/${orderId}/multiple_bookings/delete_multiple`;

    axios
      .delete(url, { headers: { 'Content-type': 'application/json;charset=utf-8' }, data: { booking_ids: bookingIds } })
      .then(() => window.bookingTable.reload(false))
      .catch(() => processingModal.hide());
  }

  return {
    single: single,
    multiple: multiple
  }
}

window.deleteBookingItem = DeleteBookingItem();