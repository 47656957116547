class HelpCentreDocument {
  constructor() { }

  start() {
    this.onChange();
  }

  onChange() {
    $('.helpcenter-document-partner-selector').on('change', (event) => {
      const $target = $(event.currentTarget);

      // TODO: Ensure @reloadUrl is a valid relative path so we can use the URL() API instead.
      // https://developer.mozilla.org/en-US/docs/Web/API/URL/URL
      //
      const partner_id = $target.val();
      const url = $target.data('reloadUrl');

      window.location = `${ url }?partner_profile_id=${ partner_id }`;
    });
  }
}

export default HelpCentreDocument;
