import React, { useState, useEffect, useMemo } from 'react';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';

const currentYear = new Date().getFullYear();
const fromMonth = new Date(2016, 0);
const toMonth = new Date(currentYear + 6, 11);

function YearMonthForm({ date, localeUtils, onChange }) {
  const months = localeUtils.getMonths();

  const years = [];
  for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
    years.push(i);
  }

  const handleChange = function handleChange(e) {
    const { year, month } = e.target.form;
    onChange(moment(new Date(year.value, month.value)));
  };

  return (
    <form className="DayPicker-Caption">
      <select name="month" onChange={handleChange} value={date.getMonth()}>
        {months.map((month, i) => (
          <option key={month} value={i}>
            {month}
          </option>
        ))}
      </select>
      <select name="year" onChange={handleChange} value={date.getFullYear()}>
        {years.map(year => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </form>
  );
}

export default ({ date, setDate, secondDate }) => {
  const [month, setMonth] = useState(date);

  const handleDayClick = (day) => {
    setDate(moment(day));
    setMonth(moment(day));
  };

  useEffect(() => {
    if (date && date.format('YYYY/MM') !== month.format('YYYY/MM')) setMonth(date);
  }, [date]);

  const selectedDays = useMemo(() => {
    if (date && secondDate) {
      return [date.toDate(), { from: date.toDate(), to: secondDate.toDate() }]
    } else if (date) {
      return [date.toDate()]
    } else {
      return [];
    }
  }, [date, secondDate])

  const dateMonth = useMemo(() => { return month ? month.toDate() : moment().toDate() }, [month]);

  return (
    <div className="YearNavigation">
      <DayPicker
        month={dateMonth}
        selectedDays={selectedDays}
        onDayClick={handleDayClick}
        fromMonth={fromMonth}
        toMonth={toMonth}
        captionElement={({ date, localeUtils }) => (
          <YearMonthForm
            date={dateMonth}
            localeUtils={localeUtils}
            onChange={setMonth}
          />
        )}
      />
    </div>
  );
}