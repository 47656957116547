import axios from "axios";

class Api {
  constructor(path) {
    this.path = path;
  }

  getQuestions = () => {
    return axios.get(`${this.path}.json`);
  }

  getNewQuestion = () => {
    return axios.get(`${this.path}/new.json`)
  }

  createQuestion = (formData) => {
    return axios.post(`${this.path}.json`, formData)
  }

  getQuestion = (id) => {
    return axios.get(`${this.path}/${id}.json`)
  }

  updateQuestion = (id, formData) => {
    return axios.put(`${this.path}/${id}.json`, formData)
  }

  deleteQuestion = (id) => {
    return axios.delete(`${this.path}/${id}.json`)
  }

  reorderQuestions = (ids) =>  {
    return axios.put(`/admin/questions/reorder.json`, { ids: ids })
  }
}

export default Api;