import Search from '../pages/search';

// MarkerClusterer

const NULL_ISLAND_COORDINATES = { lat: 0.0, lng: 0.0 };

function Map() {
  let map = null;
  let markers = [];

  function initMap(params, mapStyles) {
    const unknownArea = (params.lat === null || params.lng === null || params.lat === '' || params.lng === '')
    const center = unknownArea ? NULL_ISLAND_COORDINATES : params;

    map = new google.maps.Map(document.getElementById("map"), {
      maxZoom: 15,
      minZoom: 8,
      streetViewControl: false,
      scrollwheel: false,
      zoomControlOptions: { position: google.maps.ControlPosition.LEFT_TOP },
      styles: mapStyles || [],
      zoom: 14,
      center: center
    });

    const controlDiv = document.getElementById('control-div');
    controlDiv.index = 1;
    map.controls[google.maps.ControlPosition.TOP_CENTER].push(controlDiv);

    $('#lng').val(params.lng);
    $('#lat').val(params.lat);

    map.addListener('dragend', onDragend);

    if (unknownArea) {
      showFlashMessage();
      map.setCenter(NULL_ISLAND_COORDINATES);
    }

    applyFilters();
  }

  function init(params, mapStylesURL) {

    if (mapStylesURL) {
      $.ajax({
        url: mapStylesURL,
        dataType: 'json',
        success: (mapStyles) => initMap(params, mapStyles),
        error: () => initMap(params)
      });
    } else {
      initMap(params);
    }
  }

  function onDragend() {
    const mapSearchOnDrag = $('#map_search_on_drag');
    const checked = mapSearchOnDrag.is(':checked');

    $('#dragend').val(checked);

    const { lat, lng } = map.getCenter();
    $('#lng').val(lng());
    $('#lat').val(lat());

    if (checked) { applyFilters() } else { calculateDistance() }
  }

  function update(items, params) {
    const unknownArea = (params.lat === null || params.lng === null || params.lat === '' || params.lng === '');

    if (unknownArea) {
      showFlashMessage();
      map.setCenter(NULL_ISLAND_COORDINATES);
      return;
    }

    if (params.update_center) map.setCenter({ lat: params.lat, lng: params.lng });

    markers.forEach(marker => marker.setMap(null));

    // add markers
    items.forEach((item) => {
      const marker = new google.maps.Marker({
        position: { lat: item.lat, lng: item.lng },
        icon: item.picture.url,
        map
      });

      const infowindow = new google.maps.InfoWindow({ content: item.infowindow });
      marker.addListener("click", () => { infowindow.open({ anchor: marker, map });});

      markers.push(marker)
    });
    
    new Search().updateDistance();
  }

  function applyFilters(unknownArea) {
    new Search().applyFilters('map');
  }

  function calculateDistance() {
    new Search().updateDistance();
  }

  function showFlashMessage() {
    Helper.flash_message('error',
      `The location entered has not returned any results. \
         Please update the location field with another location, e.g. \                                
         a street name or postcode and search again.`
    );
  }

  return {
    map: () => { return map },
    init: init,
    update: update
  }
}

window.map = new Map();

