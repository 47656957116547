(function() {
  var collectBookingOptions, fixWidthHelper;

  $.initSortableBookingOptions = function() {
    $('#sorable-booking-options-custom').sortable({
      helper: fixWidthHelper,
      start: function(e, ui) {
        return ui.placeholder.height(ui.item.height());
      },
      stop: function(e, ui) {
        return collectBookingOptions();
      }
    });

    $('#sorable-booking-options-all').sortable({
      helper: fixWidthHelper,
      start: function(e, ui) {
        return ui.placeholder.height(ui.item.height());
      },
      stop: function(e, ui) {
        return collectBookingOptions();
      }
    });
  };

  $.initSortableBookingOptions();

  fixWidthHelper = function(e, ui) {
    ui.children().each(function() {
      return $(this).width($(this).width());
    });
    return ui;
  };

  collectBookingOptions = function() {
    var bookingOptions, ids, path;
    bookingOptions = $('#sorable-booking-options .sorable-booking-order-wrapper');

    ids = $.map(bookingOptions, function(el, _) {
      return $(el).data('id');
    });

    path = $('#sorable-booking-options').data('path');

    $.ajax({
      method: 'PUT',
      url: path,
      data: {
        ids: ids
      },
      success: function(result) {
        $('.success').fadeIn('slow');
        $('.success').fadeOut('slow');
      },
      fail: function(result) {
        $('.error').fadeIn('slow');
        $('.error').fadeOut('slow');
      }
    });
  };

}).call(this);