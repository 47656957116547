import moment from 'moment';

export function bookingOptionDayRateEvents() {
  const dayRateIdent = 'booking_option_booking_option_day_rate';
  const dayRateCheckbox = $('#booking_option_add_day_rate');

  const settings = {
    weekday: {
      dayRateRow: $('.booking_options_weekday_day_rate'),
      onPeakPrice: $('#booking_option_current_booking_option_price_attributes_on_peak_price'),
      dayRate:  $(`#${dayRateIdent}_attributes_weekday_day_rate`),
      dayRateRadioBtnEnabled: $(`#${dayRateIdent}_attributes_weekday_day_rate_enabled_true`)
    },
    weekend: {
      dayRateRow: $('.booking_options_weekend_day_rate'),
      onPeakPrice: $('#booking_option_current_booking_option_price_attributes_on_peak_weekend_price'),
      dayRate: $(`#${dayRateIdent}_attributes_weekend_day_rate`),
      dayRateRadioBtnEnabled: $(`#${dayRateIdent}_attributes_weekend_day_rate_enabled_true`)
    }
  }

  const onChangeDayRateTime = (name) => {
    const dayRate = settings[name].dayRate;

    let startTimeContainer = $(`.${dayRateIdent}_${name}_start_time`);
    let endTimeContainer = $(`.${dayRateIdent}_${name}_end_time`);

    dayRate.on('change', () => setDayRatePrice(name));
    startTimeContainer.each((i, startTimeSelect) => $(startTimeSelect).on('change', () => setDayRatePrice(name)));
    endTimeContainer.each((i, endTimeSelect) => $(endTimeSelect).on('change', () => setDayRatePrice(name)));
  }

  const setDayRatePrice = function(name) {
    const dayRateRadioBtnEnabled  = settings[name].dayRateRadioBtnEnabled;
    const onPeakPrice             = settings[name].onPeakPrice;
    const dayRate                 = settings[name].dayRate;

    let checked = dayRateRadioBtnEnabled.is(':checked');

    if (!checked) { return }

    onPeakPrice.prop('disabled', checked);
    onPeakPrice.val(getPrice(name));
  }

  function getPrice(name) {
    const onPeakPrice = settings[name].onPeakPrice;
    const dayRate = settings[name].dayRate;

    let rate = parseFloat(dayRate.val());
    let hours = getHours(name);
    let price = ceilPrice(rate/hours);

    return price;
  }

  function ceilPrice(number) {
    return (Math.ceil(number * 100.00))/100.0
  }

  function getHours(name) {
    let hours = diffDayHours(getTime(name, 'start'), getTime(name, 'end'));

    return hours;
  }

  function getTime(name, position) {
    const timeHour = $(`#${dayRateIdent}_attributes_${name}_${position}_time_4i`).val();
    const timeMin = $(`#${dayRateIdent}_attributes_${name}_${position}_time_5i `).val();

    return moment(`${timeHour}:${timeMin}`, 'HH:mm');
  }

  const toggleBookingOptionDayRateRadioBtn = (name) => {
    const onPeakPrice = settings[name].onPeakPrice;

    let container = $(`#${name}_day_rate_enabled input[type="radio"]`);

    container.on('click', (e) => {
      onPeakPrice.prop('disabled', e.target.value === 'true');
      setDayRatePrice(name);
      toggleDayBookingOptionDayRateRow();
    });
  }

  const onSubmitBookingOptionDayRateForm = function() {
    $('#submit_booking_option_form_btn').on('click', () => {
      settings.weekday.onPeakPrice.prop('disabled', false);
      settings.weekend.onPeakPrice.prop('disabled', false);
    });
  }

  const diffDayHours = function(startTime, endTime) {
    if (startTime.isSameOrAfter(endTime)) {
      return 1
    } else {
      return (endTime.diff(startTime, 'minutes') / 60);
    }
  }

  const toggleBookingOptionDayRateCheckbox = function() {
    dayRateCheckbox.on('change', function() {
      if (dayRateCheckbox.is(':checked')) {
        setDayRatePrice('weekday');
        setDayRatePrice('weekend');
        settings.weekday.dayRateRadioBtnEnabled.prop('checked', true);
        settings.weekend.dayRateRadioBtnEnabled.prop('checked', true);
        toggleDayBookingOptionDayRateRow();
      }
    });
  }

  const toggleDayBookingOptionDayRateRow = function() {
    let weekdayChecked = settings.weekday.dayRateRadioBtnEnabled.is(':checked');
    let weekendChecked = settings.weekend.dayRateRadioBtnEnabled.is(':checked');

    if (!weekdayChecked && !weekendChecked) dayRateCheckbox.prop('checked', false).change();

    settings.weekday.dayRateRow.toggleClass('hidden', !weekdayChecked);
    settings.weekend.dayRateRow.toggleClass('hidden', !weekendChecked);
  }

  onChangeDayRateTime('weekday');
  onChangeDayRateTime('weekend');
  toggleBookingOptionDayRateRadioBtn('weekday');
  toggleBookingOptionDayRateRadioBtn('weekend');
  onSubmitBookingOptionDayRateForm();
  toggleBookingOptionDayRateCheckbox();
  toggleDayBookingOptionDayRateRow();
}
