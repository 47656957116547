import React from "react";

export default ({ bookingOptions, value, onChange }) => (
  <div className="form-group select optional booking_booking_option_id">
    <label className="control-label select optional">Booking option</label>
    <select className="form-control select optional booking_options"
            value={value || ''}
            name="booking[booking_option_id]"
            onChange={onChange}>
      {bookingOptions.map((item, i) => <option key={i} value={item.id}>{item.title}{item.deleted ? ' (deleted)' : ''}</option>)}
    </select>
  </div>
)