import { Controller } from "@hotwired/stimulus"
import axios from "axios";

export default class extends Controller {
  connect() {
    this.$adminOrdersModal = $('#admin_orders_modal');
  }

  show(event) {
    event.preventDefault();

    const $target = $(event.target);
    if ($target.parent('li').hasClass('disabled')) return;

    this.showModal($target.attr('href'));
  }

  showFromBtn(event) {
    event.preventDefault();

    this.showModal($(event.target).attr('href'));
  }

  onModalSubmit(form) {
    form.find('[type="submit"]').on('click', (event) => {
      event.preventDefault();

      const $target = $(event.currentTarget);

      $target.prop('disabled', true);

      const $form = $target.closest('form');

      $.ajax({
        type: 'POST',
        url: $form.attr('action'),
        data: $form.serialize(),

        success: () => {
          const reload = this.$adminOrdersModal.find('form').data('reload');
          const reloadPath = this.$adminOrdersModal.find('form').data('reload-path');

          this.$adminOrdersModal.modal('hide');

          if (reload) {
            if ($('#orders-table').length > 0) {
              this.reloadData(false);
            } else {
              if (reloadPath) {
                window.localStorage.showNotification = 'showNotification';
              }

              location.replace(reloadPath || location.href) || location.reload();
            }
          }
        },

        error: (data) => {
          this.updateContent(data.responseText)
        }
      });
    })
  }

  showModal(url) {
    axios
      .get(url, { headers: { 'X-Requested-With': 'XMLHttpRequest' }})
      .then((response)=> {
        const { data } = response;
        this.$adminOrdersModal.find('.modal-dialog').css('width', '');
        this.updateContent(data);
        this.$adminOrdersModal.modal('show');
      }).catch(() => {
        Helper.flash_message('error', 'Something went wrong')
      })
  }

  updateContent(content) {
    this.$adminOrdersModal.find('.modal-content').empty().append(content);

    const form = this.$adminOrdersModal.find('form');
    if (form) {
      if (form.data('modal-width') !== void 0) {
        this.$adminOrdersModal.find('.modal-dialog').css('width', form.data('modal-width'));
      }

      this.onModalSubmit(form);
    }
  }

  // it's not correct because we should reload data not here
  reloadData(changePage) {
    $('#orders-table').trigger('reloadData');
  };
}