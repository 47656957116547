import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import DropDown from "./DropDown";

const reportTypes = [
  { title: 'Sessions', value: 'sessions' },
  { title: 'Bookings', value: 'bookings' },
  { title: 'Summary', value: 'summary' }
];

export default ({ onChanged }) => {
  const [reportType, setReportType] = useState(reportTypes[0]);

  useEffect(() => {
    const params = reportType ? { reportType: reportType.value } : null;
    onChanged({ value: reportType, pending: false, params });
  }, [reportType]);

  return (
    <div className={classNames(['filter-block filter-report-types'])}>
      <label htmlFor="filter-report-types">Type:</label>
      <div className='input-box input-box-react-select'>
        <DropDown
          value={reportType}
          options={reportTypes}
          onChange={setReportType}
          getOptionLabel={opt => opt.title}
          getOptionValue={opt => opt.value}
        />
      </div>
    </div>
  )
};
