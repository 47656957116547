import { Controller } from "@hotwired/stimulus"
import axios from "axios";

export default class extends Controller {
  connect() {
    this.togglePresence(this.element.classList.contains('presence'));
  }

  toggle() {
    const facilityId = this.element.dataset.facilityId;

    axios.put(`/favourites/${facilityId}/toggle`).then((response) => {
      const { data } = response;
      const presence = data.status === 'liked';

      this.togglePresence(presence);
    });
  }

  togglePresence(presence) {
    const { dataset } =  this.element;
    this.text = dataset.text;
    this.disabledText = dataset.disabledText;

    const hasText = (this.text && this.disabledText);

    if (presence) {
      this.element.classList.add('presence');
      if (hasText) this.element.innerHTML = this.disabledText;
    } else {
      this.element.classList.remove('presence');
      if (hasText) this.element.innerHTML = this.text;
    }
  }
}
