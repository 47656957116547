import { Controller } from "@hotwired/stimulus"
import axios from 'axios';

class IncomeOptionFormController extends Controller {
  static values = { datePickerOptions: { format: 'D, dd M yyyy', autoclose: true }};
  static targets = ['incomeType', 'incomeAmount', 'incomeEndDate', 'incomeStartDate'];

  connect() {
    this.toggleType();
  }

  toggleType(event) {
    if (!this.incomeTypeTargets[0]) return false;

    if (event?.target) {
      this.incomeAmountTarget.value = '';
      this.incomeStartDateTarget.value = '';
      this.incomeEndDateTarget.value = '';
    }

    this.setAmount();
    this.setStartDate();
    this.setEndDate();
    this.setFirstVenueCommission();
  }

  setAmount() {
    const amountBox = this.incomeAmountTarget.closest('#income-amount-box');
    const incomeTypes = ['annual_fee', 'archived_annual_fee', 'annual_fee_forecast',
                         'projected_income', 'projected_income_forecast',
                         'archived_projected_income'];

    if (incomeTypes.includes(this.incomeTypeTarget.value)) {
      amountBox.classList.remove('hidden');
    } else {
      amountBox.classList.add('hidden');
    }
  }

  setStartDate() {
    const incomeTypes = { projected_income: 'Projected income date' };
    const incomeOptionStartDateInput = this.incomeStartDateTarget.closest('#income-start-date');

    $(this.incomeStartDateTarget).datepicker(this.datePickerOptionsValue);

    this.setIncomeDate(incomeTypes, incomeOptionStartDateInput);
  }

  setEndDate() {
    const incomeTypes = { archived_annual_fee: 'Annual fee end date' };
    const incomeOptionEndDateInput = this.incomeEndDateTarget.closest('#income-end-date');

    $(this.incomeEndDateTarget).datepicker(this.datePickerOptionsValue);

    this.setIncomeDate(incomeTypes, incomeOptionEndDateInput);
  }

  setIncomeDate(options, target) {
    const label = target.querySelector('label');

    if (options[this.incomeTypeTarget.value]) {
      target.classList.remove('hidden');
      if (label) label.innerText = options[this.incomeTypeTarget.value];
    } else {
      target.classList.add('hidden');
    }
  }

  setFirstVenueCommission() {
    const firstCheckedVenueCommission = this.filterVenuesWithCommission()[0];
    const incomeTypes = ['projected_income'];
    const amountInput = this.incomeAmountTarget;

    if (firstCheckedVenueCommission && incomeTypes.includes(this.incomeTypeTarget.value)) {
      const firstVenueCommission = firstCheckedVenueCommission.dataset.commission;

      if (amountInput.value === '') this.incomeAmountTarget.value = firstVenueCommission;
    }
  }

  filterVenuesWithCommission(el) {
    return [...this.allCheckedVenues()].filter((el) => el.dataset.commission);
  }

  allCheckedVenues() {
    const checkboxes = document.querySelectorAll('#venues-in-venue-groups .check_boxes');

    return [...checkboxes].filter((el) => el.checked);
  }
}

export default IncomeOptionFormController;
