import React, { useState, useEffect, useCallback } from "react";
import Modal from './Modal';
import axios from "axios";

import './styles.scss';

export default ({ facilityId }) => {
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [childFacilities, setChildFacilities] = useState([]);
  const [deleteRequestEnabled, setDeleteRequestEnabled] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    setShowModal(true)
  }

  useEffect(() => {
    if (showModal === false) fetchMultiSwitcher();
  }, [showModal])

  const fetchMultiSwitcher = useCallback(() => {
    axios
      .get(`/admin/facilities/${facilityId}/multi_switcher.json`)
      .then(resp => {
        let { childFacilities, deleteRequestEnabled } = resp.data;
        setChildFacilities(childFacilities);
        setDeleteRequestEnabled(deleteRequestEnabled);
        setLoading(false);
      });
  }, [])

  useEffect(() => { fetchMultiSwitcher() }, []);

  const systemAdmin = !deleteRequestEnabled;
  const hasChildFacilities = childFacilities.length > 0;

  return (
    <>
      <Modal
        facilityId={facilityId}
        show={showModal}
        setShow={setShowModal}
        fetchMultiSwitcher={fetchMultiSwitcher}
      />

      {!loading &&
        <div>
          {(systemAdmin || (!systemAdmin && hasChildFacilities)) &&
            <>
              <dt>Related names</dt>

              <dd>
                <button className='btn btn-xs btn-default' onClick={handleClick}>Manage multiple facilities</button>
              </dd>
            </>
          }

          {childFacilities.map((childFacility, index) => <>
            <dt>Name {index + 1}:</dt>
            <dd>
              {childFacility.internalReference} (ID: #{childFacility.id})
              &nbsp;
              {childFacility.deleteRequest && <span className="label label-warning">Delete request</span>}
            </dd>
          </>)}
        </div>
      }
    </>
  )
}