import React, { useState } from "react";
import axios from "axios";
import rescue from '../rescue';
import ConfirmationErrorModal from "./ConfirmationErrorModal";

export default ({ onCreated, onError, creationInProgress, setCreationInProgress }) => {
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errors, setErrors] = useState(false);

  const performSubmit = () => {
    if (creationInProgress) return true;
    setCreationInProgress(true);

    const formData = new FormData(document.getElementById('staff-form'));

    axios
      .post('/admin/staff_rota.json', formData)
      .then((response) => {
        onCreated(response.data.staffRotaId);
      })
      .catch((error) => {
        setErrors(error.response.data.errors);
        setShowErrorModal(true);
        rescue(error, onError);
        setCreationInProgress(false);
      });
  }

  return (
    <>
      <ConfirmationErrorModal
        errors={errors}
        show={showErrorModal}
        setShow={setShowErrorModal}
      />

      <button className="btn btn-lg btn-primary btn-no-radius"
              style={{ width: 250 }}
              type='button'
              disabled={creationInProgress}
              onClick={performSubmit}>
        Create
      </button>
    </>
  )
}