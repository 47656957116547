import { Controller } from "@hotwired/stimulus";

class NewSpecialDateController extends Controller {
  static targets = ['typeInput', 'specialDatesContainer'];

  connect() {
    this.setPredefinedSpecialDatesContainerVisibility(this.typeInputTarget);
    this.typeInputTarget.addEventListener('change', this.handleTypeChange.bind(this));
  }

  toggleSpecialDatesButtonsContainer(event) {
    const checked = event.target.checked;

    this.element.querySelector('.actions-hidden-container').hidden = !checked;
  }

  handleTypeChange(event) {
    this.setPredefinedSpecialDatesContainerVisibility(event.target);
  }

  setPredefinedSpecialDatesContainerVisibility(typeSelect) {
    const currentAction = this.element.dataset.currentAction;
    const selectedOption = Array.from(typeSelect.selectedOptions)[0].value;
    this.specialDatesContainerTarget.hidden = !(selectedOption == 'closure' && currentAction == 'new');
  }
}

export default NewSpecialDateController;
