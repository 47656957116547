import React, {useState} from 'react'
import QuestionTitle from "./fields/QuestionTitle";
import CheckboxOption from "./fields/CheckboxOption";
import QuestionItems from "./fields/QuestionItems";
import QuestionDocument from "./fields/QuestionDocument";
import ErrorMessage from "./fields/ErrorMessage";
import Facilities from "./Facilities";
import Venues from "./Venues";
import OptionsTriggerQuestion from "./OptionsTriggerQuestion";
import { OverlayTrigger, Popover, Button } from 'react-bootstrap';

export default (({ question,
                   errors,
                   hasChild,
                   suffix,
                   deleted,
                   enabledRemoveChildButton,
                   onEnableCreateChildButton,
                   onDeleteSubQuestion }) => {
  const [displayMandatoryDocumentCheckbox, setEnabled] = useState(question.allowDocuments);
  const [enabledAllowOptions, setEnabledAllowOptions] = useState(question.allowOptions);
  const [enabledAllowYesNoOptions, setEnabledAllowYesNoOptions] = useState(question.allowYesNoOptions);
  const [enabledAllowQuestionItems, setEnabledAllowQuestionItems] = useState(question.allowQuestionItems);
  const [questionItems, setQuestionItems] = useState(question.questionItems);
  const [allowOptionTriggerList, setAllowOptionTriggerList] = useState(question.selectedOptionsTriggerQuestions);

  const enabledAllowCheckboxes = (enabledAllowOptions || enabledAllowYesNoOptions) && enabledAllowQuestionItems;
  const disabledAllowYesNoNAOptions = enabledAllowQuestionItems && !enabledAllowCheckboxes
  const enabledAllowYesNoNaOptions = enabledAllowOptions || enabledAllowYesNoOptions

  const popoverLeft = (
    <Popover id="popover-positioned-scrolling-left" title="">
      <p>
        When selected, a document will need to be provided in
        response to this question to allow checkout to be completed.
      </p>
    </Popover>
  );

  const hiddenFields = (
    <>
      <input name={`${suffix}[id]`} value={(question.id || '')} type='hidden'/>
      <input name={`${suffix}[venue_id]`} value={(question.venueId || '')} type='hidden'/>
      <input name={`${suffix}[node_id]`} value={(question.nodeId || '')} type='hidden'/>
      <input name={`${suffix}[new_sub_question_has_parent]`} value={question.isChild} type='hidden'/>
      <input type='hidden' name={`${suffix}[facility_id]`} value={(question.facilityId || '')}/>

      {deleted && <input name={`${suffix}[_destroy]`} value={deleted} type='hidden'/>}

      {!question.isChild && !hasChild &&
        <input name='question[option_trigger_sub_questions]' type='hidden'
        />
      }
    </>
  );

  if (deleted) return hiddenFields;

  const handleEnableAllowOptions = (enabled) => {
    setEnabledAllowOptions(enabled);
    setAllowOptionTriggerList([]);
    onEnableCreateChildButton(enabled || enabledAllowYesNoOptions || enabledAllowQuestionItems);
  };

  const handleEnableAllowYesNoOptions = (enabled) => {
    setEnabledAllowYesNoOptions(enabled);
    setAllowOptionTriggerList([]);
    onEnableCreateChildButton(enabled || enabledAllowOptions || enabledAllowQuestionItems);
  };

  const handleEnableAllowQuestionItems = (enabled) => {
    setEnabledAllowQuestionItems(enabled);
    onEnableCreateChildButton(enabled);
  };

  return (
    <>
      {enabledRemoveChildButton &&
        <div className='question-delete-child-button pull-right'>
          <Button className='btn-primary' onClick={() => onDeleteSubQuestion()}>
            Remove sub-question
          </Button>
        </div>
      }

      <QuestionTitle
        label="Text"
        defaultValue={question.text}
        name={`${suffix}[text]`}
        errors={((errors || {})['text'] || [])}
      />

      {!question.isChild && question.multipleVenues &&
        <Venues question={question} suffix={suffix} />
      }

      {!question.isChild && question.isVenue && !question.multipleVenues &&
        <Facilities question={question} suffix={suffix} />
      }

      <CheckboxOption
        label="Allow users to select answer from Yes/No/Not applicable options"
        name={`${suffix}[allow_options]`}
        checked={enabledAllowOptions}
        isDisabled={disabledAllowYesNoNAOptions || (hasChild && enabledAllowOptions && !enabledAllowYesNoOptions)}
        onChange={(e) => handleEnableAllowOptions(e.target.checked)}
      />

      <ErrorMessage className='items-errors' errors={((errors || {})['allow_options'] || [])}/>

      <CheckboxOption
        label="Allow users to select answer from Yes/No options"
        name={`${suffix}[allow_yes_no_options]`}
        checked={enabledAllowYesNoOptions}
        isDisabled={disabledAllowYesNoNAOptions || (hasChild && enabledAllowYesNoOptions && !enabledAllowOptions)}
        onChange={(e) => handleEnableAllowYesNoOptions(e.target.checked)}
      />

      <ErrorMessage className='items-errors' errors={((errors || {})['allow_yes_no_options'] || [])}/>

      <CheckboxOption
        label="Allow users to upload documents"
        defaultChecked={question.allowDocuments}
        name={`${suffix}[allow_documents]`}
        onChange={(e) => setEnabled(e.target.checked)}
      />

      {displayMandatoryDocumentCheckbox &&
        <div>
          <div style={{ paddingLeft: 15, display: 'inline-block', paddingRight: 5 }}>
            <CheckboxOption
              label="Mandatory"
              defaultChecked={question.mandatoryDocuments}
              name={`${suffix}[mandatory_documents]`}
            />
          </div>
          <OverlayTrigger
            container={this}
            placement="right"
            overlay={popoverLeft}
          >
            <div className='info-icon' data-original-title='' title=''/>
          </OverlayTrigger>
        </div>
      }

      <CheckboxOption
        label="Allow users to leave additional comments"
        defaultChecked={question.allowComment}
        name={`${suffix}[allow_comment]`}
      />

      <QuestionItems
        label={"Allow users to select from dropdown list"}
        data={questionItems}
        name={suffix}
        errors={errors}
        setEnabled={handleEnableAllowQuestionItems}
        enabled={enabledAllowQuestionItems}
        hasChild={hasChild}
        isDisabled={!enabledAllowCheckboxes && enabledAllowYesNoNaOptions}
        onChange={setQuestionItems}
      />

      <ErrorMessage className='items-errors' errors={((errors || {})['items'] || [])}/>

      <QuestionDocument
        label="Attach document to the question"
        id={question.questionDocumentId}
        defaultChecked={question.questionDocument}
        questionDocument={question.questionDocument}
        errors={errors}
        name={suffix}
      />

      {hasChild &&
        <OptionsTriggerQuestion
          question={question}
          allowOptionTriggerList={allowOptionTriggerList}
          onChangeAllowOptionTrigger={setAllowOptionTriggerList}
          questionItems={questionItems}
          onChangeQuestionItemTrigger={setQuestionItems}
          enabledAllowOptions={enabledAllowOptions}
          enabledAllowQuestionItems={enabledAllowQuestionItems}
          errors={errors}
        />
      }

      {hiddenFields}
    </>
  )
});


