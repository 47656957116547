import React, { useEffect, useState } from 'react';
import IndexPage from './Index/Index';
import NewPage from "./New/Index";
import { Router, Switch, Route } from "react-router-dom";
import history from "./utils/history";
import { AccessContext, ViewModeContext, StaffRotaContext } from "./utils/context";
import api from './api';
import AfterConfirmedModal from "./components/Modals/AfterConfirmed";
import ScrollToTop from "./ScrollToTop";

import './styles.scss';

export default () => {
  const [allowManage, setAllowManage] = useState(null);
  const [viewMode, setViewMode] = useState('all');
  const fullAccess = (viewMode === 'all' && allowManage);
  const [showAfterConfirmedModal, setShowAfterConfirmedModal] = useState(false);
  const [afterConfirmedMessage, setAfterConfirmMessage] = useState([]);
  const [currentUserId, setCurrentUserId] = useState(null);

  useEffect(()=> {
    api.getStaffRota().then(response => {
      setAllowManage(response.data.user.allowManage);
      setCurrentUserId(response.data.user.id);
    });
  }, [])

  const handleStaffRotaCreated = (data) => {
    setShowAfterConfirmedModal(true)
    setAfterConfirmMessage(data.message)
  }

  if (allowManage === null) return null;

  const viewModeSettings = {
    currentUserId: currentUserId,
    viewMode: viewMode,
    fullAccess: fullAccess,
    setViewMode: setViewMode
  }

  const newStaffRotaSettings = {
    staffRotaCreated: handleStaffRotaCreated
  }

  return (
    <AccessContext.Provider value={allowManage}>
      <ViewModeContext.Provider value={viewModeSettings}>
        <StaffRotaContext.Provider value={newStaffRotaSettings}>

          <AfterConfirmedModal
            show={showAfterConfirmedModal}
            setShow={setShowAfterConfirmedModal}
            message={afterConfirmedMessage}
          />

          <Router history={history}>
            <ScrollToTop>
              <Switch>
                <Route path='/admin/staff_rota/new' component={NewPage}/>
                <Route path='/admin/staff_rota' component={IndexPage}/>
              </Switch>
            </ScrollToTop>
          </Router>
        </StaffRotaContext.Provider>
      </ViewModeContext.Provider>
    </AccessContext.Provider>
  )
}
