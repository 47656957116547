(function() {
  $(function() {
    $(document).on('submit', '#conversations_bulk_actions_form', function(e) {
      if ($('.js_conversation_checkbox:checked').length === 0) {
        return e.preventDefault();
      }
    });
    $(document).on('ifChanged', '.js_conversation_checkbox', function(event) {
      if ($('.js_conversation_checkbox:checked').length === 0) {
        return $('.js_conversation_bulk_submit').addClass('disabled');
      } else {
        return $('.js_conversation_bulk_submit').removeClass('disabled');
      }
    });
    $(document).on('click', '.clickable-row', function() {
      return window.location = $(this).attr('href');
    });
  });

}).call(this);