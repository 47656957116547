import { showBootstrapAlert } from "./BootstrapAlert";

$(document).on('click', '#send-order-to-customer', function(e) {
  e.preventDefault();
  $('#send-to-user-modal').modal('show');
});





