import { Controller } from "@hotwired/stimulus"
import axios from 'axios';
import NeoModal from "src/NeoModal";

export default class extends Controller {
  update(e) {
    const id = e.target.dataset.id;

    axios.get(`/profile/card_modal?connect_customer_id=${id}`).then(response => {
      const modal = NeoModal.add(response.data);

      modal.addEventListener('neo_modal:payment_success', (e) => {
        const { data } = e.detail;

        $(modal).modal('hide');
        Helper.flash_message('success', data.message);
        document.getElementById(`card_last4_${data.connect_customer_id}`).innerHTML = data.card_last4;
      })
    })
  }
}
