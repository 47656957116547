import ReactDOM from "react-dom";
import Form from "../components/EditBooking/Form";
import React from "react";

window.clickMultipleEditButton = function(e) {
  e.preventDefault();

  const wrapper = $(e.target).closest('.booking-wrapper');

  if (wrapper.find('.multiple-check:checked').length > 1) {
    const id = wrapper.find('.multiple-check:checked:first').closest('td').find('input[name="multiple-check"]:first').val();
    const orderId = $('#bookings-block .table-bookings').data('order-id');
    const url = `/orders/${orderId}/multiple_bookings/${id}/edit`;

    let modalBox = document.getElementById('booking_edit_modal');

    if (!modalBox) {
      const elemDiv = document.createElement('div');
      elemDiv.setAttribute('id', 'booking_edit_modal');

      document.body.appendChild(elemDiv);

      modalBox = document.getElementById('booking_edit_modal');
    }

    ReactDOM.unmountComponentAtNode(modalBox);
    ReactDOM.render(<Form url={url} multiple={true}/>, modalBox);
  } else {
    Helper.flash_message('warning', 'Please select the lines you wish to edit')
  }
};