import React from "react";
import { OverlayTrigger, Popover } from 'react-bootstrap';

const popoverLeft = (
  <Popover id="popover-positioned-scrolling-left" title="">
    <p>
      Tick this box if there is more than one facility
      that falls within this category and you do not wish
      to list them separately e.g. classrooms. If in doubt,
      please contact us for assistance.
    </p>
  </Popover>
);

export default ({ groupMainFacility, setGroupMainFacility, errors }) => {
  const groupMainFacilityErrors = errors['group_main_facility'];

  return (
    <div className="form-group boolean optional facility_group_main_facility">
      <div className="checkbox">
        <input value="0"
               autoComplete="off"
               type="hidden"
               name="facility[group_main_facility]"/>
        <label className="boolean optional" htmlFor="facility_group_main_facility">
          <input className="boolean optional"
                 type="checkbox"
                 value="1"
                 onChange={(e) => setGroupMainFacility(e.target.checked)}
                 checked={groupMainFacility}
                 name="facility[group_main_facility]"/>
          Multiple facilities
          &nbsp;
          <OverlayTrigger
            container={this}
            placement="right"
            overlay={popoverLeft}
          >
            <div className="info-icon" data-original-title="" title=""/>
          </OverlayTrigger>
        </label>

        {groupMainFacilityErrors &&
          <div className='has-error'>
            <span className="help-block">
              {groupMainFacilityErrors.join(', ')}
            </span>
          </div>
        }
      </div>
    </div>
  )
}
