import React from "react";
import { Controller } from "@hotwired/stimulus";
import ReactDOM from "react-dom";
import Form from "src/components/MultiMessages/Form";

export default class MultiMessageButtonController extends Controller {
  connect() {
    this.element.addEventListener('click', (e) => {
      const venueId = e.target.dataset.venueId;

      const mountPoint = document.createElement('div');
      this.element.insertAdjacentElement('afterend', mountPoint);

      ReactDOM.render(<Form venueId={venueId}/>, mountPoint);
    })
  }
}
