const PeriodView = (function() {
  function init(refreshCalendar) {
    $('.period-view').on('change', function(e) {
      $('.hidden-period').val($(this).val());
      refreshCalendar();
    });

    $('.day-switcher').on('click', function(e) {
      var val;
      e.preventDefault();
      val = $('.hidden-view').val();

      if (val === 'day') {

      } else {
        $('#calendar').hide();
        $('#staff-rota-selector').hide();
        $('#view-day-selector').show();
        $('#view-week-selector').hide();
        $('.hidden-view').val('day');
        $('.col-legend .legend-item').toggle(true);
        $('.col-internal-booking .btn').toggle(true);
        refreshCalendar();
        $('.week-switcher').removeClass('selected-day');
        $('.day-switcher').addClass('selected-day');
        $('#days_of_week').multiselect('disable');
        $('#days_of_week').removeAttr('disabled');
        $('#calendar_top_scroll_wrapper').css('overflow-x', 'scroll')
      }

      togglePrevNextButtonsText();
    });

    $('.week-switcher').on('click', function(e) {
      e.preventDefault();
      var val = $('.hidden-view').val();
      $('#dayCalendarNote').html('');

      if (val === 'week') {
      } else {
        $('#calendar').hide();
        $('#staff-rota-selector').show();
        $('#view-day-selector').hide();
        $('#view-week-selector').show();
        $('.col-legend .legend-item').toggle($('.hidden-week-view-type').val() !== 'availability');
        $('.col-internal-booking .btn').toggle($('.hidden-week-view-type').val() !== 'availability');
        $('.hidden-view').val('week');
        refreshCalendar();
        $('.day-switcher').removeClass('selected-day');
        $('.week-switcher').addClass('selected-day');
        $('#days_of_week').multiselect('enable');

        if(window.innerWidth > 1250) {
          $('#calendar_top_scroll_wrapper').css('overflow-x', 'hidden')
        }
      }

      togglePrevNextButtonsText();
    });

    togglePrevNextButtonsText();
  }

  function togglePrevNextButtonsText() {
    const prevWeekCaption = '<< Previous week';
    const nextWeekCaption = 'Next week >>';
    const prevDayCaption = '<< Previous day';
    const nextDayCaption = 'Next day >>';
    const targetPrevButton = $('.calendar-scroll-prev');
    const targetNextButton = $('.calendar-scroll-next');

    const currentCalendarView = $('.hidden-view').val();

    if (currentCalendarView === 'day') {
      targetPrevButton.text(prevDayCaption);
      targetNextButton.text(nextDayCaption);
    } else {
      targetPrevButton.text(prevWeekCaption);
      targetNextButton.text(nextWeekCaption);
    }
  }

  return {
    init: init,
  };
})();

export default PeriodView;