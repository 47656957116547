import { Controller } from "@hotwired/stimulus";
import OrderTab from "src/nextgen/orders/Tab";

class PendingModificationController extends Controller {
  connect() {
    const modificationId = this.element.dataset.id;

    const subscriptionOptions = {
      channel: 'ModificationChannel',
      id: modificationId
    }

    const subscription = App.cable.subscriptions.create(subscriptionOptions, {
      connected: () => { subscription.send({}) },
      received: (data) => {
        if (["succeeded", "failed"].includes(data.status)) {
          subscription.unsubscribe();

          if (data.status === 'failed') {
            $('#failed-payment-modification').modal('show');
          } else {
            this.orderTab.reload('current_bill');
          }
        }
      }
    });
  }

  get orderTab() {
    return new OrderTab();
  }
}

export default PendingModificationController;