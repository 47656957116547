import { Controller } from "@hotwired/stimulus"

class ExtrasTableController extends Controller {
  static targets = ['button'];

  connect() {
    this.expanded = false;
  }

  expand_collapse(event) {
    this.expanded = !this.expanded;

    const expanded = this.expanded;

    this.element.querySelectorAll('tr:nth-child(n+3):not(.extras-expand-btn)').forEach((el) => {
      const classList = el.classList;

      if (expanded) {
        classList.remove('hidden');
      } else {
        classList.add('hidden');
      }
    });

    const button = this.buttonTarget
    const data = button.dataset

    button.innerHTML = expanded ? data.collapseText : data.expandText
  }
}

export default ExtrasTableController;
