import { useRef, useState, useMemo, useEffect } from 'react';
import axios from "axios";
import { CheckFileSize } from "./Validations";
import classnames from 'classnames';

export default ({ uid,
                  index,
                  uploadUrl,
                  file,
                  updateFile,
                  removeDocument,
                  documentsLength,
                  onInProgress,
                  mandatory,
                  disabled,
                  allowDelete = true}) => {

  const [askUpload, setAskUpload] = useState(false);
  const [uploadProcess, setUploadProcess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState('');
  const [hiddenDeleteButton, setHiddenDeleteButton] = useState(false);
  const [uploadPercent, setUploadPercent] = useState(0);

  const fileRef = useRef();

  const required = useMemo(() => index === 0 && mandatory, [index, mandatory])

  const upload = () => {
    let errorMessage = CheckFileSize(fileRef.current);

    if (errorMessage) {
      setShowError(true);
      setShowErrorMessage(errorMessage)
      return;
    }

    setShowError(false);

    if (allowDelete) {
      uploadFile();
    } else {
      setAskUpload(true);
    }
  }

  const approve = (e) => {
    e.preventDefault();
    setAskUpload(false);
    uploadFile();
  }

  const cancelUpload = () => {
    setAskUpload(false);
    updateFile(null);
    fileRef.current.value = '';
  }

  const uploadFile = () => {
    const formData = new FormData();
    formData.set('file', fileRef.current.files[0]);

    setUploadPercent(0);
    setUploadProcess(true);
    increase();

    axios
      .post(uploadUrl, formData, {
        onUploadProgress: progressEvent => {
          const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          setUploadPercent(progress);
        }
      })
      .then(response => {
        const { data } = response;
        setUploadProcess(false);
        updateFile({ name: data.filename, url: data.file_url, deleteUrl: data.delete_url });
        decrease();
      })
      .catch((error) => {
        setUploadProcess(false);
        setUploadPercent(0);

        if (error.response.status === 422) {
          fileRef.current.value = '';
          setShowError(true);
          setShowErrorMessage(error.response.data.errors.file)
        }

        decrease();
      });
  }

  useEffect(() => {
    const input = fileRef.current;
    if (fileRef.current) input.setCustomValidity(required ? 'A document must be uploaded' : '')
  }, [required])

  const removeDoc = (e) => {
    e.preventDefault();

    if (file === null) {
      removeDocument();
    } else {
      increase();
      setHiddenDeleteButton(true);
      axios.delete(file.deleteUrl).then(response => {
        setHiddenDeleteButton(false);
        decrease();
        removeDocument();
      }).catch(() => {
        setHiddenDeleteButton(false);
        decrease();
      });
    }
  }

  const increase = () => { onInProgress(1) }
  const decrease = () => { onInProgress(-1) }

  const visibleDelete = useMemo(() => {
    if (hiddenDeleteButton) return false;
    if (file !== null && !allowDelete) return false;
    if (documentsLength === 1 && file === null) return false;

    return true;
  }, [documentsLength, file, hiddenDeleteButton])

  const fileClassName = classnames(required ? 'required' : '')

  return (
    <div data-uid={uid}>
      <div className='question_document form-group'>
        {mandatory && <abbr style={{ position: 'absolute', top: 0, right: -15, textDecoration: "none" }} title='required'>*</abbr>}

        {uploadProcess &&
        <div className='upload'>
          <div className='upload_progress' style={{ width: `calc(${uploadPercent}% - 4px)` }}></div>
        </div>
        }

        <div className='pull-right'>
          {askUpload && <a href="#" style={{ paddingRight: 5 }} onClick={(e) => approve(e)}>Confirm</a>}
          {visibleDelete && <a href="#" onClick={(e) => removeDoc(e)}>Delete</a>}
        </div>

        {file ?
          <a href={file.url} target="_blank">{file.name}</a> :
          <input
            type="file"
            className={fileClassName}
            ref={fileRef}
            onChange={upload}
            required={required}
            disabled={disabled}
            onFocus={(e) => {
              // For Iphones and Ipad
              if(required) {
                const input = e.target;
                input.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                });
              }
            }}/>
        }
      </div>

      {showError &&
      <div className="has-error">
        <div className='help-block'>
          { showErrorMessage }
        </div>
      </div>
      }
    </div>
  );
}
