import { Controller } from "@hotwired/stimulus"
import axios from "axios";

export default class extends Controller {
  static targets = ['messagesLink'];

  connect() {
    const el = this.element;
    axios.get('/messenger/unread').then((rensponse) => {
      this.messagesLinkTargets.map(el => {
        el.insertAdjacentHTML('beforeend', rensponse.data);
      })
    }).catch(() => {});
  }
}

