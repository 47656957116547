import React from 'react'

export default ({ label, name, defaultChecked, checked, onChange, isDisabled }) => (
  <div className="form-group checkbox">
    <label>
      <input
        type="hidden"
        defaultValue={0}
        name={name}
      />
      <input
        className="boolean optional"
        type="checkbox"
        defaultValue={1}
        defaultChecked={defaultChecked}
        checked={checked}
        onChange={onChange}
        name={name}
        disabled={isDisabled}
      />

      {isDisabled && <input name={name} type="hidden" value={checked}/>}
      {label}
    </label>
  </div>
);