import { Controller } from "@hotwired/stimulus"

class MapBoxController extends Controller {
  static targets = ['pointer', 'description', 'child'];

  static values = {
    expandedClass: 'fa-angle-up',
    collapsedClass: 'fa-angle-down',
    hiddenBlockClass: 'hidden-block'
  }
}

export default MapBoxController;
