import React from "react";
import VenueAdminControlButton from "./VenueAdminControlButton";
import SystemAdminControlButton from "./SystemAdminControlButton";

export default (params) => {
  if (params.deleteRequestEnabled) {
    return <VenueAdminControlButton {...params}/>
  } else {
    return <SystemAdminControlButton {...params}/>
  }
}