import axios from 'axios';

$(document).on('click', '.resend-invitation', function(e) {
  e.preventDefault();

  const message = "Are you sure to want to resend the invitation?";

  if (confirm(message)) {
    axios
      .put($(this).attr('href'))
      .then(response => Helper.flash_message('success', response.data.notice))
      .catch(() => Helper.flash_message('error', 'Something went wrong'));
  }
})
