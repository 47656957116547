import SmartyForm from '../helpers/smarty_form';
import { processingModal } from "../helpers/processing_modal";

class MultiMessage {
  constructor() {
    this.smartyForm = new SmartyForm();
    this.processingModal = processingModal;
  }

  start() {
    $('.email-selectize').selectize({
      sortField: 'text',
      onChange: (list) => {
        $('#send-multimessage-submit').prop('disabled', (list || []).length === 0)
      }
    });

    this.onSubmit();
  }

  onSubmit() {
    $('#send-multimessage-submit').on('click', (event) => {
      event.preventDefault();

      const $this = $(event.currentTarget);

      if ($this.attr('disabled') !== 'disabled') {
        $this.attr('disabled', true);

        const $form = $('#multimessage-form');

        this.processingModal.show(1000);
        this.smartyForm.removeErrors($form);

        $.ajax({
          type: 'POST',
          url: $form.attr('action'),
          dataType: 'json',
          cache: false,
          contentType: false,
          processData: false,
          data: new FormData(document.getElementById('multimessage-form')),

          success: (data) => {
            this.processingModal.hide(1000);

            $this.attr('disabled', false);

            $('#select_all').prop('checked', false);
            $('#multiple_message_conversation_modal').modal('hide');
            $('.list-group-item.pair_conversation_item.selected a.venue_user_conversation').trigger('click');

            Helper.flash_message('success', data.message);

            $('#recipients li.list-group-item:first a.venue_user_conversation').click();
          },

          error: (error) => {
            this.processingModal.hide(1000);

            $this.attr('disabled', false);

            const errors = (error.responseJSON || {}).errors;

            if (errors['base']) {
              Helper.flash_message('error', errors['base'].join(', '));
            }

            this.smartyForm.addErrors($form, 'conversation', errors);
          }
        });
      }
    });
  }
}

export default MultiMessage;