import React, { useEffect, useState, useRef } from 'react';
import { Button, Modal } from 'react-bootstrap';
import MultipleFields from './MultipleFields';
import SingleFields from './SingleFields';
import axios from "axios";
import Switcher from "./Switcher";

export default ({ facilityId, show, setShow, fetchMultiSwitcher }) => {
  const [groupMainFacility, setGroupMainFacility] = useState(null);
  const [childFacilities, setChildFacilities] = useState(null);
  const [deleteRequestEnabled, setDeleteRequestEnabled] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const formEl = useRef();

  useEffect(() => {
    if (show) {
      axios
        .get(`/admin/facilities/${facilityId}/multi_switcher.json`)
        .then(resp => {
          let childFacilities = resp.data.childFacilities;

          setGroupMainFacility(resp.data.groupMainFacility)
          setDeleteRequestEnabled(resp.data.deleteRequestEnabled)
          setLoading(false);

          if (childFacilities.length === 0) {
            childFacilities = childFacilities.concat({ id: '', internalReference: "" });
            childFacilities = childFacilities.concat({ id: '', internalReference: "" });
          }

          setChildFacilities(childFacilities);
        });
    } else {
      setLoading(true);
      setErrors({});
      setChildFacilities([]);
      setGroupMainFacility(null)
    }
  }, [show])

  useEffect(() => {
    setErrors({})
  }, [groupMainFacility])

  const handleAdd = (n) => {
    let newChildFacilities = [...childFacilities];

    for (let i = 0; i < n; i++) {
      newChildFacilities = newChildFacilities.concat({ id: '', internalReference: "" });
    }

    setChildFacilities(newChildFacilities);
  }

  const sendDeleteRequest = (index) => {
    const newChildFacilities = [...childFacilities];
    const id = newChildFacilities[index].id;
    newChildFacilities[index]['deleteRequestDisabled'] = true;
    setChildFacilities(newChildFacilities);

    axios.post(`/admin/facilities/${facilityId}/sub_facilities/${id}/delete_request`).then(() => {
      newChildFacilities[index]['deleteRequestDisabled'] = false;
      newChildFacilities[index][childFacilities[index].deleteRequestEnabled ? 'deleteRequest' : '_destroy'] = true;
      setChildFacilities([...newChildFacilities]);
    })
  }

  const undoDeleteRequest = (index) => {
    const newChildFacilities = [...childFacilities];

    newChildFacilities[index]['deleteRequestDisabled'] = true;
    setChildFacilities(newChildFacilities);

    const id = newChildFacilities[index].id;

    axios.delete(`/admin/facilities/${facilityId}/sub_facilities/${id}/delete_request`).then(() => {
      newChildFacilities[index]['deleteRequestDisabled'] = false;
      newChildFacilities[index][childFacilities[index].deleteRequestEnabled ? 'deleteRequest' : '_destroy'] = false;
      setChildFacilities([...newChildFacilities]);
    })
  }

  const handleDelete = (index) => {
    let newChildFacilities = [...childFacilities];

    if (childFacilities[index].id === '') {
      newChildFacilities = newChildFacilities.filter((_, i) => i !== index);
      setChildFacilities(newChildFacilities);
    } else {
      if (childFacilities[index].deleteRequestEnabled) {
        sendDeleteRequest(index);
      } else {
        newChildFacilities[index][childFacilities[index].deleteRequestEnabled ? 'deleteRequest' : '_destroy'] = true;
        setChildFacilities(newChildFacilities);
      }
    }
  }

  const handleRevert = (index) => {
    const newChildFacilities = [...childFacilities];

    if (childFacilities[index].deleteRequestEnabled) {
      undoDeleteRequest(index);
    } else {
      newChildFacilities[index][childFacilities[index].deleteRequestEnabled ? 'deleteRequest' : '_destroy'] = false;
      setChildFacilities(newChildFacilities);
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    const button = e.currentTarget;
    button.disabled = true;

    const data = new FormData(formEl.current);

    axios
      .post(`/admin/facilities/${facilityId}/multi_switcher`, data)
      .then(() => {
        setShow(false)
        setChildFacilities(null);
        fetchMultiSwitcher();
      })
      .catch((error) => {
        button.disabled = false;
        if (error.response.status ===  422) setErrors(error.response.data.errors);
      });
  }

  return (
    <Modal show={show} onHide={() => setShow(false)} className='child-facilities-modal'>
      <form onSubmit={handleSubmit} ref={formEl}>
        <Modal.Header closeButton>
          <Modal.Title>Manage</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!loading &&
            <>
              {!deleteRequestEnabled &&
                <Switcher
                  groupMainFacility={groupMainFacility}
                  setGroupMainFacility={setGroupMainFacility}
                  errors={errors}
                />
              }

              {groupMainFacility &&
                <MultipleFields
                  childFacilities={childFacilities}
                  addChildFacility={handleAdd}
                  deleteChildFacility={handleDelete}
                  revertChildFacility={handleRevert}
                  errors={errors}
                />
              }

              {!groupMainFacility && <SingleFields errors={errors}/>}
            </>
          }
        </Modal.Body>
        {!loading &&
          <Modal.Footer>
            <Button onClick={() => setShow(false)}>Cancel</Button>
            <Button className='btn btn-primary' onClick={handleSubmit}>Confirm</Button>
          </Modal.Footer>
        }
      </form>
    </Modal>
  )
}