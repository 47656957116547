import React from "react";
import { components } from "react-select";


const Option = (props) => {
  const { selectedAll, selectAllValue } = props.selectProps;

  if (props.value === selectAllValue) {
    return (
      <components.Option {...props} isSelected={selectedAll}>
        <input type='checkbox' checked={selectedAll} onChange={() => null}/>
        {" "}
        All
      </components.Option>
    )
  }

  return (
    <components.Option {...props}>
      <input type='checkbox' checked={props.isSelected} onChange={() => null}/>
      {" "}
      {props.label}
    </components.Option>
  );
};

export default Option;