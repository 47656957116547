import React from 'react'
import ReactDOM from 'react-dom';
import List from "./List";
import './styles.scss';

document.addEventListener("DOMContentLoaded", function(event) {
  $('.area_nav').on('selected_tab_questions', function () {
    let el = document.getElementById('admin-venue-question')
    ReactDOM.render(<List url={el.dataset.url}/>, el);
  })
});