import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Multiple from "./Multiple";
import ClearHighlight from "./ClearHighlight";
import ExportButtons from '../../../../components/Reports/Export/Buttons';

export default  ({ showCreate, venueId, multiple, setMultiple, clearHighlight, setClearHighlight,
                   selectedItems, setSelectedItems, handleMultiEdit, handleClearHighlightEdit,
                   handleMultiDelete, showMultiple, showClearHighlight, filter, showExportButton }) => (
  <div className={classNames('vert-offset-bottom-10 table-actions', { 'pull-right': !showExportButton })}>
    {showExportButton &&
     <ExportButtons
       filter={filter}
       item={{ key: 'staff_rotas' }}
       exportFormats={['csv']}
       classes="btn-group"
       customPath='/admin/staff_rota'
     />
    }

    <div>
      {showCreate &&
          <Link className='btn btn-green' to={`/admin/staff_rota/new?venue_id=${venueId}`}>
            Create Shift
          </Link>
        }
        {(showMultiple && !clearHighlight) &&
          <Multiple
            multiple={multiple}
            setMultiple={setMultiple}
            selectedItems={selectedItems}
            setShowMultiEdit={handleMultiEdit}
            setShowMultiDelete={handleMultiDelete}
          />
        }
        {(showClearHighlight && !multiple) &&
        <ClearHighlight
          multiple={multiple}
          setMultiple={setMultiple}
          clearHighlight={clearHighlight}
          setClearHighlight={setClearHighlight}
          setShowClearHighlightEdit={handleClearHighlightEdit}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          setShowMultiEdit={handleMultiEdit}
          setShowMultiDelete={handleMultiDelete}
        />}
     </div>
  </div>
)
