import React from 'react';

export default React.forwardRef(({ forwardedRef, title, loading, onSendForm, children, continueButtonDisabled }, ref) => (
  <div id='editBookingModal' className="modal fade" aria-labelledby='modalLabel' role='dialog' tabIndex='-1' ref={ref}>
    <div className='modal-dialog' role='document'>
      <form className="new_booking">
        <div className='modal-content'>
          <div className='modal-header'>
            <button className='close' aria-label='Close' data-dismiss='modal' type='button'>
              <span aria-hidden='true'> × </span>
            </button>
            <h4 className='modal-title'>{title}</h4>
          </div>
          <div className='modal-body'>
            {loading === 'pending' && <div className='text-center' style={{ lineHeight: 10 }}>Loading...</div>}
            {loading === 'succeeded' && children}
          </div>
          {loading === 'succeeded' &&
            <div className='modal-footer'>
              <div className='pull-right btn-group-wrapper'>
                <a className='btn btn-default' data-dismiss='modal'>
                  Cancel
                </a>

                <button type='button'
                        disabled={continueButtonDisabled}
                        className='btn btn-primary submit-btn'
                        onClick={onSendForm}>
                  Continue
                </button>
              </div>
            </div>
          }
        </div>
      </form>
    </div>
  </div>
));
