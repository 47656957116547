import React from 'react'
import moment from "moment";

const momentDate = (date) => moment(date).format('ddd, D MMM YYYY');

export default ({ timePeriod, togglePopover }) => {
  if (!timePeriod) return null;

  const { startDate, endDate, title } = timePeriod;

  let context = `${momentDate(startDate)} - ${momentDate(endDate)}`;
  if ((moment(startDate).isSame(endDate))) context = `${momentDate(startDate)}`;

  return (
    <button type='button' className='btn btn-default btn-block time-periods-button' onClick={togglePopover}>
      {context}
    </button>
  )
};