import { Controller } from "@hotwired/stimulus"
import ReactDOM from "react-dom";
import Documents from "src/components/FileUploader/Documents";
import React from "react";
import { v4 as uuidv4 } from 'uuid';

export default class extends Controller {
  connect() {
    const el = this.element;
    const children = el.querySelectorAll('div');
    const parentElement = el.closest('[data-controller="checkouts--venue_questions"]');

    let documents = [];

    children.forEach(x => {
      const info = x.dataset;
      documents.push({
        id: info.id,
        file: { name: info.filename, url: info.fileUrl, deleteUrl: info.deleteUrl },
        uid: uuidv4()
      })
    });

    if (documents.length === 0) documents.push({ uid: uuidv4(), file: null });

    const documentComponent = <Documents
      parentElement={parentElement}
      initDocuments={documents}
      mandatory={el.dataset.mandatory === 'true'}
      disabled={el.dataset.disabled === 'true'}
      uploadUrl={el.dataset.uploadUrl}
      documentsLimit={parseInt(el.dataset.documentsLimit || 3)}
      allowDelete={el.dataset.allowDelete !== 'false'}
    />;

    ReactDOM.render(documentComponent, el);
  }
}
