import { Controller } from "@hotwired/stimulus";

class SessionStorageController extends Controller {
  clear() {
    sessionStorage.clear();
    ['shiftOptions'].forEach((key) => localStorage.removeItem(key));
  }
}

export default SessionStorageController;
