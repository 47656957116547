class Schedule {
  constructor() { }

  start() {
    const selectedClass = 'selected';
    const [onPeakEnabled, offPeakEnabled] = ['.on_peak_enabled', '.off_peak_enabled'];
    const [onPeakSelect, offPeakSelect] = ['.on_peak_select', '.off_peak_select'];

    $(document).on('click', offPeakEnabled, (event) => {
      const target = $(event.target);
      $(event.target).closest('form').submit();
      target.parents('tr').find(offPeakSelect).prop('disabled', !target.is(':checked'));
    });

    $(document).on('click', onPeakEnabled, (event) => {
      const $target = $(event.target);
      const enabled = $target.is(':checked');
      const $row = $target.parents('tr');

      $(event.target).closest('form').submit();

      $row.find(offPeakEnabled).prop('disabled', !enabled);

      if (enabled) {
        $row.addClass(selectedClass);
        $row.find(onPeakSelect).prop('disabled', false);

        if ($row.find(onPeakEnabled).first().prop('checked')) {
          $row.find(offPeakSelect).prop('disabled', !enabled);
        }
      } else {
        $row.removeClass('selected');
        $row.find(offPeakSelect).prop('disabled', true);
        $row.find(onPeakSelect).prop('disabled', true);
      }
    });
  }
}

export default Schedule;
