(function() {
  var collectExtraOrders;

  window.initSortableExtras = function() {
    $('.sortable-extras').sortable({
      stop: function(e, u) {
        return collectExtraOrders();
      }
    });
  };

  initSortableExtras();

  collectExtraOrders = function() {
    var extra_records, ids;
    extra_records = $('.sortable-extras .ui-sortable-handle');

    ids = $.map(extra_records, function(record, _) {
      return $(record).data('id');
    });

    $.ajax({
      method: 'PUT',
      url: '/admin/extras/reorder',
      data: { ids: ids },
      success: function(result) {
        $('.success').fadeIn('slow');
        $('.success').fadeOut('slow');
      },
      fail: function(result) {
        $('.error').fadeIn('slow');
        $('.error').fadeOut('slow');
      }
    });
  };
}).call(this);