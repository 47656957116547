$(document).ready(function() {
  $(document).on('click', '.dropdown-menu li a.open_modal_customer_note', function(e) {
    e.preventDefault();

    const link = $(this).attr('href')
    const modal = $('#admin_modal_customers_note')

    modal.modal('show')

    $.ajax({
      url: link,
      success: (data) => $('#admin_modal_customers_note .modal-content').html(data)
    })
  })

  $(document).on('ajax:success', '#admin_modal_customers_note form', function() {
    $('#admin_modal_customers_note').modal('hide')
    const table = $('#customers-table');

    const orders_url = table.data('source') + '?' + $("form#new_filter").serialize()
    const ajax = table.DataTable().ajax
    ajax.url(orders_url)
    ajax.reload(null, false)
  })
})
