import React, { useState } from "react";

export function useModal(Component, onCompleted) {
  const [show, setShow] = useState(false);
  const [options, setOptions] = useState({});

  const modal = <Component id={options.id} show={show} setShow={setShow} onCompleted={onCompleted}/>;

  const showModal = (options) => {
    setShow(true)
    setOptions(options)
  }

  const hideModal = () => {
    setShow(false)
    setOptions({})
  }

  return [modal, showModal, hideModal];
}