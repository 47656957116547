import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Form from "./Form";
import ErrorBoundary from "../../../components/Reports/ErrorBoundary";
import ApiContext from './ApiContext';

export default ({ show, setShow, id, onCompleted }) => {
  const api = useContext(ApiContext);
  const formRef = useRef();
  const [question, setQuestion] = useState(null);
  const [disabledConfirm, setDisabledConfirm] = useState(false);
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    if (show === true) {
      api.getQuestion(id).then(response => { setQuestion(response.data); setErrors({}) })
    } else {
      setQuestion(null)
    }
  }, [show])

  const handleUpdate = () => {
    let formData = new FormData(formRef.current);

    if (!question.isChild && question.multipleVenues) {
      if ($('.modal-body .type-venue:checked').length === 0) {
        alert("At least 1 venue must be selected.");
        return false;
      }
    } else {
      if ($('.modal-body #question_all_facilities_0:checked').length > 0) {
        if ($('.modal-body .type-facility:checked').length === 0) {
          alert("At least 1 facility must be selected.");
          return false;
        }
      }
    }

    if (disabledConfirm) return true;
    setDisabledConfirm(true)

    api
      .updateQuestion(id, formData)
      .then(response => {
        setShow(false)
        onCompleted()
        setDisabledConfirm(false)
      })
      .catch((error) => {
        setDisabledConfirm(false)
        setErrors(error.response.data.errors)
      })
  }

  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Question</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ErrorBoundary>
          {question && <Form ref={formRef} question={question} errors={errors}/>}
        </ErrorBoundary>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => setShow(false)}>Cancel</Button>
        <Button className='btn-primary' disabled={disabledConfirm} onClick={handleUpdate}>Confirm</Button>
      </Modal.Footer>
    </Modal>
  )
};