import React from "react";
import ModalEdit from "./Modals/Edit";
import ModalDelete from "./Modals/Delete";
import MultipleEdit from "./Modals/MultipleEdit";
import MultipleClearHighlight from "./Modals/MultipleClearHighlight";
import MultipleDelete from "./Modals/MultipleDelete";
import AfterEdit from "./Modals/AfterEdit";

export default ({ itemId, onUpdated, data,
                  onRemoved, onEdited,
                  selectedItemIds,
                  selectedNonCancelledItemIds,
                  showEdit, setShowEdit,
                  showDelete, setShowDelete,
                  showMultiEdit, setShowMultiEdit,
                  showClearHighlightEdit, setShowClearHighlightEdit,
                  clearHighlight, setClearHighlight,
                  showMultiDelete, setShowMultiDelete,
                  showAfterEdit, setShowAfterEdit,
                  afterEditMessageType, setAfterEditMessageType
}) => {
  const handleOnEdited = () => {
    if (typeof onEdited === 'function') onEdited() ;
    if (typeof onUpdated === 'function') onUpdated();
  }

  const handleOnRemoved= () => {
    if (typeof onRemoved === 'function') onRemoved() ;
    if (typeof onUpdated === 'function') onUpdated();
  }

  return (
    <>
      <AfterEdit
        show={showAfterEdit}
        setShow={setShowAfterEdit}
        messageType={afterEditMessageType}
        setAfterEditMessageType={setAfterEditMessageType}
      />

      <ModalEdit
        itemId={itemId}
        show={showEdit}
        setShow={setShowEdit}
        setAfterEditMessageType={setAfterEditMessageType}
        onUpdated={handleOnEdited}
      />

      <MultipleClearHighlight
        selectedItemIds={selectedItemIds}
        show={showClearHighlightEdit}
        setShow={setShowClearHighlightEdit}
        clearHighlight={clearHighlight}
        setClearHighlight={setClearHighlight}
        onUpdated={onUpdated}
      />

      <MultipleEdit
        selectedItemIds={selectedItemIds}
        selectedNonCancelledItemIds={selectedNonCancelledItemIds}
        show={showMultiEdit}
        setShow={setShowMultiEdit}
        setAfterEditMessageType={setAfterEditMessageType}
        onUpdated={handleOnEdited}
      />

      <ModalDelete
        itemId={itemId}
        show={showDelete}
        setShow={setShowDelete}
        onUpdated={handleOnRemoved}
        data={data}
      />

      <MultipleDelete
        selectedItemIds={selectedItemIds}
        show={showMultiDelete}
        setShow={setShowMultiDelete}
        onUpdated={handleOnRemoved}
        data={data}
      />
    </>
  )
}