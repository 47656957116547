import React, { useEffect } from 'react';
import axios from "axios";
import api from './api';
import MegaFilter from '../../MegaFilter/Index'
import DateInput from 'src/components/DateInput'
import FormError from '../Components/FormError'

import ReportTypes from '../Components/ReportTypes';
import ReportIntervals from '../Components/ReportIntervals';
import ReportExportType from '../Components/ReportExportType';
import ReportDays from "../Components/ReportDays";
import ReportTime from "../Components/ReportTime";
import ReportUsers from "../Components/ReportUsers";

export default ({ id, state, dispatch }) => {
  const { reportTypes, reportType, reportTypeKey, searchFilters, startDate, weekDays, availableExportOptions,
          exportType, reportTime, reportInterval, errors, availableReportIntervals,
          showCcUsersSelector, ccUsers, availableCcUsers} = state;

  const setReportTypes = (reportTypes) => { dispatch({ type: 'setReportTypes', payload: reportTypes }) };
  const setReportType = (value) => { dispatch({ type: 'setReportType', value: value }) };
  const handleFiltersChange = (value) => { dispatch({ type: 'setSearchFilters', value: value }) };
  const setStartDate = (value) => { dispatch({ type: 'setStartDate', value: value }) };
  const setWeekDays = (value) => { dispatch({ type: 'setWeekDays', value: value }) };
  const setExportType = (value) => { dispatch({ type: 'setExportType', value: value }) };
  const setReportTime = (value) =>  { dispatch({ type: 'setReportTime', value: value }) };
  const setReportInterval = (value) =>  { dispatch({ type: 'setReportInterval', value: value }) };
  const setCcUsers= (value) =>  dispatch({ type: 'setCcUsers', value: value });


  useEffect(() => {
    if (state.status === 'pending') {
      axios
        .get('/admin/reports/schedules/reports.json')
        .then((response) => setReportTypes(response.data.reportTypes));
    } else if (state.status === 'report_types_present') {
      axios
        .get(`/admin/reports/schedules/${id || 'new'}.json`)
        .then((response) => {
          dispatch({ type: 'assignSchedule', options: { reportTypes: reportTypes }, payload: response.data });
        });
    }
  }, [state.status]);

  useEffect(() => {
    if (state.venueId !== null) {
      api.fetchUsers(state.venueId, state.reportTypeKey).then((response) => {
        dispatch({ type: 'setVenueUsers', payload: response.data });
      })
    }
  }, [state.venueId, state.reportTypeKey])
  
  if (reportTypes.length === 0) return null;

  return (
    <form className="simple_form report-schedules-form" action="#" id='report-schedules-form'>
      <div className="row vert-offset-bottom-15">
        <div className='col-md-4 form-item'>
          <label>Type</label>
          <ReportTypes reportTypes={reportTypes}
                       reportTypeKey={reportTypeKey}
                       onSelectReportType={setReportType}/>
        </div>

        <div className='col-md-4 form-item'>
          <label>Interval</label>
          <ReportIntervals availableReportIntervals={availableReportIntervals}
                           reportInterval={reportInterval}
                           setReportInterval={setReportInterval}
          />
        </div>
        <div className='col-md-4 form-item'>
          <label>Export</label>
          <ReportExportType availableExportOptions={availableExportOptions}
                            exportType={exportType}
                            setExportType={setExportType}
          />
        </div>
        <div className='col-md-4' />
        <div className='col-md-8'>
          <FormError errors={errors} name='export_type'/>
        </div>
      </div>
      <div className="row vert-offset-bottom-15">
        <div className='col-md-4 start-date-input form-item'>
          <label>Start date to receive emails</label>
          {startDate && <DateInput onChange={setStartDate} value={startDate}/>}
        </div>
        <div className='col-md-4 form-item'>
          <label>Time to receive emails</label>
          <ReportTime reportTime={reportTime} setReportTime={setReportTime}/>
        </div>
        <div className='col-md-4 form-item'>
          <label>Days to receive emails</label>
          <ReportDays weekDays={weekDays} setWeekDays={setWeekDays}/>
        </div>
      </div>
      <div className="row vert-offset-bottom-15">
        <div className='col-md-12 reports-section form-item'>
          { reportType && <MegaFilter onUpdated={handleFiltersChange} item={reportType} options={searchFilters}/>}
        </div>
        {showCcUsersSelector && (availableCcUsers.length > 1 || ccUsers.length > 1) &&
          <div className='col-md-12 form-item'>
            <label>Users to receive emails</label>
            <ReportUsers availableCcUsers={availableCcUsers}
                         ccUsers={ccUsers}
                         setCcUsers={setCcUsers}/>
            <FormError errors={errors} name='cc_users'/>
          </div>
        }
      </div>

      <FormError errors={errors} name='base'/>
    </form>
  );
};
