import React from "react";
import IntervalTitleDecorator from "src/components/ReportSchedules/helpers/interval_title_decorator";

export default ({ availableReportIntervals, reportInterval, setReportInterval }) => (
  <select className="form-control select required"
          value={reportInterval || ''}
          onChange={e => setReportInterval(e.target.value)}>
    {availableReportIntervals.map(item =>
      <option key={item} value={item}>{IntervalTitleDecorator(item)}</option>)
    }
  </select>
);
