import SmartyForm from '../../helpers/smarty_form';
import axios from "axios";

class Invitation {
  constructor() { }

  start() {
    $('#invite_user').on('click', (e) => {
      e.preventDefault();
      const modal = $('#invite_employee_modal');
      modal.modal('show');
      axios.get(e.target.getAttribute('href')).then(({ data }) => {
        modal.find('.modal-body').html(data);
      });
    });

    $(document).on('click', '#invite-subadmin-submit', (event) => {
      event.preventDefault();

      const button = event.target;
      const $this = $(event.currentTarget);
      const $form = $this.closest('form');
      const smartyForm = new SmartyForm();

      button.disabled = true;

      smartyForm.removeErrors($form);

      $.ajax({
        type: 'POST',
        url: $form.attr('action'),
        data: new FormData($form[0]),
        processData: false,
        contentType: false,

        success: () => {
          $('#invite_employee_modal').modal('hide');
          window.refreshUsers();
          button.disabled = false;
        },

        error: (response) => {
          button.disabled = false;
          smartyForm.addErrors($form, 'user', (response.responseJSON || {}).errors)
        }
      });
    });
  }
}

export default Invitation;