import './style.scss';

const FacilityActivities = (function () {
  function bindElements(autoSubmit) {
    const autoSend = autoSubmit || false

    $.each($('input[class=related_category]'), function () {
      $(this).siblings('.related_category_block').toggle($(this).prop('checked'))
    });

    $('input[class=related_category]').on('click', function () {
      $(this).siblings('.related_category_block').toggle($(this).prop('checked'))
      if (autoSend) sendData();
    });

    $('.facility-feature input[type=checkbox]').on('click', function () {
      var checkboxValue = $(this).val();
      var checked = $(this).prop('checked');

      $.each($('.facility-feature input[type=checkbox]'), function () {
        if ($(this).val() === checkboxValue) $(this).prop('checked', checked);
      });

      if (autoSend) sendData();
    });
  }

  function sendData() {
    $.ajax({
      type: 'PUT',
      url: $('#edit_features').attr('action'),
      data: $('#edit_features').serialize()
    }).done(function(data) {
      $("a[href='#tab-activities']").toggleClass('exclamation-warn-icon', !data.hasFeature)
    }).fail(function() {
      reloadTab();
      Helper.flash_message('error', 'Something went wrong')
    });
  }

  function reloadTab() {
    $("#tab-activities").html('');

    $.get(window.location.href + '/tabs/activities', function( data ) {
      $("#tab-activities").html(data);
      FacilityActivities.bindElements(true)
    });
  }

  return {
    bindElements: function (autoSubmit) {
      return bindElements(autoSubmit)
    },
    reloadTab: function () {
      return reloadTab()
    },
  };
})();

$(document).ready(function() {
  const items = $('.area_nav li');
  items.on('shown.bs.tab', function(e) { loadTab(e, { showSpinner: true })});
  items.on('shown.bs.tab-reload', function(e) { loadTab(e, { showSpinner: false })});

  function loadTab(e, options = { showSpinner: true }) {
    const a = $(e.target);
    const areaNav = a.closest('.area_nav');
    const href = a.attr('href');
    const name = href.replace('#tab-','');
    const tabUrl = `${areaNav.data('url')}/tabs/${name}`;

    if (options.showSpinner) {
      $('.area-tab-content .tab-pane').empty();
      $(`#tab-${name}`).append('');
    }

    $.get(tabUrl, function(data) {
      $(`#tab-${name}`).html(data);
      areaNav.trigger(`selected_tab_${name}`)
    });
  }

  $('.area_nav li.active a').trigger('shown.bs.tab');

  $('.area_nav').on('selected_tab_activities', function() {
    FacilityActivities.reloadTab();
  });
});

