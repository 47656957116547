import React from "react";
import Toggle from 'react-toggle';
import "react-toggle/style.css"

export default ({ noticeMessage }) => {
  let enabled = noticeMessage.enabled;

  return (
    <>
      <input name="id" defaultValue={noticeMessage.id} type='hidden'/>
      <label>
        <Toggle
          defaultChecked={enabled}
          icons={false}
          name='enabled'
        />
      </label>

      <div className="form-group">
        <label className="control-label">
          Title
        </label>

        <input
          className="form-control"
          name="title"
          defaultValue={noticeMessage.title}
        />
      </div>

      <div className="form-group text">
        <label className="control-label text">
          Notice message shown to venues
        </label>

        <textarea
          className="form-control text"
          name="message"
          rows={6}
          defaultValue={noticeMessage.message}
        />
      </div>
    </>
  )
}