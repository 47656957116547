jQuery.propHooks.disabled = {
  set: function (el, value) {
    if (el.disabled !== value) {
      el.disabled = value;
      value && $(el).trigger('disabledSet');
      !value && $(el).trigger('enabledSet');
    }
  }
};

$.fn.serializeObject = function()
{
  var o = {};
  var a = this.serializeArray();
  $.each(a, function() {
    if (o[this.name] !== undefined) {
      if (!o[this.name].push) {
        o[this.name] = [o[this.name]];
      }
      o[this.name].push(this.value || '');
    } else {
      o[this.name] = this.value || '';
    }
  });
  return o;
};
