import axios from 'axios';
import './styles.scss'

$(document).on('click', '.conversation-pagination a', (e) => {
  e.preventDefault();

  let link = new URL($(e.target).attr('href'), window.location.origin);
  link.searchParams.set('only_messages', 1)

  axios.get(link.toString()).then(response => {
    const messagesList = document.getElementById('messages-list');
    const topPos = messagesList.offsetTop;

    messagesList.innerHTML = response.data
    window.scrollTo(0, topPos + 30);
  })
})