import axios from 'axios';

const openCustomMessagesPreviewModal = (target) => {
  const template = target.data('template');
  const form = $('#new_booking_custom_message');
  const params = form.serializeObject();

  axios
    .get(`/admin/booking_custom_message/${template}/preview`, { params: params })
    .then(response => {
      const modal = $('#admin_conversations_preview');
      modal.find('.modal-content').empty().append(response.data)
      modal.modal('show')
    })
    .catch((error) => {
      Helper.flash_message('error', error.response.data.error)
    });
}

$(document).on('click', '#preview-modal-close', function(e) {
  $('#admin_conversations_preview').modal('hide');
});

const openCustomMessagesModal = (e) => {
  const submenuElement = document.querySelector('.maintabs_submenu');
  const venueId = submenuElement.dataset.venue_id;

  axios
    .get('/admin/booking_custom_message', { params: {
        venue_id: venueId,
        custom_message_type: e.find('#custom_message_type').val()
    }})
    .then(response => {
      sessionStorage.removeItem('custom_messages_data')

      const modal = $('#admin_conversations_modal');
      modal.find('.modal-content').empty().append(response.data)

      modal.find("label[for='booking_custom_message_body']").text(e.find('#custom_message_label').val())
      modal.find('#custom_message_title').text(e.find('#custom_message_title').val())
      modal.find('#booking_custom_message_custom_message_type').val(e.find('#custom_message_type').val())
      modal.modal('show')

      const venueEl = $('#booking_custom_message_venue_id')
      venueEl.val(venueId)
      venueEl.trigger('change')
    })
    .catch((error) => {
      Helper.flash_message('error', error.response.data.error)
    });
}

const checkEnabledAll = () => {
  const storage = (JSON.parse(sessionStorage.getItem('custom_messages_data')) || {})
  const keys = Object.keys(storage)
  const values = Object.values(storage)
  const option_length = $('#booking_custom_message_venue_id option').length - 1
  const index = keys.indexOf('all-venues');
  if (index > -1) {
    values.splice(index, 1);
  }
  const enabled_values = values.map((o) => o.enabled)
  return (enabled_values.length == option_length &&  enabled_values.indexOf(false) == -1)
}

const getCustomMessagesDataJson = () => {
  axios
    .get('/admin/booking_custom_message/edit.json', { params: {
        venue_id: $('#booking_custom_message_venue_id').val(),
        custom_message_type: $('#booking_custom_message_custom_message_type').val()
      }})
    .then(response => {
      const storage_data = (JSON.parse(sessionStorage.getItem('custom_messages_data')) || {})[$('#booking_custom_message_venue_id').val()]
      const body = storage_data ? storage_data['body'] : response.data.body || ''
      const enabled = storage_data ? storage_data['enabled'] : response.data.enabled
      
      $('#admin_conversations_modal').find('#booking_custom_message_body').val(body)
      $('#admin_conversations_modal').find('#booking_custom_message_enabled').prop('checked', enabled)
      document.querySelector(".custom-message-time-range-container").hidden = !enabled;

      const startTime = storage_data ? storage_data['start_time'] : response.data.start_time;
      const endTime = storage_data ? storage_data['end_time'] : response.data.end_time;

      if (startTime && endTime) {
        const locale = 'en-GB'
        const localeOptions = {
          weekday: 'short',
          day: 'numeric',
          month: 'short',
          year: 'numeric',
          timeZone: 'Europe/London'
        }

        const startDate = new Date(startTime).toLocaleDateString(locale, localeOptions);
        const endDate = new Date(endTime).toLocaleDateString(locale, localeOptions);
        const startHour = new Date(startTime).toLocaleTimeString(locale, { timeZone: 'Europe/London' }).slice(0, 5)
        const endHour = new Date(endTime).toLocaleTimeString(locale, { timeZone: 'Europe/London' }).slice(0, 5)

        const modal = $('#admin_conversations_modal');
        modal.find('#booking_custom_message_start_date').datepicker('setDate', startDate)
        modal.find('#booking_custom_message_end_date').datepicker('setDate', endDate)
        modal.find('#booking_custom_message_start_hour').val(startHour)
        modal.find('#booking_custom_message_end_hour').val(endHour)
        modal.find('#booking_custom_message_schedule_toggle').prop('checked', enabled)

        const inputsContainer = document.querySelector(".time-range-inputs");
        const timeRangeCheckBox = document.querySelector("#booking_custom_message_schedule_toggle");
        inputsContainer.hidden = false;
        timeRangeCheckBox.checked = true;
      }

      if ($('#booking_custom_message_venue_id').val() == ('all-venues') && enabled == false) {
        $('#admin_conversations_modal').find('#booking_custom_message_enabled').prop('checked', checkEnabledAll())
      }
    })
    .catch((error) => {
      Helper.flash_message('error', error.response.data.error)
    });
}

const changeCustomMessagesData = () => {
  const value = JSON.parse(sessionStorage.getItem('custom_messages_data') || '{}')
  value[$('#booking_custom_message_venue_id').val()] = {
    custom_message_type: $('#booking_custom_message_custom_message_type').val(),
    enabled: $('#booking_custom_message_enabled').is(":checked"),
    body: $('#booking_custom_message_body').val(),
    start_date: $('#booking_custom_message_start_date').val(),
    end_date: $('#booking_custom_message_end_date').val(),
    start_hour: $('#booking_custom_message_start_hour').val(),
    end_hour: $('#booking_custom_message_end_hour').val(),
    schedule_enabled: $('#booking_custom_message_schedule_toggle').prop("checked")
  }

  sessionStorage.setItem('custom_messages_data', JSON.stringify(value));
}

const updateSubmenu = () => {
  const submenuElement = document.querySelector('.maintabs_submenu');

  if (submenuElement) {
    const venueId = submenuElement.dataset.venue_id;

    axios
      .get('/admin/booking_custom_message/submenu.json', {params: {venue_id: venueId}})
      .then(response => {
        $('.custom-message-user_order_request_created .toggle-btn input').prop('checked', response.data.user_order_request_created || false)
        $('.custom-message-user_order_accepted .toggle-btn input').prop('checked', response.data.user_order_accepted || false)
        $('.custom-message-user_order_completed .toggle-btn input').prop('checked', response.data.user_order_completed || false)
        $('.custom-message-message_auto_response .toggle-btn input').prop('checked', response.data.message_auto_response || false)
        $('#auto-signature .toggle-btn input').prop('checked', response.data.enabled_signature || false)
      })
      .catch((error) => {
        Helper.flash_message('error', error.response.data.error)
      });
  }
}

const saveCustomMessagesData = () => {
  changeCustomMessagesData()

  const bookingCustomMessages = JSON.parse(sessionStorage.getItem('custom_messages_data') || {});

  Object.keys(bookingCustomMessages).forEach(key => {
    if (bookingCustomMessages[key].body) {
      bookingCustomMessages[key].body = bookingCustomMessages[key].body.replace(/\n/g, '\r\n');
    }
  });

  axios
    .patch('/admin/booking_custom_message', { booking_custom_messages: bookingCustomMessages })
    .then(response => {
      sessionStorage.removeItem('custom_messages_data')
      $('#admin_conversations_modal').modal('hide')
      updateSubmenu()
    })
    .catch((error) => {
      if (error.response.data.error.body) {
        const errEl = document.querySelector("#body-error");
        const bodyEl = document.querySelector("#booking_custom_message_body");
        errEl.innerHTML = error.response.data.error.body.join("\r\n");
        bodyEl.classList.add("input-error");
      } else {
        Helper.flash_message('error', Object.values(error.response.data.error).flat().join("\r\n"));
      }
    });
}

$(document).on('click', '.custom-message-user_order_request_created,  ' +
  '.custom-message-user_order_accepted,  ' +
  '.custom-message-user_order_completed,  ' +
  '.custom-message-message_auto_response', function(e) {
  e.preventDefault()
  openCustomMessagesModal($(this))
});

$(document).on('click', '#preview_custom_message', function(e) {
  e.preventDefault();
  openCustomMessagesPreviewModal($(e.target));
});


$(document).on('change', '#booking_custom_message_venue_id', function(e) {
  e.preventDefault()
  getCustomMessagesDataJson()
});

$(document).on('change', '#booking_custom_message_enabled, #booking_custom_message_body, #booking_custom_message_start_date, #booking_custom_message_end_date, #booking_custom_message_start_hour, #booking_custom_message_end_hour, #booking_custom_message_schedule_toggle', function(e) {
  e.preventDefault()
  changeCustomMessagesData()
});

$(document).on('click', '#save_custom_message', function(e) {
  e.preventDefault()
  saveCustomMessagesData()
});

$(document).on('click', 'li.inbox_conversation_item', function(e) {
  e.preventDefault()
  const venueId = $(this).find('a').attr('data-venue');
  window.setVenueId(venueId);
});

const venueId = $('li.inbox_conversation_item.selected').find('a').attr('data-venue')

$(document).on('change', '#booking_custom_message_enabled', (event) => {
  document.querySelector(".custom-message-time-range-container").hidden = !event.target.checked;
});


document.querySelector('.maintabs_submenu')

document.addEventListener('DOMContentLoaded', () => {
  const submenuElement = document.querySelector('.maintabs_submenu');
  submenuElement.addEventListener('venue_id_changed', (data) => {
    submenuElement.dataset.venue_id = data.detail.venueId;
    updateSubmenu();
  })
});

window.setVenueId = (venueId) => {
  const submenuElement = document.querySelector('.maintabs_submenu');

  if (submenuElement) {
    const dataChangeEvent = new CustomEvent('venue_id_changed', {
      detail: { venueId: venueId }
    });

    submenuElement.dispatchEvent(dataChangeEvent);
  }
}