class ProcessingModal {
  static DEFAULT_DELAY = 2000;

  constructor() {
    this.timeout = null;
  }

  show(delay = null) {
    if (delay === 0) {
      $(this.modal).modal('show');
    } else {
      this.timeout = setTimeout(() => {
        $(this.modal).modal('show');
      }, delay || this.constructor.DEFAULT_DELAY);
    }
  }

  hide() {
    clearTimeout(this.timeout);
    $(this.modal).modal('hide');
  }

  toggle(showProcessing) {
    if (showProcessing) {
      this.show();
    } else {
      this.hide();
    }
  }

  get modal() {
    return document.getElementById('processing_modal')
  }
}

export default ProcessingModal;

export const processingModal = new ProcessingModal();
