import React, { useMemo } from "react";
import ReactSelect from "react-select";
import './styles.scss';
import Option from './Option';
import ValueContainer from './ValueContainer';

const SELECT_ALL_VALUE = '*';

export default ({ showSelectedNumber, options, onChange, ...props }) => {
  let components = { Option };

  if (showSelectedNumber) components['ValueContainer'] = ValueContainer;

  const myOptions = useMemo(() => {
    const AllOptions = [{ id: SELECT_ALL_VALUE }]
    return AllOptions.concat(options);
  }, [options]);

  const selectedAll = options.length === props.value.length;

  const onChangeSorted = (v) => {
    const values = v.map(x => x.id);
    const newV = options.filter(x => values.includes(x.id));
    onChange(newV)
  }

  const handleOnChange = (v, actionMete) => {
    const { option, action } = actionMete;

    if (['clear', 'remove-value'].includes(action)) {
      return onChangeSorted(v);
    }

    if (option === undefined) return;

    if (option.id === SELECT_ALL_VALUE) {
      if (selectedAll) {
        onChangeSorted([])
      } else {
        onChangeSorted(options);
      }
    } else {
      onChangeSorted(v)
    }
  }

  return (
    <ReactSelect
      {...props}
      options={myOptions}
      isMulti
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      allowSelectAll={true}
      components={components}
      onChange={handleOnChange}
      selectedAll={selectedAll}
      selectAllValue={SELECT_ALL_VALUE}
    />
  )
}