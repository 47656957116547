import { processingModal } from "src/nextgen/helpers/processing_modal";
import axios from 'axios';

$(document).on('click', '#calendar-send-message', function(e) {
  e.preventDefault();
  const $btn = $(this);
  const $form = $btn.closest('form');

  if ($btn.attr('disabled') !== 'disabled') {
    $btn.attr('disabled', true);

    processingModal.show(1000);

    axios.post($form.attr('action'), new FormData($($form)[0])).then(() => {
      $btn.attr('disabled', false);
      processingModal.hide();
      Helper.flash_message('success', 'Your message has been sent');
      $btn.closest('form').find("input[type=text], textarea").val("");
    }).catch(() => {
      $btn.attr('disabled', false);
      processingModal.hide();
      Helper.flash_message('error', 'Your message was not sent');
    })
  }
});