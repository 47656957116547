import axios from 'axios';
import { smartRedirect } from '../smartRedirect';
import { processingModal } from "../helpers/processing_modal";
import SmartyForm from "src/nextgen/helpers/smarty_form";


$(document).on('submit', '#new_bills_payment_option_changing', (e) => {
  e.preventDefault();
  processingModal.show();

  new SmartyForm().process({
    form: $(e.target).closest('form'),
    onSuccess: () => {
      processingModal.hide();
      smartRedirect(window.location.origin + window.location.pathname)
    },
    onError: () => Helper.flash_message('Something went wrong')
  });
});
