import React, { useState, useEffect, useMemo } from 'react';
import { useFetch } from '../Api/UseFetch';
import classNames from 'classnames';
import DropDown from "./DropDown";

export default ({ item, options, selectedOptions, onChanged}) => {
  const { invoiceCategories, invoiceCategory, setInvoiceCategory, pending } = useInvoiceCategories(item, options, selectedOptions);

  useEffect(() => {
    const params = invoiceCategory ? { invoiceCategoryId: invoiceCategory.id } : null;
    onChanged({ value: invoiceCategory, pending, params });
  }, [invoiceCategory, pending]);

  const hidden = invoiceCategories && invoiceCategories.length < 3;

  return (
    <div className={classNames(['filter-block filter-invoice-categories', { 'hidden': hidden }])}>
      <label htmlFor="filter-invoice-categories"><span>Invoice</span> Category:</label>
      <div className='input-box input-box-react-select'>
        <DropDown
          value={invoiceCategory}
          options={invoiceCategories}
          onChange={setInvoiceCategory}
          isLoading={pending}
          isDisabled={pending}
          getOptionLabel={opt => opt.title}
          getOptionValue={opt => opt.id}
        />
      </div>
    </div>
  )
};

const emptyOption = { id: null, title: 'General' };

export const useInvoiceCategories = (item, options, selectedOptions) => {
  const venueId = (selectedOptions || {}).venueId;
  const url = venueId ? `/admin/reports/venues/${venueId}/invoice_categories.json` : null;
  const [processedVenueId, setProcessedVenueId] = useState(null);
  const [invoiceCategory, setInvoiceCategory] = useState(url);
  const { pending, result, error } = useFetch(url);

  // Status
  const processed = useMemo(() => venueId=== processedVenueId, [venueId, processedVenueId]);

  // Filter options
  const invoiceCategories = useMemo(() => {
    if (pending || error) return [];
    return [emptyOption, ...result.invoiceCategories];
  }, [pending, item, processed]);

  // Select option
  useEffect(() => {
    if (!pending && !error && !processed) {
      const selectedInvoiceCategories = invoiceCategories.find(invoiceCategory => {
        return invoiceCategory.id === options.invoiceCategoryId
      });

      setInvoiceCategory(selectedInvoiceCategories || invoiceCategories[0]);
      setProcessedVenueId(venueId)
    }
  }, [pending, processed]);

  return {
    invoiceCategories,
    invoiceCategory,
    setInvoiceCategory,
    error,
    pending: !processed
  }
};
