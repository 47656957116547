import React from "react";
import Extra from "./Extra";

export default ({ extras, handleChangeChecked, handleChangePersons, handlePriceAdjustmentChange, handlePriceAdjustmentReasonChange, showPriceAdjustment }) => {
  return <>
    {extras.map((extra) =>
      <Extra
        key={extra.id}
        {...extra}
        onChangeChecked={(checked) => handleChangeChecked(extra.id, checked)}
        onChangePersons={(value) => handleChangePersons(extra.id, value)}
        onPriceAdjustmentChange={(value) => handlePriceAdjustmentChange(extra.id, value)}
        onPriceAdjustmentReasonChange={(value) => handlePriceAdjustmentReasonChange(extra.id, value)}
        showPriceAdjustment={showPriceAdjustment}
      />
    )}
  </>
}
