import { Controller } from "@hotwired/stimulus"

class OrderApprovalController extends Controller {
  static targets = ['submit', 'checkbox']

  connect() {
    this.checkboxTarget.addEventListener('change', () => {
      this.submitTarget.disabled = !this.checkboxTarget.checked;
    })
  }
}

export default OrderApprovalController;
