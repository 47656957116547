import React, { useState, useRef, useMemo } from "react";
import moment from 'moment';
import { Popover } from "react-tiny-popover";
import Calendar from "react-calendar";

import './styles.scss'

const DEFAULT_FORMAT = 'ddd, D MMM yyyy'

export default ({ value, onChange, format, maxDate, minDate, positions, ...props }) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const ref = useRef();
  const togglePopover = () => setIsPopoverOpen(!isPopoverOpen);
  const dateText = useMemo(() => value && moment(value).format(format || DEFAULT_FORMAT), [value])

  const handleChange = (value) => {
    setIsPopoverOpen(false);
    onChange(value);
  }

  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={positions ? positions : ['bottom']}
      align='center'
      disableReposition={true}
      onClickOutside={() => setIsPopoverOpen(!isPopoverOpen)}
      ref={ref}
      content={(
        <div className='calendar'>
          <Calendar onChange={handleChange} value={value} maxDate={maxDate} minDate={minDate} />
        </div>
      )}
    >
      <input
        className='form-control text-center date-input'
        value={dateText}
        onChange={e => e.preventDefault()}
        onClick={togglePopover}
        {...props}
      />
    </Popover>
  )
}
