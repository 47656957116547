class Neo {
  static confirm(message, callback) {
    if (confirm(message) === true) {
      callback();
    } else {
      console.log("You did not confirm!");
    }
  }

  static processError(error, callback) {
    if (error.response.status === 422) {
      callback(error)
    } else {
      Helper.flash_message('error', 'Something went wrong')
    }
  }

  static reloadTable(tableName) {
    const table = document.querySelector(`[data-controller="neo_table"][data-name="${tableName}"]`);
    table.dispatchEvent(new CustomEvent("reload-neo-table"));
  }
}

window.Neo = Neo;

export default Neo;
