function parseMs (ms) {
  if (typeof ms !== 'number') {
    throw new TypeError('Expected a number')
  }

  return {
    days: Math.trunc(ms / 86400000),
    hours: Math.trunc(ms / 3600000) % 24,
    minutes: Math.trunc(ms / 60000) % 60,
    seconds: Math.trunc(ms / 1000) % 60,
    ms: Math.trunc(ms) % 1000
  }
}

function addZero (value, digits) {
  digits = digits || 2

  let str = value.toString()
  let size = 0

  size = digits - str.length + 1
  str = new Array(size).join('0').concat(str)

  return str
}

function getSign (duration) {
  return duration <= -1000 ? '-' : ''
}

export function formatDuration (ms) {
  const unsignedMs = ms < 0 ? -ms : ms
  const sign = getSign(ms)
  const t = parseMs(unsignedMs)
  const seconds = addZero(t.seconds)
  let output = ''

  output = sign + ((t.days * 24) + t.hours) + ':' + addZero(t.minutes) + ':' + seconds

  return output
}
