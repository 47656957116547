import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import DropDown from "./DropDown";

const partnerMarkers = [
  { title: 'All', value: 'all' },
  { title: 'All exc WL', value: 'all_not_wl' },
];

export default ({ onChanged }) => {
  const [partnerMarker, setPartnerMarker] = useState(partnerMarkers[0]);

  useEffect(() => {
    const params = partnerMarker ? { partnerMarker: partnerMarker.value } : null;
    onChanged({ value: partnerMarker, pending: false, params });
  }, [partnerMarker]);

  return (
    <div className={classNames(['filter-block filter-report-types'])}>
      <label htmlFor="filter-report-types">Partner:</label>
      <div className='input-box input-box-react-select'>
        <DropDown
          value={partnerMarker}
          options={partnerMarkers}
          onChange={setPartnerMarker}
          getOptionLabel={opt => opt.title}
          getOptionValue={opt => opt.value}
        />
      </div>
    </div>
  )
};
