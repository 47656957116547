import { useMemo, useState } from "react";
import { FILTERS } from "./filters";

function initParams(item, activeFilters) {
  let baseParams = {};

  for (let filter of activeFilters) {
    baseParams[filter.key] = { value: null, pending: true, params: null };
  }

  return baseParams;
}

export const useSelectedOptions = (item) => {
  const filters = (item || {}).filters || [];
  const activeFilters = useMemo(() => FILTERS.filter(filter => filters.includes(filter.key)), [item]);
  const [selectedOptions, setSelectedOptions] = useState(initParams(item, activeFilters));

  function assignOption(name, value) {
    setSelectedOptions(selectedOptions => ({ ...selectedOptions, [name]: value }));
  }

  const arr = selectedOptions ? Object.values(selectedOptions) : [];
  const pending = selectedOptions ? arr.some(x => x.pending) : true;

  let mergedParams = {};

  if (selectedOptions) {
    for (let v of Object.values(selectedOptions)) {
      mergedParams = Object.assign({}, mergedParams, v.params)
    }
  }

  return [assignOption, activeFilters, pending, mergedParams, selectedOptions];
};