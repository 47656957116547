import { Controller } from "@hotwired/stimulus";
import axios from 'axios';

export default class extends Controller {
  connect() {
    const searchInput = document.querySelector('#file-search');
    const docTypeInput = document.querySelectorAll('input[name="filter_attachments_dates"]');
    const customerId = searchInput.dataset.customerId;
    const venueId = searchInput.dataset.venueId;

    const searchData = (event) => {
      let doc_type = document.querySelectorAll('input[name="filter_attachments_dates"]:checked')[0];
      let search = searchInput.value;

      axios.get("/file_managers", { params: { search: search, customer_id: customerId, venue_id: venueId, doc_type: doc_type, format: 'turbo_stream' } })
           .then((response) => { Turbo.renderStreamMessage(response.data) })
    }

    const debounce = (callback, waitTime) => {
      let timer;
      return (...args) => {
          clearTimeout(timer);
          timer = setTimeout(() => {
              callback(...args);
          }, waitTime);
      };
    }

    searchInput.addEventListener('input', debounce(searchData, 500));
    Array.from(docTypeInput).forEach((input) => { input.addEventListener('change', debounce(searchData, 0)) });
  }
}