import React from 'react';
import { Button, Modal } from 'react-bootstrap';

export default ({ show, setShow, messageType }) => (
  <Modal show={show} onHide={() => setShow(false)}>
    <Modal.Header closeButton>
      <Modal.Title>Shift(s) Amended</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {messageType}
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={() => setShow(false)}>Close</Button>
    </Modal.Footer>
  </Modal>
)