import React, { useRef } from 'react';
import { default as Select } from 'react-select';
import { components } from 'react-select';

const Option = (props) => {
  return (
    <components.Option {...props}>
      <input
        type='checkbox'
        checked={props.isSelected}
        onChange={() => null}
      />{" "}
      {props.label}
    </components.Option>
  );
};

const ValueContainer = ({ children, ...props }) => {
  let [values, input] = children;

  if (Array.isArray(values)) {
    let selectedDays = values.map(value => value.props.data.value)
    let weekDaysOnlySelected = [0,1,2,3,4].every(day => selectedDays.includes(day))
    let weekendOnlySelected = [5,6].every(day => selectedDays.includes(day))

    if (values.length === 7) {
      values = 'Full week'
    } else if(weekDaysOnlySelected && values.length === 5) {
      values = 'Weekdays'
    } else if(weekendOnlySelected && values.length === 2) {
      values = 'Weekends'
    } else if(values.length) {
      values = `${values.length} selected`;
    } else {
      values = values[0].props.children
    }
  }

  return (
    <components.ValueContainer {...props}>
      {values}
      {input}
    </components.ValueContainer>
  );
};

export default (props) => {
  const ref = useRef(null);

  return (
    <Select
      {...props}
      isMulti
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      components={{ Option, ValueContainer }}
      allowSelectAll={true}
      ref={ref}
      placeholder='Full week'
    />
  )
}
