import React, { useState, useEffect, useMemo } from 'react';
import { useFetch } from '../Api/UseFetch';
import classNames from 'classnames';
import MultiSelectDropdown from "./MultiSelectDropdown";

export default ({ item, options, onChanged, selectedOptions }) => {
  const { facilities, selectedFacilities, setFacilities, disabled, pending } = useFacilities(item, options, selectedOptions);

  useEffect(() => {
    const params = selectedFacilities ? { multiFacilityIds: selectedFacilities.map(facility => facility.id) } : [];
    onChanged({ value: selectedFacilities, pending, params })
  }, [selectedFacilities, pending]);

  return (
    <div className={classNames(['filter-block filter-facilities'])}>
      <label htmlFor="filter-facilities">Facility:</label>
      <div className='input-box input-box-react-select'>
        <MultiSelectDropdown
          value={selectedFacilities}
          isLoading={pending}
          isDisabled={pending || disabled}
          options={facilities}
          onChange={setFacilities}
          getOptionLabel={opt => `${opt.internalReference}${opt.softDeleted ? ' (deleted)' : ''}`}
          getOptionValue={opt => opt.id}
        />
      </div>
    </div>
  )
};

export const useFacilities = (item, options, selectedOptions) => {
  const venueId = selectedOptions.venueId;
  const urlParams = `disabled_facility_publishing=${item.disabledFacilityPublishing}`;
  const url = venueId ? `/admin/api/venues/${venueId}/facilities.json?${urlParams}` : null;
  const [processedVenueId, setProcessedVenueId] = useState(null);
  const [selectedFacilities, setFacilities] = useState([]);
  const { pending, result, error } = useFetch(url);

  const disabled = item.key === 'activities' && ((venueId || '').toString()).startsWith('venue-group');

  // Status
  const processed = useMemo(() => venueId === processedVenueId, [venueId, processedVenueId]);

  // Filter options
  const facilities = useMemo(() => {
    if (pending || error) return [];
    return result.facilities;
  }, [pending, item, processed]);

  // Select checkboxes
  useEffect(() => {
    if (!pending && !error && !processed) {
      if(options.multiFacilityIds) {
        setFacilities(facilities.filter(facility => options.multiFacilityIds.includes(facility.id)))
      } else {
        setFacilities([])
      }
      setProcessedVenueId(venueId)
    }
  }, [pending, processed]);

  return {
    facilities,
    selectedFacilities,
    setFacilities,
    error,
    disabled,
    pending: !processed
  }
};
