import React, { useRef } from 'react';
import Select, { components } from "react-select";

const Option = (props) => {
  return (
    <components.Option {...props}>
      <input
        type='checkbox'
        checked={props.isSelected}
        onChange={() => null}
      />{" "}
      {props.label}
    </components.Option>
  );
};

export default (props) => (
  <Select
    {...props}
    closeMenuOnSelect={false}
    isMulti
    components={{ Option }}
    required
  />
);
