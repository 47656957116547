import React from "react";

export default ({ errors, name }) => {
  if (!errors[name]) return null;

  return (
    <div className="form-group has-error">
      <div className='help-block'>
        {errors[name].map(error => `${error}`).join(', ')}
      </div>
    </div>
  )
}