import BaseController from "../neo_modal_controller";
import { processingModal } from "src/nextgen/helpers/processing_modal";
import OrderTab from "src/nextgen/orders/Tab";

export default class BillSendController extends BaseController {
  connect() {
    super.connect();
  }

  onSuccess(response) {
    try {
      this.orderTab.reload('next_bill', () => {
        setInterval(() => { $(this.element).modal('hide') }, 200);
        processingModal.hide();
        this.setInProgress(false);
      });
    } catch (e) {
      console.log(e);
    }
  }

  get orderTab() {
    return new OrderTab();
  }
}