import axios from "axios";

window.updateActionMessage = function(target) {
  const resolvedBy = target.data('resolvedBy')

  if (resolvedBy) {
    target.addClass('resolved').html(`Actioned by: ${resolvedBy}`)
  } else {
    target.removeClass('resolved').html('Click to confirm this has been actioned')
  }
}

$(document).on('click', '.action-message a', (e) => {
  e.preventDefault();
  const target = $(e.target);

  axios
    .patch(target.attr('href'))
    .then((res) => {
      target.data('resolvedBy', res.data.message.resolvedBy)
      updateActionMessage(target)
    })
    .catch((e) => {
      Helper.flash_message('error', 'Something went wrong')
    })
})
