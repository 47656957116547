const Customers = (function() {
  function init(refreshCalendar) {
    $('#user_id').on('change', () => refreshCalendar());
  }

  function calendarFilterReplaceUsers(options) {
    $('select#user_id option').remove();

    const option = $('<option/>', { text: 'All customers' } );
    $('select#user_id').append(option);

    $.each(options, function(index, user) {
      let option = $('<option/>', { value: user.id, text: user.name, selected: user.selected } );
      $('select#user_id').append(option);
    });
  }

  return {
    init: init,
    calendarFilterReplaceUsers: calendarFilterReplaceUsers
  };
})();

export default Customers;