window.usersNotifications = () => {
  $('#notifications-tooltip-icon').tooltip({ placement: 'right', html: true});

  $('.info-icon').each(function() {
    $(this).popover({
      html: true,
      trigger: "hover",
      placement: 'bottom',
      content: function() {
        return $(this).find('.popover-body').html();
      }
    });
  });
}