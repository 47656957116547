import React, { Component, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import { default as Select } from 'react-select';
import { components } from 'react-select';

const Option = (props) => {
  return (
    <components.Option {...props}>
      <input
        type='checkbox'
        checked={props.isSelected}
        onChange={() => null}
      />{" "}
      {props.label}
    </components.Option>
  );
};

const ValueContainer = ({ children, ...props }) => {
  let [values, input] = children;

  if (Array.isArray(values)) {
    if (values.length > 1) {
      values = `${values.length} selected`;
    } else {
      values = values[0].props.children
      if (values.length >= 12) values = values.substring(0, 12) + '...'
    }
  }

  return (
    <components.ValueContainer {...props}>
      {values}
      {input}
    </components.ValueContainer>
  );
};

export default (props) => {
  const ref = useRef(null);

  return (
    <Select
      {...props}
      isMulti
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      components={{ Option, ValueContainer }}
      allowSelectAll={true}
      ref={ref}
      placeholder='All'
    />
  )
}
