import React, { useState, useEffect, useMemo } from 'react';
import { useFetch } from '../Api/UseFetch';
import classNames from 'classnames';
import DropDown from "./DropDown";

export default ({ item, options, onChanged, selectedOptions }) => {
  const { facilities, facility, setFacility, pending } = useFacilities(item, options, selectedOptions);

  useEffect(() => {
    const params = facility ? { facilityId: facility.id } : null;
    onChanged({ value: facility, pending, params })
  }, [facility, pending]);

  return (
    <div className={classNames(['filter-block filter-facilities'])}>
      <label htmlFor="filter-facilities">Facility:</label>
      <div className='input-box input-box-react-select'>
        <DropDown
          value={facility}
          isLoading={pending}
          isDisabled={pending}
          options={facilities}
          onChange={setFacility}
          getOptionLabel={opt => `${opt.internalReference}${opt.softDeleted ? ' (deleted)' : ''}`}
          getOptionValue={opt => opt.id}
        />
      </div>
    </div>
  )
};

const emptyOption = { id: null, internalReference: 'All' };

export const useFacilities = (item, options, selectedOptions) => {
  const venueId = selectedOptions.venueId;
  const urlParams = `disabled_facility_publishing=${item.disabledFacilityPublishing}`;
  const url = venueId ? `/admin/api/venues/${venueId}/facilities.json?${urlParams}` : null;
  const [processedVenueId, setProcessedVenueId] = useState(null);
  const [facility, setFacility] = useState(null);
  const { pending, result, error } = useFetch(url);

  // Status
  const processed = useMemo(() => venueId === processedVenueId, [venueId, processedVenueId]);

  // Filter options
  const facilities = useMemo(() => {
    if (pending || error) return [];
    return [emptyOption, ...result.facilities];
  }, [pending, item, processed]);

  // Select option
  useEffect(() => {
    if (!pending && !error && !processed) {
      const selectedFacility = facilities.find(facility => facility.id === options.facilityId);
      setFacility(selectedFacility || facilities[0]);
      setProcessedVenueId(venueId)
    }
  }, [pending, processed]);

  return {
    facilities,
    facility,
    setFacility,
    error,
    pending: !processed
  }
};
