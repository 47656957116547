import { Controller } from "@hotwired/stimulus";
import axios from "axios";
import Neo from "../src/Neo";

class VenueController extends Controller {
  delete(e) {
    e.preventDefault();

    const { dataset } = e.target;

    Neo.confirm(dataset.message, () => {
      axios.delete(dataset.url).then((response) => {
        Helper.flash_message('success', response.data.notice)
        Neo.reloadTable('admin_venues_table');
      }).catch((error) => {
        Neo.processError(error, () => {
          Helper.flash_message('error', error.response.data.error)
        })
      })
    })
  }

  addInfoToCookie(e) {
    const stripeConnectVenueId = e.target.dataset.stripeConnectVenueId;
    document.cookie = `stripe_connect_venue_id=${stripeConnectVenueId}; path=/`;
    return true;
  }
}

export default VenueController;
