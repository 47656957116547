import React, { useState, useEffect } from "react";
import Calendar from 'react-calendar';
import DateInput from 'src/components/DateInput';
import TimeSelector from "../../Form/TimeSelector";
import axios from 'axios';
import moment from 'moment-timezone'

moment.tz.setDefault('Europe/London');

import 'rc-time-picker/assets/index.css';
import 'react-calendar/dist/Calendar.css';
import Error from "../../Form/Error";

export default (props) => {
  const { staffItem, errors } = props;
  const [valueB, onChangeValueB] = useState(moment(staffItem.eventDate).toDate());
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (staffItem.venueId) {
      axios
        .get('/admin/staff_rota/users.json', { params: { venue_id: staffItem.venueId } })
        .then((response) => setUsers(response.data))
    }

    return () => {
      setUsers([])
    }
  }, [staffItem]);

  if (users.length === 0) return null;

  const index = 0;

  return (
    <form id={`edit-staff-item-${staffItem.id}`} className='staff-rota-form'>
      <div className="shift-item bookings-time-list">
        <div className='calendar-box'>
          <Calendar
            onChange={onChangeValueB}
            value={valueB}
          />
        </div>
        <div className='calendar-outside'>
          <div className='row'>
            <div className="col-md-3 col-sm-12">
              <div className="form-group select optional">
                <label className="control-label select optional">
                  Name
                </label>
                <select className="form-control select optional"
                        name={`staff_item[0][user_id]`}
                        defaultValue={staffItem.userId}>
                  {users.map(user => <option key={user.id} value={user.id}>{user.name}</option>)}
                </select>
              </div>
            </div>

            <div className='col-md-3 col-sm-12'>
              <div className="form-group optional">
                <label className="optional control-label">Date</label>
                <DateInput
                  onChange={onChangeValueB}
                  value={valueB}
                  name={`staff_item[${index}][event_date]`}
                  className='form-control date-input text-left'
                />
              </div>
            </div>
          </div>

          <div className='row'>
            <div className="col-md-6">
              <label className="control-label select optional">Start</label>
              <TimeSelector
                name='start'
                index={0}
                defaultTimeOption={staffItem.start.timeOption}
                defaultValue={staffItem.start.time ? moment(staffItem.start.time) : moment("17:00", 'H:m')}
                defaultMinutes={staffItem.start.minutes != null ? staffItem.start.minutes : 30 }
                defaultDirection={staffItem.start.direction ? staffItem.start.direction : 'before'}
                defaultSession={staffItem.start.session ? staffItem.start.session : 'first'}
                defaultIncludeInternal={staffItem.start.includeInternal !== null ? staffItem.start.includeInternal : false}
              />
            </div>

            <div className="col-md-6">
              <label className="control-label select optional">End</label>
              <TimeSelector
                name='end'
                index={0}
                defaultTimeOption={staffItem.end.timeOption}
                defaultValue={staffItem.end.time ? moment(staffItem.end.time) : moment("22:00", 'H:m')}
                defaultMinutes={staffItem.end.minutes != null ? staffItem.end.minutes : 15}
                defaultDirection={staffItem.end.direction ? staffItem.end.direction : 'after'}
                defaultSession={staffItem.end.session ? staffItem.end.session : 'last'}
                defaultIncludeInternal={staffItem.end.includeInternal !== null ? staffItem.end.includeInternal : false}
              />
            </div>
          </div>

          <Error errors={errors} name='time_period'/>
          <Error errors={errors} name='intersection'/>
        </div>

        <div className="clearfix"/>
      </div>
    </form>
  )
}