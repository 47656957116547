import React, { useEffect, useRef } from "react";
import moment from "moment/moment";
import calendarIco from './calend-icon.png';

export default ({ eventDate, minEventDate, maxEventDate, onChange }) => {
  const ref = useRef();

  eventDate = moment(eventDate).format('D.M.YYYY');
  minEventDate = moment(minEventDate).format('D.M.YYYY');
  maxEventDate = moment(maxEventDate).format('D.M.YYYY');

  useEffect(() => {
    $(ref.current).datepicker({
      format: 'D, dd M yyyy',
      autoclose: true,
      weekStart: 1,
      startDate: minEventDate,
      endDate: maxEventDate,
    });
  }, [])

  useEffect(() => {
    $(ref.current).datepicker('update', eventDate)
  }, [eventDate]);

  useEffect(() => {
    $(ref.current).off('changeDate');
    $(ref.current).on('changeDate', (e) => {
      const val = e.date;
      const date = moment(val).format('ddd, DD MMM YYYY');
      onChange(date);
    });
  }, [onChange]);

  return (
    <div className='calendar-block'>
      <h3>
        <img src={calendarIco}/>
        Calendar
      </h3>

      <div data-date='12-06-2016' ref={ref}/>
    </div>
  )
}