import React from "react";
import DaysSelector from "src/components/ReportSchedules/Components/days_selector";

const dayNames = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map((day, index) => {
  return { label: day, value: index }
})

export default ({ weekDays, setWeekDays }) => {
  return (
    <DaysSelector
      className='weekdays-selector'
      value={weekDays}
      isLoading={false}
      isDisabled={false}
      options={dayNames}
      onChange={setWeekDays}
    />
  )
}