import { Controller } from "@hotwired/stimulus"
import 'bootstrap-tagsinput/dist/bootstrap-tagsinput.min'
import 'bootstrap-tagsinput/dist/bootstrap-tagsinput.css'

class TagsInputController extends Controller {
  connect() {
    const target = this.element;

    $(target).tagsinput();
    $('.bootstrap-tagsinput > input').prop('style', null);
  }
}

export default TagsInputController;
