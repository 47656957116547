const AgendaWeek = (function() {
  function show(options) {
    const filter_amount = options.amount;
    const weekViewHTML = atob(options.base64WeekViewHTML);
    const staff_rota_toggle = options.staff_rota_toggle;
    const $table = $('.fc-view table');
    $table.floatThead('destroy');
    $('#calendar').fullCalendar('destroy')
    $('#calendar').html('No data to show')
    $('#calendar').html(weekViewHTML)
    $('#week_view_table').css('width', valueToPx((filter_amount + 1) * 262));
    $('#week_view_table').floatThead({ position: 'absolute', autoReflow: true });
    $("#week_view_table").tableHeadFixer({'left': 1, 'head': false, parent: '#calendar'});
    $('#staff-rota-selector').toggle(staff_rota_toggle)
    $('#calendar').show();
    $('#calendar_top_scroll_content').attr('style', 'width: ' + valueToPx($('.floatThead-container > table').width()));

    if($('.floatThead-container > table').width() > 0 && window.innerWidth > 1250) {
      console.log('hide')
      $('#calendar_top_scroll_wrapper').css('overflow-x', 'hidden')
    }
  }

  function valueToPx(value) {
    return value.toString() + "px";
  }

  return {
    show: function(options) { return show(options) }
  };
})();

export default AgendaWeek;