import React, { useState, useEffect } from "react";
import List from './List';
import './styles.scss';

function prepareExtras(extras, bookingsExtras) {
  return extras.map(extra => {
    let dbe = bookingsExtras.find(be => be.extra_id === extra.id);

    extra.checked = dbe ? (dbe.hasOwnProperty('checked') ? dbe.checked : true) : false ;
    extra.booking_extra_id = dbe?.id;
    extra.persons = dbe?.persons || '';
    extra.price_adjustment = dbe?.price_adjustment
    extra.price_adjustment_reason = dbe?.price_adjustment_reason

    return extra
  });
}

function getLastIndexChecked(memoExtras) {
  let lastIndexChecked = -1;

  for (let i = 0; i < memoExtras.length; i++) {
    if (memoExtras[i].checked) {
      lastIndexChecked = i;
    }
  }

  return lastIndexChecked;
}

function getDefaultShowAll(extras, bookingsExtras) {
  return getLastIndexChecked(prepareExtras(extras, bookingsExtras)) > 2
}

export default ({ extras, bookingsExtras, setBookingsExtras, showPriceAdjustment }) => {
  const [showAllExtras, setShowAllExtras] = useState(getDefaultShowAll(extras, bookingsExtras));

  useEffect(() => {
    setShowAllExtras(getDefaultShowAll(extras, bookingsExtras))
  }, [extras]);

  const handleChangeChecked = (extraId, checked) => {
    const newBookingsExtras = [...bookingsExtras];
    const index = newBookingsExtras.findIndex(el => el.extra_id === extraId);

    if (checked) {
      if (index > -1) {
        newBookingsExtras[index].checked = true;
      } else {
        newBookingsExtras.push({ id: '', extra_id: extraId, persons: '', checked: true })
      }
    } else {
      if (index > -1) newBookingsExtras[index].checked = false;
    }

    setBookingsExtras(newBookingsExtras);
  }

  const handleChangePersons = (extraId, value) => {
    const newBookingsExtras = [...bookingsExtras];
    const index = newBookingsExtras.findIndex(el => el.extra_id === extraId);
    if (index > -1) newBookingsExtras[index].persons = value;
    setBookingsExtras(newBookingsExtras);
  }

  const handlePriceAdjustmentChange = (extraId, value) => {
    const newBookingsExtras = [...bookingsExtras];
    let extra = newBookingsExtras.find(el => el.extra_id === extraId)
    extra['price_adjustment'] = value
    setBookingsExtras(newBookingsExtras);
  }

  const handlePriceAdjustmentReasonChange = (extraId, value) => {
    const newBookingsExtras = [...bookingsExtras];
    let extra = newBookingsExtras.find(el => el.extra_id === extraId)
    extra['price_adjustment_reason'] = value
    setBookingsExtras(newBookingsExtras)
  }

  const memoExtras = prepareExtras(extras, bookingsExtras);
  const visibleExtras = showAllExtras ? memoExtras : memoExtras.slice(0, 3);
  const lastIndexChecked = getLastIndexChecked(memoExtras);

  if (memoExtras !== undefined && memoExtras.length === 0) return null;

  return (
    <div className='form-group select optional bookings_extras'>
      <div className='extras-section-labels'>
        <div className="extra-label">
          <label className='string optional control-label'>Extras</label>
        </div>
        {showPriceAdjustment &&
          <div className="extras-adjustments-labels">
            <label className='control-label extra-adjustment-price-label'>Price adjustment (£)</label>
            <label className='string optional control-label'>Reason</label>
          </div>
        }
      </div>

      <div className='react-extras-dropdown'>
        <List
          extras={visibleExtras}
          handleChangeChecked={handleChangeChecked}
          handleChangePersons={handleChangePersons}
          handlePriceAdjustmentChange={handlePriceAdjustmentChange}
          handlePriceAdjustmentReasonChange={handlePriceAdjustmentReasonChange}
          showPriceAdjustment={showPriceAdjustment}
        />

        {memoExtras && memoExtras.length > 3 ?
        <button type="button"
                disabled={lastIndexChecked > 2}
                className='btn btn-default button-extras-show-more'
                onClick={() => setShowAllExtras(!showAllExtras)}>
          {showAllExtras ? 'Show less' : 'Show more'}
        </button>
          : null}
      </div>
    </div>
  )
}
