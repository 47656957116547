import React from "react";

const Error = ({ errors, name }) => {
  if (errors === undefined) return null;
  if (!errors.hasOwnProperty(name)) return null;

  const message = errors[name].join(', ');

  return (
    <div className='has-error'>
      <span className="help-block">
        {message}
      </span>
    </div>
  )
}

export default Error;