import AgendaDay from "../Views/AgendaDay";
import AgendaWeek from "../Views/AgendaWeek";
import Venues from './Venues';
import Facilities from './Facilities';
import EventDate from './EventDate';
import PeriodView from "./PeriodView";
import Days from "./Days";
import StaffRotaView from "./StaffRotaView";
import WeekViewType from "./WeekViewType";
import Customers from "./Customers";
import { getBookingInfo } from "../Modal";
import axios from 'axios';

const Filter = (function() {
  let inProgress = false;

  function init() {
    if ($('.calendar-filter').length > 0) {
      Venues.init(refreshCalendar);
      Facilities.init(refreshCalendar);
      EventDate.init(refreshCalendar);
      Customers.init(refreshCalendar);
      Days.init(refreshCalendar);
      PeriodView.init(refreshCalendar);
      StaffRotaView.init(refreshCalendar);
      WeekViewType.init(refreshCalendar);
    }

    $('.calendar-form').on('submit', function(e) {
      e.preventDefault();
      formSubmit();
    });

    $(document).on('click', '.week-view-link', function(e) {
      e.preventDefault();
      getBookingInfo($(this).data('id'), refreshCalendar);
    });

    $('.fc-agendaWeek-button').on('click', function(e) {
      e.preventDefault();
    });

    const calendar = $('#calendar');
    const calendarOnTop = $('#calendar_top_scroll_wrapper');

    calendarOnTop.on('scroll', () => { calendar.scrollLeft(calendarOnTop.scrollLeft()) });
    calendar.on('scroll', () => { calendarOnTop.scrollLeft(calendar.scrollLeft()) });

    formSubmit();
  }

  function formSubmit() {
    const form = $('.calendar-form');
    const url = form.attr('action') + '.json?' + form.serialize();

    toggleScroll(true);

    axios.get(url).then((response) => {
      const options = response.data;

      if (options.renderer === 'day') {
        Customers.calendarFilterReplaceUsers(options.users);
        AgendaDay.show(options, refreshCalendar);
      } else if (options.renderer === 'facility') {
        $('#calendar').html(atob(options.base64WeekViewHTML))
      } else {
        Customers.calendarFilterReplaceUsers(options.users);
        AgendaWeek.show(options)
      }

      toggleScroll(false);
    }).catch((e) => {
      toggleScroll(false);
      if (e.code === 'ERR_CANCELED') {
      } else {
        Helper.flash_message('error', 'Something went wrong')
      }
    })
  }

  function refreshCalendar() {
    const form = $('form.calendar-form');
    const formJson = form.serializeJSON();

    if ($('.admin_calendars_show').length > 0) {
      localStorage.setItem('calendar_filter_json', formJson);
    }

    formSubmit();
  }

  function toggleScroll(disabled) {
    [...document.getElementsByClassName('calendar-scroll-prev')].map(el => el.dataset.disabled = disabled);
    [...document.getElementsByClassName('calendar-scroll-next')].map(el => el.dataset.disabled = disabled);
  }

  return { init: init };
})();

export default Filter;