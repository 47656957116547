import React from "react";
import Column from "src/components/Reports/Columns";
import IntervalTitleDecorator from "src/components/ReportSchedules/helpers/interval_title_decorator";
import {Button} from "react-bootstrap";
import { snakeToTitle, singularize } from '@/helpers/strings'

export default ({ reportSchedules, showModalEdit, handleDelete }) => (
  <div className='report-schedules-body'>
    <table className='table table-bordered'>
      <thead className='scheduled-reports-table-header'>
      <tr>
        <th>Type</th>
        <th>Interval</th>
        <th>Export</th>
        <th>Start date</th>
        <th>Time</th>
        <th>Days</th>
        <th>Venue</th>
        <th>Users</th>
        <th>Date created</th>
        <th>Created by</th>
        <th className='actions'>Actions</th>
      </tr>
      </thead>
      <tbody>
      {reportSchedules.map(item =>
        <tr key={item.id}>
          <Column.Text>{snakeToTitle(singularize(item.key))}</Column.Text>
          <Column.Text>{IntervalTitleDecorator(item.reportInterval)}</Column.Text>
          <Column.Text>{item.exportType}</Column.Text>
          <Column.Date>{item.startDate}</Column.Date>
          <Column.Text>{moment(item.reportTime).format('HH:mm')}</Column.Text>
          <Column.Text>{item.weekDaysString}</Column.Text>
          <Column.Text>{item.venueTitle}</Column.Text>
          <Column.Text>{item.userNamesString}</Column.Text>
          <Column.Date>{item.createdAt}</Column.Date>
          <Column.Text>{item.createdBy}</Column.Text>
          <Column.Text>
            <div className='btn-group'>
              <Button disabled={!item.allowedToEdit}
                      onClick={() => showModalEdit({id: item.id})}>
                Edit
              </Button>
              <Button disabled={!item.allowedToEdit}
                      onClick={() => handleDelete(item.id)}>
                Delete
              </Button>
            </div>
          </Column.Text>
        </tr>
      )}
      </tbody>
    </table>
  </div>
)