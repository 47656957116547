import React from "react";
import ReactDOM from "react-dom";

import NoticeMessages from './NoticeMessages/Index'

const components = [
  { id: 'admin-settings-notice-messages', Component: NoticeMessages }
]

document.addEventListener('DOMContentLoaded', () => {
  components.forEach(({ id, Component }) => {
    let element = document.getElementById(id);
    if (element) ReactDOM.render(<Component {...element.dataset}/>, element);
  });
});

