import React from "react";
import { OverlayTrigger, Popover } from 'react-bootstrap';

export default ({ children }) => {
  return (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement="right"
      overlay={<Popover id={`extra_#{id}`}>{children}</Popover>}>
      <div className='info-icon'/>
    </OverlayTrigger>
  )
}