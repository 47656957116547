import axios from "axios";

export function getBookingInfo(id, refreshCalendar) {
  axios
    .get('/admin/calendar/bookings/' + id)
    .then((resp) => {
      const modal = $('#modal_info');
      modal.find('.modal-content').empty().append(resp.data);
      modal.modal('show');
    })
    .catch((error) => {
      if ((error.response.status !== 404)) Helper.flash_message('error', 'Something went wrong')
      refreshCalendar()
    });
}

