import axios from "axios";

class Tab {
  reload(id, callback) {
    const tab = document.querySelector("a[data-target='#order_info']");
    const href = tab.getAttribute('href');
    const header = document.getElementById('order_header');
    const tabParams = { target: 'order_info' };

    Promise.all([
      axios.get(href, { params: tabParams, headers: { 'X-Requested-With': 'XMLHttpRequest' } }),
      axios.get(header.dataset.url, { headers: { 'X-Requested-With': 'XMLHttpRequest' } })
    ]).then(([response, response2]) => {
      document.getElementById('order_info').innerHTML = response.data;

      const targetDiv = document.getElementById(id);
      if (targetDiv) targetDiv.scrollIntoView({ behavior: 'smooth' });

      header.innerHTML = response2.data;

      if (callback) callback();
    })
      .catch(() => {
        const div = document.createElement('div');
        div.className = 'alert alert-danger';
        div.textContent = 'Something went wrong';

        const orderInfoElement = document.getElementById('order_info');
        orderInfoElement.innerHTML = ''; // Clear existing content (if any)
        orderInfoElement.appendChild(div);

        if (callback) callback();
      })
  }
}

export default Tab;