import axios from 'axios';
import { processingModal } from "../helpers/processing_modal";

function CheckoutOptions() {
  const onChange = function(event) {
    processingModal.show(500)

    const target = event.target;
    const url = target.closest('form').getAttribute('action');
    const submitLink = document.getElementById('continue-order');
    const table = $('#bookings-block .table-bookings');
    const checkoutOptionKey = target.value;
    const reloadPaymentsUrl = table.data('reload-payments-url');
    const reloadPaymentOptionsUrl = table.data('reload-payment-options-url');
    const reloadBillIssueUrl = table.data('reload-issue-url');

    submitLink.classList.add('disabled_link_continue');

    axios
      .put(url, { order: { checkout_option_key: checkoutOptionKey } })
      .then(() => {
        const endpoints = [reloadPaymentsUrl, reloadPaymentOptionsUrl, reloadBillIssueUrl]

        axios
          .all(endpoints.map((endpoint) => axios.get(endpoint)))
          .then(axios.spread(({ data: paymentsTable }, { data: paymentsOptions }, { data: billIssue }) => {
            submitLink.classList.remove('disabled_link_continue');
            document.getElementById('payments_list').innerHTML = paymentsTable;
            document.getElementById('payment_options').innerHTML = paymentsOptions;
            if (_.isEmpty(billIssue.issue)) {
              submitLink.classList.remove('disabled_link_continue');
              submitLink.disabled = false;
            }
            $(submitLink).data('issue', billIssue.issue);
            processingModal.hide();
          }))
          .catch((error) => {
            processingModal.hide()
            submitLink.classList.remove('disabled_link_continue');
          }
        )
      })
      .catch((error) => {
        submitLink.classList.remove('disabled_link_continue');
        Helper.flash_message('error', error.response.data.errors);
        processingModal.hide()
      });
  };

  return {
    onChange: onChange
  }
}

window.checkoutOptions = CheckoutOptions();
