import React from "react";
import classNames from "classnames";
import ControlButton from "./ControlButton";

export default ({
  index,
  data,
  deleteChildFacility,
  revertChildFacility,
  len,
  len2,
  errors,
  deleteRequestEnabled
}) => {
  const suffix = `facility[group_facilities_attributes][${index}]`;
  const internalReferenceErrors = errors[`group_facilities[${index}].internal_reference`];

  return (
    <div className="form-group citext required facility_group_facilities_internal_reference">
      <input type="hidden" name={`${suffix}[list_order]`} value={index}/>
      <input type="hidden" name={`${suffix}[deleted_at]`}/>
      <input type="hidden" name={`${suffix}[id]`} value={data.id}/>
      <input type="hidden" name={`${suffix}[_destroy]`} value={data._destroy || false}/>
      <input type="hidden" name={`${suffix}[delete_request]`} value={data.deleteRequest}/>

      <label className="control-label select optional" htmlFor="facility_facility_category_id">
        Name {index + 1}
        &nbsp;
        {!deleteRequestEnabled && data.deleteRequest && <span className="label label-warning">Delete request</span>}
      </label>

      <div className='form-inline'>
        <input
          className={classNames('form-control', 'required', { '' : data.id === '' })}
          style={{ width: '200px' }}
          required="required"
          aria-required="true"
          type="text"
          disabled={data._destroy}
          defaultValue={data.internalReference}
          name={`${suffix}[internal_reference]`}
        />

        <div style={{ width: '10px', display: 'inline-block' }}/>

        <ControlButton
          index={index}
          len={len}
          len2={len2}
          data={data}
          deleteChildFacility={deleteChildFacility}
          revertChildFacility={revertChildFacility}
          deleteRequestEnabled={deleteRequestEnabled}
        />
      </div>

      {internalReferenceErrors &&
        <div className='has-error'>
          <span className="help-block">
            {internalReferenceErrors.join(', ')}
          </span>
        </div>
      }
    </div>
  )
}