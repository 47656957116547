import React from 'react';
import IndexPage from './Index/Index';
import { Router, Switch, Route } from "react-router-dom";
import history from "./utils/history";

import './styles.scss';

export default () => {
  return (
    <div className="report-schedules-main-container">
      <Router history={history}>
        <Switch>
          <Route path='/admin/reports/schedules' component={IndexPage}/>
        </Switch>
      </Router>
    </div>
  )
}
