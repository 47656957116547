import { Controller } from "@hotwired/stimulus";
import axios from "axios";

class InfoPageController  extends Controller  {
  connect() {
    this.id = this.element.dataset.id;
    this.partnerProfileId = this.element.dataset.partnerProfileId;
    console.log(this.partnerProfileId);
  }

  publish(e) {
    const target = e.target;

    const field = target.getAttribute('name');
    const data = { [field]: target.checked };

    axios.post(`/admin/settings/info_pages/${this.id}/publish`, data).then((response) => {
      target.checked = response.data[field];
    });
  }

  up(e) {
    e.preventDefault();

    const [ids, index] = this.currentIds(e);

    if (index > 0) {
      const temp = ids[index - 1];
      ids[index - 1] = ids[index];
      ids[index] = temp;
    }

    this.pushNewOrder(ids);
  }

  down(e) {
    e.preventDefault();

    const [ids, index] = this.currentIds(e);

    if (index < ids.length - 1 && index !== -1) {
      const temp = ids[index + 1];
      ids[index + 1] = ids[index];
      ids[index] = temp;
    }

    this.pushNewOrder(ids);
  }

  currentIds(e) {
    const table = e.target.closest('table');
    const rows = table.querySelectorAll('tr[data-controller="info_page"]');
    const ids = Array.from(rows).map(row => parseInt(row.dataset.id));
    const id = this.element.dataset.id;
    const index = ids.indexOf(parseInt(id));

    return [ids, index]
  }

  pushNewOrder(ids) {
    axios
      .post('/admin/settings/info_pages/reorder', { ids: ids })
      .then((response) => {
        axios
          .get('/admin/settings/info_pages', { params: { table: true, partner_profile_id: this.partnerProfileId } })
          .then(response => Turbo.renderStreamMessage(response.data))
      })
  }
}

export default InfoPageController;
