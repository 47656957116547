import axios from 'axios';

$(document).on('click', '#overdue_payment_reminder_email', (e) => {
  e.preventDefault();

  const button = $(e.target);
  button.prop('disabled', true);

  axios
    .patch(button.data('url'))
    .then(response => {
      Helper.flash_message('success', response.data.message);
      button.prop('disabled', false);
    })
    .catch((error) => {
      Helper.flash_message('error', 'Something went wrong');
      button.prop('disabled', false);
    });
});
