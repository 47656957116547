import React from 'react';
import classnames from 'classnames';

export default ({ prefix, errors, className }) => {
  if (!Array.isArray(errors)) return null;
  if (errors.length === 0) return null;

  return (
    <div className={classnames(["error-message text-danger", className])}>
      {errors.map(error => prefix ? `${prefix} ${error}` : error).join(', ')}
    </div>
  )
}