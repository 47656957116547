class PaymentRequestCounters {
  constructor() { }

  start() {
    this.onUpdatePaymentRequest();
  }

  onUpdatePaymentRequest() {
    $(document).on('click', '#update_payment_request_counters', (event) => {
      event.preventDefault();

      const orderID = $(event.currentTarget).data('orderId');

      $.ajax({
        url: '/admin/orders/' + orderID + '/update_payment_request_counters',
        method: 'PUT',

        success: (data) => {
          Helper.flash_message('success', 'Updated');

          $('#pending_payment_requests_count').html(data.pending_payment_requests_count);
          $('#in_progress_payment_requests_count').html(data.in_progress_payment_requests_count);
          $('#successful_payment_requests_count').html(data.successful_payment_requests_count);
          $('#failed_payment_requests_count').html(data.failed_payment_requests_count);
        },

        error: () => {
          Helper.flash_message('error', "The system can't recalculate")
        }
      });
    });
  }
}

export default PaymentRequestCounters;