import { Controller } from "@hotwired/stimulus"
import axios from "axios";

class LinkVenuesFacilitiesController extends Controller {
  connect() {
    this.element.addEventListener('click', (e) => {
      e.preventDefault();

      $('.modal').modal('hide');

      const url = this.element.dataset.path;
      const modal = document.querySelector('#modal_venues');
      const content = modal.querySelector('.modal-content');

      axios
        .get(url)
        .then((response) => {
          content.innerHTML = response.data;

          $(modal).modal('show');
        });
    });
  }
}

export default LinkVenuesFacilitiesController;
