import React, { useEffect } from 'react';
import classNames from 'classnames';
import DropDown from "../DropDown";
import { useFuturePrices } from "./useFuturePrices";

const optionName = 'futurePrice';

export default ({ item, options, selectedOptions, onChanged, filtersData }) => {
  const {
    priceOptions,
    priceOption,
    setPriceOption,
    pending
  } = useFuturePrices(item, options, selectedOptions, filtersData);

  useEffect(() => {
    const params = priceOption ? { [optionName]: priceOption.value } : null;
    onChanged({ value: priceOption, pending, params })
  }, [pending, priceOption]);

  const hidden = priceOptions && priceOptions.length < 1;

  return (
    <div className={classNames(['filter-block filter-future-prices', { 'hidden': hidden }])}>
      <label htmlFor="filter-invoice-categories">Current or future:</label>
      <div className='input-box input-box-react-select'>
        <DropDown
          value={priceOption}
          options={priceOptions}
          onChange={setPriceOption}
          isLoading={pending}
          isDisabled={pending}
          getOptionLabel={opt => opt.title}
          getOptionValue={opt => opt.value}
        />
      </div>
    </div>
  )
};
