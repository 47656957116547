import React, { useRef } from 'react';
import Select from "react-select";

export default (props) => {
  const ref = useRef(null);

  const onMenuOpen = () => {
    // react-select does not scroll into view selected option
    // https://github.com/JedWatson/react-select/issues/3769
    setTimeout(() => {
      ref.current.select.scrollToFocusedOptionOnUpdate = true;

      const focusedOption = props.options.find(option => {
        return props.getOptionValue(option || {}) === props.getOptionValue(props.value || {});
      });

      if (focusedOption) ref.current.select.setState({ focusedOption });
    }, 10);
  };

  return (
    <Select {...props} ref={ref} onMenuOpen={onMenuOpen}/>
  )
}