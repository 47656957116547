import React from 'react';
import ExportButton from './Button';

const buttonTitles = {
  'pdf': 'Export to PDF',
  'csv': 'Export to Excel'
};

const defaultClasses = 'btn-group pull-right'

export default ({ exportFormats, item, filter, classes, customPath = null }) => (
  <div className={classes || defaultClasses}>
    {exportFormats.map((format) =>
      <ExportButton
        key={format}
        item={item}
        filter={filter}
        format={format}
        title={buttonTitles[format]}
        customPath={customPath}
      />
    )}
  </div>
);
