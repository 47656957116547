import moment from 'moment';

const dayNames = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']

export const initialState = {
  status: 'pending',
  id: null,
  reportType: null,
  reportTypeKey: null,
  searchFilters: {},
  startDate: null,
  weekDays: [],
  exportType: null,
  reportTime: moment('20:00', 'HH:mm'),
  ccUsers: [],
  reportInterval: null,
  errors: {},
  reportTypes: [],
  availableCcUsers: [],
  availableReportIntervals: [],
  availableExportOptions: [],
  venueId: null,
  showCcUsersSelector: false
}

function findReportType(reportTypes, reportTypeKey) {
  return reportTypes.find(item => item.key === reportTypeKey)
}

function filterReportIntervals(reportType, venueId) {
  if (!reportType) return [];

  const reportTypeKey = reportType.key;
  const list = reportType.availableTimePeriods;
  const singleVenue = (typeof venueId === 'number');

  if (reportTypeKey === 'activities' && !singleVenue) {
    return list.filter(item => item !== 'this_month');
  } else {
    return list
  }
}

export function reducer(state, action) {
  switch (action.type) {
    case 'setReportTypes': {
      return {
        ...state,
        status: 'report_types_present',
        reportTypes: action.payload
      };
    }
    case 'assignSchedule': {
      const { payload } = action;

      const obj = {};
      obj.reportTypeKey = payload.report;
      obj.reportType = findReportType(state.reportTypes, payload.report);

      if (action.payload.filters !== null) obj.searchFilters = action.payload.filters;

      obj.startDate = moment(payload.startDate).toDate();
      obj.weekDays = (payload.weekDays || []).map(dayNumber => ({ label: dayNames[dayNumber], value: dayNumber}));
      obj.exportType = payload.exportType;
      obj.reportTime = moment(payload.reportTime);
      obj.ccUsers = payload.ccUsers;
      obj.availableReportIntervals = filterReportIntervals(obj.reportType, obj.venueId);
      obj.reportInterval = payload.reportInterval || obj.availableReportIntervals[0];
      obj.availableExportOptions = obj.reportType ? obj.reportType.availableExportOptions : [];
      
      obj.status = 'ok';

      return {
        ...state,
        ...obj
      };
    }
    case 'setReportType': {
      const obj = {};
      obj.reportTypeKey = action.value;
      obj.reportType = findReportType(state.reportTypes, obj.reportTypeKey);
      obj.availableExportOptions = obj.reportType.availableExportOptions;
      obj.availableReportIntervals = filterReportIntervals(obj.reportType, state.venueId);

      if (!obj.availableReportIntervals.includes(state.reportInterval)) {
        obj.reportInterval = obj.availableReportIntervals[0];
      }

      if (!obj.availableExportOptions.includes(state.exportType)) obj.exportType = obj.availableExportOptions[0];

      return {
        ...state,
        ...obj
      }
    }
    case 'setStartDate': {
      return { ...state, startDate: action.value }
    }
    case 'setWeekDays': {
      return { ...state, weekDays: action.value }
    }
    case 'setExportType': {
      return { ...state, exportType: action.value }
    }
    case 'setReportTime': {
      return { ...state, reportTime: action.value }
    }
    case 'setReportInterval': {
      return { ...state, reportInterval: action.value }
    }
    case 'setSearchFilters': {
      const { value } = action;
      const venueId = value['venueId']
      const showCcUsersSelector = (typeof venueId === 'number')

      const obj = {
        searchFilters: value,
        venueId: venueId,
        showCcUsersSelector: showCcUsersSelector
      }

      obj.availableReportIntervals = filterReportIntervals(state.reportType, obj.venueId);

      if (!showCcUsersSelector) {
        obj.ccUsers = [];
        obj.availableUsers = [];
      }

      return {
        ...state,
        ...obj
      }
    }
    case 'setVenueUsers': {
      const { venueUsers, currentUser } = action.payload;
      const availableUsers = venueUsers;
      const roUsersAllowed = state.reportType.roUsersAllowed;
      const availableCcUsers = roUsersAllowed ? availableUsers : availableUsers.filter(user => !user['readOnly?']);
      const obj = { availableCcUsers: availableCcUsers }

      if (!state.currentUser && currentUser) obj.currentUser = currentUser;
      if (state.ccUsers.length === 0) obj.ccUsers = [currentUser];

      return {
        ...state,
        ...obj
      }
    }
    case 'setCcUsers': {
      return { ...state, ccUsers: action.value }
    }
    case 'setErrors': {
      return { ...state, errors: action.value }
    }
    case 'resetSchedule': {
      return initialState
    }
  }
  
  throw Error('Unknown action: ' + action.type);
}
