import React, { useState } from "react";
import { Button, ButtonGroup } from 'react-bootstrap'
import EmptyListModal from "./EmptyListModal";

export default ({ multiple, setMultiple, setShowMultiEdit, setShowMultiDelete, selectedItems }) => {
  const [showModal, setShowModal] = useState(false)

  const handleEdit = () => { selectedItems.length > 1 ? setShowMultiEdit(true) : setShowModal(true) };
  const handleDelete = () => { selectedItems.length > 1 ? setShowMultiDelete(true) : setShowModal(true) };

  return (
    <>
      {!multiple && <Button style={{ marginLeft: 10 }} onClick={() => setMultiple(true)}>Edit/Remove Multiple</Button>}
      {
        multiple &&
        <>
          <EmptyListModal show={showModal} setShow={setShowModal}/>
          <ButtonGroup style={{ marginLeft: 10 }} className='multiple-modification'>
            <Button bsStyle='primary' onClick={handleEdit}>Edit Multiple</Button>
            <Button bsStyle='danger' onClick={handleDelete}>Delete Multiple</Button>
            <Button onClick={() => setMultiple(false)}>Cancel</Button>
          </ButtonGroup>
        </>
      }
    </>
  )
}