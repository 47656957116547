class Facilities {
  constructor() { }

  start() {
    if ($('body.admin_facilities_index').length < 1) {
      return;
    }

    this.toDataTable();

    $('#facilities_filter .venue_id_filter').on('change', () => {
      this.reloadDataDebounced();
    });

    $('#facilities_filter input').on('keyup', () => {
      this.reloadDataDebounced();
    });
  }

  toDataTable() {
    const facilities_table_params = {
      order: [[2, 'asc']],
      processing: true,
      serverSide: true,
      searching: false,
      lengthChange: false,
      pageLength: 30,
      ajax: this.facilitiesURL,
      pagingType: 'full_numbers',

      fnInitComplete: (oSettings) => {
        window.assignDatatableAutoscroll(oSettings);
      },

      fnDrawCallback: (oSettings) => {
        const $tableWrapper = $(oSettings.nTableWrapper);
        const $table = $tableWrapper.find('table');
        const show = oSettings._iDisplayLength <= oSettings.fnRecordsDisplay();

        $('#facilities-table').DataTable()
          .column(1)
          .visible($('#facilities_filter .venue_id_filter').val() === '');

        $('thead th:contains("Date created")')[0].style.width = '70px'
        $('thead th:contains("Created by")')[0].style.width = '140px'
        $('thead th:contains("Published")')[0].style.width = '50px'

        const titles = _.map($table.find('thead th'), (x, y) => x.innerText); // Should be after column visibility patching

        $tableWrapper.find('.dataTables_paginate').toggle(show);
        $tableWrapper.find('.dataTables_info').toggle(show);

        _.each($table.find('tr'), (row) => {
          _.each($(row).find('td'), (cell, index) => {
            $(cell).attr('data-title', titles[index]);
          });
        });
      }
    };

    $('#facilities-table.admin_side').dataTable($.extend({}, facilities_table_params, {
      columnDefs: [
        {
          targets: [0],
          visible: $('#facilities-table thead th:eq(0)').data('visible')
        },
        {
          targets: [7],
          orderable: false
        }
      ]
    }));
  }

  reloadDataDebounced() {
    _.debounce(() => this.reloadData(true), 250)();
  }

  reloadData(changePage) {
    const ajax = $('#facilities-table').DataTable().ajax;
          ajax.url(this.facilitiesURL);
          ajax.reload(null, changePage);
  }

  get facilitiesURL() {
    return $('#facilities-table').data('source') + '?' + $('form#facilities_filter').serialize();
  }
}

export default Facilities;
