import { Controller } from "@hotwired/stimulus";
import React, { lazy, Suspense } from 'react';
import ReactDOM from "react-dom";

const Components = {
  'AdminImages': () => import("src/components/AdminImages"),
  'Messenger': () => import("src/components/Messenger/Index")
}

export default class extends Controller {
  static values = {
    name: String
  };

  connect() {
    if (!Components[this.nameValue]) {
      throw new Exception(`"${this.nameValue}" is not known component.`);
    }

    const Component = lazy(Components[this.nameValue]);
    const options = this.element.dataset;

    ReactDOM.render(
      <Suspense fallback={<div/>}>
        <Component {...options}/>
      </Suspense>, this.element
    );
  }
}
