import React, { useState, useEffect } from "react";
import ChildFacility from "./ChildFacility";

const MAX_NUMBER = 32;

export default ({ childFacilities, addChildFacility,
                  deleteChildFacility,
                  revertChildFacility,
                  errors }) => {
  const [selectedNum, setSelectedNum] = useState(1);

  const nonDeletedChildFacilities = (childFacilities || []).filter((childFacility) => {
    return !(childFacility['_destroy'])
  });

  const nonDeleteRequestedChildFacilities = (childFacilities || []).filter((childFacility) => {
    return !(childFacility['deleteRequest'])
  });

  const len2 = nonDeletedChildFacilities.length;
  const len = nonDeleteRequestedChildFacilities.length;
  const sumDeletedFacilities = len + len2
  const n = MAX_NUMBER - sumDeletedFacilities;

  useEffect(() => { if (selectedNum > n) setSelectedNum(1) }, [n]);

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <label>Related names:</label>
          {(childFacilities || []).map((childFacility, index) =>
            <ChildFacility
              key={index}
              index={index}
              data={childFacility}
              deleteChildFacility={deleteChildFacility}
              revertChildFacility={revertChildFacility}
              len={len}
              len2={len2}
              errors={errors}
              deleteRequestEnabled={childFacility.deleteRequestEnabled}
            />
          )}
        </div>
      </div>

      <div className='row'>
        <div className='col-lg-12 col-md-12'>
          <div className="form-group">
            <label className="control-label select optional" htmlFor="facility_facility_category_id">
              Add new facilities:
            </label>

            <div className='form-inline'>
              <select className='form-control select optional'
                      value={selectedNum}
                      style={{ width: '200px' }}
                      onChange={(e) => setSelectedNum(parseInt(e.target.value))}>
                {[...Array(MAX_NUMBER).keys()].map((i) => <option key={i} value={i+1}>{i+1}</option>)}
              </select>

              <div style={{ width: '10px', display: 'inline-block' }}/>

              <button type="button"
                      className="btn btn-primary"
                      style={{ width: '165px' }}
                      onClick={() => addChildFacility(selectedNum)}>
                Add
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="clearfix"></div>
    </>
  )
}
