import { Controller } from "@hotwired/stimulus"
import ReactDOM from "react-dom";
import Form from "../src/components/EditBooking/Form";
import React from "react";

export default class extends Controller {
  static targets = ['description', 'description2', 'pointer'];

  static values = {
    expandedClass: 'fa-angle-up',
    collapsedClass: 'fa-angle-down',
    hiddenBlockClass: 'hidden-block'
  }

  expanded = false;

  connect() {
  }

  pointerTargetConnected(element) {
    element.classList.add(this.expanded ? this.expandedClassValue : this.collapsedClassValue);
  }

  toggle_expand_row() {
    if (this.expanded) {
      this.collapse()
      this.dispatch('expanded_row');
    } else {
      this.expand()
      this.dispatch('expanded_row');
    }
  }

  expand() {
    if (this.descriptionTargets[0]) {
      this.descriptionTarget.classList.remove(this.hiddenBlockClassValue);
      this.description2Target.classList.remove(this.hiddenBlockClassValue);
      this.pointerTarget.classList.replace(this.collapsedClassValue, this.expandedClassValue);
      this.expanded = true;
    }
  }

  collapse() {
    if (this.descriptionTargets[0]) {
      this.descriptionTarget.classList.add(this.hiddenBlockClassValue);
      this.description2Target.classList.add(this.hiddenBlockClassValue);
      this.pointerTarget.classList.replace(this.expandedClassValue, this.collapsedClassValue);
      this.expanded = false;
    }
  }

  editBooking(event) {
    let modalBox = document.getElementById('booking_edit_modal');

    if (!modalBox) {
      const elemDiv = document.createElement('div');
      elemDiv.setAttribute('id', 'booking_edit_modal');

      document.body.appendChild(elemDiv);

      modalBox = document.getElementById('booking_edit_modal');
    }

    ReactDOM.unmountComponentAtNode(modalBox);
    ReactDOM.render(<Form url={event.params.url}/>, modalBox);
  }
}
