import React, { useEffect, useRef } from "react";
import classNames from 'classnames';

const statusOrPrice = function(item) {
  if (!item.available_period) return "UNAVAILABLE";
  if (item.closed) return "CLOSED";
  if (!item.time_available) return "BUSY";

  const settings = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: 'decimal',
    currency: 'GBP'
  };

  return `£ ${new Intl.NumberFormat('en-UK', settings).format(item.price)}`;
}

const Notice = ({ notice }) => (
  <div className='time-block'>
    <div className='closed'>
      <span className='periods_notice_text'>
        {notice.text}
      </span>
      <br/>
      <br/>
      <span className='periods_notice_subtext'>
        {notice.subtext}
      </span>
    </div>
  </div>
);

export default ({ periods, startTime, endTime, onChangeStartTime, onChangeEndTime }) => {
  const startTimeIndex = (periods.list || []).findIndex(element => element.start_time === startTime);
  const endTimeIndex = (periods.list || []).findIndex(element => element.end_time === endTime);

  const clickItem = function(period, index) {
    if (periods.booking_day_rate_enabled) return false;

    const arr = [startTimeIndex, endTimeIndex, index]

    if (arr.every( (val, i, arr) => val === arr[0])) return false;

    if (index === -1) {
      return true;
    } else if (index === startTimeIndex) {
      const n = periods.list[index + 1]
      if (n) onChangeStartTime(n.start_time);
    } else if (index === endTimeIndex) {
      const p = periods.list[index - 1];
      if (p) onChangeEndTime(p.end_time);
    } else if (index === startTimeIndex - 1) {
      onChangeStartTime(period.start_time);
    } else if (index === endTimeIndex + 1) {
      onChangeEndTime(period.end_time);
    } else {
      if (startTimeIndex + 1 <= index && endTimeIndex - 1 >= index) {
        return false;
      } else {
        onChangeStartTime(period.start_time);
        onChangeEndTime(period.end_time);
      }
    }
  }

  if (periods.type === 'notice') return <Notice notice={periods.notice}/>;

  return (
    <div className='time-block'>
      {periods.list && periods.list.map((period, index) => {
        const isSelected = index >= startTimeIndex && index <= endTimeIndex;
        const isDisabled = !period.time_available || period.closed;

        return (
          <label key={period.period}
                 onClick={() => clickItem(period, index)}
                 className={classNames(['item', { 'disabled': isDisabled, 'green': isSelected }])}>
            {period.start_time} - {period.end_time}
            <span>{statusOrPrice(period)}</span>
          </label>
        )
      })}
    </div>
  )
}