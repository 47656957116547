const WeekViewType = (function() {
  function init(refreshCalendar) {
    $('.week-view-type').on('change', function(e) {
      $('.hidden-week-view-type').val($(this).val());

      const weekViewTypeEl = $('.hidden-week-view-type');
      const weekViewType = weekViewTypeEl.val();
      const isAvailability = weekViewType !== 'availability';

      $('.col-legend .legend-item').toggle(isAvailability);
      $('.col-internal-booking .btn').toggle(isAvailability);
      $('.users-field').toggle(isAvailability);
      $('select#user_id').attr('disabled', !isAvailability);

      refreshCalendar();
    });
  }

  return {
    init: init,
  };
})();

export default WeekViewType;