import Venues from "./Venues";
import Facilities from "./Facilities";
import TimePeriods from "./TimePeriods";
import InvoiceCategories from "./InvoiceCategories";
import FuturePrices from "./FuturePrices";
import DocumentTypes from "./DocumentTypes";
import ReportTypes from "./ReportTypes";
import OfflineStatuses from "./OfflineStatuses";
import PartnerMarker from "./PartnerMarker";
import BookingsStatuses from "./BookingsStatuses";
import Date from "./Date";
import Customer from "./Customers";
import Days from "./Days";
import MultiFacilities from "./MultiFacilities";
import IncomeTypes from "./IncomeTypes";

export const FILTERS = [
  { key: 'venues', Component: Venues },
  { key: 'facilities', Component: Facilities },
  { key: 'multi_facilities', Component: MultiFacilities },
  { key: 'time_periods', Component: TimePeriods },
  { key: 'invoice_categories', Component: InvoiceCategories },
  { key: 'days', Component: Days },
  { key: 'bookings_statuses', Component: BookingsStatuses },
  { key: 'future_price', Component: FuturePrices },
  { key: 'document_types', Component: DocumentTypes },
  { key: 'report_types', Component: ReportTypes },
  { key: 'offline_statuses', Component: OfflineStatuses },
  { key: 'partner_marker', Component: PartnerMarker },
  { key: 'date', Component: Date },
  { key: 'customer', Component: Customer },
  { key: 'income_types', Component: IncomeTypes }
];
