import React, { useState } from "react";
import { Button, ButtonGroup } from 'react-bootstrap'
import EmptyListModal from "./EmptyListModal";

export default ({ multiple, setMultiple, clearHighlight, setClearHighlight, setShowMultiEdit, selectedItems,
                  setSelectedItems, setShowClearHighlightEdit }) => {
  const [showModal, setShowModal] = useState(false)

  const handleEdit = () => { setShowClearHighlightEdit(true) };

  return (
    <>
      {!clearHighlight && <Button style={{ marginLeft: 10 }} onClick={() => setClearHighlight(true)}>Clear Highlighting</Button>}
      {
        clearHighlight &&
        <>
          <EmptyListModal show={showModal} setShow={setShowModal}/>
          <ButtonGroup style={{ marginLeft: 10 }} className='multiple-modification'>
            <Button bsStyle='primary' onClick={handleEdit}>Clear Highlighting</Button>
            <Button onClick={() => {
              setClearHighlight(false)
              setSelectedItems([])
            }}>Cancel</Button>
          </ButtonGroup>
        </>
      }
    </>
  )
}