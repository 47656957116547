import React, { useEffect } from 'react';

import { useSelectedOptions } from "./useSelectedOptions";

import './styles.scss';
import { getQueryString } from "../Reports/Api";

export default ({ item, options, target, onUpdated }) => {
  const [
    assignOption,
    activeFilters,
    pending,
    mergedParams,
    filtersData
  ] = useSelectedOptions(item);
  const queryString = getQueryString(mergedParams);

  useEffect(() => { if (!pending) onUpdated(mergedParams) }, [pending, queryString]);

  return (
    <div className={`mega-filter mega-filter-${item.key}`}>
      {activeFilters.map(({ key, name, Component }) =>
        <Component
          key={key}
          item={item}
          options={options}
          target={target}
          selectedOptions={mergedParams}
          filtersData={filtersData}
          onChanged={(value) => assignOption(key, value)}
        />
      )}
      <div className='clearfix'/>
    </div>
  );
}
