export function showBootstrapAlert(title, text) {
  const alertModal = $('#bootstrap-alert');
  const titleElement = alertModal.find('.modal-header .modal-title');
  const bodyElement = alertModal.find('.modal-body .alert-context');

  titleElement.html(title);
  bodyElement.html(text);

  alertModal.modal('show');
}

