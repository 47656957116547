window.adminDocumentsForm = () => {
  const toggleFacilities = () => {
    const val = $('input[name="document[all_facilities]"]:checked').val();
    $('.facilities-for-documents').toggle(val === '0');
  }

  $('input[name="document[all_facilities]"]').on('change', toggleFacilities);

  toggleFacilities();
}
