import React, { useState, useEffect } from "react";
import axios from "axios";

export default ({ nonViewed, nonViewedNames, setNonViewed, venueId }) => {
  const [checked, setChecked] = useState(false);
  useEffect(() => setChecked(false), [nonViewed])

  const handleChange = () => {
    setChecked(true);

    axios
      .post(`/admin/staff_items/mark_viewed.json`, { venue_id: venueId })
      .then(() => { setNonViewed(false) });
  }

  if (nonViewed !== true) return null;

  return (
    <div className='mark-viewed'>
      <div className='col-checkbox'>
        <input type='checkbox' checked={checked} onChange={handleChange}/>
      </div>
      <div className='col-description'>
        Please tick this box to confirm that you have reviewed your shift(s). New shifts created
        are shown in green, shifts amended are shown in yellow, and shifts cancelled are shown in red.
        If you have any questions, please contact {nonViewedNames} directly.
      </div>
    </div>
  )
}