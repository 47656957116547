import React from "react";

export default ({ value, onChange }) => (
  <div className="form-group decimal optional booking_price_adjustment">
    <label className="control-label decimal optional">
      Price adjustment (£)
      {/*= "&nbsp;".html_safe*/}
      {/*= multiple ? render('orders/multiple_line_adjustment_info') : render('orders/line_adjustment_info')*/}
    </label>
    <input className="form-control numeric decimal optional facility_id"
           name="booking[price_adjustment]"
           step="any"
           style={{ width: 80 }}
           type="number"
           value={value}
           onChange={onChange}/>
  </div>
)