import React from "react";
import { Button, Modal } from "react-bootstrap";


export default ({ children, show, onHide, buttons, backdrop, className, title }) => {
  return (
    <Modal show={show} onHide={onHide} backdrop={backdrop ? "static" : false} dialogClassName={className}>
      <Modal.Header closeButton>
        <Modal.Title>{title ? title : 'Send message to multiple customers'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {children}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Close</Button>
        {buttons}
      </Modal.Footer>
    </Modal>
  )
}