import { Controller } from "@hotwired/stimulus";
import axios from 'axios';
import SmartyForm from '../src/nextgen/helpers/smarty_form';
import { processingModal } from "../src/nextgen/helpers/processing_modal";

class EditAccountPasswordController extends Controller {
  showModal(e) {
    e.preventDefault();

    const requirementText = "Password requirements: minimum of 8 characters, " +
                            "with at least 1 upper case letter, 1 lower case " +
                            "letter and 1 number";
    axios
      .get(e.target.href)
      .then(response => {
        const modal = document.querySelector('#edit-form-modal');
        const modalContent = modal.querySelector('.modal-content');

        modalContent.innerHTML = response.data;

        const passwordBlock = modalContent.querySelector('.user_password');
        const requirementBlock = document.createElement('div');
        requirementBlock.innerHTML = requirementText;
        requirementBlock.classList.add('requirements');
        passwordBlock.appendChild(requirementBlock);
        $(modal).modal('show');
      })
      .catch((error) => {
        Helper.flash_message('error', 'Something went wrong')
      });
  }

  submit(e) {
    e.preventDefault();

    const form = this.element;
    const modal = form.closest('.modal');

    new SmartyForm().process({
      form: $(e.target),
      onSuccess: () => {
        $(modal).modal('hide');
        Helper.flash_message('success', 'Password updated');
      },
      onError: (data) => {
        const errors = data.responseJSON.errors
        const requirementBlock = form.querySelector('.requirements');

        if (errors.password) {
          requirementBlock.classList.add('hidden');
        } else {
          requirementBlock.classList.remove('hidden');
        }

        processingModal.hide();
      }
    });
  }
}

export default EditAccountPasswordController;
