import React from "react";
import DateInput from "src/components/DateInput";

const TimePeriods = [
  { id: 'present_and_upcoming', title: 'Present and Upcoming' },
  { id: 'all_times', title: 'All Times' },
  { id: 'today', title: 'Today' },
  { id: 'this_week', title: 'This Week' },
  { id: 'last_week', title: 'Last Week' },
  { id: 'this_month', title: 'This Month' },
  { id: 'last_month', title: 'Last Month' },
  { id: 'custom', title: 'Custom' }
];

export default ({ timePeriod, setTimePeriod, startDate, setStartDate, endDate, setEndDate }) => (
  <>
    <div className="form-group radio_buttons optional">
      <label className="control-label radio_buttons optional">Time period:</label>
      {TimePeriods.map(item => (
        <span className="radio" key={item.id}>
          <label>
            <input className="radio_buttons optional"
                   type="radio"
                   value={item.id}
                   checked={timePeriod === item.id}
                   onChange={() => setTimePeriod(item.id)}
                   name="filter[time_period]"/>
            {item.title}
          </label>
        </span>
      ))}
    </div>
    {timePeriod === 'custom' &&
    <div className="custom_dates">
      <div className="form-group string optional">
        <label className="control-label string optional">
          Start date
        </label>
        <DateInput
          onChange={setStartDate}
          value={startDate}
          name={`filter[start_date]`}
          positions={['top']}
        />
      </div>
      <div className="form-group string optional">
        <label className="control-label string optional">
          End date
        </label>
        <DateInput
          onChange={setEndDate}
          value={endDate}
          name={`filter[end_date]`}
          positions={['top']}
        />
      </div>
    </div>
    }
  </>
)