import React, { useContext, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import api from '../../../api';
import { ViewModeContext } from "../../../utils/context";

const MESSAGE = `
  Are you sure you want to proceed? Cancelled shifts are highlighted
  in red until the relevant staff member signs in to SchoolHire and
  acknowledges that they are aware of this change. Once acknowledged,
  the shift(s) will be removed.
`

const MESSAGE2 = `
  Are you sure you want to proceed? Once confirmed, the shift(s) will be removed.
`

export default ({ itemId, show, setShow, onUpdated, data }) => {
  let message = MESSAGE;
  const { currentUserId } = useContext(ViewModeContext);
  const [removalInProgress, setRemovalInProgress] = useState(false);

  const handleRemove = () => {
    if (removalInProgress) return true;

    setRemovalInProgress(true)

    api
      .removeRow(itemId)
      .then(() => {
        setShow(false)
        onUpdated();
        setTimeout(() => setRemovalInProgress(false), 150)
      })
      .catch(() => {
        setRemovalInProgress(false)
      })
  }
  
  const item = data.find((staffItem => staffItem.id === itemId));
  if (item && item.userId === currentUserId) message = MESSAGE2;

  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Shift(s) Cancelled</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {message}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleRemove} disabled={removalInProgress}>Confirm</Button>
      </Modal.Footer>
    </Modal>
  );
}