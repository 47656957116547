import { Controller } from "@hotwired/stimulus";

export default class PopoverController extends Controller {

  connect() {
    const self = $(this.element);
    const content = this.element.querySelector('.popover-body').innerHTML;
    const dataset = this.element.dataset;
    const trigger = dataset.trigger || 'hover';
    const placement = dataset.placement || 'right';

    self.popover({
      html: true,
      trigger: trigger,
      placement: placement,
      content: content
    });
  }
}
