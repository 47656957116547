import SmartyForm from '../helpers/smarty_form';

class Themes {
  constructor() {
    this.modalElement = $('#modal_theme');
  }

  start() {
    this.onNew();
    this.onUpload();
  }

  onNew() {
    $('#new_theme_button').on('click', () => this.modalElement.modal('show'));
  }

  onUpload() {
    $('#upload_theme').on('click', (event) => {
      const $this = $(event.currentTarget);
      const $form = $this.closest('.modal-content').find('form#new_theme');

      $.ajax({
        type: 'POST',
        url: $form.attr('action'),
        data: new FormData($form[0]),
        processData: false,
        contentType: false,

        success: () => { this.modalElement.modal('hide') },

        error: (response) => {
          new SmartyForm().addErrors($form, 'theme', (response.responseJSON || {}).errors);
        }
      });
    });
  }
}

export default Themes;