import React, { useContext } from "react";
import { DebounceInput } from "react-debounce-input";
import { AccessContext } from "../../utils/context";

export default ({ term, setTerm}) => {
  const allowManage = useContext(AccessContext);

  if (!allowManage) return null;

  return (
    <div className="form-search">
      <div className="form-group string optional filter_terms">
        <label className="control-label string optional">Search:</label>
        <DebounceInput
          className="form-control string optional"
          name="filter[terms]"
          minLength={2}
          debounceTimeout={300}
          value={term}
          onChange={e => setTerm(e.target.value)}
        />
      </div>
    </div>
  )
}