import React from "react";
import TimePicker from "rc-time-picker";

export default ({ reportTime, setReportTime }) => {
  return (
    <TimePicker
      value={reportTime}
      onChange={setReportTime}
      minuteStep={15}
      allowEmpty={false}
      showSecond={false}
    />
  )
}