(function() {
  $(function() {

    const newVenueNoteModal = $('#admin_orders_modal');

    const showVenueNoteModal = function (link) {
      $.ajax({
        type: "GET",
        url: link,
        async: true,
        success: function (data) {
          newVenueNoteModal.find('.modal-content').empty().append(data);
          newVenueNoteModal.modal('show');
        },
        error: function (data) {
          newVenueNoteModal.find('.modal-content').empty();
          newVenueNoteModal.modal('close');
          Helper.flash_message('error', "Something went wrong!");
        }
      });
    };

    $(document).on('click', 'a.open_show_initiated_message_modal', function (e) {
      e.preventDefault();
      showVenueNoteModal($(this).attr('href'));
    });

  });
}).call(this);
