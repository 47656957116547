import React, { useState, useEffect, useRef, useMemo } from 'react';
import AnimateHeight from 'react-animate-height';
import { Button } from 'react-bootstrap';
import Modal from 'src/components/Modal';
import Error from 'src/components/Form/Error';
import DatePicker from "src/components/DateInput";
import MultiSelect from "src/components/MultiSelect";
import InfoIcon from "src/components/InfoIcon";

import axios from "axios";
import moment from "moment";

import { processingModal } from "src/nextgen/helpers/processing_modal";

import './styles.scss';

export default ({ show, setShow, venueId }) => {
  const [messageHeight, setMessageHeight] = useState(0);
  const [dateOfBooking, setDateOfBooking] = useState(false);
  const [hirers, _setHirers] = useState('current');
  const [startDate, setStartDate] = useState(new Date);
  const [endDate, setEndDate] = useState(new Date);
  const [users, setUsers] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [errors, setErrors] = useState({});
  const [usersReady, setUsersReady] = useState(false);
  const [facilitiesReady, setFacilitiesReady] = useState(false);

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedFacilities, setSelectedFacilities] = useState([]);
  const [userSignature, setUserSignature] = useState('');
  const [inProgress, setInProgress] = useState(false);

  const messageRef = useRef();
  const fileRef = useRef();

  const setHirers = (hirer) => {
    _setHirers(hirer)

    if (hirer === 'enquiries_only' || hirer === 'all_threads') {
      setDateOfBooking(false);
      setSelectedFacilities(facilities);
    }
  };

  useEffect(() => {
    const customMessageSignature = axios.get(`/admin/custom_message_signature.json?venue_id=${venueId}`);
    const venueAndRecipients = axios.get(`/conversations/venue_and_recipients/${venueId}.json`);
    const facilities = axios.get(`/admin/messenger/venues/${venueId}/facilities.json`)

    axios
      .all([customMessageSignature, venueAndRecipients, facilities])
      .then(axios.spread(function(res1, res2, res3) {
        if (res1.data.body) setUserSignature('\n\n\n' + res1.data.body);
        setUsers(res2.data.recipients)
        setUsersReady(true);
        setFacilities(res3.data.facilities);
        setSelectedFacilities(res3.data.facilities);
        setFacilitiesReady(true);
      }));
  }, []);

  useEffect(() => processingModal.toggle(inProgress), [inProgress]);

  const ready = useMemo(() => (usersReady && facilitiesReady), [usersReady, facilitiesReady])
  const enabledSendButton = useMemo(() => {
    return selectedUsers.length > 0 && ready && !inProgress
  }, [ready, selectedUsers, inProgress]);

  const applyFilter = () => {
    setUsersReady(false);

    let params = {}

    params.hirers = hirers;
    params.facility_ids = selectedFacilities.map(facility => facility.id);
    params.date_of_booking = dateOfBooking;
    params.venue_id = venueId;

    if (dateOfBooking) {
      params.from_date = moment(startDate).format('D.M.YYYY');
      params.to_date = moment(endDate).format('D.M.YYYY');
    }

    axios.get(`/admin/conversations/recipients_for_venue.json`, { params: params }).then(response => {
      const userIds = response.data.user_ids;
      const newSelectedUsers = users.filter(user => userIds.includes(user.id));
      setSelectedUsers(newSelectedUsers);

      if (userIds.length === 0) {
        setMessageHeight('auto')
        setTimeout(() => { setMessageHeight(0) }, 4000);
      }

      setUsersReady(true);
    }).catch(e => {
      Helper.flash_message('error', 'Something went wrong');
      setUsersReady(true);
    });
  }

  const handleClickDateOfBooking = (e) => {
    const checked = e.target.checked;
    setDateOfBooking(e.target.checked);
    if (checked) setHirers('all');
  }

  const sendMessage = () => {
    setErrors({});
    setInProgress(true);

    const formData = new FormData();

    selectedUsers.forEach(({ id }, index) => {
      formData.append(`multi_message_users_attributes[${index}][user_id]`, id);
    });

    formData.append('message', messageRef.current.value);
    formData.append('file', fileRef.current.files[0]);

    axios.post(`/admin/venues/${venueId}/multi_message`, formData).then(response => {
      Helper.flash_message('success', response.data.notice);
      setInProgress(false);
      setShow(false);
    }).catch(error => {
      if (error.response.status === 422) {
        setErrors(error.response.data.errors)
      } else {
        Helper.flash_message('error', 'Something went wrong')
      }
      setInProgress(false);
    });
  }

  const facilitiesAndBookingDatesDisabled = () => {
    return hirers == 'enquiries_only' || hirers == 'all_threads';
  };

  return (
    <Modal
      title='Send message to multiple customers'
      show={show}
      onHide={() => setShow(false)}
      buttons={<Button bsStyle='primary' onClick={sendMessage} disabled={!enabledSendButton}>Send</Button>}
      backdrop={true}
      className="modal-width"
    >
      <form className='multi_message_form'>
        <div className="form-group">
          <MultiSelect
            options={users}
            getOptionLabel={option => option.deleted ? `${option.name} (deleted)` : option.name}
            getOptionValue={option => option.id}
            showSelectedNumber={false}
            value={selectedUsers}
            onChange={setSelectedUsers}
            isDisabled={!usersReady}
            placeholder='Select recipients...'
          />
          <Error errors={errors} name='multi_message_users'/>
        </div>

        <AnimateHeight id="example-panel" duration={500} height={messageHeight}>
          <div className="bottom-gap error-filter">
            <p className="show-effect">
              The options selected do not show any customers. Please check your options and try again
            </p>
          </div>
        </AnimateHeight>

        <div className="form-group hirers">
          <label htmlFor="hirers_true">
            <input type="radio" name="hirers" checked={hirers === 'current'}
                   disabled={dateOfBooking}
                   onChange={() => setHirers('current')}/>
            Current hirers
            <InfoIcon>Message to all customers who have an active booking at your venue</InfoIcon>
          </label>

          <label value="false" htmlFor="hirers_false">
            <input type="radio" name="hirers" checked={hirers === 'all'} onChange={() => setHirers('all')}/>
            All hirers
            <InfoIcon>
              Message to all customers who have previously made bookings at your venue.
              This will include both historic and active customers
            </InfoIcon>
          </label>

          <label value="false" htmlFor="hirers_false">
            <input type="radio" name="hirers" checked={hirers === 'enquiries_only'} onChange={() => setHirers('enquiries_only')}/>
            Enquiries only
            <InfoIcon>
              Message to all potential customers who have sent a message but have never made a booking
            </InfoIcon>
          </label>

          <label value="false" htmlFor="hirers_false">
            <input type="radio" name="hirers" checked={hirers === 'all_threads'} onChange={() => setHirers('all_threads')}/>
            All hirers plus enquiries
            <InfoIcon>
              Message to everyone, including those who have only made an enquiry previously
            </InfoIcon>
          </label>
        </div>

        <div className="form-group facility">
          <label>Facility</label>
          <div className='dropdown'>
            <div>
              <MultiSelect
                options={facilities}
                getOptionLabel={option => option.internalReference}
                getOptionValue={option => option.id}
                value={selectedFacilities}
                onChange={setSelectedFacilities}
                isDisabled={!facilitiesReady || facilitiesAndBookingDatesDisabled()}
                showSelectedNumber={true}
                placeholder='Select facilities...'
              />
            </div>
          </div>
        </div>

        <div className="form-group date-of-booking">
          <label htmlFor="date">
            Date of booking
            {" "}
            <input type="checkbox"
                   name="date_switcher"
                   onChange={(e) => handleClickDateOfBooking(e)}
                   disabled={facilitiesAndBookingDatesDisabled()}
                   checked={dateOfBooking}/>
          </label>

          {dateOfBooking ?
            <div className='daterange-box'>
              <div className="input-group input-daterange">
                <div className='daterange-picker'>
                  <DatePicker
                    onChange={setStartDate}
                    value={startDate}
                  />
                </div>
                <span className='to'>to</span>
                <div className='daterange-picker'>
                  <DatePicker
                    onChange={setEndDate}
                    value={endDate}
                  />
                </div>
              </div>
            </div>
            : null}

          <div className='clearfix'/>
        </div>

        <div className="form-group">
          <button type="button" className="btn btn-primary" onClick={applyFilter}>Apply</button>
        </div>

        <div className='form-group text required conversation_message'>
          <textarea
            name="conversation[message]"
            required="required"
            className="form-control"
            placeholder="Enter message"
            rows={9}
            ref={messageRef}
            defaultValue={userSignature}
          />
          <Error errors={errors} name="message"/>
        </div>

        <div className="form-group text required conversation_message">
          <label htmlFor="conversation_attachment">
            Attachment:
            &nbsp;
            <span style={{ fontWeight: 100 }}>
              Please note that the Customer will need to login to view this attachment
            </span>
          </label>
          <input
            type="file"
            ref={fileRef}
            name="conversation[attachment]"
            data-controller="file_uploader_validator"
          />
          <Error errors={errors} name="file"/>
        </div>
      </form>
    </Modal>
  )
}