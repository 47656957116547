import { Controller } from "@hotwired/stimulus";

export default class DynamicPopoverController extends Controller {

  connect() {
    const self = $(this.element);
    const dataset = this.element.dataset;
    const trigger = dataset.trigger || 'hover';
    const placement = dataset.placement || 'right';
    const url = dataset.url;
    const turboFrameId = dataset.turboFrameId;


    self.popover({
      html: true,
      trigger: trigger,
      placement: placement,
      sanitize: false,
      content: `<div class="dynamic-popover-content"><turbo-frame id="${turboFrameId}" src="${url}" loading="lazy">Loading...</turbo-frame></div>`
    });
  }
}
