import ReactDOM from "react-dom";
import React from "react";
import PanelButton from './MultiConverter/PanelButton';

window.adminFacilityConverter = function() {
  const el = document.getElementById('facility_converter_component');
  if (el) {
    ReactDOM.render(<PanelButton facilityId={el.dataset.facilityId}/>, el);
  } else {
    console.error('I do not see a #facility_converter_component')
  }
}
