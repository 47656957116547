class Home {
  constructor() { }

  start() {
    this.init();

    $('#user_modal')
      .on('show.bs.modal', () => { $('body').css('overflow', 'hidden') })
      .on('hidden.bs.modal', () => { $('body').css('overflow', 'auto') });

    if ($(window).width() <= 800) {
      $('.footer-block').click((event) => {
        const $this = $(event.currentTarget);
        const element = $this.next('dd').children();

        element.toggle('slow');
      });
    }

    $(document).on('click', '#user_blocked', () => {
      if ($('.blocked_message_field').hasClass('hide')) {
        $('.blocked_message_field').removeClass('hide');
      } else {
        $('.blocked_message_field').addClass('hide');
        $('#user_blocked_message').val('');
      }
    });

    $('#submit-main-search').click((event) => {
      event.preventDefault();

      try {
        if ($('#categories').val() === '') return Helper.flash_message('error', "Please select a facility");
        if ($.trim($('#area').val()) === '') return Helper.flash_message('error', "Please enter a location");

        const date = $('#search-datepicker').val();
        const multiVenueId = $('#multi_venue_id').val();
        const multiVenueIdOption = multiVenueId ? `&multi_venue_id=${multiVenueId}` : ''

        window.location = `all-facilities?categories=${$('#categories').val()}&area=${$('#area').val()}&date=${date || ''}${multiVenueIdOption}`;
      } catch (error) {
        Helper.flash_message('error', "The browser you are using is not showing any results. Please try again on a different browser or with another device.");
      }
    });

    $(document).on('click', '.destroy_relation', (event) => {
      const $this = $(event.currentTarget);

      const accessTypeSelect = $this.parents('tr').find('.access_type_select');

      $this.parents('.form-group').prev('.destroy_field').val(!$this.is(':checked'));

      if ($this.is(':checked')) {
        accessTypeSelect.removeAttr('disabled');
      } else {
        accessTypeSelect.attr('disabled', true);
      }
    });

    $(document).on('click', '.radio-checkbox', (event) => {
      const $this = $(event.currentTarget);

      $this.closest('.photos').find('input[type="checkbox"]').each((i, object) => {
        if ($(object).attr('name') !== $this.attr('name')) {
          $(object).attr('checked', false);
        }
      });
    });

    $(document).on('click', '.request-city', () => {
      $('.venue_city_id').find('.help-block').hide();
      $('.new-city-fields').removeClass('hide');
    });

    $(document).on('click', '.city-request-submit', (event) => {
      event.preventDefault();

      const $this = $(event.currentTarget);

      $('.new-city-fields').addClass('hide');
      $('.venue_city_id').find('.help-block').text("We'll process your request shortly. For now you can select any city.");
      $('.venue_city_id').find('.help-block').show();

      $.ajax({
        url: $this.attr('href'),
        method: 'PUT',
        data: {
          city: $('#new_city').val()
        }
      });
    });

    $(document).on('click', '.related_category_checkboxes input', (event) => {
      const $this = $(event.currentTarget);

      if ($this.is(':checked')) {
        $(`#related_cateogry_${ $this.val() }`).removeClass('hide');
      } else {
        $(`#related_cateogry_${ $this.val() }`).addClass('hide');
        $(`#related_cateogry_${ $this.val() }`).find('input').removeAttr('checked');
      }
    });

    $(document).on('click', '.autosubmit-checkbox', (event) => {
      $(event.currentTarget).closest('form').submit();
    });

    $(document).on('change', '.autosubmit-dropdown', (event) => {
      $(event.currentTarget).closest('form').submit();
    });

    $(document).on('focusout', '.autosubmit-input', (event) => {
      $(event.currentTarget).closest('form').submit();
      Helper.flash_message('success', 'Successfully updated');
    });

    $('.admin-datatable').DataTable({
      pageLength: 50,
      bInfo: false,
      bLengthChange: false,
      conditionalPaging: true,
      aaSorting: [],
      columnDefs: [{
        targets: [3, 4],
        orderable: false
      }],
      dom: '<div><"search-field row filter-wrapper m-0"f><"pull-right"l>tip</div>'
    });

    $('.admin-datatable-without-search').DataTable({
      pageLength: 50,
      bInfo: false,
      bLengthChange: false,
      conditionalPaging: true,
      aaSorting: [],
      dom: '<div>tip</div>'
    });

    if ($('.venue_filter').length !== 0) {
      $('.venue_filter').appendTo('div.search-field');
    }

    $(document).on('change', '.venue_id_filter', (event) => {
      const $this = $(event.currentTarget);
      const table = $('.admin-datatable').DataTable();

      table.columns().search('');
      table.column('#venue_column').search($this.val()).draw();
    });

    $(document).on('change', '.role_filter', (event) => {
      const $this = $(event.currentTarget);
      const value = $this.val();
      const table = $('.admin-datatable').DataTable();

      table.columns().search('');

      if (value === 'none') {
        table.column('#role_column').search('^$', true, false).draw();
      } else {
        table.column('#role_column').search(value).draw();
      }
    });

    $(document).on('click', '.datepicker_block', (event) => {
      const $this = $(event.currentTarget);

      $this.find('.datepicker').datepicker({
        format: 'dd/mm/yyyy'
      });

      $this.find('.datepicker').datepicker('show');
    });

    addressAutocomplete($('.address-autocomplete'));
  }

  init() {
    this.toDatepicker($('#search-datepicker'), { autoclose: true });
    this.toDatepicker($('#inline-datepicker'));

    $('#inline-datepicker').on('changeDate', () => {
      $('#booking_date').val($('#inline-datepicker').datepicker('getFormattedDate'))
    });

    $('.selectize').selectize();

    $('.smallGallery').lightSlider({
      item: 1,
      slideMargin: 0,
      loop: true,
      pager: false
    });

    const top = document.getElementById('top');
    if (top) {
      const images = $("#top").data('images');

      $(top).backstretch(images, { duration: 3000, fade: 750 });
    }
  }

  toDatepicker(element, options = {}) {
    const optionsForDatepicker = {
      orientation: "bottom auto",
      maxViewMode: "days",
      format: 'dd/mm/yyyy',
      weekStart: 1
    }

    element.datepicker(Object.assign(optionsForDatepicker, options));
  }
}

export default Home;

window.addressAutocomplete = (inputs) => {
  const optionsForAutocomplete = {
    language: 'en-GB',
    componentRestrictions: {
      country: "uk"
    },
    fields: ['name'],
  };

  inputs.each((index, input) => {
    new google.maps.places.Autocomplete(input, optionsForAutocomplete);
  });
};

window.Helper = {
  flash_message(type, messages, title, duration) {
    if (title == null) { title = "" }
    if (!duration) { duration = 5000 }

    toastr.options = {
      "closeButton": false,
      "debug": false,
      "progressBar": false,
      "positionClass": "toast-top-center",
      "onclick": null,
      "showDuration": "300",
      "hideDuration": "500",
      "timeOut": duration,
      "preventDuplicates": true,
      "showEasing": "swing",
      "hideEasing": "linear",
      "showMethod": "fadeIn",
      "hideMethod": "fadeOut",
      'body-output-type': 'trustedHtml'
    }

    const message = (Array.isArray(messages) ? messages.join("<br/>") : messages);
    toastr[type](`<span>${ message }</span>`, title);

    message.replace("<br/>", "\n");
  },

  uuid() {
    'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = (Math.random() * 16) | 0;
      const v = c === 'x' ? r : ((r & 0x3)|0x8);

      v.toString(16);
    });
  }
}
