import React, { useState, useEffect, useCallback, Fragment } from 'react';
import ApiContext from './ApiContext';
import Api from './api';
import Item from "./Item";
import { useModal } from './useModal';
import ModalCreate from "./ModalCreate";
import ModalEdit from "./ModalEdit";
import ModalRemove from "./ModalRemove";
import update from "immutability-helper";
import classNames from 'classnames';

export default ({ url }) => {
  const api = new Api(url);
  const [questions, setQuestions] = useState(null);
  const [isVenue, setIsVenue] = useState(null);
  const [isUser, setIsUser] = useState(null);

  const fetchData = useCallback(()=> {
    api.getQuestions().then(response => {
      setQuestions(response.data.questions);
      setIsVenue(response.data.isVenue);
      setIsUser(response.data.isUser);

      $("a[href='#tab-questions']").toggleClass('exclamation-warn-icon', parseInt(response.data.questionsCount) === 0);
    });
  }, []);

  const [modalCreate, showModalCreate] = useModal(ModalCreate, fetchData);
  const [modalEdit, showModalEdit] = useModal(ModalEdit, fetchData);
  const [modalRemove, showModalRemove] = useModal(ModalRemove, fetchData);

  useEffect(() => {
    fetchData()
  }, []);

  if (!questions) return null

  const moveQuestion = (dragIndex, hoverIndex) => {
    const tmpQuestions = questions.slice();
    const newQuestions = update(questions, { $splice: [[dragIndex, 1], [hoverIndex, 0, questions[dragIndex]]] })
    setQuestions(newQuestions);

    const ids = newQuestions.filter(x => x.allowReorder).map(x => x.id)

    api.reorderQuestions(ids).then((response) => {
      if (response.status !== 200) setQuestions(tmpQuestions);
    }).catch(() => setQuestions(tmpQuestions));
  };

  const moveUp = (index) => moveQuestion(index, index - 1);
  const moveDown = (index) => moveQuestion(index, index + 1);

  const renderQuestionRow = (item, index) => (
    <Item
      key={item.id}
      index={index}
      text={item.text}
      allFacilities={item.allFacilities}
      isVenue={isVenue}
      isUser={isUser}
      multipleVenues={item.multipleVenues}
      canManage={item.canManage}
      venuesCount={item.venuesCount}
      facilitiesCount={item.facilitiesCount}
      onUp={((questions[index-1] || {}).allowReorder) ? () => moveUp(index) : null }
      onDown={((questions[index+1] || {}).allowReorder) ? () => moveDown(index) : null }
      allowComment={item.allowComment}
      allowDocuments={item.allowDocuments}
      mandatoryDocuments={item.mandatoryDocuments}
      allowOptions={item.allowOptions}
      allowQuestionItems={item.allowQuestionItems}
      allowYesNoOptions={item.allowYesNoOptions}
      questionItems={item.questionItems}
      questionDocument={item.questionDocument}
      onRemove={() => showModalRemove({ id: item.id })}
      onEdit={() => showModalEdit({ id: item.id })}
      allowReorder={item.allowReorder}
      marker={item.marker}
      isChild={item.parentQuestionId}
    />
  );

  const renderAddQuestionButton = (
    <a className={classNames(["btn btn-primary"])} style={{ marginTop: 5 }} onClick={showModalCreate}>
      Add question
    </a>
  );

  return (
    <ApiContext.Provider value={api}>
      {modalCreate}
      {modalEdit}
      {modalRemove}

      <div className='panel panel-default'>
        <div className="panel-heading">
          <h3 className="pull-left">Questions</h3>
          <div className="pull-right">{renderAddQuestionButton}</div>
          {isVenue && <h3 className="pull-right question-facility-venue">Facilities</h3>}
          {isUser && <h3 className="pull-right question-facility-venue">Venues</h3>}
          <div className="clearfix"/>
        </div>

        <div className='panel-body'>
          <div className='questions-list'>
            {questions.map((item, index) => {
              return (
                <Fragment key={index}>
                  {renderQuestionRow(item, index)}
                  {item.subQuestions && item.subQuestions.map((child) => renderQuestionRow(child, index))}
                </Fragment>
              )
            })}
          </div>
        </div>
      </div>
    </ApiContext.Provider>
  )
}