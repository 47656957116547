import React, { useState } from 'react'
import CheckboxOption from "./CheckboxOption";
import QuestionItem from "./QuestionItem";
import update from 'immutability-helper';
import { uuid } from '../uuid';
import ErrorMessage from "./ErrorMessage";
import MultiSelect from "./MultiSelectOptions";

export default ({ label,
                  data,
                  name,
                  enabled,
                  setEnabled,
                  hasChild,
                  isDisabled,
                  onChange,
                  errors }) => {
  const [questionItems, setQuestionItems] = useState(data);

  const handleNewQuestionItems = (items) => {
    setQuestionItems(items);
    onChange(items);
  }

  const addItem = () => {
    let newQuestionItems = questionItems.slice();

    newQuestionItems.push({ id: uuid(), title: '', type: 'card', isNew: true });

    handleNewQuestionItems(newQuestionItems);
  }

  const deleteItem = (index) => {
    let newQuestionItems = questionItems.slice();
    const questionItem = questionItems[index];

    if (questionItem.new) {
      newQuestionItems.splice(index, 1);
    } else {
      questionItem.deleted = '1';
      newQuestionItems[index] = questionItem;
    }

    handleNewQuestionItems(newQuestionItems);
  }

  const moveQuestionItem = (dragIndex, hoverIndex) => {
    const newQuestions = update(questionItems, { $splice: [[dragIndex, 1], [hoverIndex, 0, questionItems[dragIndex]]] })

    handleNewQuestionItems(newQuestions);
  };

  const moveUp = (index) => moveQuestionItem(index, index - 1);
  const moveDown = (index) => moveQuestionItem(index, index + 1);

  const updateTitle = (index, title) => {
    let newQuestionItems = questionItems.slice();

    newQuestionItems[index].title = title;

    if (title.replaceAll(/\s/g,'') === '') newQuestionItems[index].triggerSubQuestion = false;

    handleNewQuestionItems(newQuestionItems);
  }

  return (
    <>
      <div className='form-group checkbox'>
        <CheckboxOption
          label={label}
          name={`${name}[allow_question_items]`}
          onChange={(e) => setEnabled(e.target.checked)}
          checked={enabled}
          isDisabled={isDisabled || hasChild}
        />

        <ErrorMessage errors={((errors || {})['allow_question_items'] || [])}/>

        {enabled &&
        <div className='question-items'>
          {questionItems.map((questionItem, index) =>
            <QuestionItem
              name={`${name}[question_items_attributes][]`}
              key={questionItem.id}
              index={index}
              id={questionItem.id}
              title={questionItem.title}
              deleted={questionItem.deleted}
              isNew={questionItem.isNew}
              onDelete={() => deleteItem(index)}
              onUp={questionItems[index-1] ? () => moveUp(index) : null }
              onDown={questionItems[index+1] ? () => moveDown(index) : null }
              onChangeTitle={updateTitle}
              triggerQuestion={questionItem.triggerSubQuestion}
            />
          )}
          <div>
            <ErrorMessage className='question-items-error' errors={((errors || {})['question_items'] || [])}/>
            <ErrorMessage className='question-items-error' prefix="Item title" errors={((errors || {})['question_items.title'] || [])}/>
            <a className="btn btn-primary btn-xs" onClick={addItem}>Add option</a>
          </div>
        </div>
        }
      </div>
    </>
  )
}
