import React, { useState, useEffect, useRef, useMemo } from 'react';
import AnimateHeight from 'react-animate-height';
import { Button } from 'react-bootstrap';
import Modal from 'src/components/Modal';
import Error from 'src/components/Form/Error';
import DatePicker from "src/components/DateInput";
import MultiSelect from "src/components/MultiSelect";
import InfoIcon from "src/components/InfoIcon";

import axios from "axios";
import moment from "moment";

import { processingModal } from "src/nextgen/helpers/processing_modal";

export default ({ venueId }) => {
  const [messageHeight, setMessageHeight] = useState(0);
  const [errors, setErrors] = useState({});
  const [members, setMembers] = useState([]);
  const [show, setShow] = useState(true);
  const [membersReady, setmembersReady] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [inProgress, setInProgress] = useState(false);

  const messageRef = useRef();
  const fileRef = useRef();

  useEffect(() => {
    const onlineMemberRecipients = axios.get(`/conversations/online_member_recipients.json`);

    axios
      .all([onlineMemberRecipients])
      .then(axios.spread(function(res) {
        setMembers(res.data.recipients);
        setSelectedMembers(res.data.recipients);
        setmembersReady(true);
      }));
  }, []);

  useEffect(() => processingModal.toggle(inProgress), [inProgress]);

  const ready = useMemo(() => (membersReady), [membersReady])
  const enabledSendButton = useMemo(() => {
    return selectedMembers.length > 0 && ready && !inProgress
  }, [ready, selectedMembers, inProgress]);

  const sendMessage = () => {
    setErrors({});
    setInProgress(true);

    const formData = new FormData();

    selectedMembers.forEach(({ id }, index) => {
      formData.append(`multi_message_online_members_attributes[${index}][online_member_id]`, id);
    });

    formData.append('message', messageRef.current.value);
    formData.append('file', fileRef.current.files[0]);
    formData.append('venue_id', venueId);

    axios.post(`/admin/members_info/send_multi_message`, formData).then(response => {
      setShow(false);
      setInProgress(false);
      Helper.flash_message('success', response.data.notice);
    }).catch(error => {
      if (error.response.status === 422) {
        setErrors(error.response.data.errors)
      } else {
        Helper.flash_message('error', 'Something went wrong')
      }
      setInProgress(false);
    });
  }

  return (
    <Modal
      title='Send message to multiple members'
      show={show}
      onHide={() => setShow(false)}
      buttons={<Button bsStyle='primary' onClick={sendMessage} disabled={!enabledSendButton}>Send</Button>}
      backdrop={true}
      className="modal-width"
    >
      <form className='multi_message_form'>
        <div className="form-group">
          <MultiSelect
            options={members}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            showSelectedNumber={false}
            value={selectedMembers}
            onChange={setSelectedMembers}
            isDisabled={!membersReady}
            placeholder='Select recipients...'
          />
          <Error errors={errors} name='multi_message_users'/>
        </div>

        <AnimateHeight id="example-panel" duration={500} height={messageHeight}>
          <div className="bottom-gap error-filter">
            <p className="show-effect">
              The options selected do not show any customers. Please check your options and try again
            </p>
          </div>
        </AnimateHeight>

        <div className='form-group text required conversation_message'>
          <textarea
            name="conversation[message]"
            required="required"
            className="form-control"
            placeholder="Enter message"
            rows={9}
            ref={messageRef}
          />
          <Error errors={errors} name="message"/>
        </div>

        <div className="form-group text required conversation_message">
          <label htmlFor="conversation_attachment">
            Attachment:
            &nbsp;
          </label>
          <input
            type="file"
            ref={fileRef}
            name="conversation[attachment]"
            data-controller="file_uploader_validator"
          />
          <Error errors={errors} name="file"/>
        </div>
      </form>
    </Modal>
  )
}
