import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import MultipleForm from "./MultipleForm";
import axios from "axios";
import rescue from "../../../rescue";
import ErrorBoundary from "../../../ErrorBoundary";

export default ({ show, setShow, selectedItemIds, onUpdated, clearHighlight, setClearHighlight}) => {
  const [errors, setErrors] = useState({});
  const [updatingInProgress, setUpdatingInProgress] = useState(false);


  const confirmChanges = () => {
    if (updatingInProgress) return true;
    setUpdatingInProgress(true);

    const formData = {ids: selectedItemIds}

    axios
      .put(`/admin/staff_items/clear_highlight.json`, formData)
      .then((response) => {
        setShow(false);
        setClearHighlight(false);
        onUpdated();
        setTimeout(() => setUpdatingInProgress(false), 150)
      })
      .catch((error) => {
        setUpdatingInProgress(false);
        rescue(error, setErrors)
      });
  }

  return (
    <ErrorBoundary>
      <Modal show={show} onHide={() => setShow(false)} dialogClassName="modal-xm">
        <Modal.Header closeButton>
          <Modal.Title>Clear Highlighting</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure?
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShow(false)}>Close</Button>
          <Button bsStyle="primary" disabled={updatingInProgress} onClick={confirmChanges}>Confirm</Button>
        </Modal.Footer>
      </Modal>
    </ErrorBoundary>
  );
}