import axios from 'axios';

window.OfflineSchedulesClass = function() {
  function show(url) {
    const modal = $('#modal_offline_payment')

    axios.get(url).then((response) => {
      modal.find('.modal-content').html(response.data);
      modal.modal('show');

      $('#inline-datepicker').datepicker({ weekStart: 1, format: 'dd/mm/yyyy' });

      modal.find('input[type="submit"]').on('click', (e) => {
        e.preventDefault()

        const form = modal.find('form');
        const date = $('#inline-datepicker').datepicker('getFormattedDate');
        const value = $('#no_due_date_checkbox').is(':checked') ? null : date;

        $('#offline_schedule_due_date').val(value);

        $.ajax({
          type: "PUT",
          url: form.attr('action'),
          async: false,
          data: form.serialize(),
          success: () => {
            $('body').removeClass('modal-open')
            $('#modal_offline_payment').modal('hide')
            window.loadOrderTab($("a[data-target='#payments_info']"), true, true)
            window.reloadOrderHeader();
          },
          error: () => Helper.flash_message('error', 'Something went wrong')
        });
      });
    })
  }

  return { show: show }
}

window.OfflineSchedules = OfflineSchedulesClass();
