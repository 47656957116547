(function() {
  $(document).on('click', '#create_closed_hours', function() {
    $.ajax({
      url: '/admin/booking_closed_hours',
      dataType: 'json',
      data: { booking_hour_id: $(this).data('booking-hour-id') },
      type: 'POST',
      success: function() {
        'ok'
      }
    });
  });

  $(document).on('click', '.btn-delete-close-hour', function() {
    $(this).parent().parent().find('#destroy_closed_hour').val('1')
  });
}).call(this);
