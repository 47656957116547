$(document).ready(function() {
  (function() {
    const $admin_orders_modal = $('#messenger_modal')

    const showModal = function(link) {
      $.get(link, function(data) {
        $admin_orders_modal.find('.modal-content').empty().append(data);
        $admin_orders_modal.find('.modal-dialog').css('width', '');

        const form = $admin_orders_modal.find('form');

        if (form.length > 0) {
          if (form.data('modal-width') !== void 0) {
            $admin_orders_modal.find('.modal-dialog').css('width', form.data('modal-width'));
          }
        }

        $admin_orders_modal.modal('show');
      });
    };

    $(document).on('click', '.dropdown-menu li a.open_send_message_modal', function(e) {
      e.preventDefault();
      if ($(this).parent('li').hasClass('disabled')) return;
      showModal($(this).attr('href'));
    });

    function removeErrors() {
      $('#new_conversation').find('span.text-danger').remove();
    }

    function addErrors(errors) {
      removeErrors();

      _.each(errors, (value, key) => {
        console.log(value, key)
        const span = `<span class="text-danger">${value.join(', ')}</span>`;
        $('#conversation_' + key).closest('.form-group').append(span)
      });
    }

    function loadMessages($btn, url) {
      $.ajax({
        url: url,
        success: function(data) {
          if ($btn) { $btn.attr('disabled', false) }
          $('.messenger-messages').empty().replaceWith(data);
          $('img[alt="ViewBooking"]').hide();
        }
      });
    }

    $(document).on('click', '#send_message', function(e) {
      e.preventDefault();
      removeErrors();

      const $btn = $(this);
      const self = $(this);
      const form = self.closest('form');

      if ($btn.attr('disabled') !== 'disabled') {
        $btn.attr('disabled', true);
        form.find("input[type=text], input[type=file], textarea").prop('readonly', true);

        $.ajax({
          url: form.attr('action'),
          type: 'POST',
          data: new FormData($(form)[0]),
          async: false,
          cache: false,
          contentType: false,
          processData: false,
          success: () => {
            form.find("input[type=text], input[type=file], textarea").val("");
            loadMessages($btn, $('#send_message_form_url').data('url'))
            form.find("input[type=text], input[type=file], textarea").prop('readonly', false);
          },
          error: function(data) {
            $btn.attr('disabled', false);
            form.find("input[type=text], input[type=file], textarea").prop('readonly', false);
            addErrors(data.responseJSON.errors);
          }
        });
      }
    });

    $(document).on('click', '.messenger-pagination .page-link', function(e) {
      e.preventDefault();
      loadMessages(null, $(this).attr('href') + '&body=true')
    });

  }).call(this);
});
