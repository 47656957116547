class Venue {
  constructor() {
    this.$modalElement = $('#standalone-message-modal');
  }

  start() {
    $(document).on('click', '#standalone-message-btn, #standalone-message-button', (event) => {
      this.$modalElement.modal('show');
      $('form', this.$modalElement).data('serialized', $('form', this.$modalElement).serialize());
      event.preventDefault();
      event.stopPropagation();
    });

    if (this.$modalElement.length > 0) {
      this.$modalElement.on('show.bs.modal', () => {
        this.$modalElement.find('form')[0].reset();
        this.$modalElement.find('.settings-confirmation').remove();
        this.$modalElement.find('.modal-header, .modal-body, .modal-footer').show();

        $.ajax({
          method: 'GET',
          url: this.$modalElement.data('url'),
          dataType: 'json',

          success: (data) => {
            $('#standalone_message_enabled').prop('checked', ~~data.standalone_message_enabled);
            $('#venue_standalone_message').val(data.standalone_message);
          }
        });
      });

      this.$modalElement.find('form')
        .on('ajax:success', (event, data) => {
          if (data.unchanged) {
            this.$modalElement.modal('hide');
            return;
          }

          $('#standalone-message-button input').prop('checked', data.status);

          if ($('#btn-save').data('reload') !== 'modal_confirmation') {
            this.$modalElement.modal('hide');

            Helper.flash_message('success', 'Successfully updated!');
          }
        })
        .on('ajax:error', () => {
          this.$modalElement.modal('hide');

          Helper.flash_message('error', 'Something went wrong!');
        });

      this.$modalElement.find('.btn-primary')
        .on('confirm', (event, data) => {
          let changed = $('form', this.$modalElement).serialize() != $('form', this.$modalElement).data('serialized')

          if (!changed) {
            this.$modalElement.modal('hide');
          }

          return changed;
        });
    }

    if ($('.admin_venues_show, .admin_facilities_show').length > 0) {
      $(document).on('click', '.disable-on-click', (event) => {
        $(event.currentTarget).prop('disabled', true);
      });
    }
  }
}

export default Venue;
