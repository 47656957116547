class OfflinePayments {
  start() {
    if ($('.admin_offline_payments_edit, .admin_offline_payments_new, .admin_offline_payments_create').length > 0) {
      $('.datepicker').datepicker({
        format: 'D, dd M yyyy',
        autoclose: true
      })
    }
  }
}

export default OfflinePayments;