import { Controller } from "@hotwired/stimulus";

class ToggleInputsController extends Controller {
  static targets = ['toggleButton'];

  connect() {
    const currentState = this.toggleButtonTarget.checked;

    this.toggleableInputs().forEach((input) => { input.disabled = !currentState });
  }

  toggle(event) {
    const toggleInputs = JSON.parse(event.target.checked);
    const inputs = this.toggleableInputs();
    inputs.forEach(input => {  input.disabled = !toggleInputs });
  }

  toggleableInputs() {
    return this.element.querySelectorAll('input[data-toggleable="true"]');
  }
}

export default ToggleInputsController;
