import React, { useEffect, useState } from "react";
import DateInput from "src/components/DateInput";

const DAYS = [
  { name: 'Mon', id: 1 },
  { name: 'Tue', id: 2 },
  { name: 'Wed', id: 3 },
  { name: 'Thu', id: 4 },
  { name: 'Fri', id: 5 },
  { name: 'Sat', id: 6 },
  { name: 'Sun', id: 7 }
]

export default (props) => {
  const { index, eventDate, setEventDate } = props;
  const [value, onChange] = useState(new Date());
  const [endCondition, setEndCondition] = useState('occurrences');
  const [selectedDays, setSelectedDays] = useState([moment().day()]);
  const maxEndConditionDate = new Date(moment().add(2, 'years'));

  useEffect(() => {
      const weekDay = moment(eventDate).day() === 0 ? 7 : moment(eventDate).day();
      setSelectedDays([weekDay]);
    },
    [eventDate]
  )

  const toggleSelectedDay = (dayId) => {
    let newSelectedDays;

    if (selectedDays.includes(dayId)) {
      newSelectedDays = selectedDays.filter(x => x !== dayId);
    } else {
      newSelectedDays = selectedDays.concat([dayId]);
    }

    setSelectedDays(newSelectedDays);
  }

  return (
    <div>
      <div className="form-inline vert-offset-bottom-10">
        <span className="color-booking-title">Repeat shift every</span>
        <div className="form-group select optional">
          <select defaultValue={'1'}
                  name={`staff_item[${index}][repeat_every]`}
                  className="form-control select optional">
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
          </select>
        </div>
        <span className="color-booking-title">week(s)</span>
      </div>

      <div className='row'>
        <div className="col-md-3">
          <div className="booking-drop-calendar">
            <div className="form-group select optional">
              <label>
                <b>Start Date</b>
              </label>

              <DateInput
                onChange={setEventDate}
                value={eventDate}
                className='form-control date-input text-left'
              />
            </div>
          </div>
        </div>

        <div className="col-md-9">
          <div className='form-inline vert-offset-bottom-10'>
            <div className="radio occurrences">
              <label>
                <input
                  type="radio"
                  value="occurrences"
                  checked={endCondition === 'occurrences'}
                  name={`staff_item[${index}][end_condition]`}
                  onChange={() => setEndCondition('occurrences')}
                />

                <span className="end-title">End after:</span>

                <div className="form-group string optional">
                  <input className="form-control string optional"
                         style={{ width: 60 }}
                         type="text"
                         disabled={endCondition !== 'occurrences'}
                         defaultValue="10"
                         name={`staff_item[${index}][occurrences]`}/>
                </div>
                <span className="end-title-occur">occurrences</span>
              </label>
            </div>
          </div>

          <div className='form-inline vert-offset-bottom-10'>
            <div className="radio">
              <label>
                <input
                  type="radio"
                  value="date"
                  checked={endCondition === 'end_date'}
                  name={`staff_item[${index}][end_condition]`}
                  onChange={() => setEndCondition('end_date')}
                />

                <span className="end-title">End by:</span>

                <div className="form-group string optional">
                  <DateInput
                    onChange={onChange}
                    value={value}
                    disabled={endCondition !== 'end_date'}
                    name={`staff_item[${index}][end_date]`}
                    maxDate={maxEndConditionDate}
                    className='form-control date-input text-left'
                  />
                </div>
              </label>
            </div>
          </div>
        </div>
        <div className="clearfix"/>
      </div>

      <div className="form-inline vert-offset-bottom-10">
        <div className="form-group check_boxes optional">on</div>
        <div className="form-group check_boxes optional week_days">
          {DAYS.map(day =>
            <span key={day.id} className="checkbox">
                <input className="check_boxes optional"
                       type="checkbox"
                       value={day.id}
                       checked={selectedDays.includes(day.id)}
                       onChange={() => toggleSelectedDay(day.id)}
                       name={`staff_item[${index}][week_days][]`}/>
                <span className='day-name'>{day.name}</span>
            </span>
          )}
        </div>
      </div>
    </div>
  )
}