import { Controller } from "@hotwired/stimulus";
import axios from "axios";
import NeoModal from "src/NeoModal";

class AttachmentsController extends Controller {
  static targets = ['action']

  toggle_link_to_activity_report(event) {
    event.preventDefault();

    const toggleUrl = '/admin/attachments/' + event.params.id + '/toggle_link_to_activity_report';

    axios.post(toggleUrl).then((response) => {
      this.actionTarget.innerHTML = response.data.linked ? 'Unlink from activity report' : 'Link to activity report';
    })
  };

  remove_attachment(e) {
    e.preventDefault();

    const url = this.element.dataset.url;

    axios.get(url).then((response) => {
      const modal = NeoModal.add(response.data);

      modal.addEventListener('neo_modal:success', (data) => {
        const id = data.detail?.response?.data?.id
        const elements = Array.from(document.getElementsByClassName(`attachment_${id}`));

        elements.forEach((element) => element.remove());

        $(modal).modal('hide')
      });
    });
  }
}

export default AttachmentsController;
