window.adminFacilitiesForm = function() {
  function checkVenueTimePeriod() {
    const venueTimePeriod = $("input[name='facility[venue_time_period]']:checked").val();

    $('.facility_period_minutes').toggle(venueTimePeriod !== 'true');
  }

  function checkMinimumBookingRule() {
    const minimumTimeRuleEnabled = $("input[name='facility[minimum_booking_period_rule]']:checked").val();

    $('.minimum_booking_period_rule_radio').toggle(minimumTimeRuleEnabled !== 'false');
  }

  $("input[name='facility[venue_time_period]']").on('change', function() {
    checkVenueTimePeriod();
  });

  $("input[name='facility[minimum_booking_period_rule]']").on('change', function() {
    checkMinimumBookingRule();
  })

  checkVenueTimePeriod();
  checkMinimumBookingRule();
}
