import { useFetch } from "../../Api/UseFetch";
import { useEffect, useMemo, useState } from "react";
import queryString from 'query-string';
import {FILTERS} from "../filters";

const defaultOptions = [
  { title: 'Current prices', value: false },
  { title: 'Future prices', value: true }
];

function prepareUrl(item, selectedOptions, filtersData) {
  if (filtersData.venue?.pending || filtersData.multi_facilities?.pending) return null;
  
  const path = '/admin/reports/future_prices.json';
  const itemKey = item.key;
  const venueId = (selectedOptions || {}).venueId;
  const multiFacilityIds = (selectedOptions || {}).multiFacilityIds;

  const queryParams = {}
  if (itemKey) queryParams['item_key'] = item.key;
  if (venueId) queryParams['venue_id'] = venueId;
  if (multiFacilityIds) queryParams['multi_facility_ids[]'] = multiFacilityIds;

  return `${path}?${queryString.stringify(queryParams)}`;
}

export const useFuturePrices = (item, options, selectedOptions, filtersData) => {
  const url = prepareUrl(item, selectedOptions, filtersData);
  const { pending, result, error } = useFetch(url);
  const [futurePricesOptionInitialized, setFuturePricesOptionInitialized] = useState(false);
  const useFuturePricesParam = JSON.parse(new URLSearchParams(location.search).get('future_price'));
  const optionIndex = (useFuturePricesParam && !futurePricesOptionInitialized) ? 1 :0;
  const [option, setOption] = useState(defaultOptions[optionIndex]);

  // Filter options
  const priceOptions = useMemo(() => {
    if (pending) return [];
    if (error) return [];
    if (!result.futurePricesExist) return [];

    return defaultOptions;
  }, [pending]);

  useEffect(() => {
    if (!pending && !error) {
      if (futurePricesOptionInitialized) {
        setOption(defaultOptions[0]);
      } else {
        setFuturePricesOptionInitialized(true);
      }
    }
  }, [pending]);

  return {
    priceOptions: priceOptions,
    priceOption: option,
    setPriceOption: setOption,
    pending: pending,
  }
};