import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import DropDown from "./DropDown";
import moment from "moment";

const offlineStatuses = [
  { title: 'All', value: 'all' },
  { title: 'No due date', value: 'no_due_date' },
  { title: 'Not yet due', value: 'not_yet_due' },
  { title: 'Overdue', value: 'overdue' },
  { title: 'Overpaid', value: 'overpaid' },
  { title: 'Paid', value: 'paid' }
];

export default ({ onChanged }) => {
  const [offlineStatus, setOfflineStatus] = useState(offlineStatuses[0]);

  useEffect(() => {
    const params = offlineStatus ? { offlineStatus: offlineStatus.value } : null;

    onChanged({ value: offlineStatus, pending: false, params })
  }, [offlineStatus])

  return (
    <div className={classNames(['filter-block filter-report-types'])}>
      <label htmlFor="filter-report-types">Status:</label>
      <div className='input-box input-box-react-select'>
        <DropDown
          value={offlineStatus}
          options={offlineStatuses}
          onChange={setOfflineStatus}
          getOptionLabel={opt => opt.title}
          getOptionValue={opt => opt.value}
        />
      </div>
    </div>
  )
};
