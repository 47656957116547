import { useState, useEffect } from "react";

export const useFetch = (url) => {
  const [processedUrl, setProcessedUrl] = useState(null);
  const [pending, setPending] = useState(true);
  const [result, setResult] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    const FetchData = async () => {
      if (url) {
        setPending(true);

        try {
          const res = await fetch(url);
          const json = await res.json();
          setResult(json);
          setPending(false);
          setProcessedUrl(url);
        } catch (error) {
          setError(error);
          setPending(false);
          setProcessedUrl(url);
        }
      }
    };

    FetchData();
  }, [url]);

  return { pending, error, result };
};