import React from 'react';

export default ({ periods, startTime, endTime, onChangeStartTime, onChangeEndTime }) => {
  let startPeriods = [];
  let endPeriods = [];

  if (Array.isArray(periods.list) && periods.list.length > 0) {
    startPeriods = periods.list;
    endPeriods = periods.list;
  }

  const dropdownsDisabled =
    periods?.booking_day_rate_enabled ||
    startPeriods.length === 0 ||
    endPeriods.length === 0;

  return (
    <div className='periods'>
      <div className="form-group select optional order_booking_start_time">
        <label className="control-label select optional">Start</label>
        <select className="form-control select optional start_time"
                disabled={dropdownsDisabled}
                value={startTime || ''}
                onChange={(e) => onChangeStartTime(e.target.value)}>
          {startPeriods.map((item, i) => <option key={i} value={item.start_time}>{item.start_time}</option>)}
        </select>
      </div>
      <div className="hyphen">—</div>
      <div className="form-group select optional order_booking_end_time">
        <label className="control-label select optional">End</label>
        <select className="form-control select optional end_time"
                value={endTime || ''}
                disabled={dropdownsDisabled}
                onChange={(e) => onChangeEndTime(e.target.value)}>
          {endPeriods.map((item, i) => <option key={i} value={item.end_time}>{item.end_time}</option>)}
        </select>
      </div>
    </div>
  )
}