import { Controller } from "@hotwired/stimulus"
import axios from 'axios';
import NeoModal from "src/NeoModal";
import { smartRedirect } from "src/nextgen/smartRedirect";

class ConversationButtonsController extends Controller {
  connect() {
    const hiddenNewMessageInput = document.querySelector('input#is_new_message');

    if (hiddenNewMessageInput.value === 'new') {
      this.element.click();
    }
  }

  send(e) {
    e.preventDefault();

    const button = e.target;
    const { url } = e.target.dataset;

    if (button.disabled) return true;
    button.disabled = true;

    axios.get(url).then((response) => {
      const modal = NeoModal.add(response.data, { callback: () => {
        setTimeout(() => { button.disabled = false }, 200)
      } });

      modal.addEventListener('neo_modal:success', () => {
        $(modal).modal('hide');
      });
    }).catch((e) => {
      if (e.response.status === 405) {
        window.location.reload();
      } else {
        button.disabled = false;
      }
    })
  }

  redirectTo(e) {
    const { url } = e.target.dataset;

    smartRedirect(url);
  }
}

export default ConversationButtonsController;
