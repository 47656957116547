const StaffRotaView = (function() {
  function init(refreshCalendar) {
    $('.staff-rota-view').on('change', function(e) {
      $('.hidden-staff-rota').val($(this).val());
      refreshCalendar();
    });
  }

  return {
    init: init,
  };
})();

export default StaffRotaView;
