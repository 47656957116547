import React, { useCallback, useEffect, useState } from 'react';
import Modal from "../Modal/Index";
import { useModal } from '../useModal';
import axios from "axios";
import { withRouter } from 'react-router-dom';
import Header from './Header';
import Table from './Table';

function Index({ location, history }) {
  const [reportsLoaded, setReportsLoaded] = useState(false);
  const [reportSchedules, setReportSchedules] = useState([]);
  const [currentUserIsRo, setCurrentUserIsRo] = useState([]);

  const updateReportSchedules = useCallback(()=> {
    axios
      .get('/admin/reports/schedules.json')
      .then((response) => {
        const { reportSchedules } = response.data;

        setReportSchedules(reportSchedules);
        setReportsLoaded(true);
      });
  }, []);

  const [modal, showModal] = useModal(Modal, updateReportSchedules);

  const handleDelete = (scheduleId) => {
    if (confirm('Are you sure?')) {
      axios
        .delete(`/admin/reports/schedules/${scheduleId}.json`)
        .then((response) => updateReportSchedules());
    }
  };

  useEffect(() => {
    axios
      .get('/admin/reports/schedules.json')
      .then((response) => {
        const { currentUserIsRo } = response.data;
        setCurrentUserIsRo(currentUserIsRo);
      });

    updateReportSchedules();
  }, [])


  return (
    <>
      {modal}

      <Header reportsLoaded={reportsLoaded}
              reportSchedules={reportSchedules}
              currentUserIsRo={currentUserIsRo}
              showModalCreate={showModal}/>

      <Table reportSchedules={reportSchedules}
             showModalEdit={showModal}
             handleDelete={handleDelete}/>
    </>
  )
}

export default withRouter(Index);
