import axios from 'axios';
import SmartyForm from '../../helpers/smarty_form';

$(document).on('click', '#open_user_to_action_reminder_modal', (e) => {
  e.preventDefault();

  axios
    .get($(e.target).data('url'))
    .then(response => {
      var modal = $('#user_to_action_reminder_modal');
      modal.find('.modal-content').empty().append(response.data);
      modal.modal('show');
    })
});

$(document).on('submit', '#edit_user_to_action_reminder', (e) => {
  e.preventDefault();

  new SmartyForm().process({
    form: $(e.target),
    onSuccess: () => {
      $('#user_to_action_reminder_modal').modal('hide');
      Helper.flash_message('success', 'Message sent');
    },
    onError: () => { processingModal.hide() }
  });
});
