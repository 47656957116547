import { Controller } from "@hotwired/stimulus";
import axios from "axios";

class ApiTokensController extends Controller {
  static targets = ['apiAccessSection']
  static classes = ['loading']

  generate(event) {
    event.target.classList.add(this.loadingClass);

    axios.post(event.target.dataset.url).then((response) => {
      this.apiAccessSectionTarget.innerHTML = response.data;
    })
  };

  regenerate(event) {
    let answer = confirm('Anything using the existing token will no longer be able to access the API. Are you sure?');

    if (answer === true) {
      event.target.classList.add(this.loadingClass);

      axios.put(event.target.dataset.url).then((response) => {
        this.apiAccessSectionTarget.innerHTML = response.data;
      })
    }
  };

  delete(event) {
    let answer = confirm('Anything using the token will no longer be able to access the API. Are you sure?');

    if (answer == true) {
      event.target.classList.add(this.loadingClass);

      axios.delete(event.target.dataset.url).then((response) => {
        this.apiAccessSectionTarget.innerHTML = response.data;
      })
    }
  };
}

export default ApiTokensController;
