import { Controller } from "@hotwired/stimulus"
import linkifyElement from "linkify-element";

class LinkifyController extends Controller {
  connect() {
    linkifyElement(this.element);
  }
}

export default LinkifyController;
