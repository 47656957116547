import { showBootstrapAlert } from './BootstrapAlert';

$(document).on('click', '.send_modification_request', function(e) {
  e.preventDefault();

  const { issue, internal } = $(this).data()

  if (issue) {
    showBootstrapAlert('', issue)
  } else {
    $('#confirm-modification').modal('show');
  }
});
