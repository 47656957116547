import React from "react";

export default ({ errors }) => {
  return (
    <div>
      {errors.map((error, index) =>
        <div key={index} className='has-error'>
          <span className='help-block'>
            {error}
          </span>
        </div>
      )}
    </div>
  )
};