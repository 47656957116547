(function() {
  $(function() {
    const header = $('header');
    const footer = $('footer');
    const pageWrapper = $('#page-wrapper');
    const headerHeight = header.height();
    const footerHeight = footer.height() + 10;

    header.css('marginTop', (0 - headerHeight) + "px");
    footer.css('marginBottom', (0 - footerHeight) + "px");

    pageWrapper.css('padding', headerHeight + "px 0 " + footerHeight + "px 0");
  });

}).call(this);