import React, {useState} from 'react'
import ErrorMessage from "./ErrorMessage";
import MultiSelect from "./MultiSelectOptions";

export default ({ questionItems, onChange }) => {
  const isEmptyTitle = (title) => (title.replaceAll(/\s/g,'') === '');

  let options = questionItems.filter((q) => !isEmptyTitle(q.title) && !q.deleted);
  let selectedOptions = options.filter((option) => option.triggerSubQuestion);

  const handleSelectOptions = (items) => {
    let newQuestionItems = questionItems.slice();

    newQuestionItems.map((item) => item.triggerSubQuestion = items.indexOf(item) !== -1);

    onChange(newQuestionItems);
  };

  return (
    <MultiSelect
      value={selectedOptions}
      options={options}
      onChange={handleSelectOptions}
      getOptionLabel={opt => opt.title}
      getOptionValue={opt => opt.id}
      isOptionDisabled={() => selectedOptions.length >= (options.length - 1)}
    />
  )
};
