import { Controller } from "@hotwired/stimulus"

class FlashController extends Controller {
  connect() {
    const { notice, error } = this.element.dataset;

    if (notice) Helper.flash_message('success', notice);
    if (error) Helper.flash_message('error', error);
  }
}

export default FlashController;