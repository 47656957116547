import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import MultiSelectDropdown from "./MultiSelectDropdown";

export default ({ item, options, onChanged }) => {
  const { days, selectedDays, setDays } = useDays(item, options);

  useEffect(() => {
    const params = selectedDays ? { days: selectedDays.map(day => day.id) } : [];
    onChanged({ value: selectedDays, params })
  }, [selectedDays]);

  return (
    <div className={classNames(['filter-block filter-days'])}>
      <label htmlFor="filter-days">Days:</label>
      <div className='input-box input-box-react-select'>
        <MultiSelectDropdown
          value={selectedDays}
          isLoading={false}
          isDisabled={false}
          options={days}
          onChange={setDays}
          getOptionLabel={opt => opt.internalReference}
          getOptionValue={opt => opt.id}
        />
      </div>
    </div>
  )
};

export const useDays = (item, options) => {
  const dayNames = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  const days = dayNames.map((day, index) => {
    return { id: (index+1)%7, internalReference: day };
  });
  const [selectedDays, setDays] = useState([]);

  return {
    days,
    selectedDays,
    setDays
  }
};
