import { Controller } from "@hotwired/stimulus"
import axios from 'axios';

class SoftDeletedFacilityController extends Controller {
  toggleVisibility(e) {
    const form = e.target.form;
    const formData = new FormData(form);

    axios
      .put(form.action, formData)
      .catch((e) => Helper.flash_message('error', 'Something went wrong'));
  }
}

export default SoftDeletedFacilityController;
