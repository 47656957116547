class OrdersDataTable {
  defaultParams(ordersURL) {
    return {
      order: [[0, 'desc']],
      processing: true,
      serverSide: true,
      searching: false,
      pageLength: 30,
      lengthChange: false,
      ajax: ordersURL,
      pagingType: 'full_numbers',
      fnInitComplete: (oSettings) => {
        assignDatatableAutoscroll(oSettings)
      },
      fnDrawCallback: (oSettings) => {
        const $tableWrapper = $(oSettings.nTableWrapper);
        const $table = $tableWrapper.find('table');
        const titles = _.map($table.find('thead th'), (x, y) => x.innerText);
        const show = oSettings._iDisplayLength <= oSettings.fnRecordsDisplay();

        $tableWrapper.find('.dataTables_paginate').toggle(show);
        $tableWrapper.find('.dataTables_info').toggle(show);

        _.each($table.find('tr'), (row) => {
          _.each($(row).find('td'), (cell, index) => {
            $(cell).attr('data-title', titles[index]);
          });
        });
      }
    };
  }
}

export default OrdersDataTable;