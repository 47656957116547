import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['pointer', 'description', 'child'];

  static values = {
    expandedClass: 'fa-angle-up',
    collapsedClass: 'fa-angle-down',
    hiddenBlockClass: 'hidden-block'
  }

  expanded = false;

  toggle_expand_all() {
    if (this.expanded) {
      this.setCollapsed();
    } else {
      this.setExpanded();
    }

    this.childTargets.forEach(child => {
      this.dispatch(this.expanded ? "expanded_all" : "collapsed_all", { target: child })
    })
  }

  pointerTargetConnected(element) {
    element.classList.add(this.expanded ? this.expandedClassValue : this.collapsedClassValue);
  }

  expandedRow() {
    let expandedDescriptionsCount = 0;

    this.descriptionTargets.forEach(function(target) {
      if (!target.classList.contains('hidden-block')) expandedDescriptionsCount++;
    })

    if (expandedDescriptionsCount > 0) {
      this.setExpanded();
    } else {
      this.setCollapsed();
    }
  }

  setExpanded() {
    this.pointerTarget.classList.replace(this.collapsedClassValue, this.expandedClassValue);
    this.expanded = true;
  }

  setCollapsed() {
    this.pointerTarget.classList.replace(this.expandedClassValue, this.collapsedClassValue);
    this.expanded = false;
  }
}
