import React from "react";
import classnames from "classnames";

export default ({ name, id, isNew, title, deleted, index, onDelete,
                  onUp, onDown, onChangeTitle, triggerQuestion }) => {
  const hiddenFields = (
    <>
      <input name={`${name}[id]`} value={isNew ? '' : id } type='hidden'/>
      <input name={`${name}[list_order]`} value={index} type='hidden'/>
      <input name={`${name}[_destroy]`} value={deleted} type='hidden'/>
      <input name={`${name}[trigger_sub_question]`} value={triggerQuestion || ''} type='hidden'/>
    </>
  );

  if (deleted) return hiddenFields;

  let moveUpBtn = null;
  let moveDownBtn = null;

  if (onUp) {
    moveUpBtn = (
      <a className={classnames(['move-up', !onUp && 'disabled'])} onClick={() => onUp && onUp()}>
        <i className="fa fa-arrow-up"/>
      </a>
    )
  }

  if (onDown) {
    moveDownBtn = (
      <a className={classnames(['move-down', !onDown && 'disabled'])} onClick={() => onDown && onDown()}>
        <i className="fa fa-arrow-down"/>
      </a>
    )
  }

  return (
    <div className='question-item'>
      {hiddenFields}

      <div className='row'>
        <div className='col-lg-9 col-md-12'>
          <input
            className='title-input'
            name={`${name}[title]`}
            defaultValue={title}
            onChange={(e) => {onChangeTitle(index, e.target.value)}}
            disabled={deleted}
          />
        </div>

        <div className='col-lg-3 col-md-12'>
          <div className='action_block pull-right'>
            {moveUpBtn}
            {moveDownBtn}
            <a onClick={onDelete}>Delete</a>
          </div>
        </div>
      </div>
    </div>
  );
};
