class Checkout {
  constructor() { }

  start() {
    $("input[name='business_information[different_invoice_details]']").on('change', (event) => {
      const $this = $(event.currentTarget);

      if ($this.prop('checked')) {
        $this.attr('checked', 'checked');
        $("input[name='business_information[different_invoice_details]']:not(:checked)").attr('checked', false);
      }

      return this.disableFormFields();
    });

    this.disableFormFields();
  }

  init() {
    if ($('.checkouts_terms_and_conditions_show').length > 0) {
      const checkbox = $('#terms_and_conditions_confirmed');
      const error = 'Please tick to confirm that you agree to the Terms and Conditions';

      this.showError(checkbox, error);

      checkbox.change(() => {
        this.showError(checkbox, error);
      });
    }
  }

  showError(input, error) {
    let message = '';

    if (!input.prop('checked')) {
      message = error;
    }

    return input[0].setCustomValidity(message);
  }

  disableFormFields() {
    const isRadioButtonOff = ($("input[name='business_information[different_invoice_details]']:checked").val() === '0')

    $('.different_business_information').find('input, select').prop('disabled', isRadioButtonOff);
    $('.user_business_information').find('input, select').toggleClass('highlight', isRadioButtonOff);
  }
}

export default Checkout;