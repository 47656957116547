import React from 'react';
import { Button, Modal } from 'react-bootstrap';

export default ({ errors, show, setShow, title }) => (
  <Modal show={show} onHide={() => setShow(false)}>
    <Modal.Header>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {Object.keys(errors).map((key, index) => (
        <p className='text-center'>
          {errors[key].join(', ')}
        </p>
      ))}
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={() => setShow(false)}>Ok</Button>
    </Modal.Footer>
  </Modal>
);