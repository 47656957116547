import { Controller } from "@hotwired/stimulus"
import OrdersTable from "src/nextgen/admin/orders/table";

import axios from "axios";

export default class extends Controller {
  connect() {
    this.reloadDataDebounced = this.debouncedSearch();
    const ordersTable = new OrdersTable();
    ordersTable.start();
    this.ordersTable = ordersTable;
    this.statusCheckboxes = this.element.querySelectorAll('.all_none_filter input[type=checkbox]');
  }

  search() {
    this.reloadDataDebounced();
  }

  allStatuses(event) {
    event.preventDefault();
    this.changeAllFilterStatuses(true);
  }

  noneStatuses(event) {
    event.preventDefault();
    this.changeAllFilterStatuses(false);
  }

  changeAllFilterStatuses(status) {
    this.statusCheckboxes.forEach(element => element.checked = status);
    this.reloadData();
  };

  changedFilter() {
    this.reloadData();
  }

  changedVenue(event) {
    const customerSelectExists = this.element.querySelector('.filter_customer_id select') !== null;

    if (!customerSelectExists) {
      this.reloadData();
      return;
    }

    const venueId = event.target.value;

    axios
      .get(`/admin/bookings/${venueId}/customers`)
      .then(response => {
        const { data } = response;

        const items = $.map(data, (v) => {
          return '<option value=' + v[0] + '>' + v[1] + '</option>';
        });

        const el = $('#new_filter .filter_customer_id select');

        el.children().remove();
        el.append(items);

        this.reloadData();
      })
      .catch(() => this.reloadData());
  }

  changedTimePeriod(event) {
    this.reloadData();
    const checkedValue = this.element.querySelector('.filter_time_period input[type=radio]:checked').value;
    const customDates = this.element.querySelector('.custom_dates');
    customDates.classList.toggle('hidden', checkedValue !== 'custom');
  }

  reloadData() {
    this.ordersTable.prepareDataTableColumns();
    this.ordersTable.reloadData(true);
  }

  debouncedSearch() {
    return _.debounce(() => this.reloadData(), 250)
  }
}

// $(document).on('change', '.offline_order_detail_paid', (event) => {
//   const $form = $(event.currentTarget).closest('form');
//
//   $.ajax({
//     type: 'POST',
//     url: $form.attr('action'),
//     data: $form.serialize(),
//     success: () => Helper.flash_message('success', 'Successfully updated'),
//     error: () => Helper.flash_message('error', 'Failed')
//   });
// });
//
// $(document).on('click', 'input.discount_group[type=radio]', (event) => {
//   const $target = $(event.currentTarget);
//
//   $target.parent().siblings('.vat').find('input[type=checkbox]').prop('checked', $target.data('vat'));
// });

// $('#filter_statuses_internal').on('change', () => {
//   if ($('#filter_statuses_internal').prop('checked') && !$('#filter_statuses_accepted').prop('checked')) {
//     $('#filter_statuses_accepted').click();
//   }
// });