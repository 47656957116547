import React, { useState } from 'react';
import Shift from './Shift';
import BackButton from "./BackButton";
import CreateButton from "./CreateButton";
import AddShiftButton from "./AddShiftButton";

const initItems = [{}];

export default ({ onCreated, venueId, creationInProgress, setCreationInProgress }) => {
  const [items, setItems] = useState(initItems);
  const [staffRotaId, setStaffRotaId] = useState(0);
  const [errors, setErrors] = useState({});

  const handleAdd = () => {
    const newItems = items.concat({});
    setItems(newItems);
  }

  const removeItem = (index) => {
    const newItems = items.filter((_el, i) => i !== index);
    setItems(newItems);
  }

  const handleCreate = (staffRotaId) => {
    setStaffRotaId(staffRotaId)
    onCreated(staffRotaId)
  }

  return (
    <form className="simple_form staff-rota-form" action="#" method="post" id='staff-form'>
      {items.map((x, index) =>
        <Shift
          key={index}
          index={index}
          venueId={venueId}
          removeItem={() => removeItem(index)}
          size={items.length}
          errors={errors}
        />
      )}
      <input type="hidden" name='venue_id' value={venueId}/>
      <input type="hidden" name='staff_rota_id' value={staffRotaId}/>
      <div className="vert-offset-bottom-15 shift-item">
        <div className="form-inline vert-offset-bottom-10"/>
        <AddShiftButton handleAdd={handleAdd}/>
      </div>
      <div className="row vert-offset-bottom-15">
        <div className='col-md-6'>
          <BackButton venueId={venueId}/>
        </div>
        <div className='col-md-6 text-right' >
          <CreateButton
            onCreated={handleCreate}
            onError={setErrors}
            setCreationInProgress={setCreationInProgress}
            creationInProgress={creationInProgress}
          />
        </div>
      </div>
    </form>
  );
};