import React, { useEffect, useMemo, useState } from 'react';
import Popover from './TimePeriods/DropDown';
import classNames from 'classnames';
import { useFetch } from "../Api/UseFetch";
import moment from "moment";

export default ({ item, options, onChanged }) => {
  const { rangeLimit, timePeriods, timePeriod, setTimePeriod, pending } = useTimePeriods(item, options);

  useEffect(() => {
    const params = timePeriod ? {
      startDate: moment(timePeriod.startDate).format('YYYY-MM-DD'),
      endDate: moment(timePeriod.endDate).format('YYYY-MM-DD')
    } : null;

    onChanged({ value: timePeriod, pending, params })
  }, [timePeriod, pending])

  return (
    <div className={classNames(['filter-block filter-time-periods'])}>
      <label htmlFor="filter-time-periods">Period:</label>
      <div className='input-box'>
        {!pending &&
        <Popover
          rangeLimit={rangeLimit}
          onChange={setTimePeriod}
          timePeriod={timePeriod}
          setTimePeriod={setTimePeriod}
          timePeriods={timePeriods}
        />
        }
      </div>
    </div>
  )
};

export const useTimePeriods = (item, options) => {
  const [processedItem, setProcessedItem] = useState({});
  const [timePeriod, setTimePeriod] = useState(null);
  const { pending, result, error } = useFetch(`/admin/reports/time_periods.json`);

  // Status
  const processed = useMemo(() => (item || {}).key === processedItem.key, [item, processedItem]);

  // Filter options result
  const timePeriods = useMemo(() => {
    if (pending || error) return [];

    const { availableTimePeriods } = item;

    return result.timePeriods.filter(timePeriod => availableTimePeriods.includes(timePeriod.id));
  }, [pending, item, processed]);

  // Select option
  useEffect(() => {
    if (!pending && !error && !processed) {

      const { availableTimePeriods, defaultTimePeriod } = item;
      let defaultPeriod = timePeriods.filter(timePeriod => timePeriod.id == defaultTimePeriod)[0];
      let newTimePeriod = timePeriod;

      if (!timePeriod) {
        if (defaultPeriod) {
          newTimePeriod = defaultPeriod;
        } else if (options.startDate && options.endDate) {
          newTimePeriod = { startDate: moment(options.startDate), endDate: moment(options.endDate) };
        } else {
          newTimePeriod = timePeriods[1];
        }
      }

      if (moment(newTimePeriod.endDate).diff(moment(newTimePeriod.startDate), 'days') > item.rangeLimit) {
        newTimePeriod = timePeriods[1];
      }

      setTimePeriod(newTimePeriod);
      setProcessedItem(item)
    }
  }, [pending, processed]);

  const rangeLimit = useMemo(() => processedItem.rangeLimit, [processedItem]);

  return {
    timePeriods,
    timePeriod,
    setTimePeriod,
    error,
    rangeLimit,
    pending: !processed
  }
};
