window.adminVenuesFuturePrices = () => {
  const datepickerElement = $('input.datepicker');

  const valueBookingOptionsMap = {
    '0': $('input[data-custom-booking-option="true"]').toArray(),
    '1': $('input[data-custom-booking-option="false"]').toArray()
  };
  const allBookingOptions = valueBookingOptionsMap['0'].concat(valueBookingOptionsMap['1']);
  
  const initForm = () => {
    const formUtils = new FormUtils();
    const formData = formUtils.getFromLocalStorage('new_venues_future_prices_form');

    if (formData != undefined && formData != null){

      allBookingOptions.forEach((input) => {
        const nameValue = `${input.name}=${input.value}`;
        if (formData.includes(nameValue)) {
          checkCheckBoxes([input], true); 
        } else {
          checkCheckBoxes([input], false);
        }
      });
    } else {
      const allFacilities = $('input[name="venues_future_prices_form[all_facilities]"]:checked').val();

      if (allFacilities == '1') {
        checkCheckBoxes(allBookingOptions, true);
      } else {
        checkCheckBoxes(allBookingOptions, false);
      }
    };

    formUtils.clearLocalStorage('new_venues_future_prices_form');
  };

  const toggleSpecificFacilitiesSelector = () => {
    const val = $('input[name="venues_future_prices_form[all_facilities]"]:checked').val();
    $('.custom_facilities_container').toggle(val == '0');

    return val;
  };

  const allOrCustomBookingOptions = () => {
    const val = toggleSpecificFacilitiesSelector();
    uncheckSpecificBookingOptions();

    if (val == '1') {
      checkCheckBoxes(allBookingOptions, true);
    } else {
      checkCheckBoxes(allBookingOptions, false);
    }
  };

  const checkSpecificFacilitiesBookingOptions = () => {
    const checkboxes = $('input[name="venues_future_prices_form[custom_facilities][]"]:checked');
    const checkboxesValues = checkboxes.toArray().map((checkbox) => checkbox.value);

    if (checkboxesValues.length == 2) {
      checkCheckBoxes(allBookingOptions, true)
    } else if (checkboxesValues.length == 1) {
      const selectedBookingOptions = valueBookingOptionsMap[checkboxesValues[0]];
      checkCheckBoxes(allBookingOptions, false)
      checkCheckBoxes(selectedBookingOptions, true)
    } else {
      checkCheckBoxes(allBookingOptions, false)
    }
  }

  const checkCheckBoxes = (bookingOptions, value) => {
    bookingOptions.forEach((input) => {
      const facilityId = input.dataset.bookingOptionFacilityId
      const bookingOptionList = $("#facility_booking_options_list_" + facilityId);
      const facilityInput = document.querySelector(`input[data-facility-id="${facilityId}"]`)
      input.checked = value;
      const checkedInputsCount = bookingOptionList[0].querySelectorAll('input:checked').length;

      bookingOptionList[0].hidden = checkedInputsCount == 0;
      facilityInput.checked = checkedInputsCount > 0;
    })
  }

  const setupDatepicker = () => {
    datepickerElement.datepicker({
      format: 'D, dd M yyyy',
      autoclose: true,
      startDate: '+1d',
      weekStart: 1
    });
  }

  const toggleFacilityBookingOptions = (event) => {
    const checkValue = JSON.parse(event.target.checked);
    const facilityId = event.target.dataset['facilityId'];
    const bookingOptionListSelector = "#facility_booking_options_list_" + facilityId;
    const bookingOptionList = $(bookingOptionListSelector);
    const bookingOptions =  $(bookingOptionListSelector + " " + 'input');
    bookingOptions.each((_, v) => { v.checked = checkValue })
    bookingOptionList.toggle(checkValue);

    const facilitiesUnselected = Array.from($('input[name="venues_future_prices_form[all_facilities]"]:checked')).length == 0;

    if (facilitiesUnselected) {
      const facilitiesInput = $('#venues_future_prices_form_all_facilities_0');
      facilitiesInput[0].checked = true;
      $('.custom_facilities_container').toggle(true);
    }
  }

  const uncheckSpecificBookingOptions = () => {
    const checkBoxes = Array.from($('input[name="venues_future_prices_form[custom_facilities][]"]:checked'));
    checkBoxes.forEach((checkbox) => checkbox.checked = false);
  };

  const bookingOptionHandler = (event) => {
    const facilityId = event.target.dataset.bookingOptionFacilityId;
    const parentFacilityCheckbox = document.querySelector(`#venues_future_prices_form_facility_ids_${facilityId}`);
    const childBookingOptions = document.querySelectorAll(`input[data-booking-option-facility-id="${facilityId}"]`);
    const checkedChildBookingOptionsCount = Array.from(childBookingOptions).filter((bo) => bo.checked);
    parentFacilityCheckbox.checked = checkedChildBookingOptionsCount != 0
  };

  $('input[name="venues_future_prices_form[all_facilities]"]').on('click', allOrCustomBookingOptions);
  $('input[name="venues_future_prices_form[custom_facilities][]"]').on('click', checkSpecificFacilitiesBookingOptions);
  $('.facilities-for-venue input[name="venues_future_prices_form[facility_ids][]"]').on('click', toggleFacilityBookingOptions)
  $('input[name="venues_future_prices_form[booking_option_ids][]"]').on('click', bookingOptionHandler);

  initForm();
  toggleSpecificFacilitiesSelector();
  setupDatepicker();
}
