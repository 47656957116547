import { Controller } from "@hotwired/stimulus"
import axios from 'axios';

export default class extends Controller {
  static targets = ['list', 'loader'];

  connect() {
    this.show = false;
    this.billsCache = {};
    this.listCache = null;
  }

  toggleList(e) {
    const button = e.target;

    button.disabled = true;

    this.show = !this.show;

    if (this.show) {
      button.innerHTML = 'View current version';
      this.loaderTarget.classList.remove('hidden');

      const cacheNumber = e.target.dataset.cacheNumber;

      if (!this.billsCache.hasOwnProperty(cacheNumber)) {
        this.billsCache[cacheNumber] = document.getElementById('current_bill').innerHTML;
      }

      const setData = () => {
        button.disabled = false;
        this.loaderTarget.classList.add('hidden');
        this.listTarget.innerHTML = this.listCache;
        const buttonsList = this.listTarget.querySelectorAll('button');
        this.upd(buttonsList[buttonsList.length -1]);
      }

      if (this.listCache) {
        setData();
      } else {
        axios.get(e.target.dataset.listLink).then(response => {
          this.listCache = response.data;
          setData();
        });
      }
    } else {
      button.innerHTML = 'View previous versions';
      this.listTarget.innerHTML = '';
      this.upd(e.target);
      button.disabled = false;
    }
  }

  setBill(e) {
    e.preventDefault();
    this.upd(e.target);
  }

  upd(target) {
    const container = document.getElementById('current_bill');
    const cancellationRequestBtnBlock = document.getElementById('cancellation_request_buttons');
    const link = target.dataset.link;
    const cacheNumber = target.dataset.cacheNumber;
    const el = this.element;

    target.classList.add('loading_version');
    container.classList.add('loading');

    const removeClassFor = (x) => el.querySelectorAll(`button.${x}`).forEach(el => el.classList.remove(x));

    const updateBill = (html) => {
      container.classList.remove('loading');
      container.innerHTML = html;

      removeClassFor('active_version');
      removeClassFor('loading_version');

      target.classList.add('active_version');

      const amendButton = document.querySelector('#amend_booking_button');
      const activeAmendmentContainer = document.querySelector('#modification.be_boxed.vert-offset-bottom-20');
      const addSessionsBlock = document.querySelector('#add_sessions_block');

      [amendButton, activeAmendmentContainer, addSessionsBlock].forEach((el) => {
        if (el) {
          el.style.display = this.show ? 'none' : 'block';
        }
      })
    }

    const hideCancellationRequestButtons = () => {
      if (cacheNumber) {
        cancellationRequestBtnBlock.classList.add('hidden');
      } else {
        cancellationRequestBtnBlock.classList.remove('hidden');
      }
    }

    if (this.billsCache.hasOwnProperty(cacheNumber)) {
      updateBill(this.billsCache[cacheNumber]);
    } else {
      axios.get(link).then(response => {
        this.billsCache[cacheNumber] = response.data;
        updateBill(response.data);
      });
    }

    if (cancellationRequestBtnBlock) hideCancellationRequestButtons();
  }
}

