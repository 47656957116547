import axios from 'axios';
import { haversineDistance } from "../Map/distance";
import { searchAddressAutocomplete } from '../Map/searchAddressAutocomplete';

class Search {
  start() {
    if ($('.search-wrapper').length === 0) return;

    this.onHideLinkToggle()
    this.applyFiltersOn('.autosubmit-checkbox-filter');
    this.applyFiltersOn('.autosubmit-dropdown-filter');

    $('#submit-search').click((event) => {
      event.preventDefault();
      $('#lat').val('');
      $('#lng').val('');
      this.applyFilters('submit');
    });

    this.initFeatures();
    this.initDistanceSlider();
    this.initPricesSlider();
    this.updateShowFeatures();

    $('.facility-category-dropdown-filter').on('change', (event) => {
      const val = event.target.value;
      const list = $('ul.facility_features');
      list.empty();
      this.applyFilters();
      axios.get(`/facility_categories/${val}`).then(response => {
        list.html(response.data)
        this.updateShowFeatures();
        this.updateFeaturesTitle();
      });
    })

    $('#search-datepicker-2').datepicker({
      orientation: "bottom auto",
      maxViewMode: "days",
      format: 'dd/mm/yyyy',
      weekStart: 1,
      autoclose: true
    }).on('changeDate', () => this.applyFilters());

    this.autocomplete = searchAddressAutocomplete($('.search-address-autocomplete')[0], (place) => {
      if (place) {
        const { location } = place.geometry;
        $('#lat').val(location.lat());
        $('#lng').val(location.lng());
        window.map.map().setCenter(place.geometry.location);
        this.applyFilters();
      } else {
        this.applyFilters();
      }
    });
  }

  applyFilters(typeRequest) {
    const $form = $("form[name='search-form']");
    const url = $form.attr('action');

    let data = $form.serialize();
    if (typeRequest === 'submit') data = data + '&update_center=true'

    $.get({ url: url, data: data, success: (content) => {
      this.updateList(content);
      if(typeRequest !== 'map') this.updatePath();
    } });
  }

  updateList(content, scrollToTop = false) {
    $('#facilities-list').html(content);
    $('.smallGallery').lightSlider({ item: 1, slideMargin: 0, loop: true, pager: false });
    $('.facilities-block .facility-icon-item a[data-toggle="tooltip"]').tooltip({
      animated: 'fade',
      placement: 'bottom',
      trigger: 'hover'
    });
    this.updateDistance();
    if (scrollToTop) $('.search-list').animate({ scrollTop: 0 });

    $('#facilities-list ul.pagination a').on('click', (event) => {
      event.preventDefault();

      $.ajax({
        url: $(event.target).attr('href'),
        method: 'GET',
        dataType: 'script',
        async: true,
        complete: (response) => { this.updateList(response.responseText, true) }
      });
    });
  }

  updateDistance() {
    const center = window.map.map().getCenter();
    const globalLng = center.lng();
    const globalLat = center.lat();

    $.each($('#facilities-list .room'), (index, room) => {
      const distance = $(room).find('.distance');
      const longitude = parseFloat(distance.data('longitude'));
      const latitude = parseFloat(distance.data('latitude'));
      const d = haversineDistance({ lng: longitude, lat: latitude}, { lng: globalLng, lat: globalLat });
      $(distance).html(`${d} m`);
    })
  }

  applyFiltersOn($element) {
    $(document).on('change', $element, (event) => {
      this.applyFilters($(event.currentTarget));
    });
  }

  updatePath(unknownArea) {
    if (!unknownArea) {
      if ($('#categories').val() === '') return Helper.flash_message('error', "Please select a facility");
      if ($.trim($('#area').val()) === '') return Helper.flash_message('error', "Please enter a location");
    }

    const searchParams = new URLSearchParams();
    searchParams.set("categories", $('#categories').val());
    searchParams.set("area", $('#area').val());
    searchParams.set("date", $('#search-datepicker-2').val() || '');

    window.history.replaceState('', '', window.location.pathname + '?' +  searchParams.toString());
  }

  onHideLinkToggle() {
    $('.hide-link').click((event) => {
      const $this = $(event.currentTarget);
      const text = ($this.text() === '+ Maximize the filter') ? '- Hide the filter' : '+ Maximize the filter';

      $('.hide-filter-block').toggle();
      $this.text(text);
    });
  }

  initFeatures() {
    $('.features-toggle').on('click', () => {
      const featuresGroup = $('.features-group');
      const isOpen = featuresGroup.hasClass('open');
      featuresGroup.toggleClass('open', !isOpen);
    });

    $(document).on('click', '.feature-checkbox', this.updateFeaturesTitle);

    $(document).on('click', (event) => {
      if (!$(event.target).closest('.features-group').length) {
        $('.features-group').removeClass('open');
      }
    });
  }

  updateFeaturesTitle() {
    const checkedCount = $('.features-group').find("input[type='checkbox']:checked").length;
    const text = (checkedCount !== 0) ? `${ checkedCount } selected` : 'Facilities Features';

    $('.features-dropdown-title').text(text);
  }

  initDistanceSlider() {
    $('#sl2').slider({ tooltip: 'hide' });

    $('#sl2').on('slide', (slideEvent) => $('#sl2-minVal').text(slideEvent.value));
    $('#sl2').on('slideStop', (slideEvent) => {
      $('#distance').val(slideEvent.value);
      this.applyFilters();
    });

    // for mobile
    $('.distance-control').on('change', (e) => {
      if(e.target.value <= 50) {
        $('#distance').val(e.target.value)
        this.applyFilters();
      } else {
        $('.distance-control').focus()
        Helper.flash_message('error', 'Distance required between 5-50 miles')
      }
    })
  }

  initPricesSlider() {
    $('#sl3').slider({ tooltip: 'hide' });
    $('#sl3').on('slide', (slideEvent) => {
      $('#sl3-minVal').text(slideEvent.value[0]);
      $('#sl3-maxVal').text(slideEvent.value[1]);
    });

    $('#sl3').on('slideStop', (slideEvent) => {
      $('#min_price').val(slideEvent.value[0]);
      $('#max_price').val(slideEvent.value[1]);

      this.applyFilters();
    });

    // for mobile
    $('.price-control-min').on('change', (e) => {
      if (e.target.value <= $('.price-control-max').val()) {
        $('#min_price').val(e.target.value);
        this.applyFilters();
      } else {
        $('.price-control-min').focus()
        Helper.flash_message('error', 'Please provide correct value')
      }
    })

    $('.price-control-max').on('change', (e) => {
      if ($('.price-control-min').val() <= e.target.value) {
        $('#max_price').val(e.target.value);
        this.applyFilters();
      } else {
        $('.price-control-max').focus()
        Helper.flash_message('error', 'Please provide correct value')
      }
    })
  }

  updateShowFeatures() {
    $('.features-group').toggle($('ul.facility_features li').length > 0);
  }

  initMap() {

  }

  updateMap() {

  }
}

export default Search;



