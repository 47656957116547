import { Controller } from "@hotwired/stimulus"
import axios from 'axios';
import Neo from '../src/Neo';


class ConversationRemoveThreadController extends Controller {
  remove(e) {
    e.preventDefault();

    const button = e.target;
    const { url, message } = e.target.dataset;

    if (button.disabled) return true;
    button.disabled = true;

    if (confirm(message) === true) {
      axios.delete(url).then((response) => {
        const parentElement = e.target.parentElement;
        const selectedInbox = document.querySelector('.venues-list li.selected a');
        if (parentElement) parentElement.remove();
        if (selectedInbox) selectedInbox.click();
      }).catch((error) => {
        console.log(error);
        button.disabled = false;
        Neo.processError(error, () => {
          Helper.flash_message('error', e.response.message)
        })
      });
    } else {
      button.disabled = false;
    }
  }
}

export default ConversationRemoveThreadController;
