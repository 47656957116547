import { Controller } from "@hotwired/stimulus"

class CognitaVenueFieldsController extends Controller {
  static targets = ['checkbox', 'fieldsBlock'];

  connect() {
    this.checkboxTarget.addEventListener('change', (e) => this.toggleFieldsVisibility(e.target.checked));
    this.toggleFieldsVisibility(this.checkboxTarget.checked)
  }

  toggleFieldsVisibility(isVisible) {
    const block = this.fieldsBlockTarget;

    if (isVisible) {
      block.classList.remove("hidden");
    } else {
      block.classList.add("hidden");
    }
  }
}

export default CognitaVenueFieldsController;