import React from "react";

export default ({ value, onChange}) => (
  <div className="form-group string optional booking_price_adjustment_reason">
    <label className="control-label string optional">Reason</label>
    <input className="form-control string optional facility_id"
           name="booking[price_adjustment_reason]"
           style={{ width: '213' }}
           type="text"
           value={value}
           onChange={onChange}
    />
  </div>
)