import Facilities from "../admin/migrated/facilities";

let openLinkModal = false;
let renderAfterFormSubmit = false;
let saveFormData = false;

$(document).on('click', 'a.open_link_in_modal', (e) => {
  e.preventDefault();
  
  if (openLinkModal) return;

  openLinkModal = true

  if (e.target.dataset.saveFormData == 'true') {
    saveFormData = true
  }

  
  if (e.target.dataset.renderAfterFormSubmit !== undefined) {
    renderAfterFormSubmit = JSON.parse(e.target.dataset.renderAfterFormSubmit);
  }
  
  const target = $(e.currentTarget);
  const link = target.attr('href');
  const modal = $('#modal_form');
  const dataAttrs = ['data-keyboard', 'data-backdrop'];
  const content =  modal.find('.modal-content');

  dataAttrs.map((attr) => modal.attr(attr, target.attr(attr)));
  target.addClass('in-progress');
  content.empty();

  function enableButton() {
    setTimeout(() => {
      target.removeClass('in-progress');
      openLinkModal = false
    }, 200);
  }

  $.get(link)
    .done((data) => {
      content.empty().append(data)
      modal.modal('show');
      enableButton();
    })
    .fail(() => {
      Helper.flash_message('error', 'Something went wrong')
      enableButton();
    });
});

$(document).on('click', '#modal_form form [type=submit]', (e) => {
  const target = $(e.currentTarget);

  if (target.closest('form').data('skip-modal')) return;

  const $form = target.closest('form');
  if (!$form.attr('novalidate') && $form.find('input[required][type="text"]').filter(() => !target.val()).length > 0) return;

  e.preventDefault();

  if (target.prop('disabled')) return;

  target.prop('disabled', true)

  const $modal = $('#modal_form')

  function processError(data) {
    target.prop('disabled', false)

    if (data.status === 422) {
      $modal.find('.modal-content').empty().append(data.responseText);
    }
  }

  function processSuccess(data) {
    new FormUtils($form).clearLocalStorage($($form)[0].id);

    if ((data || {}).message) { Helper.flash_message('success', data.message) }
    setTimeout(() => target.prop('disabled', false), 200);

    if ($modal.find('form [type=submit]').data('reload') === 'facilities') {
      new Facilities().reloadData(false);
    }

    if ($modal.find('form [type=submit]').data('reload') === 'modal_confirmation') {
      $modal.find('.modal-content').empty().append(data);
    } else {
      $modal.modal('hide');

      if (renderAfterFormSubmit === true) {
        $form.submit();
      }
    }
  }

  const url = $form.attr('action');
  const enctype = $form.attr('enctype');
  if (enctype === 'multipart/form-data') {
    $.ajax({
      type: "POST",
      url: url,
      async: false,
      cache: false,
      contentType: false,
      processData: false,
      data: new FormData($($form)[0]),
      success: processSuccess,
      error: processError
    });
  } else {
    if (saveFormData) {
      new FormUtils().saveToLocalStorage($($form)[0]);
    }

    $.ajax({
      type: "POST",
      url: url,
      data: $form.serialize(),
      success: processSuccess,
      error: processError
    });
  }
});
