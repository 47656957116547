import { Controller } from '@hotwired/stimulus'

class CustomerVenueLinkingsController extends Controller {
  static targets = [ 'venueSelectorHint' ]

  submitForm(event) {
    event.preventDefault()
    const checkboxesContainer = this.element.getElementsByClassName('venue-checkbox-container')
    let checkedCheckbox = Array.from(checkboxesContainer).find(element => {
      return element.getElementsByTagName('input')[0].checked
    })

    if (checkedCheckbox) {
      document.getElementById('link_venues_to_customer').submit()
    } else {
      this.venueSelectorHintTarget.classList.remove('hidden')
    }
  }
}

export default CustomerVenueLinkingsController;
