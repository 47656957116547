import React, { useState, useEffect, useMemo } from "react";
import TimeSelector from "../../Form/TimeSelector";
import axios from 'axios';
import moment from "moment-timezone";

export default ({ staffItem, selectedItemIds, errors }) => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (staffItem) {
      axios
        .get('/admin/staff_rota/users.json', { params: { venue_id: staffItem.venueId } })
        .then((response) => setUsers(response.data))
    }

    return () => console.log('unmount')
  }, [staffItem]);

  const staffItemsErrors = useMemo(() => {
    let e = {};
    Object.keys(errors).map((key, index) => {
      let a = key.match(/staff_item\[(\d+)\]\.(.+)/)
      if (a) {
        let id = parseInt(a[1]), name = a[2];
        if (e[id] === undefined) e[id] = {};
        if (e[id][name] === undefined) e[id][name] = [];

        e[id][name].push(errors[key]);
      }
    });

    return e;
  }, [errors]);

  if (users.length === 0) return null;

  const index = 0;

  return (
    <form id={`edit-multi-staff-items`} className='staff-rota-form'>
      <div className='bookings-time-list'>
        <div className='row'>
          <input type='hidden' name='staff_item_id' value={selectedItemIds[0]}/>
          {selectedItemIds && selectedItemIds.map(id => <input key={id} type='hidden' name='id[]' value={id}/>)}
          <div className="col-md-12 col-sm-12">
            <div className="form-group select optional">
              <label className="control-label select optional">
                Name
              </label>
              <select className="form-control select optional"
                      name={`staff_item[${index}][user_id]`}
                      defaultValue={staffItem.userId}>
                {users.map(user => <option key={user.id} value={user.id}>{user.name}</option>)}
              </select>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className="col-md-12">
            <label className="control-label select optional">Start</label>
            <TimeSelector
              name='start'
              index={0}
              defaultTimeOption={staffItem.start.timeOption}
              defaultValue={staffItem.start.time ? moment(staffItem.start.time) : moment("17:00", 'H:m')}
              defaultMinutes={staffItem.start.minutes ? staffItem.start.minutes : 30 }
              defaultDirection={staffItem.start.direction ? staffItem.start.direction : 'before'}
              defaultSession={staffItem.start.session ? staffItem.start.session : 'first'}
              defaultIncludeInternal={staffItem.start.includeInternal !== null ? staffItem.start.includeInternal : false}
            />
          </div>
        </div>

        <div className='row'>
          <div className="col-md-12">
            <label className="control-label select optional">End</label>
            <TimeSelector
              name='end'
              index={0}
              defaultTimeOption={staffItem.end.timeOption}
              defaultValue={staffItem.end.time ? moment(staffItem.end.time) : moment("22:00", 'H:m')}
              defaultMinutes={staffItem.end.minutes ? staffItem.end.minutes : 15}
              defaultDirection={staffItem.end.direction ? staffItem.end.direction : 'after'}
              defaultSession={staffItem.end.session ? staffItem.end.session : 'last'}
              defaultIncludeInternal={staffItem.end.includeInternal !== null ? staffItem.end.includeInternal : false}
            />
          </div>
        </div>
      </div>

      {Object.keys(staffItemsErrors).length > 0 &&
      <div key={index} className="form-group has-error">
        {Object.keys(staffItemsErrors).map((key, index) => {
          let m = staffItemsErrors[key];

          return (
            <div key={key}>
              <strong>Shift ID: {key}</strong>
              {Object.keys(m).map((k, index) =>
                <div key={index} className='help-block'>
                  {m[k].join(', ')}
                </div>
              )}
            </div>
          )
        })}
      </div>
      }
    </form>
  )
}