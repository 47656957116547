import { Controller } from "@hotwired/stimulus"
import axios from "axios";

class OrderCancellationRequestController extends Controller {
  static targets = ['refundPercent', 'customRefundPercent', 'reasonBlock'];

  connect() {
    const customRefundElement = this.customRefundPercentTargets[0];

    if (customRefundElement) {
      const formGroup = customRefundElement.closest('.form-group');

      this.refundPercentTarget.addEventListener('change', function(e) {
        if (e.target.value === 'custom') {
          customRefundElement.value = '';
          $(formGroup).show();
        } else {
          $(formGroup).hide();
          customRefundElement.value = e.target.value;
        }
      });

      this.onChange();
    };
  }

  onChange() {
    const url = this.element.dataset.refundUrl;
    const refundableTextEl = this.element.querySelector('#refundable_text');
    const selectedRefundPercent = this.refundPercentTarget.value;
    const customRefundPercent = (this.customRefundPercentTarget.value || 0);
    const refundPercent = selectedRefundPercent === 'custom' ? customRefundPercent : selectedRefundPercent

    if (selectedRefundPercent === '100') {
      this.reasonBlockTarget.classList.add('hidden');
      this.reasonBlockTarget.querySelector('textarea').value = '';
    } else {
      this.reasonBlockTarget.classList.remove('hidden');
    }

    axios
      .get(url, { params: { refund_percent: refundPercent }})
      .then((response) => {
        refundableTextEl.innerHTML = response.data;
      });
  }
}

export default OrderCancellationRequestController;
