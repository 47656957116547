import SmartyForm from "../../helpers/smarty_form";
import { processingModal } from "../../helpers/processing_modal";

(function() {
  $(document).on('click', 'form#new_document [type=submit]', function(e) {
    e.preventDefault();
    if (missingFacilities()) return false;
    if (missingVenues()) return false;

    sendForm($(this), 'POST');
  });

  $(document).on('click', '.edit_document [type=submit]', function(e) {
    e.preventDefault();
    if (missingFacilities()) return false;
    if (missingVenues()) return false;

    sendForm($(this), 'PUT');
  });

  function missingFacilities() {
    if ($("#document_all_facilities_0:checked").length > 0) {
      if ($("#document_facilities input").length > 0) {
        if ($("#document_facilities input:checked").length == 0) {
          alert("At least 1 facility must be selected");
          return true;
        }
      }
    }

    return false
  }

  function missingVenues() {
    if ($("#document_venues input").length > 0) {
      if ($("#document_venues input:checked").length == 0) {
        alert("At least 1 venue must be selected");
        return true;
      }
    }

    return false
  }

  function sendForm(element, method) {
    const $form = element.closest('form');
    const url = $form.attr('action');
    const formData = new FormData($($form)[0]);
    const confirm_button = $(".btn-primary", $form);

    confirm_button.prop('disabled', true);
    processingModal.show();

    const smartyForm = new SmartyForm();

    $.ajax({
      type: method,
      url: url,
      data: formData,
      dataType: 'json',
      cache : false,
      processData: false,
      contentType: false,
      success: () => {
        processingModal.hide();
        confirm_button.prop('disabled', false);
        $('#modal_form').modal('hide');
        reloadDocuments();
      },
      error: (response) => {
        processingModal.hide();
        confirm_button.prop('disabled', false);
        smartyForm.removeErrors($form);
        smartyForm.addErrors($form, 'document', (response.responseJSON || {}).errors);
      }
    });
  }

  window.initSortableDocuments = function() {
    $('.sortable-documents').sortable({
      items: "> tr.allow-sort",
      stop: function(e, u) {
        collectDocumentOrders();
      }
    });
  }

  initSortableDocuments();

  function collectDocumentOrders() {
    const document_records = $('.sortable-documents .ui-sortable-handle');
    const ids = $.map(document_records, (record, _) => $(record).data('id'));

    $.ajax({
      method: 'PUT',
      url: '/admin/documents/reorder',
      data: { ids: ids },
      success: function(result) {
        $('.success').fadeIn('slow');
        $('.success').fadeOut('slow');
      },
      fail: function(result) {
        $('.error').fadeIn('slow');
        $('.error').fadeOut('slow');
      }
    });
  }

  function reloadDocuments() {
    const a = $('.area_nav li.active a');
    const areaNav = a.closest('.area_nav');
    const href = a.attr('href');
    const name = href.replace('#tab-','');
    const tabUrl = `${areaNav.data('url')}/tabs/${name}`;

    $.get(tabUrl, function(data) {
      $(`#tab-${name}`).html(data);
      initSortableDocuments();
    });
  }

  $(document).ready(function() {
    $('.area_nav').on('selected_tab_documents', function() {
      initSortableDocuments();
    });
  })
}).call(this);