import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['optionType', 'days'];

  connect() {
    super.connect();
    this.optionType = this.optionTypeTarget.value;
    this.toggleDays();
  }

  selectOption(e) {
    const radioButton = e.target;
    this.optionTypeTarget.value = radioButton.dataset.optionType;
    this.optionType = radioButton.dataset.optionType;
    this.toggleDays();
  }

  toggleDays() {
    if (this.optionType === 'overall_split_by_days') {
      this.daysTarget.classList.add('hidden')
    } else {
      this.daysTarget.classList.remove('hidden')
    }
  }
}
