import React, {useState} from 'react'

export default ({question, suffix}) => {
  const [enabled, setEnabled] = useState(question.allFacilities);

  const toggleFacilities = (e, type) => {
    e.preventDefault();

    const checked = type === 'all';
    const checkboxes = $('.modal-body').find(`.type-facility`);

    $.each(checkboxes, (index, el) => $(el).prop('checked', checked).trigger('change'));
  }

  return (
    <div className="form-group">
      <label className="m-t">Applicable Facilities</label>
      <div className="row">
        <div className="col-md-12 no-space-form-group space-radio-buttons">
          <div className="form-group radio_buttons optional question_all_facilities">
            <input type="hidden" name={`${suffix}[all_facilities]`} value={question.allFacilities}/>
              <span className="radio inline">
                <label htmlFor={`${suffix}_all_facilities_1`}>
                  <input className="radio_buttons optional" type="radio" value="1"
                         defaultChecked={question.allFacilities}
                         onChange={(e) => setEnabled(e.target.checked)}
                         name={`${suffix}[all_facilities]`} id={`${suffix}_all_facilities_1`}/>
                  All facilities
                </label>
              </span>
              <span className="radio inline">
                <label htmlFor={`${suffix}_all_facilities_0`}>
                  <input className="radio_buttons optional" type="radio" value="0"
                         defaultChecked={!question.allFacilities}
                         onChange={(e) => setEnabled(!e.target.checked)}
                         name={`${suffix}[all_facilities]`} id={`${suffix}_all_facilities_0`}/>
                  Select facilities
                </label>
              </span>
          </div>
        </div>

        {!enabled &&
          <div>
            <div className="col-md-12" style={{paddingTop: "10px"}}>
              <a className="multiselect facility-btn" href="#" onClick={(e) => toggleFacilities(e, 'all')}>All</a>
              &nbsp;&nbsp;&nbsp;
              <a className="multiselect facility-btn" href="#" onClick={(e) => toggleFacilities(e, 'none')}>None</a>
            </div>
            <div id="question_facilities" className="col-md-12 " style={{columns: "2"}}>
              <input name={`${suffix}[facility_ids][]`} type="hidden"/>

              {question.questionFacilities.map(facility =>
                <div className="form-group checkbox" key={`${suffix}_facility_ids_`+facility.id}>
                  <label htmlFor={`${suffix}_facility_ids_`+facility.id}>
                    <input
                      className="boolean optional type-facility"
                      type="checkbox"
                      value={facility.id}
                      defaultChecked={question.questionFacilityIds.includes(facility.id)}
                      name={`${suffix}[facility_ids][]`}
                      id={`${suffix}_facility_ids_`+facility.id}
                    />
                    {facility.internalReference}
                  </label>
                </div>
              )
              }
            </div>
          </div>
        }

      </div>
    </div>
  )
};
