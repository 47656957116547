import React from 'react'
import ErrorMessage from "./fields/ErrorMessage";
import AllowOptionsTrigger from "./fields/AllowOptionsTrigger";
import QuestionItemTrigger from "./fields/QuestionItemTrigger";

export default ({ question,
                  questionItems,
                  onChangeQuestionItemTrigger,
                  allowOptionTriggerList,
                  onChangeAllowOptionTrigger,
                  enabledAllowOptions,
                  enabledAllowQuestionItems,
                  errors }) => (
  <div className='mb-4'>
    <label>Please select the answer(s) that will trigger the sub-question:</label>

    {enabledAllowQuestionItems &&
      <QuestionItemTrigger questionItems={questionItems} onChange={onChangeQuestionItemTrigger}/>
    }

    {!enabledAllowQuestionItems &&
      <AllowOptionsTrigger
        onChange={onChangeAllowOptionTrigger}
        selectedOptions={allowOptionTriggerList}
        options={question[enabledAllowOptions ? 'allowOptionsTriggerQuestions' : 'allowYesNoOptionsTriggerQuestions']}
      />
    }

    <ErrorMessage errors={((errors || {})['option_trigger_sub_questions'] || [])}/>
  </div>
)
