import axios from "axios";

class Api {
  getNew = () => {
    return axios.get('/admin/settings/notice_messages/new.json')
  }

  getLast = () => {
    return axios.get('/admin/settings/notice_messages/last.json')
  }

  updateLast = (data) => {
    return axios.put('/admin/settings/notice_messages/update_last.json', data)
  }
}

const api = new Api();
export default api;