import { Controller } from "@hotwired/stimulus"
import axios from 'axios';
import NeoModal from "src/NeoModal";

export default class extends Controller {
  new(e) {
    const url = e.target.dataset.url;

    const button = e.target;
    if (button.disabled) return true;
    button.disabled = true;

    axios.get(url).then(response => {
      const modal = NeoModal.add(response.data, { callback: () => {
        setTimeout(() => { button.disabled = false }, 200)
      }});


      modal.addEventListener('neo_modal:payment_success', (e) => {
        const { data } = e.detail;

        $(modal).modal('hide');
        Helper.flash_message('success', data.message)
      })

      modal.addEventListener('neo_modal:payment_failed', (e) => {
        const { data } = e.detail;
      })

      modal.addEventListener('neo_modal:failed', (e) => {
        const errors = e.detail.data.errors;
        //const baseError = (errors || {}).base;
        this.addErrors(errors);
      })

      modal.addEventListener('neo_modal:submit', (e) => {
        this.removeErrors();
      })
    }).catch(() => {
      button.disabled = false;
      Helper.flash_message('error', 'Something went wrong')
    })
  }

  removeErrors() {
    $('#new_casual_payment').find('span.text-danger').remove();
  }

  addErrors(errors) {
    this.removeErrors();

    _.each(errors, (value, key) => {
      const span = `<span class="text-danger">${value.join(', ')}</span>`;
      $('#casual_payment_' + key).closest('.form-group').append(span)
    });
  }
}
