import { Controller } from '@hotwired/stimulus'
import axios from 'axios';

class CustomerCustomOptionsController extends Controller {
  static targets = ['customOptionForm'];

  connect() {
  }

  addForm(event) {
    const url = event.target.dataset.url;

    axios.get(url).then((response) => {
      this.customOptionFormTarget.innerHTML = response.data;
    });
  }

  addExtrasList(event) {
    this.disableSubmitButton(true);

    const $parent = this.targets.element;
    const facilityId = event.target.value;
    const existedRecords = $parent.querySelectorAll('.custom_extra');
    const exceptedIds = [...existedRecords].map((row) => {
      return 'excepted_ids[]=' + row.dataset.customExtraId;
    });
    const url = '/admin/facilities/' + facilityId + '/extras/custom_list?' + exceptedIds.join('&');

    axios.get(url).then(function(response) {
      const extraList = $parent.querySelectorAll('.extra-list')[0];

      if (!extraList) return;

      extraList.innerHTML = response.data;
    });
  }

  addBookingOptionsList(event) {
    this.disableSubmitButton(true);

    const $parent = this.targets.element;
    const facilityId = event.target.value;
    const existedRecords = $parent.querySelectorAll('.custom_booking_option');
    const submitButton = this.customOptionFormTarget.querySelectorAll('input[type=submit]')[0];
    const exceptedIds = [...existedRecords].map((row) => {
      return 'excepted_ids[]=' + row.dataset.bookingOptionId;
    });
    const url = '/admin/facilities/' + facilityId + '/booking_options/custom_list?' + exceptedIds.join('&');

    axios.get(url).then(function(response) {
      const bookingOptionList = $parent.querySelectorAll('.booking-option-select-block')[0];

      if (!bookingOptionList) return;

      bookingOptionList.innerHTML = response.data;
    });
  }

  onChangeCustomOption(event) {
    const value = event.target.value;

    this.disableSubmitButton(value.length === 0);
  }

  disableSubmitButton(disabled) {
    const button = this.customOptionFormTarget.querySelectorAll('input[type=submit]')[0];

    if (button) button.disabled = disabled;
  }

  submitForm(event) {
    event.preventDefault();

    const resultClassName = event.target.dataset.resultClassName;
    const $resultBlock = this.targets.element.querySelectorAll(resultClassName)[0];
    const form = document.createElement('form');
    const fields = this.customOptionFormTarget.children;

    [...fields].map((field) => form.appendChild(field));

    axios({
      method: 'post',
      url: event.target.dataset.url,
      data: $(form).serializeObject()
    }).then((response) => {
      if ($resultBlock) $($resultBlock).append(response.data);
    });
  }
}

export default CustomerCustomOptionsController;
