class DatatableAutoscroll {
  constructor(oSettings) {
    const $tableWrapper = $(oSettings.nTableWrapper)

    $tableWrapper.on('page.dt', () => {
      $('html, body').animate({
        scrollTop: $tableWrapper.offset().top
      }, 250);

      $('thead tr th:first-child').focus().blur();
    });
  }
}

export default DatatableAutoscroll;